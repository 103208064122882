import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import React, {useCallback, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Label} from '../../components/inputs/Input';
import {Select} from '../../components/Select';
import {Section} from '../../ui/Section';
import {PrimaryButton} from '../../widgets/Button';
import {Input} from '../../widgets/Input';
import {LightboxCard} from '../../widgets/LightboxCard';
import {TextLayout} from '../../widgets/Text';
import {PriceRowModel} from '../backend/models/PriceModel';
import {TrainingModel} from '../backend/models/TrainingModel';
import {useBackend} from '../backend/useBackend';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';

dayjs.extend(duration);
dayjs.extend(relativeTime, {rounding: Math.ceil});
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

const HistoryRow = styled(Section)<{isFirst: boolean}>`
    background-color: ${props => (props.isFirst ? props.theme.subBackground : undefined)};
    padding: ${props => (props.isFirst ? '10px 15px' : '0 15px')};
    border-radius: 10px;
`;

const ErrorText = styled.div`
    color: ${props => props.theme.error};
`;
export const PriceLightbox: React.FC<{isOpen: boolean; training: TrainingModel; onChange: (value: TrainingModel) => void}> = ({isOpen, training, onChange}) => {
    const theme = useTheme();
    const backend = useBackend();
    const [newPrice, setNewPrice] = useState<PriceRowModel>({
        price: 0,
        pricePerWeek: 0,
        pricePerMonth: 0,
        pricePerYear: 0,
        intervalWeek: 1,
        intervalMonth: 1,
        intervalYear: 1,
        currency: 'EUR',
        freePlays: 3,
        objectId: training.id,
        objectType: 'TRAINING',
        current: true,
        payments: 0,
        createdAt: Date.now().toString(),
    });
    const [errorMessage, setErrorMessage] = useState('');
    const hasUnusedPrice = useMemo(() => {
        return training.prices === null || (training.prices!.length >= 1 && training.prices![0].payments === 0);
    }, [training.prices]);
    const handleCurrency = useCallback((e: { target: { value: any; }; }) => {
        setNewPrice(p => ({...p, currency: e.target.value}));
    }, []);
    const handleCreateTrainingPrice = useCallback(async () => {
        setErrorMessage('');
        try {
            await backend.createTrainingPrice(newPrice);
            // onChange(result);
        } catch (err) {
            setErrorMessage((err as Error).message);
        }
    }, [backend, newPrice]);
    const handleRemovePrice = useCallback(async () => {
        setErrorMessage('');
        try {
            if (training.prices) {
                await backend.deleteTrainingPrice(training.prices[0].id!);
            }
            onChange({...training, prices: []});
        } catch (err) {
            setErrorMessage((err as Error).message);
        }
    }, [backend, onChange, training]);
    return (
        <LightboxCard padding="20px" align="center" spacing={10} width="500px" height="90%" scroll={true} maxWidth="90%" direction="y" isOpen={isOpen}>
            <TextLayout size={1.5} weight="bold">
                <FormattedMessage id="app.price.title" />
            </TextLayout>
            <TextLayout color={theme.subForeground}>
                <FormattedMessage id="app.price.description" />
            </TextLayout>
            <Section direction="y" spacing={20} scroll={true} grow={1} width="100%">
                <Section spacing={10} align="start" width="100%">
                    <Section direction="y">
                        <Label>
                            <FormattedMessage id="app.price.currency" />
                        </Label>
                        <Select value={newPrice.currency} onChange={handleCurrency}>
                            <option value="USD">USD</option>
                            <option value="EUR">EUR</option>
                        </Select>
                    </Section>
                    <Section direction="y">
                        <Label>
                            <FormattedMessage id="app.price.freePlays" />
                        </Label>
                        <Input type="number" value={newPrice.freePlays} onChange={e => setNewPrice(p => ({...p, freePlays: parseInt(e.target.value)}))} />
                    </Section>
                </Section>
                <Section direction="y" align="start" width="100%" spacing={10}>
                    <Label>
                        <FormattedMessage id="app.price.prices" />
                    </Label>
                    <Section align="center" spacing={10}>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.price}
                            onChange={e => setNewPrice(p => ({...p, price: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            <FormattedMessage id="app.price.once" />
                        </TextLayout>
                    </Section>
                    <Section align="center" spacing={10}>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.pricePerWeek}
                            onChange={e => setNewPrice(p => ({...p, pricePerWeek: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            {newPrice.currency === 'EUR' ? '€' : '$'} <FormattedMessage id="app.price.every" />
                        </TextLayout>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.intervalWeek}
                            onChange={e => setNewPrice(p => ({...p, intervalWeek: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            <FormattedMessage id="app.price.week" />
                        </TextLayout>
                    </Section>
                    <Section align="center" spacing={10}>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.pricePerMonth}
                            onChange={e => setNewPrice(p => ({...p, pricePerMonth: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            {newPrice.currency === 'EUR' ? '€' : '$'} <FormattedMessage id="app.price.every" />
                        </TextLayout>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.intervalMonth}
                            onChange={e => setNewPrice(p => ({...p, intervalMonth: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            <FormattedMessage id="app.price.month" />
                        </TextLayout>
                    </Section>
                    <Section align="center" spacing={10}>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.pricePerYear}
                            onChange={e => setNewPrice(p => ({...p, pricePerYear: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            {newPrice.currency === 'EUR' ? '€' : '$'} <FormattedMessage id="app.price.every" />
                        </TextLayout>
                        <Input
                            type="number"
                            style={{width: '50px'}}
                            value={newPrice.intervalYear}
                            onChange={e => setNewPrice(p => ({...p, intervalYear: parseInt(e.target.value)}))}
                        />
                        <TextLayout>
                            <FormattedMessage id="app.price.year" />
                        </TextLayout>
                    </Section>
                </Section>
                <Section align="start" spacing={10}>
                    <PrimaryButton onClick={ () => {
                        handleCreateTrainingPrice();
                        amplitude.getInstance().logEvent('create_training_payment', {trainingId: training.id, newPrice: newPrice, page: 'edit_training'});
                    }}>
                        <FormattedMessage id={`app.price.${hasUnusedPrice ? 'replacePrice' : 'createTrainingPrice'}`} />
                    </PrimaryButton>
                    {hasUnusedPrice ? (
                        <PrimaryButton
                            onClick={() => {
                                handleRemovePrice();
                                amplitude.getInstance().logEvent('remove_training_payment', {trainingId: training.id, page: 'edit_training'});
                            }}
                        >
                            <FormattedMessage id="app.price.removePrice" />
                        </PrimaryButton>
                    ) : null}
                </Section>
                {errorMessage ? (
                    <ErrorText>
                        <FormattedMessage id={`app.error.${errorMessage}`} />
                    </ErrorText>
                ) : null}
                <TextLayout size={1.4} weight="bold">
                    <FormattedMessage id="app.price.history" />
                </TextLayout>
                {training.prices?.length === 0 ? (
                    <TextLayout>
                        <FormattedMessage id="app.price.historyEmpty" />
                    </TextLayout>
                ) : null}
                {training.prices?.map((price, index) => (
                    <HistoryRow direction="y" isFirst={index === 0}>
                        <TextLayout weight="bold">
                            <FormattedMessage id="app.price.added" values={{date: dayjs(price.createdAt).format('L')}} />
                        </TextLayout>
                        {price.price ? (
                            <TextLayout>
                                <FormattedMessage
                                    id="app.price.priceOnce"
                                    values={{
                                        price: price.price,
                                        symbol: price.currency === 'EUR' ? '€' : '$',
                                    }}
                                />
                            </TextLayout>
                        ) : null}
                        {price.pricePerWeek ? (
                            <TextLayout>
                                <FormattedMessage
                                    id="app.price.pricePerWeek"
                                    values={{
                                        price: price.pricePerWeek,
                                        symbol: price.currency === 'EUR' ? '€' : '$',
                                        interval: price.intervalWeek,
                                    }}
                                />
                            </TextLayout>
                        ) : null}
                        {price.pricePerMonth ? (
                            <TextLayout>
                                <FormattedMessage
                                    id="app.price.pricePerMonth"
                                    values={{
                                        price: price.pricePerMonth,
                                        symbol: price.currency === 'EUR' ? '€' : '$',
                                        interval: price.intervalMonth,
                                    }}
                                />
                            </TextLayout>
                        ) : null}
                        {price.pricePerYear ? (
                            <TextLayout>
                                <FormattedMessage
                                    id="app.price.pricePerYear"
                                    values={{
                                        price: price.pricePerYear,
                                        symbol: price.currency === 'EUR' ? '€' : '$',
                                        interval: price.intervalYear,
                                    }}
                                />
                            </TextLayout>
                        ) : null}
                    </HistoryRow>
                ))}
            </Section>
        </LightboxCard>
    );
};
