import React from 'react';
import styled from '@emotion/styled';

import {Input} from './inputs/Input';
import {Section} from '../ui/Section';
import {YoutubeResponsive} from './YoutubeResponsive';

const UrlInput = styled(Input)<{isInvalid: boolean}>`
    font-family: ${props => props.theme.body};
    font-size: 1em;
    color: ${props => props.theme.foreground};
    flex: 1;
    box-sizing: border-box;
`;

interface YoutubeLinkProps {
    value: string;
    autoplay?: boolean;
    onChange: (value: string) => void;
}

export const YoutubeLink: React.FC<YoutubeLinkProps> = ({value, autoplay, onChange}) => {
    const [url, setUrl] = React.useState<string>('');
    const [youtubeId, setYoutubeId] = React.useState<string>(value || '');
    const handleUrlChange = React.useCallback(
        (e: { currentTarget: { value: string; }; }) => {
            const [, id] =
                /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/ytscreeningroom\?v=|\/feeds\/api\/videos\/|\/user\S*[^\w\-\s]|\S*[^\w\-\s]))([\w-]{11})[?=&+%\w-]*/gi.exec(
                    e.currentTarget.value,
                ) || [];
            setYoutubeId(id);
            setUrl(e.currentTarget.value || '');
            onChange(id || '');
        },
        [onChange],
    );
    React.useEffect(() => {
        setYoutubeId(value || '');
        if (youtubeId) {
            setUrl(`https://youtu.be/${youtubeId}`);
        }
    }, [value, youtubeId]);
    return (
        <Section direction="y" spacing={10}>
            <YoutubeResponsive youtubeId={youtubeId} radius="10px" />
            <UrlInput isInvalid={url !== '' && youtubeId === undefined} placeholder="https://youtu.be/video_id" value={url} onChange={handleUrlChange} />
        </Section>
    );
};
