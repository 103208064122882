import styled from '@emotion/styled';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {YoutubeResponsive} from '../../components/YoutubeResponsive';
import {Icon} from '../../ui/Icon';
import {Section} from '../../ui/Section';
import {TextLayout} from '../../widgets/Text';
import {ExerciseModel} from '../backend/models/ExerciseModel';
import {RichTextRenderer} from '../../widgets/RichText/RichTextRender';

interface ExerciseShowProps {
    exercise: ExerciseModel;
    onClose: () => void;
}

const CloseIcon = styled(Icon)`
    align-self: flex-start;
`;

export const ExerciseShow: React.FC<ExerciseShowProps> = ({exercise, onClose}) => {
    return (
        <Section direction="y" width="100%" align="center" padding="0 0 20px 0" scroll={true}>
            {exercise.playYoutubeId ? <YoutubeResponsive youtubeId={exercise.playYoutubeId} mute={true} radius="0" /> : undefined}
            <Section spacing={20} distribute="between" align="start" padding="20px 20px 10px 20px" width="100%">
                <TextLayout align="left" grow={1} size={1.6} weight="bold">
                    {exercise.name}
                </TextLayout>
                <CloseIcon name="close" onClick={onClose} />
            </Section>
            <Section direction="y" width="100%" padding="0 20px">
                {exercise.explanation ? <RichTextRenderer description={exercise.explanation} /> : <FormattedMessage id="app.exercise.noExplanations" />}
            </Section>
        </Section>
    );
};
