import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {Section} from '../../ui/Section';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import {ActivityModel} from '../backend/models/ActivityModel';
import {Button, PrimaryButton} from '../../widgets/Button';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {useTheme} from '@emotion/react';
import amplitude from 'amplitude-js';
import { ResizeInput } from '../../components/inputs/ResizeInput';
import { notify } from '../../components/toast/Toast';


export const CreateActivityDialog: React.FC<{onClose: () => void, trainingId: string}> = ({onClose, trainingId}) => {
    const theme = useTheme();
    const intl = useIntl();
    const navigate = useNavigate();
    const backend = useBackend();
    const handleValidNumber = React.useCallback((value: string) => parseInt(value) >= 1 && parseInt(value) < 1000, []);
    const [activity, setActivity] = React.useState<ActivityModel>({
        id: '',
        user: backend.authUser!,
        sport: undefined,
        title: '',
        duration: 0,
        drop: false,
        trainingId: trainingId,
        manual: true
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const handleEditDuration = React.useCallback((duration: string) => {
        setActivity(a => ({...a, duration: parseInt(duration) * 60}));
    }, []);
    const handleCreate = React.useCallback(async () => {
        setIsLoading(true);
        try {
            await backend.createActivity(activity);
            navigate(`/activity`);
            onClose();
            notify(intl.formatMessage({id: 'app.activity.wellDone'}));
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }, [activity, backend, navigate, intl, onClose]);

    return (
        <Section direction="y" padding="20px" spacing={20} width="100%">
            <TextLayout size={1.5} weight="bold" margin="0 auto" padding="0 40px">
                <FormattedMessage id="app.activity.createTitle" />
            </TextLayout>
            {/* duration */}
            <div style={{marginLeft: '6px'}}>
            <TextLayout weight="bold" size={1.1}>
                <FormattedMessage id="app.activity.duration" />
                    <ResizeInput isBig={true} value={activity.duration.toString()} onValue={handleEditDuration} validate={handleValidNumber} />
                <FormattedMessage id="app.activity.minutes" />
            </TextLayout>
            </div >
            <Section distribute="between">
                <Button onClick={() => {
                        onClose();
                        amplitude.getInstance().logEvent('cancel_create_new_activity', {activityTitle: activity.title, page: 'training'});
                    }}
                >
                    <FormattedMessage id="app.activity.cancel" />
                </Button>
                <PrimaryButton
                    disabled={isLoading || activity.duration === 0}
                    onClick={() => {
                        handleCreate();
                        amplitude.getInstance().logEvent('create_new_activity', {activityTitle: activity.title, page: 'training'});
                    }}
                >
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={18} /> : <FormattedMessage id={'app.activity.create'} />}
                </PrimaryButton>
            </Section>
        </Section>
    );
};
