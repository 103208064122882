import React from 'react';
import {useRef, useEffect, useCallback} from 'react';
import type {EditorState} from 'draft-js';
import type {VideoPlugin} from '@draft-js-plugins/video/lib/createVideoPlugin';
import {FormattedMessage, useIntl} from 'react-intl';
import {useTheme} from '@emotion/react';
import {Input, useInputState} from '../Input';
import {Popover, PopoverState} from '../Popover';
import {ButtonLayout} from './RichTextButton';
import {Icon} from '../../ui/Icon';
import {Button} from '../Button';
import {Menu} from '../Menu';
import {Section} from '../../ui/Section';

interface VideoButtonProps {
    popoverState: PopoverState;
    videoPlugin: VideoPlugin;
    //
    editorState: EditorState;
    onStateChange: (nextState: EditorState) => void;
}

export const VideoButton: React.FC<VideoButtonProps> = ({popoverState, videoPlugin, editorState, onStateChange}) => {
    const intl = useIntl();
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    const [url, setUrl] = useInputState('');
    const {isOpen, setIsOpen, handleToggle} = popoverState;

    const handleEnter = useCallback(
        (e: { code: string; }) => {
            if (e.code === 'Enter') {
                const nextState = videoPlugin.addVideo(editorState, {src: url});
                onStateChange(nextState);
                setIsOpen(false);
                setUrl('');
            }
        },
        [editorState, videoPlugin, onStateChange, setIsOpen, setUrl, url],
    );
    const handleAddImage = useCallback(
        (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            const nextState = videoPlugin.addVideo(editorState, {src: url});
            onStateChange(nextState);
            setIsOpen(false);
            setUrl('');
            return false;
        },
        [editorState, videoPlugin, onStateChange, setIsOpen, setUrl, url],
    );

    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus();
        }
    }, [isOpen]);

    return (
        <Popover
            node={
                <ButtonLayout isActive={isOpen}>
                    <Icon name="youtube" color={isOpen ? theme.background : theme.foreground} />
                </ButtonLayout>
            }
            isOpen={isOpen}
            padding="0 0 0 20px"
            placement="bottom"
            //
            onToggle={handleToggle}
        >
            <Menu direction="y" isDark={true}>
                <Section direction="y" padding="0 10px" spacing={10}>
                    <Input
                        ref={inputRef}
                        value={url}
                        placeholder={intl.formatMessage({id: 'widgets.richText.videoPlaceholder'})}
                        onChange={setUrl}
                        onKeyDown={handleEnter}
                    />
                    <Button onClick={handleAddImage}>
                        <FormattedMessage id="widgets.richText.add" />
                    </Button>
                </Section>
            </Menu>
        </Popover>
    );
};
