import React from 'react';
import styled from '@emotion/styled';

import {Link} from '../../components/Link';
import {Section} from '../../ui/Section';
import {Thumbnail} from '../../widgets/Thumbnail';
import {QuestRowModel} from '../backend/models/QuestModel';
import {FormattedMessage} from 'react-intl';
import amplitude from 'amplitude-js';

const SubTitleSection = styled(Section)`
    color: ${props => props.theme.subForeground};
    flex-wrap: wrap;
    font-family: ${props => props.theme.title};
    padding-bottom: 5px;
`;

const StyledLink = styled(Link)`
    color: ${props => props.theme.subForeground};
    transition: all 0.3s;
    &:hover {
        color: ${props => props.theme.primary};
    }
`;

const AvatarThumbnail = styled(Thumbnail)`
    width: 20px;
    border-radius: 50%;
`;

export const QuestSubtitle: React.FC<{quest: QuestRowModel}> = ({quest}) => {
    return (
        <SubTitleSection align="center" spacing={5}>
            <AvatarThumbnail kind="avatar" src={quest.author.avatar} />
            <StyledLink
                to={`/user/${quest.author.id}`}
                onClick={() => { amplitude.getInstance().logEvent('check_author_profile_from_quest', {questId: quest.id, authorId: quest.author.id, page: 'quest'}); }}
            >
                {quest.author.username}
            </StyledLink>
            <>
                <span>•</span>
                <span style={{textTransform: 'lowercase'}}>
                    {quest.trainingsCount || 0} <FormattedMessage id="app.quest.trainings" />
                </span>
            </>
        </SubTitleSection>
    );
};
