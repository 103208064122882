import React, {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {TextLayout} from './Text';
import {PrimaryButton} from './Button';
import {RouteNotFound} from '../app/appRouter/RouteNotFound';
import {Section} from '../ui/Section';
import {useTheme} from '@emotion/react';

interface ErrorPageProps {
    error: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({error}) => {
    const theme = useTheme();
    const backToLogin = useCallback(async () => {
        localStorage.clear();
        window.location.reload();
    }, []);
    if (error === 'notFound') {
        return <RouteNotFound />;
    }
    return (
        <Section margin="auto" spacing={30} direction="y" align="center">
            <Section direction="y" spacing={10} align="center">
                <TextLayout weight="bold" size={1.8}>
                    <FormattedMessage id="app.errorTitle" />
                </TextLayout>
                <TextLayout size={1.2} color={theme.subForeground}>
                    <FormattedMessage id={`app.error.${error}`} />
                </TextLayout>
            </Section>
            {error === 'userNotAuthenticated' || error === 'unknown' || error === 'serverError' ? (
                <PrimaryButton onClick={backToLogin}>
                    <FormattedMessage id="app.backToLogin" />
                </PrimaryButton>
            ) : undefined}
        </Section>
    );
};
