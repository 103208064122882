import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import type {StyledOptions} from '@emotion/styled';

export interface LayoutProps {
    scroll?: boolean;
    hidden?: boolean;
    //
    width?: string; // `${number}px` | `${number}%` | 'fit-content';
    minWidth?: string; // `${number}px` | `${number}%`
    maxWidth?: string; // `${number}px` | `${number}%`
    //
    height?: string; // `${number}px` | `${number}%` | 'fit-content';
    minHeight?: string; // `${number}px` | `${number}%`
    maxHeight?: string; // `${number}px` | `${number}%`
    //
    grow?: number;
    shrink?: number;
    //
    margin?: string;
    padding?: string;
    //
    appearWithDelay?: number;
}

const appear = keyframes`
    0% { opacity: 0; transform: scale(0.5); }
    100% { opacity: 1; transform: scale(1); }
`;

const layoutOptions: StyledOptions<LayoutProps> = {
    shouldForwardProp: prop =>
        isPropValid(prop) &&
        prop !== 'hidden' &&
        prop !== 'width' &&
        prop !== 'minWidth' &&
        prop !== 'maxWidth' &&
        prop !== 'height' &&
        prop !== 'minHeight' &&
        prop !== 'maxHeight' &&
        prop !== 'grow' &&
        prop !== 'shrink' &&
        prop !== 'margin' &&
        prop !== 'padding',
};

export const Layout = styled('div', layoutOptions)<LayoutProps>`
    display: ${props => (props.hidden ? 'none' : 'flex')};
    overflow: ${props => (props.scroll ? 'auto' : 'visible')};
    box-sizing: border-box;

    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    min-width: ${props => props.minWidth};

    height: ${props => props.height};
    max-height: ${props => props.maxHeight};
    min-height: ${props => props.minHeight};

    flex-grow: ${props => props.grow};
    ${props => props.grow !== undefined && `flex-basis: 0;`}
    flex-shrink: ${props => props.shrink || 0};

    margin: ${props => props.margin};
    padding: ${props => props.padding};

    :focus {
        outline: none !important;
    }

    /* scrollbar */

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        cursor: pointer;
    }
    ::-webkit-scrollbar-thumb {
        transition: background-color 0.3s;
        border-radius: 5px;
        background-color: rgba(130, 130, 130, 0.3);
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(130, 130, 130, 0.4);
    }
    ${props =>
        props.appearWithDelay &&
        css`
            opacity: 0;
            animation: ${appear} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) ${props.appearWithDelay}s forwards;
        `}
`;
