import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import {Section} from '../../ui/Section';
import {LoadingPart} from '../../widgets/LoadingPart';
import {TextLayout} from '../../widgets/Text';
import {PracticeTypeModel} from '../backend/models/PracticeModel';
import { useLocale } from '../locale/Locale';

const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const SportSection = styled(Section)<{selected: boolean}>`
    color: ${props => props.theme.foreground};
    cursor: pointer;
    animation: ${appear} 0.3s;
    transition: 0.3s all;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => (props.selected ? props.theme.subBackground : undefined)};
    .thumbnail {
        transition: transform 0.3s;
    }
    &:hover {
        background-color: ${props => props.theme.subBackground};
        .thumbnail {
            transform: scale(0.95);
        }
    }
    &:active {
        transform: scale(0.97);
    }
    ${props => props.selected && ` background-color: ${props.theme.subBackground};`}
`;

interface TypeRowProps {
    type: PracticeTypeModel;
    selected: boolean;
    onSelect?: (sport: PracticeTypeModel) => void;
}

export const TypeRow: React.FC<TypeRowProps> = ({type, selected, onSelect}) => {
    const locale = useLocale();
    return (
        <>
            {onSelect && (
                <SportSection onClick={() => onSelect(type)} selected={selected} spacing={10} padding="10px" align="center">
                    <Section direction="y" distribute="center" grow={1}>
                        <TextLayout align="left" weight="bold">
                            {locale.locale === 'fr-FR' ? type.frValue : type.enValue}
                        </TextLayout>
                    </Section>
                </SportSection>
            )}
        </>
    );
};

export const LoadingTypeRow: React.FC = () => {
    return (
        <Section spacing={10} padding="10px" width="100%">
            <Section direction="y" spacing={10} grow={1} distribute="center">
                <LoadingPart width="70%" height="15px" />
                <LoadingPart width="50%" height="10px" />
            </Section>
        </Section>
    );
};
