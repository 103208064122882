import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {Section} from '../../ui/Section';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import styled from '@emotion/styled';
import {useLocation, useMedia} from 'react-use';
import {ActivityModel, ActivitySummaryModel} from '../backend/models/ActivityModel';
import {useTheme} from '@emotion/react';
import {useLoadData} from '../backend/useLoadData';
import {ActivityCard} from '../trainings/ActivityCard';
import {EmptyTrainingCard} from '../trainings/TrainingCard';
import { useTrackers } from '../tracking/useTrackers';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { LightboxCard } from '../../widgets/LightboxCard';
import { Button, PrimaryButton } from '../../widgets/Button';
import { useLightboxState } from '../../ui/Lightbox/useLightboxState';
import { CreateTrainingDialog } from '../trainings/CreateTrainingDialog';
import { Link, useNavigate } from 'react-router-dom';
import { EmptyActivityCard } from '../trainings/EmptyActivityCard';
import { QuestRowModel } from '../backend/models/QuestModel';
import { Thumbnail } from '../../widgets/Thumbnail';
import { InvisibleLink } from '../../components/Link';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';
import { EmptyFollowedQuestsCard } from '../quests/EmptyFollowedQuestsCard';
import { QuestFollow } from '../quests/QuestFollow';
import { Statistics } from '../activity/Statistics';
import { Tools } from '../tools/Tools';

const RecentTrainingsSection = styled(Section)`
    ::-webkit-scrollbar {
        display: none !important;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`

const QuestTitle = styled.div`
    font-size: 1.5em;
    font-family: ${props => props.theme.title};
`;

const SubTitleSection = styled(Section)`
    color: ${props => props.theme.subForeground};
    flex-wrap: wrap;
    font-size: 0.9em;
    font-family: ${props => props.theme.title};
    padding-bottom: 5px;
`;

const StyledLink = styled(Link)`
    color: ${props => props.theme.subForeground};
    transition: all 0.3s;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.primary};
    }
`;

const AvatarThumbnail = styled(Thumbnail)`
    width: 20px;
    border-radius: 50%;
`;

export const Home: React.FC = () => {
    const theme = useTheme();
    const backend = useBackend();
    const match900 = useMedia('(max-width: 900px)');
    const navigate = useNavigate();
    useTrackers();

    const match600 = useMedia('(max-width: 600px)');
    const showWelcome = new URLSearchParams(useLocation().search).get('showWelcome');
    const welcomeLightbox = useLightboxState();
    const createTrainingLightBox = useLightboxState();
    const handleOpenExplore = useCallback(() => {
        navigate('/explore');
        welcomeLightbox.close();
    }, [navigate, welcomeLightbox]);
    const handleCreateTraining = useCallback(() => {
        createTrainingLightBox.open();
    }, [createTrainingLightBox]);


    useEffect(() => {
        if (showWelcome === 'true') {
            welcomeLightbox.open();
        }
    }, [showWelcome, welcomeLightbox]);

    const [activitiesStats, setActivitiesStats] = useState<ActivitySummaryModel>();
    const [isLoadingStats, setIsLoadingStats] = useState(true);

    const recentTrainings = useLoadData<ActivityModel>(
        async (search, page, perPage) => {
            if (!backend.authUser) {
                throw new Error('userNotAuthenticated');
            }
            return backend.getActivities({objectType: 'USER', objectId: backend.authUser.id, page, perPage, drop: false, manual: false});
        },
        {direction: 'x', perPage: 6},
    );

    const [followedQuests, setFollowedQuests] = useState<{
        loadingState: "loading" | "loaded" | "error";
        data: QuestRowModel[];
    }>({
        loadingState: "loading",
        data: [],
    });

    useEffect(() => {
        async function fetchFollowedQuests() {
            try {
                const quests = await backend.listFollowedQuests();
                setFollowedQuests({
                    loadingState: "loaded",
                    data: quests,
                });
            } catch (e: any) {
                console.log(e.message);
                if (e.message === 'userNotAuthenticated') {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
        fetchFollowedQuests();
    }, [backend]);

    const handleQuestUnfollow = (id: string) => {
        const updatedQuests = followedQuests.data.filter((quest) => quest.id !== id);
        setFollowedQuests({
            loadingState: "loaded",
            data: updatedQuests,
        });
    }

    useEffect(() => {
        if (!backend.authUser) {
            return;
        }
        setIsLoadingStats(true);
        backend
            .getActivitiesStatsSummary(backend.authUser.id)
            .then(data => {
                setActivitiesStats((data as any).data);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoadingStats(false);
            });
    }, [backend]);

    return (
        <Section direction="y" grow={1} spacing={20} scroll={true}>
            <Section direction="y" padding="20px 0 0 20px" width="100%" maxWidth="1000px" margin="0 auto">
                <TextLayout weight="bold" size={1.5}>
                    <FormattedMessage id="app.home.recentlyPlayed" />
                </TextLayout>
                <RecentTrainingsSection direction="x" scroll spacing={20} padding="20px 0 0">
                    {(recentTrainings.loadingState === 'loaded' || (recentTrainings.loadingState === 'loading' && recentTrainings.data.length > 0)) &&
                        recentTrainings.data.map((activity, i) => {
                            return (
                                <Section width="300px" key={i}>
                                    <ActivityCard activity={activity} />
                                    {recentTrainings.moreBtn}
                                </Section>
                            );
                        }
                    )}
                    {recentTrainings.loadingState === 'loading' && (
                        <Section direction="x" scroll spacing={20}>
                            {[0, 1, 2].map(id => <EmptyTrainingCard key={id} />)}
                        </Section>
                    )}
                    {recentTrainings.data.length === 0 && recentTrainings.loadingState === 'loaded' && (
                        <Section direction="x" scroll spacing={20}>
                            <Section width="300px">
                                <EmptyActivityCard />
                            </Section>
                        </Section>
                    )}
                </RecentTrainingsSection>
            </Section>
            <Section direction="y" padding="0 20px 0 20px" width="100%" maxWidth="1000px" margin="0 auto">
                <TextLayout weight="bold" size={1.5}>
                    <FormattedMessage id="app.home.followedQuests" />
                </TextLayout>
                {followedQuests.loadingState === "loading" ? [0].map(id => <EmptyTrainingCard key={id} />) :
                (followedQuests.data.length) ? (
                    <Section direction="y" spacing={20} padding="20px 0 0">
                        {followedQuests.data.map((quest, i) => {
                            return (
                                <InvisibleLink to={`/quests/${quest.id}`} onClick={() => {
                                    amplitude.getInstance().logEvent(`check_quest_from_home`, {questId: quest.id, page: 'home'});
                                    ReactGA.event('check_quest', {questId: quest.id});
                                }}>
                                    <Section key={quest.id} direction="y" width="100%" maxWidth="1000px">
                                        <div style={{position: 'relative'}}>
                                            <img style={{borderRadius: '15px', maxWidth: '100%'}} src={quest.banner} alt="" />
                                            <Section onClick={() => handleQuestUnfollow(quest.id)}>
                                                <QuestFollow quest={quest as any} currentPage='quest' />
                                            </Section>
                                        </div>
                                        <Section direction="y" padding="10px 0px" spacing={10} align="start">
                                            <QuestTitle>{quest.title}</QuestTitle>
                                        </Section>
                                        <SubTitleSection align="center" spacing={5}>
                                            <AvatarThumbnail kind="avatar" src={quest.author.avatar} />
                                            <StyledLink
                                                to={`/user/${quest.author.id}`}
                                                onClick={() => {amplitude.getInstance().logEvent(`check_user_profile_from_quest_in_home`, {page: 'home', questId: quest.id, profileId: quest.author.id});}}
                                            >
                                                {quest.author.fullname || quest.author.username.toLowerCase()}
                                            </StyledLink>
                                        </SubTitleSection>
                                    </Section>
                                </InvisibleLink>
                            );
                        })}
                    </Section>
                ) : (
                    <Section width="300px">
                        <EmptyFollowedQuestsCard />
                    </Section>
                )}
            </Section>
            <Section
                direction={'y'}
                reverse={false}
                padding="0 20px 0 20px"
                spacing={match900 ? 20 : 40}
                maxWidth="1000px"
                width="100%"
                margin="0 auto"
            >
                <TextLayout weight="bold" size={1.5}>
                    <FormattedMessage id="app.home.activitiesStats" />
                </TextLayout>
                <Statistics isLoadingActivitiesStatsSummary={isLoadingStats} activitiesStatsSummary={activitiesStats}></Statistics>
            </Section>
            <Tools displayTools={false}></Tools>
            <Lightbox isOpen={welcomeLightbox.isOpen} onRequestClose={welcomeLightbox.close}>
                <LightboxCard isOpen={welcomeLightbox.isOpen} direction="y" padding="20px" spacing={20} maxWidth="90%" width="500px">
                    <TextLayout size={1.5} weight="bold" margin="0 auto" padding="0 40px">
                        <FormattedMessage id="app.explore.welcomeTitle" values={{username: backend.authUser?.username}} />
                    </TextLayout>
                    <TextLayout color={theme.subForeground}>
                        <FormattedMessage id="app.explore.welcomeSubtitle" />
                    </TextLayout>
                    <Section direction={match600 ? 'y' : 'x'} distribute="between" spacing={20}>
                        <Button onClick={handleOpenExplore}>
                            <FormattedMessage id="app.explore.startExploring" />
                        </Button>
                        <PrimaryButton onClick={handleCreateTraining}>
                            <FormattedMessage id="app.explore.createTraining" />
                        </PrimaryButton>
                    </Section>
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={createTrainingLightBox.isOpen} onRequestClose={createTrainingLightBox.close}>
                <LightboxCard isOpen={createTrainingLightBox.isOpen} maxWidth="90%">
                    <CreateTrainingDialog onClose={createTrainingLightBox.close} />
                </LightboxCard>
            </Lightbox>
        </Section>
    );
};
