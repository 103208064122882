// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'app.loading': 'Chargement en cours...',
    'app.toInstall': 'Pour installer, appuie sur',
    'app.addToHomeScreen': "puis 'Sur l'écran d'accueil'",
    'app.reloadApp' : 'TrainingQuest a été mise à jour {reloadAppLink} pour obtenir la dernière version !',
    'app.reloadAppLink' : 'clique ici',

    //
    'app.yes': 'Oui',
    'app.no': 'Non',

    //
    'app.errorTitle': 'Oups ! Une erreur est arrivée 😬',
    'app.backToLogin': 'Retour au login',

    //
    'app.today': "aujourd'hui",
    'app.tomorrow': 'demain',
    'app.yesterday': 'hier',

    //
    'app.visibility.PUBLIC': 'Public',
    'app.visibility.PRIVATE': 'Privé',
    'app.visibility.HIDDEN': 'Caché',

    //
    'app.mon': 'Lundi',
    'app.tue': 'Mardi',
    'app.wed': 'Mercredi',
    'app.thu': 'Jeudi',
    'app.fri': 'Vendredi',
    'app.sat': 'Samedi',
    'app.sun': 'Dimanche',

    // Dialog
    'app.dialog.confirm': `Confirmer`,
    'app.dialog.cancel': `Annuler`,

    // Image Picker
    'widgets.imagePicker.dragImage': "Glisser l'image ici...",
    'widgets.imagePicker.uploadFile': 'Uploader une image',

    // Rich text
    'widgets.richText.imgPlaceholder': "URL de l'image",
    'widgets.richText.videoPlaceholder': 'https://youtu.be/video_id',
    'widgets.richText.linkPlaceholder': 'URL du lien',
    'widgets.richText.add': 'Add',

    // Youtube Link
    'app.youtubeLink.howTo': `Comment ajouter un lien YouTube ?`,

    // Navbar
    'app.navbar.explore': `Explorer`,
    'app.navbar.home': `Accueil`,
    'app.navbar.activities': `Mon activité`,
    'app.navbar.tools': `Outils`,

    // RouteNotFound
    'app.appRouter.routeNotFound.title': `Page introuvable 😱`,
    'app.appRouter.routeNotFound.subTitle': `Oups! Cette page ne semble pas exister ou tu n'y as pas accès, désolé...`,
    'app.appRouter.routeNotFound.goBack': `Retour à l'accueil`,

    // Login
    'app.auth.login.title': `Ravis de te revoir 🎉`,
    'app.auth.login.noAccount': `Pas encore de compte ? <a>Créer un compte</a>`,
    'app.auth.login.email': `Email`,
    'app.auth.login.password': `Mot de passe`,
    'app.auth.login.login': `C'est parti !`,
    'app.auth.login.forgotPassword': `Mot de passe oublié ?`,
    'app.auth.login.showPassword': `Afficher le mot de passe`,
    'app.auth.login.wrongEmailFormat': `Format d'email erroné`,
    'app.auth.login.serverError': `Oups, ça n'a pas fonctionné ! Vérifie tes entrées 🧐`,
    'app.auth.login.userProgram': `Tu es déjà inscrit-e à ce programme, connecte-toi pour le pratiquer 💪!`,
    'app.auth.login.noUserProgram': `Nous nous connaissons déjà 😬, connecte-toi et active le programme directement depuis l'application!`,

    // Register
    'app.auth.register.subtitle': `Crée ton compte et commence dès maintenant à t'entraîner !`,
    'app.auth.register.username': `Nom d'utilisateur`,
    'app.auth.register.email': `Email`,
    'app.auth.register.password': `Mot de passe`,
    'app.auth.register.confirmPassword': `Confirmation du mot de passe`,
    'app.auth.register.register': `C'est parti !`,
    'app.auth.register.gotAccount': `J'ai déjà un compte`,
    'app.auth.register.serverError': `Oups, ça n'a pas fonctionné ! Vérifie tes entrées 🧐`,
    'app.auth.register.invalidEmail': `Email invalide`,
    'app.auth.register.emailTaken': `Nous connaissons cet email, connecte-toi ou fais une demande de nouveau mot de passe 😉`,
    'app.auth.register.usernameTaken': `Ce nom d'utilisateur est déjà pris !`,
    'app.auth.register.usernameBlank': `Renseigne un nom d'utilisateur`,
    'app.auth.register.usernameLengthInvalid': `Le nom d'utilisateur doit faire entre 3 et 30 caractères`,
    'app.auth.register.usernameFormatInvalid': `Les noms d’utilisateurs ne peuvent contenir que des lettres, des chiffres, des traits de soulignement et des points.`,
    'app.auth.register.usernameBadFormat': `Les noms d’utilisateurs ne peuvent contenir que des lettres, des chiffres, des traits de soulignement et des points.`,
    'app.auth.register.passwordMustBeSixChars': `Le mot de passe doit faire 6 caractères minimum`,
    'app.auth.register.knowYouBetter': 'Mieux te connaître 🐺...',
    'app.auth.register.interests': 'Quel genre de sport ou compétence t\'intéresse ?',
    'app.auth.register.otherSuggestions': 'D’autres sports ou compétences que tu aimerais développer ?',
    'app.auth.register.climbing': 'Escalade',
    'app.auth.register.fitness': 'Fitness',
    'app.auth.register.flexibility': 'Souplesse',
    'app.auth.register.handstand': 'Handstand',
    'app.auth.register.martialArts': 'Arts martiaux',
    'app.auth.register.streetworkout': 'Street workout',

    // Forgot password
    'app.auth.forgotPassword.subtitle': `Mot de passe oublié ?\nEnvoyer un email pour obtenir un nouveau mot de passe`,
    'app.auth.forgotPassword.email': `Email`,
    'app.auth.forgotPassword.sendEmail': `Envoyer un email`,
    'app.auth.forgotPassword.backToLogin': `Se connecter`,
    'app.auth.forgotPassword.serverError': `Erreur serveur`,
    'app.auth.forgotPassword.emailInvalid': `Email invalide`,
    'app.auth.forgotPassword.emailSent': `Va voir tes mails ! 😉`,

    // Reset password
    'app.auth.resetPassword.subtitle': `Entre ton nouveau mot de passe (au moins 6 car.)`,
    'app.auth.resetPassword.passwordMustBeSixChars': `Le mot de passe doit faire au moins 6 caractères`,
    'app.auth.resetPassword.passwordsDontMatch': `Les mots de passe ne correspondent pas`,
    'app.auth.resetPassword.password': `Mot de passe`,
    'app.auth.resetPassword.passwordConfirmation': `Confirmation`,
    'app.auth.resetPassword.ResetPassword': `Réinitialiser`,
    'app.auth.resetPassword.backToLogin': `Se connecter`,
    'app.auth.resetPassword.passwordSaved': `Mot de passe mis à jour !`,
    'app.auth.resetPassword.serverError': `Oups, ça n'a pas fonctionné ! Vérifie tes entrées 🧐`,

    // Subscription activation
    'app.auth.subscriptionActivation.title': `Active ton compte 💪`,
    'app.auth.subscriptionActivation.subtitle': `Accède à ton programme`,
    'app.auth.subscriptionActivation.username': `Nom d'utilisateur`,
    'app.auth.subscriptionActivation.password': `Mot de passe`,
    'app.auth.subscriptionActivation.usernameLengthInvalid': `Le nom d'utilisateur doit faire entre 3 et 30 caractères`,
    'app.auth.subscriptionActivation.usernameFormatInvalid': `Le nom d'utilisateur doit être composé de caractères alphanumériques`,
    'app.auth.subscriptionActivation.passwordMustBeSixChars': `Le mot de passe doit faire 6 caractères minimum`,
    'app.auth.subscriptionActivation.serverError': `Erreur serveur, nous avons été notifié et revenons vers toi au plus vite`,
    'app.auth.subscriptionActivation.activate': `Allez`,

    // User
    'app.user.myTrainings': 'Mes entraînements',
    'app.user.myQuests': 'Mes quêtes',
    'app.user.trainings': 'Entraînements',
    'app.user.quests': 'Quêtes',

    // Trainings
    'app.training.difficulty.-1': 'Tous niveaux',
    'app.training.difficulty.0': 'Débutant',
    'app.training.difficulty.1': 'Intermédiaire',
    'app.training.difficulty.2': 'Avancé',
    'app.training.difficulty.3': 'Expert',

    'app.training.RECENTLY_CREATED': 'Créés récemment',
    'app.training.RECENTLY_UPDATED': 'Mis à jour récemment',
    'app.training.MOST_PLAYED': 'Plus joués',
    'app.training.MOST_BOOKMARKED': 'Plus de favoris',
    'app.training.MOST_DIFFICULT': 'Plus difficiles',
    'app.training.LEAST_DIFFICULT': 'Plus faciles',

    'app.training.premium': 'Payant',
    'app.training.quest': 'Quête',

    // Exercises
    'app.exercises.searchPlaceholder': `Rechercher un exercice...`,
    'app.exercises.addButton': `Ajouter`,
    'app.exercises.loading': `Chargement des exercises...`,
    'app.exercises.noExercisesFound': `Aucun exercise ne correspond à cette recherche, en créer un 👆`,
    'app.exercises.addExercise': `Créer un exercice`,

    // Exercise
    'app.exercise.newExercise': 'Nouvel exercice',
    'app.exercise.create': `Créer un exercice`,
    'app.exercise.edit': `Editer l'exercice`,
    'app.exercise.remove': "Supprimer l'exercice",
    'app.exercise.saveToast': `Exercice sauvegardé !`,
    'app.exercise.confirmRemoveTitle': `Es-tu sûr(e) ?`,
    'app.exercise.descriptionPlaceholder': `Description de l'exercice...`,
    'app.exercise.namePlaceholder': `Nom de l'exercice...`,
    'app.exercise.confirmRemoveMessage': `Une fois cet exercice supprimé, il est perdu pour toujours...`,
    'app.exercise.createSuccess': "L'exercice a été créé !",
    'app.exercise.updateSuccess': "L'exercice a été sauvegardé !",
    'app.exercise.exercisePlaceholder': "Vidéo de l'exercice",
    'app.exercise.explanationPlaceholder': "Vidéo d'explications",
    'app.exercise.noExplanations':
        "Il n'y a pas d'explications pour cet exercice. Si tu penses que cet exercice n'est pas clair, n'hésite pas à envoyer un message à son auteur 😁",

    // Search exercise
    'app.exercises.searchExercise.emptyState': `Pas encore d'exercice. Il faut créer des exercices pour les utiliser dans les entraînements !`,

    // Library
    'app.library.trainings': `Entraînements`,
    'app.library.exercises': `Exercices`,
    'app.library.programs': `Programmes`,

    // Explore
    'app.explore.questTitle': 'Choisis ta quête',
    'app.explore.questDescription':
        "Les quêtes sont des programmes d'entraînements interactifs conçus pour t'aider à atteindre progressivement tes objectifs 🤙",
    'app.explore.learnMore': 'En savoir plus',
    'app.explore.recentlyPlayed': 'Joués récemment',
    'app.explore.noPublicWorkouts': `Il n'y a pas encore d'entraînement public, ça ne saurait tarder 😉`,
    'app.explore.programs': 'Choisi ta quête',
    'app.explore.programsSubtitle': 'Les quêtes sont des entraînements personnalisés avec une progression et des défis',
    'app.explore.allTrainings': 'Tous les entraînements',
    'app.explore.allTrainingsSubtitle': 'Découvre tous les entraînements créés par notre communauté ✨',
    'app.explore.welcomeTitle': 'Salut {username} 👋',
    'app.explore.welcomeSubtitle':
        'Bienvenue à bord ! Training Quest te permet de suivre ton activité sportive, de créer tes propres entraînements mais aussi de suivre des programmes d\'entraînement (que l\'on appelle des quêtes) proposés par la communauté. Quelle sera ta première action ? 😎',
    'app.explore.startExploring': 'Commencer à explorer',
    'app.explore.createTraining': 'Créer un entraînement',
    'app.explore.trainings': 'Entraînements',
    'app.explore.quests': 'Quêtes',

    // Create
    'app.create.title': 'Créer',
    'app.create.myTrainings': 'Mes entraînements',
    'app.create.userTrainings': 'Entraînements de {user}',
    'app.create.myQuests': 'Mes quêtes',
    'app.create.userQuests': "Quêtes de {user}",
    'app.create.exercisesLibrary': "Bibliothèque d'exercices",
    'app.create.subTitle':
        'Training Quest est une platforme ouverte, tout le monde peut créer du contenu et partager ses connaissances. Commence à créer maintenant !',
    'app.create.viewQuest': 'Aperçu',
    'app.create.editQuest': 'Editer',

    // Quests
    'app.quest.difficulty.-1': 'Tous niveaux',
    'app.quest.difficulty.0': 'Débutant',
    'app.quest.difficulty.1': 'Intermédiaire',
    'app.quest.difficulty.2': 'Avancé',
    'app.quest.difficulty.3': 'Expert',
    'app.quest.overview': 'Aperçu',
    'app.quest.instructions': 'Instructions',
    'app.quest.trainings': 'Entraînements',
    'app.quest.activity': 'Activité',
    'app.quest.playedBy': 'joué par',
    'app.quest.unlockToAccess': '🔒 Débloque cette quête pour accéder à tout son contenu !',
    'app.quest.premiumQuestion': '💎 Question premium',
    'app.quest.premiumQuestionDescription': 'Il s’agit d’une des questions posées au coach par les membres de la quête. Pour voir toutes les réponses et poser tes propres questions, débloque la quête dès aujourd’hui en cliquant sur le bouton… ',
    'app.quest.gotIt': 'Ça marche !',
    'app.quest.back': 'Retour',
    'app.quest.titlePlaceholder': 'Titre',
    'app.quest.intro': 'Petit texte pour décrire rapidement la quête qui sera affiché dans le menu d\'exploration des quêtes et entraînements',
    'app.quest.addTraining': 'Ajouter un entraînement',
    'app.quest.followed': 'Tu suis cette quête !',
    'app.quest.unfollowed': 'Tu ne suis plus cette quête.',
    'app.quest.newQuest': '+ Créer une nouvelle quête',
    'app.quest.createTitle': 'Créer une nouvelle quête',
    'app.quest.privateDescription': `Tu es seul(e) à pouvoir voir cette quête, passes la en public/caché pour le partager.`,
    'app.quest.publicDescription': `Tout le monde peut voir cette quête, elle apparaît sur ton profil public et sur la page Explorer.`,
    'app.quest.hiddenDescription': `Cette quête n'est pas visible sur Explorer ou ton profil public. Tu peux par contre la partager avec le lien.`,
    'app.quest.private': `Privée`,
    'app.quest.public': `Publique`,
    'app.quest.hidden': `Cachée`,

    // Workouts
    'app.trainings.title': `Mes entraînements`,
    'app.trainings.add': `Ajouter`,
    'app.trainings.searchPlaceholder': `Rechercher un entraînement...`,
    'app.trainings.addButton': `Ajouter`,
    'app.trainings.noTrainingYet': `Pas encore d'entraînement ici 😬`,
    'app.trainings.noSearchResult': `Pas d'entraînement correspondant à cette recherche 😕`,

    // Home
    'app.home.myPrograms': 'Mes programmes',
    'app.home.programsEmptyState': "Tu n'as souscrit à aucun programme.",
    'app.home.recentlyPlayed': `Entraînements récents`,
    'app.home.recentlyPlayedEmptyState': `Pas d'entraînements récents`,
    'app.home.bookmarks': `Mes favoris`,
    'app.home.totalTrainingDone': "Entraînements faits",
    'app.home.totalTrainingTime': "Temps d'entraînement",
    'app.home.avgTrainingTimePerDay': "Temps moyen d'entraînement",
    'app.home.trainingTimePerDay': "Temps d'entraînement par jour",
    'app.home.otherTrainings': 'Autres entraînements',
    'app.home.activitiesStats': 'Stats d\'entraînement',
    'app.home.emptyActivitiesStats' : "Pas d'entraînement récent. Commence à {exploreLink} les entraînements et les quêtes proposés par la communauté ou {createTrainingLink} et joue le !",
    'app.home.exploreLink' : 'explorer',
    'app.home.createTrainingLink' : 'crée un entraînement',
    'app.home.followedQuests' : 'Quêtes suivies',
    'app.home.emptyFollowedQuests' : "Tu ne suis aucune quête. Commence à {exploreLink} les quêtes proposées par la communauté et clique sur l'althère pour les voir apparaître ici ou {handleInterestsLink} ce que tu souhaites apprendre !",
    'app.home.handleInterestsLink' : 'indique nous',

    // Bookmarks
    'app.bookmarks.title': `Mes favoris`,
    'app.bookmarks.searchPlaceholder': `Rechercher un favori...`,
    'app.bookmarks.noBookmarks': `Rendez-vous sur la page Explorer pour trouver des entraînements à mettre en favori ! 😉`,

    // Activity
    'app.activity.activities': `Activités`,
    'app.activity.history': `Historique`,
    'app.activity.statistics': `Statistiques`,
    'app.activity.bookmarks': `Favoris`,
    'app.activity.playedThe': 'Joué le ',
    'app.activity.createTitle': 'Créer une nouvelle activité',
    'app.activity.titlePlaceholder': 'Titre (optionnel)',
    'app.activity.create': 'Créer',
    'app.activity.cancel': 'Annuler',
    'app.activity.duration': 'Durée de l\'activité : ',
    'app.activity.minutes': ' minutes',
    'app.activity.choseSport': 'Choisir le sport',
    'app.activity.choseType': 'Choisir un type d\'activité',
    'app.activity.search': 'Curling',
    'app.activity.notes': 'Notes personnelles',
    'app.activity.notesPlaceholder': 'Contenu de ta session, comment tu t\'es senti...',
    'app.activity.remove': 'Supprimer',
    'app.activity.confirmRemoveActivityTitle': 'Sûr.e ?',
    'app.activity.confirmRemoveActivityMessage': 'La suppression de cette activité est définitive.',
    'app.activity.wellDone': 'Bien joué !',

    // Sports
    'app.sports.searchPlaceholder': 'Curling',

    // Training
    'app.training.newTraining': 'Nouvel entraînement',
    'app.training.createTitle': 'Créer un nouvel entraînement',
    'app.training.titleLabel': 'Titre',
    'app.training.youtubeIntro': 'Vidéo intro',
    'app.training.youtubeOutro': 'Vidéo outro',
    'app.training.thumbnailLabel': 'Image',
    'app.training.descriptionLabel': 'Description',
    'app.training.tagsLabel': 'Tags',
    'app.training.setsLabel': 'Sets',
    'app.training.titlePlaceholder': `Titre de l'entraînement...`,
    'app.training.start': `Commencer`,
    'app.training.shareLinkCopied': `Lien de partage copié !`,
    'app.training.edit': `Editer`,
    'app.training.remove': `Supprimer`,
    'app.training.duplicate': `Dupliquer`,
    'app.training.descriptionPlaceholder': `Description de l'entraînement...`,
    'app.training.newSet': `Set`,
    'app.training.addSet': `Ajouter un set`,
    'app.training.addExercise': `Ajouter un exercice`,
    'app.training.save': `Sauvegarder`,
    'app.training.saved': `Sauvegardé`,
    'app.training.cancel': `Annuler`,
    'app.training.create': `Créer`,
    'app.training.back': `Retour`,
    'app.training.private': `Privé`,
    'app.training.public': `Public`,
    'app.training.hidden': `Caché`,
    'app.training.bookmark': `favori`,
    'app.training.bookmarks': `favoris`,
    'app.training.trainingSaved': `Entraînement sauvegardé !`,
    'app.training.privateDescription': `Tu es seul(e) à pouvoir éditer cet entraînement, passes le en public/caché pour le partager.`,
    'app.training.publicDescription': `Tout le monde peut voir cet entraînement, il apparaît sur ton profil public et sur la page Explore.`,
    'app.training.hiddenDescription': `Cet entraînement n'est pas visible sur Explore ou ton profil public. Tu peux par contre le partager avec le lien.`,
    'app.training.confirmRemoveTitle': `Es-tu sûr(e) ?`,
    'app.training.confirmRemoveMessage': `Une fois supprimé, ton entraînement est perdu pour toujours...`,
    'app.training.DURATION': `secondes`,
    'app.training.REPS': `reps`,
    'app.training.DISTANCE': `mètres`,
    'app.training.MAX_HOLD': `durée max`,
    'app.training.MAX_REPS': `reps max`,
    'app.training.MAX_DISTANCE': `distance max`,
    'app.training.REST': `repos`,
    'app.training.NO_REST': `pas de repos`,
    'app.training.copy': 'Copie',
    'app.training.share': 'Partager',
    'app.training.saveActivity': 'Enregistrer une activité',

    // Workout row
    'app.workoutRow.bookmark': `favori`,
    'app.workoutRow.bookmarks': `favoris`,
    'app.workoutRow.by': 'par',

    // Price
    'app.price.title': 'Configurer les prix',
    'app.price.description': `Tu peux spéficier des prix et des intervalles, tout prix à 0 ne sera pas pris en compte. Si il y a déjà des payeurs, le prix restera le même pour eux et il sera ajouté à l'historique de paiement.`,
    'app.price.currency': 'Devise',
    'app.price.freePlays': 'Essais gratuits',
    'app.price.prices': 'Prix',
    'app.price.history': 'Historique de paiement',
    'app.price.historyEmpty': 'Pas de prix configuré, cet entraînement est gratuit.',
    'app.price.once': 'une fois',
    'app.price.every': 'chaque',
    'app.price.week': 'semaine',
    'app.price.month': 'mois',
    'app.price.year': 'année',
    'app.price.priceOnce': '{price}{symbol} une fois',
    'app.price.pricePerWeek': '{price}{symbol} chaque {interval} semaine',
    'app.price.pricePerMonth': '{price}{symbol} chaque {interval} mois',
    'app.price.pricePerYear': '{price}{symbol} chaque {interval} année',
    'app.price.added': 'Added the {date}',
    'app.price.replacePrice': 'Remplacer le prix',
    'app.price.createTrainingPrice': 'Créer un prix',
    'app.price.removePrice': 'Supprimer le prix',

    // Payment
    'app.payment.questTitle': 'Débloque la quête',
    'app.payment.questDescription': 'Une fois la quête débloquée, tu peux jouer les entraînements inclus autant de fois que tu veux',
    'app.payment.trainingTitle': 'Débloque cet entraînement',
    'app.payment.trainingDescription': 'Une fois débloqué, tu peux jouer cet entraînement autant de fois que tu veux',
    'app.payment.subDescription': `80% de ton achat sera reversé à ton coach, le reste permettra à TrainingQuest de se développer.`,
    'app.payment.once': 'pour toujours',
    'app.payment.buy': 'Acheter',
    'app.payment.everyNWeek': 'chaque {n} semaine',
    'app.payment.everyNMonth': 'chaque {n} mois',
    'app.payment.everyNYear': 'chaque {n} année',
    'app.payment.WEEK': ' / {n} semaine',
    'app.payment.MONTH': ' / {n} mois',
    'app.payment.YEAR': ' / {n} année',
    'app.payment.subscribe': 'Souscrire',
    'app.payment.freePlays': 'lectures gratuites',
    'app.payment.noFreePlays': 'Cet entraînement ne peut pas être joué gratuitement, débloque le pour le jouer',
    'app.payment.questPublicTraining': 'Cet entraînement gratuit fait partie d\'une quête, débloque la pour jouer tous ses entraînements',
    'app.payment.noFreePlaysQuest': 'Cet entraînement appartient à une quête, découvre',
    'app.payment.noMoreFreePlays': 'Tu ne peux plus jouer cet entraînement gratuitement, débloque le pour le jouer en illimité',
    'app.payment.noMoreFreePlaysQuest': 'Tu ne peux plus jouer cet entraînement gratuitement, débloque la quête',
    'app.payment.unlockDescription': 'sur cet entraînement, débloque le pour le jouer en illimité',
    'app.payment.unlock': 'Débloquer',
    'app.payment.unlockQuest': 'Débloquer la quête',
    'app.payment.cancelled': 'Le paiement a été annulé',
    'app.payment.expired': 'Le paiement a expiré',
    'app.payment.locked': 'Débloque cette quête pour accéder à tout son contenu',
    'app.payment.lockedTraining': 'Débloque cet entraînement pour accéder à tout son contenu',
    'app.payment.error': 'Il y a une erreur liée au paiment',
    'app.payment.openPortal': 'Ouvrir le portail',
    'app.payment.safariWarning': 'Si rien ne se passe, il faut probablement autoriser les popups (Réglages > Safari > Bloquer les pop-up)',

    // Play
    'app.play.getReadyFor': 'Prépare-toi pour',
    'app.play.restFor': 'Repose-toi ',
    'app.play.trainingStartsIn': "L'entraînement commence dans ",
    'app.play.done': 'Confirmer',
    'app.play.rest': 'Repos',
    'app.play.gotIt': "J'ai compris !",
    'app.play.skip': 'Passer',
    'app.play.ready': 'Prêt ?',
    'app.play.wellDone': '🏆 Bien joué !',
    'app.play.timeToRest': 'Repose toi',
    'app.play.watchIntro': "Regarder l'intro",
    'app.play.startTraining': "Commencer l'entraînement",
    'app.play.trainingCompleted': 'Entraînement terminé !',
    'app.play.totalTime': 'Temps total : ',
    'app.play.exit': 'Quitter',
    'app.play.exerciseExplanation': "Explication de l'exercice",
    'app.play.confirm': 'Confirmer',
    'app.play.continue': 'Continuer',
    'app.play.restart': 'Recommencer',
    'app.play.wantToQuit': `Es-tu sûr(e) de vouloir quitter ? 🤨`,
    'app.play.qualityLabel': 'As-tu aimé cet entraînement ?',
    'app.play.difficultyLabel': 'Comment était la difficulté ?',
    'app.play.feedback': "D'autres remarques ?",
    'app.play.feedbackPlaceholder': 'Écris tes remarques...',
    'app.play.tooHard': 'Beauuucoup trop dur 🥵',
    'app.play.littleTooHard': 'Un peu trop dur pour moi 😬',
    'app.play.perfectForMe': "C'est parfait pour moi 👌",
    'app.play.littleTooEasy': "C'était un peu trop facile 🤷‍♂️",
    'app.play.tooEasy': "C'était beaucoup trop facile 🤪",
    'app.play.chooseDifficulty': 'Selectionne une difficulté',
    'app.play.failedToSaveStat': 'La sauvegarde des stats a échouée 😔',
    'app.play.statSuccess': '🏆 Bien joué !',
    'app.play.pauseTitle': 'Entraînement en pause',
    'app.play.pauseDescription': 'Si tu quittes tu perds ta progression 😬',

    // Programs
    'app.program.infos': 'Infos',
    'app.program.config': `Général`,
    'app.program.configuration': `Configuration`,
    'app.program.progressEmptyState': `Tu doit t'inscrire au programme pour voir tes progrès 😬`,
    'app.program.progressionEmptyState': `Tu n'as pas encore de progressions. Finis des entraînements pour voir tes progressions !`,
    'app.program.payments': `Gérer les abonnements`,
    'app.program.paymentsMessage': `Une erreur de paiement a eu lieu. Mets à jour tes informations de paiement en cliquant sur le bouton ci-dessus.`,
    'app.program.startTrialMessage': `✅ {count} entraînements gratuits, puis {price}/mois<br>✅ Pas de carte de crédit requise<br>✅ Désabonnement à tout moment`,
    'app.program.expiredMessage': `La période d'essai touche à sa fin, achète le programme pour débloquer les autres entraînement.`,
    'app.program.startTrial': `Essayer ce programme gratuitement`,
    'app.program.startTrialConfirm': `C'est parti !`,
    'app.program.buyThisProgram': `Souscris à ce programme pour {price}/mois`,
    'app.program.rebuyThisProgram': `Reprend ton abonnement pour {price}/month`,
    'app.program.canceledMessage': `Ton abonnement est annulé.`,
    'app.program.trialMessage': `Il te reste <strong>{remaining} entraînements gratuits sur {count}</strong>, souscris à ce programme pour débloquer tous les entraînements ! 💪`,
    'app.program.update': 'Mettre à jour',
    'app.program.complete': 'Compléter',
    'app.program.replace': 'Remplacer',
    'app.program.paymentSuccess': 'Paiement effectué avec succès, tu vas recevoir un email de confirmation.',
    'app.program.paymentError': "Paiement annulé, tu n'as pas été débité.",

    // Settings
    'app.settings.title': `Réglages`,
    'app.settings.general': `Général`,
    'app.settings.banner': 'Bannière',
    'app.settings.language': `Langue`,
    'app.settings.updateUsername': "Changer le nom d'utilisateur",
    'app.settings.updateEmail': "Changer l'email",
    'app.settings.updatePassword': 'Changer le mot de passe',
    'app.settings.userAccount': `Compte utilisateur`,
    'app.settings.userProfile': 'Profil',
    'app.settings.update': `Changer`,
    'app.settings.fullname': 'Nom complet',
    'app.settings.newUsername': `Le nom d'utilisateur doit être unique, contenir uniquement les lettres, des nombres, -, _ et être entre 2 et 30 caractères.`,
    'app.settings.newEmail': `Nouvel email`,
    'app.settings.newPassword': `Nouveau mot de passe`,
    'app.settings.logout': `Me déconnecter`,
    'app.settings.fr-FR': `🇫🇷 Français`,
    'app.settings.en-US': `🇺🇸 Anglais`,
    'app.settings.theme': 'Thème',
    'app.settings.lightTheme': '☀️ Mode clair',
    'app.settings.darkTheme': '🌙 Mode foncé',
    'app.settings.bio': `Bio`,
    'app.settings.bioPlaceholder': `Quelque infos à propos de toi ou de ton studio...`,
    'app.settings.websiteUrl': `Site web`,
    'app.settings.portalNavigate': `Mes abonnements`,
    'app.settings.removeAccount': `Supprimer le compte`,
    'app.settings.confirmRemoveTitle': `Es-tu sûr(e) de vouloir supprimer ton compte ?`,
    'app.settings.confirmRemoveMessage': `Une fois le compte supprimé tu ne peux pas le récupérer !`,
    'app.settings.userSaved': `Utilisateur sauvegardé !`,
    'app.settings.passwordChanged': `Mot de passe mis à jour !`,
    'app.settings.soundTheme': 'Thème sonore',
    'app.settings.default': '🎵 Défaut',
    'app.settings.boxing': '🥊 Boxe',
    'app.settings.digital': '🤖 Digital',
    'app.settings.gong': '🧘‍♂️ Gong',
    'app.settings.magic': '🧙‍♂️ Magique',
    'app.settings.sonar': '🦇 Sonar',
    'app.settings.win': '🥇 Victoire',
    'app.settings.wtf': '👽 WTF',
    'app.settings.payment': 'Paiements et factures',

    'tags.addTag': `Nom du tag`,
    'tags.newTag': 'Nouveau tag...',

    // Interests
    'app.interests.updateInterests': 'Mettre à jour mes intérêts',
    'app.interests.update': 'Mettre à jour',

    // Tools
    'app.tools.newActivity': 'Saisir une activité',
    'app.tools.newTraining': 'Créer un entraînement',
    'app.tools.stopwatch': 'Lancer un chronomètre',
    'app.tools.startStopwatchTitle': '⏱️ Bientôt là !',
    'app.tools.startStopwatchSubtitle': 'Clique sur le bouton ci-dessous pour voter pour cette fonctionnalité.',
    'app.tools.startStopwatchUpvote': 'Je dis oui 👍',
    'app.tools.upvotedStartStopwatch': 'A vôté !',

    // Errors
    'app.error.unknown': 'Erreur interne du serveur',
    'app.error.serverError': 'Erreur interne du serveur',
    'app.error.badEmailOrPassword': 'Email ou mot de passe invalide',
    'app.error.wrongEmailFormat': "L'email n'est pas correctement formatté",
    'app.error.emailAlreadyTaken': "L'email est déjà utilisé",
    'app.error.emailInvalid': "Le format de l'email est invalide",
    'app.error.usernameAlreadyTaken': "Le nom d'utilisateur est déjà pris",
    'app.error.usernameFormatInvalid': "Le nom d'utilisateur doit être composé uniquement de lettres, nombres - et _ et compris entre 2 et 20 caractères",
    'app.error.passwordMustBeSixChars': "Le mot de passe doit être composé d'au moins 6 caractères",
    'app.error.missingEmail': "L'email doit être rempli",
    'app.error.emailBlank': "L'email ne peut pas être vide",
    'app.error.usernameBlank': "Le nom d'utilisateur ne peut pas être vide",
    'app.error.userNotFound': "L'utilisateur n'existe pas",
    'app.error.unprocessableEmail': "L'email est invalide",
    'app.error.passwordsDontMatch': 'Les mots de passe ne correspondent pas',
    'app.error.passwordMissing': 'Le mot de passe doit être rempli',
    'app.error.userNotAuthenticated': "Tu n'es plus connecté",
    'app.error.userNotAccessible': "Tu n'as pas accès à cet utilisateur",
    'app.error.fileIsTooBig': 'Les images doivent faire 3Mo maximum',
    'app.error.fileMustBeJpgJpegPng': 'Les images doivent être au format jpg ou png',
    'app.error.nameAlreadyTaken': 'Ce nom est déjà utilisé',
    'app.error.exerciseNotFound': "This exercise doesn't exists or has been removed",
    'app.error.trainingNotFound': "This training doesn't exists or has been removed",
    'app.error.userNotAllowed': "Tu n'as pas les droits",
    'app.error.objectTypeBadFormat': 'Mauvaise type envoyé',
    'app.error.priceIsMissing': 'Il faut spécifier au moins un prix différent de 0',
    'app.error.missingObjectTypeOrId': "Il manque le type ou l'id du prix",
    'app.error.objectNotFound': 'Object non trouvé',
    'app.error.unprocessablePrice': 'Le prix ne peut pas être pris en compte',
    'app.error.missingPriceType': 'Type de prix manquant',
    'app.error.priceTypeBadFormat': 'Format de type de prix invalide',
    'app.error.objectPrivate': 'Objet privé',
    'app.error.objectNotPayable': 'Objet ne peut pas être payé',
    'app.error.authorDoesntNeedToPay': "L'auteur n'a pas besoin de payer",
    'app.error.productHasNoCurrentPrice': "Le produit n'a pas de prix courant",
    'app.error.selectedPricingNotPositive': "La valeur du prix sélectionné n'est pas positive",
    'app.error.alreadyPaying': 'Le paiement est déjà effectué',
    'app.error.currentPaymentErrored': 'Il y a un problème avec le paiement, ouvre le portail stripe depuis les réglages pour le corriger',
    'app.error.errorPleaseRetry': 'Erreur inatendue, essaye à nouveau',
};
