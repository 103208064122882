import React from 'react';

import styled from '@emotion/styled';

import {ReactComponent as LogoIcon} from './assets/logo.svg';

const LogoWrapper = styled(LogoIcon)`
    [fill='black'] {
        fill: ${props => props.theme.foreground};
    }
    [stroke='black'] {
        stroke: ${props => props.theme.foreground};
    }
`;

export const Logo: React.FC<{width?: number; height?: number}> = ({width, height}) => {
    return <LogoWrapper width={width} height={height} />;
};
