import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {FormattedMessage} from 'react-intl';
import {Section} from '../../ui/Section';
import {EmptyState} from '../../components/EmptyState';

import {Icon} from '../../ui/Icon';
import {useBackend} from '../backend/useBackend';
import {useLoadData} from '../backend/useLoadData';
import {PracticeTypeModel} from '../backend/models/PracticeModel';
import {LoadingTypeRow, TypeRow} from './TypeRow';

interface TypesProps {
    isOpen: boolean;
    currentType?: PracticeTypeModel;
    onSelect?: (exercise: PracticeTypeModel) => void;
    onCancel: () => void;
}

const appear = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const TypesSection = styled(Section)`
    animation: ${appear} 0.3s;
`;

export const Types: React.FC<TypesProps> = ({isOpen, currentType, onSelect, onCancel}) => {
    const backend = useBackend();
    const [type, setType] = React.useState<PracticeTypeModel>(
        currentType || {
            id: '',
            key: '',
            frValue: '',
            enValue: ''
        },
    );

    const {data, error, loadingState, search, moreBtn} = useLoadData<PracticeTypeModel>((search, page, perPage, orderBy) => {
        return backend.listTypes({search, page, perPage});
    });

    React.useEffect(() => {
        if (currentType) {
            setType(currentType);
        }
    }, [currentType]);

    return (
        <TypesSection direction="y" spacing={10} height="100%" width="100%" padding="20px">
            <Section width="100%" spacing={10}>
                <Icon name="close" onClick={onCancel} />
            </Section>
            <Section grow={1} direction="y" scroll={true}>
                {type.id !== '' && search === '' && (
                    <TypeRow key={type.id} selected={true} onSelect={onSelect} type={type} />
                )}
                {(loadingState === 'loading' || loadingState === 'loaded') &&
                    data
                        .filter(spt => type.id === '' || search !== '' || type.id !== spt.id)
                        .map((spt, i) => <TypeRow key={spt.id} selected={false} onSelect={onSelect} type={spt} />)}
                {loadingState === 'loading' && [0, 1, 2, 3, 4, 5].map(id => <LoadingTypeRow key={id} />)}
                {loadingState === 'loaded' && moreBtn}
                {loadingState === 'error' && (
                    <EmptyState isError align="center" distribute="center" width="100%" height="100%" padding="20px">
                        <FormattedMessage id={`app.error.${error}`} />
                    </EmptyState>
                )}
            </Section>
        </TypesSection>
    );
};
