import React, {ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {Input} from '../../components/inputs/Input';
import {Section} from '../../ui/Section';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import {TrainingModel} from '../backend/models/TrainingModel';
import {Button, PrimaryButton} from '../../widgets/Button';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {useTheme} from '@emotion/react';
import amplitude from 'amplitude-js';

export const CreateTrainingDialog: React.FC<{onClose: () => void}> = ({onClose}) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();
    const backend = useBackend();
    const [training, setTraining] = React.useState<TrainingModel>({
        id: '',
        title: '',
        thumbnail: '',
        description: '',
        introYoutubeId: '',
        difficulty: -1,
        duration: 0,
        author: backend.authUser!,
        visibility: 'PRIVATE',
        playsCount: 0,
        bookmarksCount: 0,
        isBookmarked: false,
        sets: [],
        prices: [],
        tags: [],
        availableTags: []
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const handleChangeTitle = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setTraining(w => ({...w, title: e.target.value}));
    }, []);
    const handleCreate = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const t = await backend.createTraining(training);
            navigate(`/trainings/${t.id}?edit=true`);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }, [backend, navigate, training]);
    return (
        <Section direction="y" padding="20px" spacing={20} width="100%">
            <TextLayout size={1.5} weight="bold" margin="0 auto" padding="0 40px">
                <FormattedMessage id="app.training.createTitle" />
            </TextLayout>
            <Input placeholder={intl.formatMessage({id: 'app.training.titlePlaceholder'})} value={training.title} onChange={handleChangeTitle} />
            <Section distribute="between">
                <Button onClick={() => {
                        onClose();
                        amplitude.getInstance().logEvent('cancel_create_new_training', {trainingTitle: training.title, page: 'profile'});
                    }}
                >
                    <FormattedMessage id="app.training.cancel" />
                </Button>
                <PrimaryButton
                    disabled={isLoading || training.title === ''}
                    onClick={() => {
                        handleCreate();
                        amplitude.getInstance().logEvent('create_new_training', {trainingTitle: training.title, page: 'profile'});
                    }}
                >
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={18} /> : <FormattedMessage id={'app.training.create'} />}
                </PrimaryButton>
            </Section>
        </Section>
    );
};
