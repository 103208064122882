import React from 'react';
import Mousetrap from 'mousetrap';

// @ts-ignore
const mousetrap = Mousetrap(document);

mousetrap.stopCallback = () => false;

const isInput = (element: HTMLElement): boolean => {
    return (
        element.tagName === 'INPUT' ||
        element.tagName === 'SELECT' ||
        element.tagName === 'TEXTAREA' ||
        (!!element.contentEditable && element.contentEditable === 'true')
    );
};

type ShortcutCallback = (e: KeyboardEvent, combo: string) => void;
export const useShortcut = (keys: string | string[], callback: ShortcutCallback, preventDefault: boolean, allowInput?: boolean) => {
    const reactCallback = React.useCallback(
        (e: KeyboardEvent, combo: string) => {
            if (!allowInput && e.target && isInput(e.target as HTMLElement)) {
                return;
            }
            if (preventDefault) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }
            callback(e, combo);
        },
        [allowInput, callback, preventDefault],
    );

    React.useEffect(() => {
        const instance = mousetrap.bind(keys, reactCallback);
        return () => {
            instance.unbind(keys);
        };
    }, [keys, reactCallback]);
};
