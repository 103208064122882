import React from 'react';

import { Section } from '../../ui/Section';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../../components/Button';


export const NewQuestRow: React.FC<{onClick: () => void}> = ({onClick}) => {
    return (
        <Section direction="y" width='100%' padding='20px'>
            <PrimaryButton onClick={onClick}>
                <FormattedMessage id="app.quest.newQuest" />
            </PrimaryButton>
        </Section>
    );
};
