import styled from '@emotion/styled';

import {Layout} from './Layout';

export const Grid = styled(Layout)<{limit: number; spacing: number}>`
    gap: ${props => props.spacing}px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: ${props => `repeat(auto-fill, minmax(${props.limit}px, 1fr))`};
`;
