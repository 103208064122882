import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';

interface ButtonProps {
    size?: 'small' | 'medium' | 'big';
    radius?: number;
}

export const Button = styled.button<ButtonProps>`
    cursor: pointer;
    border: none;
    outline: none;
    user-select: none;

    color: ${props => props.theme.background};
    background-color: ${props => props.theme.foreground};

    display: flex;
    flex-direction: row;
    text-align: center;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    font-size: ${props => (props.size === 'big' ? '1.2em' : props.size === 'small' ? '0.8em' : '1em')};
    font-family: ${props => props.theme.title};
    font-weight: normal;

    padding: ${props => (props.size === 'big' ? '10px 20px' : '8px 15px')};
    transition: transform 0.2s;
    border-radius: ${props => props.radius || 10}px;
    .icon {
        padding-right: 10px;
    }
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
    &:active {
        transform: scale(0.95);
    }
`;

export const PrimaryButton = styled(Button)`
    color: ${props => props.theme.primaryForeground};
    background-color: ${props => props.theme.primary};
`;

export const InlineButton = styled(Button)`
    color: ${props => props.theme.primary};
    background-color: transparent;
`;

const animatedGradient = keyframes`
	0% { background-position: 0% 0%; }
	20% { background-position: 99% 0%; }
	40% { background-position: 0% 0%; }
	100% { background-position: 0% 0%; }
`;

export const GoldButton = styled(Button)`
    color: #222222;
    animation: ${animatedGradient} 6s ease infinite;
    background-size: 200% 100%;
    background-image: linear-gradient(45deg, #e4b65d, #f9e999, #e4b65d, #f9e999);
    background-position: 0% 0%;
`;