import React from 'react';
import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';

const drawOne = keyframes`
    0% { stroke-dashoffset: 250; }
    30% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: 0; }
`;

const drawTwo = keyframes`
    0% { stroke-dashoffset: 250; }
    10% { stroke-dashoffset: 250; }
    40% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: 0; }
`;

const drawThree = keyframes`
    0% { stroke-dashoffset: -160; }
    20% { stroke-dashoffset: -160; }
    40% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: 0; }
`;

const drawFour = keyframes`
    0% { stroke-dashoffset: -110; }
    30% { stroke-dashoffset: -110; }
    50% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: 0; }
`;

const Content = styled.svg<{color?: string; size?: number; duration: number}>`
    width: 150px;
    height: 150px;
    path {
        stroke: ${props => props.color};
        stroke-dasharray: 250;
    }
    path:nth-of-type(1) {
        animation: ${drawOne} ${props => props.duration}s infinite forwards ease-in-out;
        stroke-dashoffset: 250;
    }
    path:nth-of-type(2) {
        animation: ${drawTwo} ${props => props.duration}s infinite forwards ease-in-out;
        stroke-dashoffset: 250;
    }
    path:nth-of-type(3) {
        animation: ${drawThree} ${props => props.duration}s infinite forwards ease-in-out;
        stroke-dashoffset: -160;
    }
    path:nth-of-type(4) {
        animation: ${drawFour} ${props => props.duration}s infinite forwards ease-in-out;
        stroke-dashoffset: -110;
    }
`;

export const TQSpinner: React.FC<{color?: string; size?: number; duration?: number}> = ({color, size, duration = 2}) => {
    return (
        <Content color={color} size={size} duration={duration}>
            <path fill="none" d="M25.3502 22.3802C23.5 33.4999 29.5 52.9999 38.2654 57.4389" strokeWidth="24" />
            <path fill="none" d="M122.499 21.5001C125.499 35.0001 114.999 57.5001 102.999 65.0001" strokeWidth="24" />
            <path fill="none" d="M4 123.5C32.5 114.5 83.5 75.5 73 2" strokeWidth="24" />
            <path fill="none" d="M139 123.5C100 119 71.5 102.5 59.5 71" strokeWidth="24" />
        </Content>
    );
};
