import React from 'react';
import {Section} from '../ui/Section';
import {LightboxCard} from './LightboxCard';
import {TextLayout} from './Text';
import {Button, PrimaryButton} from './Button';
import {FormattedMessage} from 'react-intl';
import {useTheme} from '@emotion/react';

interface LightboxConfirmProps {
    isOpen: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const LightboxConfirm: React.FC<LightboxConfirmProps> = ({isOpen, title, message, onConfirm, onCancel}) => {
    const theme = useTheme();
    return (
        <LightboxCard align="center" padding="20px" spacing={20} width="500px" maxWidth="90%" maxHeight="90%" direction="y" isOpen={isOpen}>
            <Section direction="y" spacing={10} align="center">
                <TextLayout size={1.5} weight="bold">
                    {title}
                </TextLayout>
                <TextLayout color={theme.subForeground}>{message}</TextLayout>
            </Section>
            <Section distribute="between" width="100%">
                <Button onClick={onCancel}>
                    <FormattedMessage id="app.dialog.cancel" />
                </Button>
                <PrimaryButton onClick={onConfirm}>
                    <FormattedMessage id="app.dialog.confirm" />
                </PrimaryButton>
            </Section>
        </LightboxCard>
    );
};
