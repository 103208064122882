import { keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Section } from "../../ui/Section";
import { TQSpinner } from "../../widgets/Spinner/TQSpinner";
import { TextLayout } from "../../widgets/Text";

const appear = keyframes`
    from {
        opacity: 0;
        transform: scale(0.7) translateY(10px);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const Text1 = styled(TextLayout)`
    opacity: 0;
    transform: scale(0.7) translateY(10px);
    animation: ${appear} 0.5s ease-in-out forwards 1.2s;
`;

const Text2 = styled(TextLayout)`
    opacity: 0;
    transform: scale(0.7) translateY(10px);
    animation: ${appear} 0.4s ease-in-out forwards 1.5s;
`;

export const SplashScreen = () => {
    const theme = useTheme();
    return (
        <Section direction="y" spacing={20} grow={1} align="center" distribute="center">
            <TQSpinner size={24} color={theme.primary}  duration={1} />
            <Text1 size={3} weight="bold">Training Quest</Text1>
            <Text2 size={2} weight="bold" color={theme.subForeground}>Comment planifier un entrainement ?</Text2>
        </Section>
    );
};