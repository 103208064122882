import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export const useTrackers = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: window.location.href });
      ReactPixel.pageView();
    }
  }, [location]);

};