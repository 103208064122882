import {useInView} from 'react-cool-inview';
import {Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Button} from '../../widgets/Button';
import React from 'react';
import {keyframes} from '@emotion/react';

const PER_PAGE = 15;

type LoadingStates = 'loading' | 'loaded' | 'empty' | 'error';

export interface LoadDataProps<DataType> {
    data: DataType[];
    setData: Dispatch<SetStateAction<DataType[]>>;
    error: string;
    loadingState: LoadingStates;
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
    orderBy: string;
    setOrderBy: Dispatch<SetStateAction<string>>;
    filters: string[];
    setFilters: Dispatch<SetStateAction<string[]>>;
    moreBtn: ReactElement | null;
}

const appear = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const MoreButton = styled(Button)`
    opacity: 0;
    animation: ${appear} 0.3s;
    background-color: ${props => props.theme.primaryForeground};
`;

export const useLoadData = <DataType extends {id?: string}>(
    load: (searchQuery: string, page: number, perPage: number, orderBy: string, filter: string[]) => Promise<DataType[]>,
    options?: {perPage?: number; searchTimeout?: number; direction?: 'x' | 'y'},
) => {
    const [page, setPage] = useState(1);
    const [error, setError] = useState('');
    const [search, setSearch] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [filters, setFilters] = useState(['']);
    const [data, setData] = useState<DataType[]>([]);
    const [everythingIsLoaded, setEverythingIsLoaded] = useState(false);
    const [loadingState, setLoadingState] = useState<LoadingStates>('loading');
    const handleLoadMore = useCallback((forcedPage?: number) => {
        const effectivePage = forcedPage ?? page;
        setLoadingState('loading');
        load(search, effectivePage, options?.perPage || PER_PAGE, orderBy, filters || [])
            .then(newData => {
                setData(oldData => {
                    return [
                        ...oldData,
                        ...newData.filter(newDatum => newDatum.id === undefined || oldData.find(oldDatum => oldDatum.id === newDatum.id) === undefined),
                    ];
                });
                setEverythingIsLoaded(newData.length < (options?.perPage || PER_PAGE));
                setLoadingState('loaded');
            })
            .catch(e => {
                setLoadingState('error');
                setError(e.message);
            });
    }, [load, options?.perPage, orderBy, page, search, filters]);
    const {observe: moreBtnRef} = useInView({
        onEnter: () => {
            setPage(p => p + 1);
        },
    });
    useEffect(() => {
        setPage(1);
        setData([]);
        handleLoadMore(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, orderBy, filters]);
    useEffect(() => {
        if (page > 1) {
            handleLoadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    return {
        data,
        setData,
        error,
        loadingState,
        search,
        setSearch,
        orderBy,
        setOrderBy,
        filters,
        setFilters,
        moreBtn: everythingIsLoaded ? null : (
            <MoreButton ref={moreBtnRef} onClick={e => setPage(p => p + 1)}>
                +
            </MoreButton>
        ),
    };
};
