import React from 'react';
import styled from '@emotion/styled';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';

import {Logo} from '../logo/Logo';
import {TextLayout} from '../../widgets/Text';
import {Section} from '../../ui/Section';
import {useGoHome} from '../appRouter/useGoHome';

import {Thumbnail} from '../../widgets/Thumbnail';
import {useBackend} from '../backend/useBackend';
import {Icon} from '../../ui/Icon';
import {useTheme} from '@emotion/react';
import {LoadingPart} from '../../widgets/LoadingPart';
import amplitude from 'amplitude-js';

const LogoSection = styled(Section)`
    cursor: pointer;
`;

const NavSection = styled(Section)`
    /* box-shadow: 0 2px 20px ${props => props.theme.background}; */
    z-index: 1;
    @media (max-width: 600px) {
        justify-content: space-around;
        padding-top: 5px;
        padding-bottom: 15px;
        .logo {
            display: none;
        }
        border-top: 1px solid rgba(200, 200, 200, 0.2);
    }
`;

const NavLink = styled(Link)<{selected: boolean}>`
    display: flex;
    font-size: 1em;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    font-family: ${props => (props.selected ? props.theme.title : props.theme.body)};
    color: ${props => (props.selected ? props.theme.primary : props.theme.subForeground)};
    transition: all 0.2s;
    overflow: visible;
    .icon {
        display: none;
    }
    &:hover {
        color: ${props => props.theme.foreground};
    }
    &:active {
        transform: scale(0.9);
    }
    @media (max-width: 600px) {
        .icon {
            display: flex;
        }
        .text {
            display: none;
        }
    }
`;

const AccountLink = styled(Link)<{selected: boolean}>`
    display: flex;
    font-size: 1em;
    text-decoration: none;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    font-family: ${props => props.theme.title};
    color: ${props => (props.selected ? props.theme.foreground : props.theme.subForeground)};
    transition: all 0.3s;
    overflow: visible;
    .avatar {
        transition: all 0.3s;
        width: 32px;
        height: 32px;
        border: ${props => (props.selected ? `2px solid ${props.theme.foreground}` : '2px solid transparent')};
        border-radius: 50%;
    }
    .avatarText {
        display: none;
    }
    &:hover {
        .avatar {
            border: ${props => (props.selected ? `2px solid ${props.theme.foreground}` : `2px solid ${props.theme.subForeground}`)};
        }
    }
    &:active {
        transform: scale(0.95);
    }

    @media (max-width: 600px) {
        font-family: ${props => props.theme.body};
        font-size: 0.8em;
    }
`;

interface NavbarProps {
    hiddenRoutes?: string[];
}

export const Navbar: React.FC<NavbarProps> = ({hiddenRoutes}) => {
    const location = useLocation();
    const theme = useTheme();
    const backend = useBackend();
    const goHome = useGoHome();
    return !hiddenRoutes || hiddenRoutes?.indexOf(location.pathname) === -1 ? (
        <NavSection align="center" distribute="between" height="60px" padding="0 20px" shrink={0}>
            <LogoSection
                className="logo"
                align="center"
                spacing={10}
                onClick={() => {
                    goHome();
                    amplitude.getInstance().logEvent('click_on_logo', {page: 'navbar'});
                }}
            >
                <Logo width={24} />
                <TextLayout weight="bold" size={1.4}>
                    Training Quest
                </TextLayout>
            </LogoSection>
            <NavLink
                selected={location.pathname === '/home'}
                to="/home"
                onClick={() => {
                    amplitude.getInstance().logEvent('click_on_home', {page: 'navbar'});
                }}
            >
                <Icon className="icon" name="home" color={location.pathname === '/home' ? theme.primary : theme.subForeground} />
                <span className="text">
                    <FormattedMessage id="app.navbar.home" />
                </span>
            </NavLink>
            <NavLink
                selected={location.pathname.includes('/activity')}
                to="/activity"
                onClick={() => {
                    amplitude.getInstance().logEvent('click_on_activity', {page: 'navbar'});
                }}
            >
                <Icon className="icon" name="activity" color={location.pathname.includes('/activity') ? theme.primary : theme.subForeground} />
                <span className="text">
                    <FormattedMessage id="app.navbar.activities" />
                </span>
            </NavLink>
            <NavLink
                selected={location.pathname === '/explore'}
                to="/explore"
                onClick={() => {
                    amplitude.getInstance().logEvent('click_on_explore', {page: 'navbar'});
                }}
            >
                <Icon className="icon" name="search" color={location.pathname === '/explore' ? theme.primary : theme.subForeground} />
                <span className="text">
                    <FormattedMessage id="app.navbar.explore" />
                </span>
            </NavLink>
            {backend.authUser ? (
                <AccountLink
                    selected={location.pathname === `/user/${backend.authUser.id}`}
                    to={`/user/${backend.authUser.id}`}
                    onClick={() => {
                        amplitude.getInstance().logEvent('click_on_my_profile', {page: 'navbar'});
                    }}
                >
                    <Thumbnail className="avatar" src={backend.authUser.avatar} kind="avatar" radius="50%" />
                </AccountLink>
            ) : (
                <LoadingPart width="32px" height="32px" borderRadius="50%" />
            )}
        </NavSection>
    ) : null;
};
