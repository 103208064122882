import styled from '@emotion/styled';
import React, {FC, HTMLAttributes} from 'react';
import {Layout, LayoutProps} from '../ui/Layout';

interface TextProps {
    size?: number;
    color?: string;
    align?: 'left' | 'right' | 'center' | 'justify';
    weight?: 'bold' | 'normal';
    ellipsis?: boolean;
    lineHeight?: number
}

const Text = styled.span<TextProps>`
    color: ${props => props.color || props.theme.foreground};
    font-size: ${props => props.size || 1}em;
    line-height: ${props => props.lineHeight || 1.8}em;
    text-align: ${props => props.align || 'center'};
    font-family: ${props => (props.weight === 'bold' ? props.theme.title : props.theme.body)};
    ${props => props.ellipsis && `text-overflow: ellipsis; white-space: nowrap; overflow: hidden;`}
`;

interface TextLayoutProps extends LayoutProps, TextProps, HTMLAttributes<HTMLDivElement> {}

export const TextLayout: FC<TextLayoutProps> = ({size, color, align, weight, ellipsis, children, className, ...layout}) => {
    return (
        <Layout {...layout}>
            <Text className={className} size={size} color={color} align={align} weight={weight} ellipsis={ellipsis}>
                {children}
            </Text>
        </Layout>
    );
};
