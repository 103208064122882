import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useTheme} from '@emotion/react';

import {useBackend} from '../backend/useBackend';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import { QuestRowModel } from '../backend/models/QuestModel';
import amplitude from 'amplitude-js';
import { Icon } from '../../ui/Icon';
import { notify } from '../../components/toast/Toast';
import styled from '@emotion/styled';
import { Section } from '../../ui/Section';

const QuestFollowSection = styled(Section)`
    position: absolute;
    top: 15px;
    right: 20px;
    @media (max-width: 800px) {
        right: 15px;
    }
    @media (max-width: 600px) {
        right: 10px;
    }
`;

const QuestFollowIcon = styled(Icon)`
    height: auto;
    width: 100%;
    @media (max-width: 800px) {
        width: 80%;
    }
    @media (max-width: 600px) {
        width: 60%;
    }
`;

export const QuestFollow: React.FC<{quest: QuestRowModel, currentPage: String}> = ({quest, currentPage}) => {
    const theme = useTheme();
    const backend = useBackend();
    const {formatMessage} = useIntl();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isFollowed, setIsFollowed] = React.useState<boolean>(false);
    const handleQuestFollow = React.useCallback(
        async (e: { preventDefault: () => void; }) => {
            if (quest.id === undefined) {
                return;
            }
            e.preventDefault();
            setIsLoading(true);
            if (isFollowed) {
                amplitude.getInstance().logEvent(`remove_follow_quest_from_${currentPage}`, {questId: quest.id, page: currentPage});
                await backend.unfollowQuest(quest.id);
                notify(formatMessage({id: 'app.quest.unfollowed'}), 'error');
                e.preventDefault();
            } else {
                amplitude.getInstance().logEvent(`add_follow_quest_from_${currentPage}`, {questId: quest.id, page: currentPage});
                await backend.followQuest(quest.id);
                notify(formatMessage({id: 'app.quest.followed'}));
                e.preventDefault();
            }
            setIsFollowed(b => !b);
            setIsLoading(false);
        },
        [backend, currentPage, formatMessage, isFollowed, quest.id],
    );
    useEffect(() => {
        setIsFollowed(quest.followed);
    }, [quest.followed]);
    return isLoading ? (
        <QuestFollowSection spacing={5}>
            <DotsSpinner color={theme.primary} size={16} />
        </QuestFollowSection>
    ) : (
        <QuestFollowSection spacing={5}>
            <QuestFollowIcon
                color={theme.primary}
                name={isFollowed ? 'unfollow' : 'follow'}
                onClick={handleQuestFollow}
            />
        </QuestFollowSection>
    );
};
