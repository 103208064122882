import React from 'react';
import styled from '@emotion/styled';
import type {ReactNode} from 'react';

import {Section} from '../ui/Section';
import {Layout} from '../ui/Layout';

export type Options = {value: string; label: ReactNode}[];

interface SegmentedControlProps {
    value?: string;
    onChange: (value: string) => void;
    options: Options;
    defaultValue?: string;
}

const BarSection = styled(Section)`
    font-size: 0.9em;
    border-radius: 10px;
    background-color: ${props => props.theme.subBackground};
`;

const OptionRow = styled(Layout)<{selected: boolean}>`
    color: ${props => (props.selected ? props.theme.background : props.theme.subForeground)};
    cursor: pointer;
    transition: all 0.3s;
    font-family: ${props => props.theme.title};
    border-radius: 10px;
    background-color: ${props => (props.selected ? props.theme.foreground : 'transparent')};
    &:hover {
        color: ${props => (props.selected ? props.theme.background : props.theme.foreground)};
        transform: ${props => (props.selected ? undefined : 'scale(1.1)')};
    }
    &:active {
        transform: scale(0.9);
    }
    padding: 8px 20px;
    @media (max-width: 600px) {
        padding: 5px 12px;
    }
`;

export const SegmentedControl: React.FC<SegmentedControlProps> = ({value, onChange, options, defaultValue}) => {
    return (
        <BarSection padding="4px">
            {options.map(option => (
                <OptionRow
                    key={option.value}
                    selected={option.value === value || (option.value === defaultValue && !value)}
                    onClick={() => onChange(option.value)}
                >
                    {option.label}
                </OptionRow>
            ))}
        </BarSection>
    );
};
