import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Section} from '../../ui/Section';
import {Exercise} from './Exercise';
import {EmptyState} from '../../components/EmptyState';

import {Icon} from '../../ui/Icon';
import {useBackend} from '../backend/useBackend';
import {SearchInput} from '../../widgets/Input';
import {useLoadData} from '../backend/useLoadData';
import {ExerciseModel} from '../backend/models/ExerciseModel';
import {LoadingExerciseRow, ExerciseRow, NewExerciseRow} from './ExerciseRow';
import amplitude from 'amplitude-js';

interface ExercisesProps {
    currentExercise?: ExerciseModel;
    isOpen: boolean;
    onSelect?: (exercise: ExerciseModel) => void;
    onCancel: () => void;
}

const appear = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const ExercisesSection = styled(Section)`
    animation: ${appear} 0.3s;
`;

export const Exercises: React.FC<ExercisesProps> = ({isOpen, currentExercise, onSelect, onCancel}) => {
    const intl = useIntl();
    const backend = useBackend();
    const [exercise, setExercise] = React.useState<ExerciseModel>(
        currentExercise || {
            id: '',
            name: '',
            explanation: '',
            tags: [],
            playYoutubeId: '',
            explanationYoutubeId: '',
            known: false,
        },
    );
    const {data, setData, error, loadingState, search, setSearch, moreBtn} = useLoadData<ExerciseModel>((search, page, perPage, orderBy) => {
        return backend.searchExercises({search, page, perPage});
    });
    const [isCreating, setIsCreating] = React.useState<boolean>(false);
    const handleCancel = React.useCallback(() => {
        setIsCreating(false);
    }, []);
    const handleAddExercise = React.useCallback(() => {
        setIsCreating(true);
    }, []);

    const handleSave = React.useCallback(
        (exercise: ExerciseModel) => {
            if (isCreating) {
                setSearch(exercise.name);
            } else {
                setData(exercises => exercises.map(ex => (ex.id === exercise.id ? exercise : ex)));
                setSearch(exercise.name);
            }
            setIsCreating(false);
            setExercise({
                id: '',
                name: '',
                explanation: '',
                playYoutubeId: '',
                explanationYoutubeId: '',
                tags: [],
                known: false,
            });
        },
        [isCreating, setData, setSearch],
    );
    const handleRemove = React.useCallback(
        (exercise: ExerciseModel) => {
            setData(exercises => exercises.filter(ex => ex.id !== exercise.id));
            setIsCreating(false);
            setExercise({
                id: '',
                name: '',
                explanation: '',
                playYoutubeId: '',
                explanationYoutubeId: '',
                tags: [],
                known: false,
            });
        },
        [setData],
    );
    const handleEdit = React.useCallback((exercise: ExerciseModel) => {
        setExercise(exercise);
    }, []);
    React.useEffect(() => {
        if (currentExercise) {
            setExercise(currentExercise);
        }
    }, [currentExercise]);
    if (isCreating || exercise.id) {
        return <Exercise currentExerciseId={exercise.id} onSave={handleSave} onRemove={handleRemove} onCancel={handleCancel} onSelect={onSelect} />;
    }
    return (
        <ExercisesSection direction="y" spacing={10} height="100%" width="100%" padding="20px">
            <Section width="100%" spacing={10}>
                <SearchInput
                    grow={1}
                    placeholder={intl.formatMessage({id: 'app.exercises.searchPlaceholder'})}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onBlur={() => {
                        amplitude.getInstance().logEvent('search_exercise', {search: search, page: 'exercise_library'});
                    }}
                />
                <Icon name="close" onClick={onCancel} />
            </Section>
            <Section grow={1} direction="y" scroll={true}>
                {(loadingState === 'loaded' || loadingState === 'empty') && <NewExerciseRow onSelect={handleAddExercise} />}
                {exercise.id !== '' && search === '' && (
                    <ExerciseRow key={exercise.id} selected={true} onSelect={onSelect} onEdit={handleEdit} exercise={exercise} />
                )}
                {(loadingState === 'loading' || loadingState === 'loaded') &&
                    data
                        .filter(ex => exercise.id === '' || search !== '' || exercise.id !== ex.id)
                        .map((ex, i) => <ExerciseRow key={ex.id} selected={false} onSelect={onSelect} onEdit={handleEdit} exercise={ex} />)}
                {loadingState === 'loading' && [0, 1, 2, 3, 4, 5].map(id => <LoadingExerciseRow key={id} />)}
                {moreBtn}
                {loadingState === 'empty' && search !== '' && (
                    <EmptyState align="center" distribute="center" width="100%" grow={1}>
                        <FormattedMessage id="app.exercises.noExercisesFound" />
                    </EmptyState>
                )}
                {loadingState === 'error' && (
                    <EmptyState isError align="center" distribute="center" width="100%" height="100%" padding="20px">
                        <FormattedMessage id={`app.error.${error}`} />
                    </EmptyState>
                )}
            </Section>
        </ExercisesSection>
    );
};
