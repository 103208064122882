
import React, {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {Input, TextArea} from '../../components/inputs/Input';
import {notify} from '../../components/toast/Toast';
import {ReactComponent as PlusIcon} from './assets/plus.svg';
import {Icon} from '../../ui/Icon';
import {Layout} from '../../ui/Layout';
import {Section} from '../../ui/Section';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {ImagePicker} from '../../widgets/ImagePicker';
import {LoadingPage} from '../../widgets/LoadingPage';
import {InlineButton} from '../../widgets/Button';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {useBackend} from '../backend/useBackend';
import {QuestModel} from '../backend/models/QuestModel';
import {RichText} from '../../widgets/RichText/RichText';
import amplitude from 'amplitude-js';
import styled from '@emotion/styled';
import { useMedia } from 'react-use';
import { useTheme } from '@emotion/react';
import { EditableTags } from '../../components/Tags';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { moveItem } from '../../components/SortableList';
import { TrainingRowModel } from '../backend/models/TrainingModel';
import { LightboxCard } from '../../widgets/LightboxCard';
import { SelectQuestTrainings } from './SelectQuestTrainings';
import { EditQuestTrainingRow } from './EditQuestTrainingRow';
import { useShortcut } from '../../utils/useShortcut';
import { ErrorMessage } from '../auth/ErrorMessage';
import { QuestVisibilityPopover } from '../user/QuestVisibilityPopover';
import { PriceLightbox } from './PriceLightBox';

const TitleInput = styled(Input)`
    font-family: ${props => props.theme.title};
    font-size: 1.6em;
    box-sizing: border-box;
    max-width: 600px;
    width: 100%;
`;

const InputLabel = styled.p`
    fontWeight: 'bold';
    color: ${props => props.theme.foreground};
`

export const EditQuest: React.FC<{quest: QuestModel; onChange: (value: QuestModel) => void}> = ({
    quest,
    onChange,
}) => {

    const navigate = useNavigate();
    const addTraining = useLightboxState();
    const theme = useTheme();
    const matches = useMedia('(max-width: 800px)');
    const {id} = useParams<{id: string}>();
    const intl = useIntl();
    const backend = useBackend();
    const [saved, setSaved] = useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [bannerIsLoading, setBannerIsLoading] = React.useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();


    const handleEditTitle = React.useCallback(
        (e: { target: { value: any; }; }) => {
            onChange({...quest, title: e.target.value});
            setSaved(false);
        },
        [onChange, quest],
    );

    const handleBannerUpload = React.useCallback(
        async (file: File) => {
            if (!quest) {
                return;
            }
            setBannerIsLoading(true);
            if (quest.id) {
                try {
                    const t = await backend.uploadQuestBanner(quest.id, file);
                    onChange(t);
                } catch (err) {
                    notify(intl.formatMessage({id: (err as Error).message}), 'error');
                }
            }
            setBannerIsLoading(false);
        },
        [backend, intl, onChange, quest],
    );

    const handleEditIntro = React.useCallback(
        (e: { target: { value: any; }; }) => {
            onChange({...quest, intro: e.target.value});
            setSaved(false);
        },
        [onChange, quest],
    );

    const handleEditDescription = React.useCallback(
        (value: any) => {
            onChange({...quest, description: value});
            setSaved(false);
        },
        [onChange, quest],
    );

    const handleEditInstructions = React.useCallback(
        (value: any) => {
            onChange({...quest, instructions: value});
            setSaved(false);
        },
        [onChange, quest],
    );

    const handleEditSeoDescription = React.useCallback(
        (e: { target: { value: any; }; }) => {
            onChange({...quest, seoDescription: e.target.value});
            setSaved(false);
        },
        [onChange, quest],
    );

    const handleEditSeoKeywords = React.useCallback(
        (value: any) => {
            onChange({...quest, seoKeywords: value});
            setSaved(false);
        },
        [onChange, quest],
    );

    const handleAddTraining = React.useCallback(
        (value: TrainingRowModel) => {
            onChange({...quest, trainings: [...quest.trainings, value]});
            addTraining.close();
            setSaved(false);
        },
        [addTraining, onChange, quest],
    );

    const handleRemoveTraining = React.useCallback(
        (index: number) => (training: TrainingRowModel) => {
            onChange({...quest, trainings: quest.trainings.filter((training, i) => i !== index)});
            setSaved(false);
        },
        [onChange, quest],
    );

    const onDragEnd = (result: DropResult) => {
        if (!quest || !result.destination || result.destination.index === result.source.index) {
            return;
        }
        onChange({...quest, trainings: moveItem(quest.trainings, result.source.index, result.destination.index)});
        setSaved(false);
    };

    const handleSave = React.useCallback(async () => {
        setIsLoading(true);
        if (id === undefined) {
            setError('notFound');
            setIsLoading(false);
        } else {
            try {
                backend
                .updateQuest({...quest, id})
                .then(() => setSaved(true))
                .catch(err => setError(err.message))
                .finally(() => setIsLoading(false));
            } catch (err) {
                notify(intl.formatMessage({id: (err as Error).message}), 'error');
            }

        }
    }, [backend, id, intl, quest]);

    useShortcut('mod+s', handleSave, true);

    const handleBack = useCallback(async () => {
        await handleSave();
        amplitude.getInstance().logEvent('click_on_back_edit_quest', {questId: quest.id, page: 'edit_quest'});
        navigate(`/user/${backend.authUser?.id}`)
    }, [backend, handleSave, navigate, quest.id]);

    const handleChangeVisibility = React.useCallback(
        async (visibility: any) => {
            const newQuest = {...quest, visibility: visibility};
            onChange(newQuest);
            if (id !== undefined) {
                try {
                    await backend.updateQuest(newQuest);
                } catch (err) {
                    notify(intl.formatMessage({id: (err as Error).message}), 'error');
                }
            }
        },
        [backend, id, intl, onChange, quest],
    );

    const price = useLightboxState();

    return (
        <Section direction="y" grow={1}>
            <Lightbox isOpen={price.isOpen} onRequestClose={price.close}>
                <PriceLightbox isOpen={price.isOpen} quest={quest} onChange={onChange} />
            </Lightbox>
            <Section align="center" padding="0 20px" spacing={15} height="60px">
                    <Icon name="back" title={intl.formatMessage({id: 'app.quest.back'})} onClick={handleBack} />
                <Layout margin="0 auto 0 0 !important">
                    <QuestVisibilityPopover value={quest.visibility} onChange={handleChangeVisibility} questId={quest.id} />
                    <Layout margin="0 0 0 10px"></Layout>
                    <Icon
                        name="payment"
                        onClick={() => {
                            price.open();
                            amplitude.getInstance().logEvent('click_on_payments_button', {questId: quest.id, page: 'edit_quest'});
                        }}
                    />
                </Layout>
                {isLoading ? (
                        <DotsSpinner color={theme.subForeground} size={16} />
                    ) : (
                        <Icon
                            disabled={!quest.title || saved}
                            name={saved ? 'saved' : 'save'}
                            color={saved ? theme.foreground : theme.primary}
                            onClick={() => {
                                handleSave();
                                amplitude.getInstance().logEvent('save_quest', {questId: quest.id, page: 'edit_quest'});
                            }}
                        />
                )}
            </Section>
            <Section direction={matches ? 'y' : 'x'} grow={1} scroll={true}>
                {quest.id === undefined && <LoadingPage />}
                {quest.id !== undefined && (
                    <>
                        <Section direction="y" grow={matches ? undefined : 0.8} spacing={20} padding="0 20px 20px 20px" scroll={true}>
                            {/* Title */}
                            <InputLabel>Titre</InputLabel>
                            <TitleInput
                                placeholder={intl.formatMessage({id: 'app.quest.titlePlaceholder'})}
                                value={quest.title}
                                onChange={handleEditTitle}
                            />
                            {/* Banner */}
                            <Section direction="y" spacing={20}>
                                <InputLabel>Bannière</InputLabel>
                                <ImagePicker
                                    isLoading={bannerIsLoading}
                                    imageSrc={quest.banner}
                                    width="100%"
                                    height="100%"
                                    isAbsolute={false}
                                    onImagePick={handleBannerUpload}
                                />
                            </Section>
                            {/* Intro */}
                            <InputLabel style={{ top: "30px", position: "relative", margin: '40px 0 40px 0' }}>Introduction</InputLabel>
                            <Section direction="y" spacing={20}>
                                <TextArea value={quest.intro} onChange={handleEditIntro} placeholder={intl.formatMessage({id: 'app.quest.intro'})}></TextArea>
                            </Section>
                            {/* Description */}
                            <InputLabel>Description</InputLabel>
                            <RichText value={quest.description} onChange={handleEditDescription} />
                            {/* Instructions */}
                            <InputLabel>Instructions</InputLabel>
                            <RichText value={quest.instructions} onChange={handleEditInstructions} />
                            {/* Seo description */}
                            <InputLabel>Description utilisée pour le SEO</InputLabel>
                            <Section direction="y" spacing={20}>
                                <TextArea value={quest.seoDescription} onChange={handleEditSeoDescription}></TextArea>
                            </Section>
                            {/* Seo keywords */}
                            <InputLabel>Mots clés SEO par défaut, aucun</InputLabel>
                            <EditableTags value={quest.seoKeywords} onChange={handleEditSeoKeywords} />
                        </Section>
                        {/* Trainings */}
                        <Section direction="y" padding="0 20px" grow={matches ? undefined : 1} scroll={true}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {provided => (
                                        <Section direction="y" spacing={10} ref={provided.innerRef} {...provided.droppableProps}>
                                            {quest.trainings.length > 0 &&
                                                quest.trainings.map((training, i) => (
                                                    <EditQuestTrainingRow
                                                        isDragging={false}
                                                        key={i}
                                                        index={i}
                                                        training={training}
                                                        onRemove={handleRemoveTraining(i)}
                                                    />
                                                ))}
                                            {provided.placeholder}
                                        </Section>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {error && (
                                <ErrorMessage>
                                    <FormattedMessage id={`app.error.${error}`} />
                                </ErrorMessage>
                            )}

                            <div style={{paddingBottom: 20, backgroundColor: theme.background, paddingTop: 20}}>
                                <InlineButton onClick={addTraining.open}>
                                    <PlusIcon className="icon" />
                                    <span>
                                        <FormattedMessage id="app.quest.addTraining" />
                                    </span>
                                </InlineButton>
                            </div>
                            <Lightbox isOpen={addTraining.isOpen} onRequestClose={addTraining.close}>
                                <LightboxCard sheet={true} isOpen={addTraining.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                                    <SelectQuestTrainings isOpen={addTraining.isOpen}  onSelect={handleAddTraining} />
                                </LightboxCard>
                            </Lightbox>
                        </Section>
                    </>
                )}
            </Section>
        </Section>
    );
}
