import React from 'react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type {FC} from 'react';
import {backdropInAnimation, backdropOutAnimation, lightboxNoneAnimation} from './lightboxAnimations';
import type {AnimType} from './lightboxAnimations';

interface Transition {
    delay?: number;
    easing?: string;
    duration: number;
}

interface BackdropProps {
    isOpen: boolean;
    noAnim: boolean;
    transition: Transition;
    blur: number;
    opacity: number;
    background: string;
}

const Backdrop = styled.div<BackdropProps>`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    position: absolute;
    //
    opacity: ${props => props.opacity / 100};
    background: ${props => props.background};
    /* backdrop-filter: blur(10px); */
    /* backdrop-filter: saturate(0%); */
    /* backdrop-filter: ${props => (props.blur > 0 ? `blur(${props.blur}px)` : 'blur(10px)')}; */
    //
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    //
    animation: ${props => `${props.transition.duration}s ${props.transition.easing} ${props.transition.delay}s`}
        ${props => (props.noAnim ? lightboxNoneAnimation : props.isOpen ? backdropInAnimation : backdropOutAnimation)} forwards;
`;

interface LightboxProps {
    root?: HTMLElement | null;
    isOpen: boolean;
    animation?: AnimType;
    transition?: Transition;
    children?: React.ReactNode;
    //
    backdrop?: {
        blur?: number;
        opacity?: number;
        background?: string;
    };
    //
    onClose?: () => void;
    onRequestClose?: () => void;
}

export const Lightbox: FC<LightboxProps> = ({children, root, isOpen, animation, transition, backdrop, onRequestClose, onClose}) => {
    const backdropRef = useRef<HTMLDivElement>(null);

    const [showBox, setShowBox] = useState(isOpen);

    const closeTransition = useMemo<Transition>(() => transition || {delay: 0, easing: 'ease-in-out', duration: 0.3}, [transition]);

    const handleRequestClose = useCallback(
        (e: React.SyntheticEvent<HTMLDivElement>) => {
            if (e.target === backdropRef.current) {
                onRequestClose?.();
            }
        },
        [onRequestClose],
    );
    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (isOpen === false) {
            timeout = setTimeout(
                () => {
                    setShowBox(false);
                    onClose?.();
                },
                animation === 'none' ? 0 : ((closeTransition.delay || 0) + closeTransition.duration) * 1000,
            );
        } else {
            setShowBox(true);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [animation, isOpen, onClose, closeTransition.delay, closeTransition.duration]);
    if (!showBox) {
        return null;
    }
    return ReactDOM.createPortal(
        <Backdrop
            ref={backdropRef}
            blur={backdrop?.blur || 0}
            isOpen={isOpen}
            noAnim={(animation || 'center') === 'none'}
            opacity={backdrop?.opacity || 1}
            background={backdrop?.background || 'hsla(0, 0%, 0%, 0.7)'}
            transition={closeTransition}
            //
            onClick={handleRequestClose}
        >
            {children}
        </Backdrop>,
        root || document.body,
    );
};
