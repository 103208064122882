import styled from '@emotion/styled';

export const Button = styled.button`
    border-radius: ${props => props.theme.borderRadius};
    font-size: 1em;
    font-family: ${props => props.theme.title};
    font-weight: normal;
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    outline: none;
    transition: transform 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    .icon {
        padding-right: 10px;
    }
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
    &:active {
        transform: scale(0.95);
    }
    @media (max-width: 500px) {
        font-size: 0.9em;
    }
`;

export const PrimaryButton = styled(Button)`
    color: ${props => props.theme.primaryForeground};
    background-color: ${props => props.theme.primary};
    .icon * {
        fill: ${props => props.theme.primaryForeground};
    }
`;

export const ErrorButton = styled(Button)`
    color: ${props => props.theme.primaryForeground};
    background-color: ${props => props.theme.error};
    .icon * {
        fill: ${props => props.theme.primaryForeground};
    }
`;

export const SecondaryButton = styled(Button)`
    color: ${props => props.theme.subForeground};
    background-color: ${props => props.theme.subBackground};
    .icon * {
        fill: ${props => props.theme.subForeground};
    }
`;

export const InlineButton = styled(Button)`
    color: ${props => props.theme.primary};
    background-color: transparent;
    .icon * {
        fill: ${props => props.theme.primary};
    }
`;

export const InvisibleButton = styled.div`
    cursor: pointer;
    transition: all 0.2s;
    &:active {
        transform: scale(0.9);
    }
`;
