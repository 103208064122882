import React, {ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {Input} from '../../components/inputs/Input';
import {Section} from '../../ui/Section';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import {QuestModel} from '../backend/models/QuestModel';
import {Button, PrimaryButton} from '../../widgets/Button';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {useTheme} from '@emotion/react';
import amplitude from 'amplitude-js';

export const CreateQuestDialog: React.FC<{onClose: () => void}> = ({onClose}) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();
    const backend = useBackend();
    const [quest, setQuest] = React.useState<QuestModel>({
        id: '',
        title: '',
        intro: '',
        description: '',
        visibility: 'PRIVATE',
        banner: '',
        author: backend.authUser!,
        trainingsCount: 0,
        instructions: '',
        seoKeywords: [],
        seoDescription: '',
        followed: false,
        prices: [],
        trainings: []
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const handleChangeTitle = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setQuest(q => ({...q, title: e.target.value}));
    }, []);
    const handleCreate = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const q = await backend.createQuest(quest);
            navigate(`/quests/${q.id}?edit=true`);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }, [backend, navigate, quest]);
    return (
        <Section direction="y" padding="20px" spacing={20} width="100%">
            <TextLayout size={1.5} weight="bold" margin="0 auto" padding="0 40px">
                <FormattedMessage id="app.quest.createTitle" />
            </TextLayout>
            <Input placeholder={intl.formatMessage({id: 'app.quest.titlePlaceholder'})} value={quest.title} onChange={handleChangeTitle} />
            <Section distribute="between">
                <Button onClick={() => {
                        onClose();
                        amplitude.getInstance().logEvent('cancel_create_new_quest', {questTitle: quest.title, page: 'profile'});
                    }}
                >
                    <FormattedMessage id="app.training.cancel" />
                </Button>
                <PrimaryButton
                    disabled={isLoading || quest.title === ''}
                    onClick={() => {
                        handleCreate();
                        amplitude.getInstance().logEvent('create_new_training', {questTitle: quest.title, page: 'profile'});
                    }}
                >
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={18} /> : <FormattedMessage id={'app.training.create'} />}
                </PrimaryButton>
            </Section>
        </Section>
    );
};
