import styled from '@emotion/styled';
import React, {ChangeEvent, useState} from 'react';
import {useMedia} from 'react-use';
import {Section} from '../../ui/Section';
import {ActivityModel} from '../backend/models/ActivityModel';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '../../ui/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { DotsSpinner } from '../../widgets/Spinner/DotSpinner';
import { useTheme } from '@emotion/react';
import { useBackend } from '../backend/useBackend';
import { LoadingPage } from '../../widgets/LoadingPage';
import { RouteNotFound } from '../appRouter/RouteNotFound';
import { ErrorPage } from '../../widgets/ErrorPage';
import amplitude from 'amplitude-js';
import { TextLayout } from '../../widgets/Text';
import { Input, TextArea } from '../../widgets/Input';
import { Layout } from '../../ui/Layout';
import { useLightboxState } from '../../ui/Lightbox/useLightboxState';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { LightboxCard } from '../../widgets/LightboxCard';
import { Sports } from '../sports/Sports';
import { Types } from '../sports/Types';
import { ErrorButton, PrimaryButton, SecondaryButton } from '../../components/Button';
import { useLocale } from '../locale/Locale';
import { ResizeInput } from '../../components/inputs/ResizeInput';
import { LightboxConfirm } from '../../widgets/LightboxConfirm';

const TitleInput = styled(Input)`
    font-family: ${props => props.theme.title};
    font-size: 1.6em;
    box-sizing: border-box;
    width: 100%;
`;

const DateTimeInput = styled(Input)`

`;

DateTimeInput.defaultProps = {
    type: 'datetime-local',
};

export const EditActivity:  React.FC = () => {
    const navigate = useNavigate();
    const matches = useMedia('(max-width: 800px)');
    const locale = useLocale();
    const theme = useTheme();
    const [saved, setSaved] = useState(true);
    const backend = useBackend();
    const [isLoading, setIsLoading] = React.useState(true);
    const [activity, setActivity] = React.useState<ActivityModel>();
    const choseSport = useLightboxState();
    const choseType = useLightboxState();
    const remove = useLightboxState();
    const {id} = useParams<{id: string}>();
    const [error, setError] = useState('');
    const intl = useIntl();

    const handleBack = React.useCallback(() => {
        navigate(`/activity`);
    }, [navigate])

    const handleConfirmRemove = React.useCallback(async () => {
        if (activity) {
            backend
                .deleteActivity(activity.id)
                .then(() => navigate('/activity'))
                .catch(err => setError(err.message));
        }
    }, [backend, navigate, activity]);

    const handleSave = React.useCallback(async () => {
        setIsLoading(true);
        if (id === undefined) {
            setError('notFound');
            setIsLoading(false);
        } else {
            if (activity) {
                backend
                    .updateActivity({...activity, id})
                    .then(() => setSaved(true))
                    .catch(err => setError(err.message))
                    .finally(() => setIsLoading(false));
            }
        }
    }, [backend, id, activity]);

    const handleValidNumber = React.useCallback((value: string) => parseInt(value) >= 1 && parseInt(value) < 1000, []);
    const handleEditTitle = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (activity) {
            setActivity(a => ({...activity, title: e.target.value}));
            setSaved(false);
        }
    }, [activity]);

    const handleEditDuration = React.useCallback((duration: string) => {
        if (activity) {
            setActivity(a => ({...activity, duration: parseInt(duration) * 60}));
            setSaved(false);
        }
    }, [activity]);

    const handleSelectSport = React.useCallback((value: any) => {
        choseSport.close();
        if (activity) {
            setActivity(a => ({...activity, sport: value}));
            setSaved(false);
        }
    }, [choseSport, activity])

    const handleSelectType = React.useCallback((value: any) => {
        choseType.close();
        if (activity) {
            setActivity(a => ({...activity, type: value}));
            setSaved(false);
        }
    }, [choseType, activity])

    const handleEditNotes = React.useCallback(
        (e: { target: { value: any; }; }) => {
            if (activity) {
                setActivity({...activity, notes: e.target.value});
                setSaved(false);
            }
        }, [activity],
    );

    const handleEditDate = React.useCallback(
        (e: { target: { value: any; }; }) => {
            if (activity) {
                setActivity({...activity, date: e.target.value});
                setSaved(false);
            }
        }, [activity],
    );

    React.useEffect(() => {
        setIsLoading(true);
        if (id) {
            backend
                .getActivity(id)
                .then(a => {
                    setActivity(a);
                })
                .catch(err => {
                    setError(err.message);
                })
                .finally(() => setIsLoading(false));
        }
    }, [backend, id, intl]);
    if (isLoading) {
        return <LoadingPage />;
    }
    if (error === 'notFound' || activity === undefined) {
        return <RouteNotFound />;
    } else if (error) {
        return <ErrorPage error={error} />;
    }

    return(
        <Section margin="auto" direction="y" grow={1} width={matches ? '100%' : '50%'}>
            <Section distribute="center" align="center" padding="0 20px" spacing={15} height="60px">
                <Layout margin="0 auto 0 0 !important">
                    <Icon name="back" onClick={handleBack} />
                </Layout>
                {isLoading ? (
                    <DotsSpinner color={theme.subForeground} size={16} />
                ) : (
                    <>
                        <Icon
                            disabled={saved}
                            name={saved ? 'saved' : 'save'}
                            color={saved ? theme.foreground : theme.primary}
                            onClick={() => {
                                handleSave();
                                amplitude.getInstance().logEvent('save_activity', {activityId: activity.id, page: 'edit_activity'});
                            }}
                        />
                    </>
                )}
            </Section>
            {activity.id === undefined && <LoadingPage />}
            {activity.id !== undefined && (
                <>
                    <Section direction="y" width="100%" spacing={20} padding="0 20px 20px 20px" scroll={true}>
                        {/* title */}
                        <TitleInput
                                placeholder={intl.formatMessage({id: 'app.activity.titlePlaceholder'})}
                                value={activity.title}
                                onChange={handleEditTitle}
                        />
                        {/* duration */}
                        <div style={{marginLeft: '6px'}}>
                            <TextLayout weight="bold" size={1.1}>
                                <FormattedMessage id="app.activity.duration" />
                                <ResizeInput isBig={true} value={(activity.duration / 60).toString()} onValue={handleEditDuration} validate={handleValidNumber} />
                                <FormattedMessage id="app.activity.minutes" />
                            </TextLayout>
                        </div >
                        {/* sport */}
                        {(!activity.sport) ? (
                            <SecondaryButton onClick={choseSport.open}>
                                <FormattedMessage id="app.activity.choseSport" />
                            </SecondaryButton>
                        ) : (
                            <PrimaryButton onClick={choseSport.open}>
                                {locale.locale === 'fr-FR' ? activity.sport.frValue : activity.sport.enValue}
                            </PrimaryButton>
                        )}
                        {/* practice type */}
                        {(!activity.type) ? (
                            <SecondaryButton onClick={choseType.open}>
                                <FormattedMessage id="app.activity.choseType" />
                            </SecondaryButton>
                        ) : (
                            <PrimaryButton onClick={choseType.open}>
                                {locale.locale === 'fr-FR' ? activity.type.frValue : activity.type.enValue}
                            </PrimaryButton>
                        )}
                        {/* notes */}
                        <div style={{marginLeft: '6px'}}>
                            <TextLayout weight="bold" size={1.1}><FormattedMessage id="app.activity.notes" /></TextLayout>
                        </div>
                        <TextArea value={activity.notes} onChange={handleEditNotes} placeholder={intl.formatMessage({id: 'app.activity.notesPlaceholder'})}></TextArea>
                        {/* date */}
                        <DateTimeInput onChange={handleEditDate} value={activity.date ? (activity.date.substring(0,19)) : (new Date()).toISOString().substring(0,19)}/>
                        {/* TODO */}
                            {/* tags */}
                            {/* shape */}
                            {/* distance */}
                            {/* difficulty */}
                            {/* rating */}
                        <ErrorButton onClick={remove.open}>
                            <FormattedMessage id="app.activity.remove"/>
                        </ErrorButton>
                    </Section>
                </>
            )}
            <Lightbox isOpen={choseSport.isOpen} onRequestClose={choseSport.close}>
                <LightboxCard sheet={true} isOpen={choseSport.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Sports isOpen={choseSport.isOpen}  onSelect={handleSelectSport} onCancel={choseSport.close} />
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={choseType.isOpen} onRequestClose={choseType.close}>
                <LightboxCard sheet={true} isOpen={choseType.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Types isOpen={choseType.isOpen}  onSelect={handleSelectType} onCancel={choseType.close} />
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={remove.isOpen} onRequestClose={remove.close}>
                <LightboxConfirm
                    isOpen={remove.isOpen}
                    onCancel={() => {
                        remove.close();
                        amplitude.getInstance().logEvent('cancel_delete_activity', {activityId: activity.id, page: 'edit_activity'});
                    }}
                    onConfirm={() => {
                        handleConfirmRemove();
                        amplitude.getInstance().logEvent('confirm_delete_activity', {activityId: activity.id, page: 'edit_activity'});
                    }}
                    title={intl.formatMessage({id: 'app.activity.confirmRemoveActivityTitle'})}
                    message={intl.formatMessage({id: 'app.activity.confirmRemoveActivityMessage'})}
                />
            </Lightbox>
        </Section>
    )
}