import React, {useEffect, useState} from 'react';
import Iframe from 'react-iframe';
import {FormattedMessage} from 'react-intl';
import styled from '@emotion/styled';
import {MenuButton, MenuTitle, MenuLayout, Topbar, MenuIcon} from '../MenuElements';
import {keyframes} from '@emotion/react';

import {ReactComponent as Close} from '../assets/close.svg';
import {Section} from '../../../ui/Section';
import {RichTextRenderer} from '../../../widgets/RichText/RichTextRender';
import type {ExerciseModel} from '../../backend/models/ExerciseModel';

interface PauseModeProps {
    isOpen: boolean;
    exercise: ExerciseModel;
    totalTime: number;
    workoutId: string;
    setTitle: string;
    stepTitle: string;
    stepIndex: number;
    onClose: () => void;
    onConfirm: () => void;
}

const itemAppear = keyframes`
    0% { opacity: 0; transform: scale(0.5); }
    100% { opacity: 1; transform: scale(1); }
`;

const YoutubeIframe = styled(Iframe)<{delay?: number}>`
    opacity: 0;
    animation: ${itemAppear} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) ${props => props.delay || 0}s forwards;
    max-width: 100%;
    border: none;
    border-radius: ${props => props.theme.borderRadius};
    @media (max-width: 1000px) {
        width: 480px;
        height: 270px;
    }
    @media (max-width: 800px) {
        width: 320px;
        height: 180px;
    }
`;

const Description = styled(Section)`
    font-size: 1.5em;
    @media (max-width: 1200px) {
        font-size: 1.2em;
    }
    @media (max-width: 800px) {
        font-size: 1em;
    }
`;

const GotItButton = styled(MenuButton)`
    color: ${props => props.theme.primaryForeground};
    background-color: ${props => props.theme.explanation};
`;

export const ExplanationsMode: React.FC<PauseModeProps> = ({isOpen, exercise, totalTime, workoutId, setTitle, stepTitle, stepIndex, onClose, onConfirm}) => {
    const [showMode, setShowMode] = useState(isOpen);

    const handleClose = React.useCallback(
        (e: { stopPropagation: () => void; }) => {
            onClose();
            e.stopPropagation();
        },
        [onClose],
    );
    const handleConfirm = React.useCallback(
        (e: { stopPropagation: () => void; }) => {
            onConfirm();
            e.stopPropagation();
        },
        [onConfirm],
    );
    useEffect(() => {
        let ts: NodeJS.Timeout | null = null;
        if (isOpen === false) {
            ts = setTimeout(() => {
                setShowMode(false);
            }, 500);
        } else {
            setShowMode(true);
        }
        return () => {
            if (ts !== null) {
                clearTimeout(ts);
            }
        };
    }, [isOpen]);
    if (!showMode) {
        return null;
    }
    return (
        <MenuLayout direction="y" isOpen={isOpen}>
            <Topbar align="center" distribute="end" spacing={20}>
                <MenuIcon onClick={handleClose}>
                    <Close />
                </MenuIcon>
            </Topbar>
            <Section direction="y" grow={1} padding="0 0 20px 0" distribute="start" align="center" spacing={10} maxWidth="800px" scroll={true} margin="auto">
                <MenuTitle delay={0.1}>{exercise.name}</MenuTitle>
                <Section direction="y" grow={1} width="100%" align="center" padding="20px" spacing={20}>
                    {(exercise.explanationYoutubeId || exercise.playYoutubeId) && (
                        <YoutubeIframe
                            delay={0.2}
                            title="Exercise Preview"
                            width="640"
                            height="360"
                            url={`https://www.youtube.com/embed/${exercise.explanationYoutubeId || exercise.playYoutubeId}`}
                            allowFullScreen
                        ></YoutubeIframe>
                    )}
                    {exercise.explanation && (
                        <Description direction="y" width="100%" scroll={true}>
                            <RichTextRenderer description={exercise.explanation} />
                        </Description>
                        // <Description delay={0.2} style={{color: theme.subForeground}}>
                        //     {exercise.explanation}
                        // </Description>
                    )}
                </Section>
                <GotItButton delay={0.3} onClick={handleConfirm}>
                    <FormattedMessage id="app.play.gotIt" />
                </GotItButton>
            </Section>
        </MenuLayout>
    );
};
