import React from 'react';
import {StrictMode} from 'react';

import './index.css';
import App from './app/App';
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement!);
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

serviceWorkerRegistration.register();
