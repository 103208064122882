import React from 'react';
import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import {useTheme} from '@emotion/react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useLocation} from 'react-router-dom';

import {Link} from '../../components/Link';
import {Logo} from '../logo/Logo';
import {Section} from '../../ui/Section';
import {useGoHome} from '../appRouter/useGoHome';
import {useLocale} from '../locale/Locale';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {ErrorMessage} from './ErrorMessage';
import {useThemeSelector} from '../theme/Theme';
import {Input, PasswordInput, useInputState} from '../../widgets/Input';
import {InlineButton, PrimaryButton} from '../../widgets/Button';
import {useTrackers} from '../tracking/useTrackers';
import ReactGA from 'react-ga4';

const LogoSection = styled(Section)`
    cursor: pointer;
`;

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const backend = useBackend();
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [email, setEmail] = useInputState('');
    const [password, setPassword] = useInputState('');
    const [error, setError] = React.useState<string | undefined>();
    const EmailRegex = RegExp(/^[^@\s]+@[^@\s.]+\.[^@.\s]+$/i);
    const trainingId = new URLSearchParams(useLocation().search).get('training');
    const questId = new URLSearchParams(useLocation().search).get('quest');
    const userId = new URLSearchParams(useLocation().search).get('user');
    const {changeLocale} = useLocale();
    const {changeTheme} = useThemeSelector();
    const handleLogin = React.useCallback(async () => {
        setIsLoading(true);
        setError(undefined);
        try {
            if (!EmailRegex.test(email)) {
                throw new Error('wrongEmailFormat');
            }
            const user = await backend.login(email, password);
            changeLocale && changeLocale(user.settings.locale);
            changeTheme && changeTheme(user.settings.theme);
            amplitude.getInstance().setUserId(user.id);
            amplitude.getInstance().logEvent('login', {page: 'auth'});
            ReactGA.event('login');
            if (trainingId) {
                navigate(`/trainings/${trainingId}`);
            } else if (questId) {
                navigate(`/quests/${questId}`);
            } else if (userId) {
                navigate(`/user/${userId}`);
            } else {
                navigate('/home');
            }
        } catch (err) {
            setError((err as Error).message);
            setIsLoading(false);
        }
    }, [EmailRegex, email, backend, password, changeLocale, changeTheme, trainingId, questId, navigate, userId]);
    const handleForgotPassword = React.useCallback(() => {
        navigate('/forgotPassword' + (trainingId ? `?training=${trainingId}` : questId ? `?quest=${questId}` : userId ? `?user=${userId}` : ''));
    }, [navigate, questId, trainingId, userId]);

    const handleEnterToLoginEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await handleLogin()
        }
    };
    const goHome = useGoHome();
    useTrackers();

    const renderLink = (parts: React.ReactNode[]): React.ReactNode => (
        <Link to={`/register${trainingId ? `?training=${trainingId}` : questId ? `?quest=${questId}` : userId ? `?user=${userId}` : ''}`}>
            {parts}
        </Link>
    );

    return (
        <Section width="100%" height="100%" align="center" distribute="center">
            <Section direction="y" align="stretch" maxWidth="400px" width="100%" spacing={20} padding="20px">
                <LogoSection direction="y" align="center" spacing={20} onClick={goHome}>
                    <Logo width={100} />
                    <TextLayout weight="bold" size={2}>
                        Training Quest
                    </TextLayout>
                </LogoSection>
                <TextLayout margin="0 auto 20px auto" color={theme.subForeground}>
                    <FormattedMessage
                        id="app.auth.login.noAccount"
                        values={{ a: renderLink }}
                    />
                </TextLayout>
                <Input type="email" placeholder={formatMessage({id: 'app.auth.login.email'})} value={email} onChange={setEmail} />
                <PasswordInput
                    placeholder={formatMessage({id: 'app.auth.login.password'})}
                    value={password}
                    onChange={setPassword}
                    onKeyPress={handleEnterToLoginEvent}
                />
                <PrimaryButton disabled={isLoading} onClick={handleLogin}>
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={16} /> : <FormattedMessage id="app.auth.login.login" />}
                </PrimaryButton>
                {error && (
                    <ErrorMessage>
                        <FormattedMessage id={`app.error.${error}`} />
                    </ErrorMessage>
                )}
                <InlineButton onClick={handleForgotPassword}>
                    <FormattedMessage id="app.auth.login.forgotPassword" />
                </InlineButton>
            </Section>
        </Section>
    );
};
