import React, {useCallback, useMemo} from 'react';
import styled from '@emotion/styled';
import {Layout} from '../../ui/Layout';
import {Section} from '../../ui/Section';
import {SetModel, TrainingModel} from '../backend/models/TrainingModel';

interface PlayProgressBarProps {
    training: TrainingModel;
    setIndex: number;
    stepIndex: number;
    hasVideo: boolean;
    onSelect: (index: number) => void;
}

const BarLayout = styled(Section)`
    z-index: 2;
    height: 10px;
    flex-grow: 1;
    padding: 0 30px;
    @media (max-width: 500px) {
        height: 8px;
        padding: 0 15px;
    }
`;

const BarItem = styled(Layout)<{selected: boolean; hasVideo: boolean}>`
    cursor: pointer;
    background: ${props => (props.selected ? props.theme.foreground : props.hasVideo ? 'rgba(255, 255, 255, 0.2)' : props.theme.subBackground)};
    border-radius: 10px;
`;

export const PlayProgressBar: React.FC<PlayProgressBarProps> = ({training, setIndex, stepIndex, hasVideo, onSelect}) => {
    const sets = useMemo<SetModel[]>(() => {
        return training.sets
            .reduce((result: SetModel[], set: SetModel) => {
                return set.reps === 1
                    ? [...result, set]
                    : [...result, ...Array.from({length: set.reps}, (x, i) => i).map((i: number) => ({...set, title: `${set.title} (${i + 1}/${set.reps})`}))];
            }, [])
            .filter(set => set.steps.length > 0);
    }, [training.sets]);
    const countSteps = useCallback(
        (i: number) => sets.slice(0, i).reduce((result, set) => result + set.steps.reduce((stepCount, step) => stepCount + (step.rest > 0 ? 2 : 1), 0), 0),
        [sets],
    );
    return (
        <BarLayout spacing={10}>
            {sets.map((set, i) => (
                <Section key={i} spacing={5} grow={1}>
                    {i < setIndex && <BarItem grow={1} selected={true} hasVideo={hasVideo} onClick={() => onSelect(countSteps(i))} />}
                    {i === setIndex &&
                        set.steps.map((_, j) => (
                            <BarItem
                                key={j}
                                grow={1}
                                onClick={() => onSelect(set.steps.slice(0, j).reduce((count, step) => count + (step.rest > 0 ? 2 : 1), 0) + countSteps(i))}
                                selected={stepIndex >= j}
                                hasVideo={hasVideo}
                            />
                        ))}
                    {i > setIndex && <BarItem grow={1} selected={false} hasVideo={hasVideo} onClick={() => onSelect(countSteps(i))} />}
                </Section>
            ))}
        </BarLayout>
    );
};
