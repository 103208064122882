import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import {useMedia} from 'react-use';
import {Section} from '../../ui/Section';
import {LoadingPart} from '../../widgets/LoadingPart';
import {TextLayout} from '../../widgets/Text';
import {Thumbnail} from '../../widgets/Thumbnail';
import { TrainingRowModel } from '../backend/models/TrainingModel';
import { SelectExerciseButton } from '../exercises/SelectExerciseButton';
import { SelectQuestTrainingThumbnail } from './SelectQuestTrainingThumbnail';

const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const SelectQuestTrainingSection = styled(Section)<{selected: boolean}>`
    color: ${props => props.theme.foreground};
    cursor: pointer;
    animation: ${appear} 0.3s;
    transition: 0.3s all;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => (props.selected ? props.theme.subBackground : undefined)};
    .thumbnail {
        transition: transform 0.3s;
    }
    &:hover {
        background-color: ${props => props.theme.subBackground};
        .thumbnail {
            transform: scale(0.95);
        }
    }
    &:active {
        transform: scale(0.97);
    }
    ${props => props.selected && ` background-color: ${props.theme.subBackground};`}
`;

interface SelectQuestTrainingsRowProps {
    training: TrainingRowModel;
    selected: boolean;
    onSelect?: (training: TrainingRowModel) => void;
}

export const SelectQuestTrainingsRow: React.FC<SelectQuestTrainingsRowProps> = ({training, selected, onSelect}) => {
    return (
        <SelectQuestTrainingSection selected={selected} spacing={10} padding="10px" align="center">
            <SelectQuestTrainingThumbnail thumbnail={training.thumbnail} />
            <Section direction="y" distribute="center" grow={1}>
                <TextLayout align="left" weight="bold">
                    {training.title}
                </TextLayout>
            </Section>
            {!training.paid && onSelect && (
                <SelectExerciseButton width="32" height="32" viewBox="0 0 32 32" fill="none" onClick={() => onSelect(training)}>
                    <circle cx="16" cy="16" r="16" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.8709 11.5987C24.2897 11.0175 23.3473 11.0175 22.7661 11.5987L14.3465 20.0182L9.61057 15.2823C9.02933 14.701 8.08694 14.701 7.5057 15.2823C6.92445 15.8635 6.92445 16.8059 7.5057 17.3871L13.2941 23.1755C13.8754 23.7568 14.8177 23.7568 15.399 23.1755C15.4 23.1745 15.4011 23.1734 15.4022 23.1723L24.8709 13.7036C25.4522 13.1223 25.4522 12.18 24.8709 11.5987Z"
                    />
                </SelectExerciseButton>
            )}
            { training.paid && (
                <>
                    <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.1" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#323232"/>
                        <path d="M14.5 9C14.5 9 13.7609 8 11.9999 8C8.49998 8 8.49998 12 11.9999 12C15.4999 12 15.5 16 12 16C10.5 16 9.5 15 9.5 15" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 7V17" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#323232" stroke-width="2"/>
                    </svg>
                </>
            )}
        </SelectQuestTrainingSection>
    );
};

export const LoadingExerciseRow: React.FC = () => {
    const match500 = useMedia('(max-width: 500px)');
    return (
        <Section spacing={10} padding="10px" width="100%">
            <Thumbnail kind="empty" width={match500 ? '70px' : '90px'} isLoading={true} />
            <Section direction="y" spacing={10} grow={1} distribute="center">
                <LoadingPart width="70%" height="15px" />
                <LoadingPart width="50%" height="10px" />
            </Section>
        </Section>
    );
};
