import React from 'react';

import type {ThemeModel} from './models/ThemeModel';
import {defaultTheme} from './themes/defaultTheme';
import {darkTheme} from './themes/darkTheme';
import {ThemeProvider} from '@emotion/react';

interface ThemeContextProps {
    theme: ThemeModel;
    changeTheme: (themeName: string) => void;
}

interface ThemeSelectorProviderProps {
    children: React.ReactNode;
}

export const ThemeContext = React.createContext<Partial<ThemeContextProps>>({});
export const useThemeSelector = () => React.useContext(ThemeContext);

export const themes: Record<string, ThemeModel> = {
    default: defaultTheme,
    dark: darkTheme,
};

export const ThemeSelectorProvider: React.FC<ThemeSelectorProviderProps> = ({children}) => {
    const [theme, setTheme] = React.useState<ThemeModel>(themes[localStorage.getItem('theme') || 'default']);
    const changeTheme = React.useCallback((themeName: string) => {
        setTheme(themes[themeName]);
        localStorage.setItem('theme', themeName);
    }, []);
    return (
        <ThemeContext.Provider value={{theme, changeTheme}}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};
