import React, {ChangeEvent} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import styled from '@emotion/styled';
import {MenuTitle, MenuButton, MenuLabel, MenuLayout, Topbar, TimerLabel, Content} from '../MenuElements';

import {ReactComponent as Star} from '../assets/star.svg';
import {TextArea, useInputValue} from '../../../components/inputs/Input';
import {Select} from '../../../components/Select';
import {notify} from '../../../components/toast/Toast';
import {keyframes} from '@emotion/react';
import {Section} from '../../../ui/Section';
import {transparentize} from 'polished';
import {DotsSpinner} from '../../../widgets/Spinner/DotSpinner';
import {formatSeconds} from '../../backend/models/TrainingModel';
import {useBackend} from '../../backend/useBackend';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';

interface FinishModeProps {
    isOpen: boolean;
    onExit: () => void;
    totalTime: number;
    trainingId: string;
}

const drawCheck = keyframes`
    from {
        opacity: 0;
        stroke-dashoffset: 100;
    }
    50% {
        opacity: 0;
        stroke-dashoffset: 100;
    }
    to {
        opacity: 1;
        stroke-dashoffset: 0;
    }
`;

const drawCircle = keyframes`
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const Check = styled.svg`
    width: 120px;
    height: 120px;
    .check {
        animation: ${drawCheck} 0.75s forwards;
        animation-delay: 0.3s;
        fill: none;
        stroke: ${props => props.theme.primary};
        stroke-width: 10;
        stroke-linecap: round;
        stroke-dasharray: 100;
        stroke-dashoffset: 0;
        opacity: 0;
    }
    .shadowCircle {
        transform: scale(0);
        animation: ${drawCircle} 0.5s forwards;
        transform-origin: 50% 50%;
        fill: ${props => transparentize(0.8, props.theme.primary)};
    }
    .checkCircle {
        transform: scale(0);
        opacity: 0;
        animation: ${drawCircle} 0.3s forwards;
        animation-delay: 0.2s;
        transform-origin: 50% 50%;
        fill: ${props => transparentize(0.8, props.theme.primary)};
    }
    transform: scale(1.5);
    margin-bottom: 50px;
    @media (max-width: 1200px) {
        font-size: 3em;
        transform: scale(1.2);
        margin-bottom: 30px;
    }
    @media (max-width: 800px) {
        font-size: 2em;
        transform: scale(1);
        margin-bottom: 20px;
    }
`;

const FeedbackLink = styled(MenuLabel)<{disabled: boolean}>`
    color: ${props => props.theme.primary};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

const StarItem = styled.div<{selected: boolean}>`
    flex: initial;
    [fill='black'] {
        fill: ${props => (props.selected ? '#FFD077' : props.theme.subBackground)};
    }
    transform: scale(0.9);
    &:hover {
        transform: scale(1);
        [fill='black'] {
            fill: #ffd077;
        }
    }
    cursor: pointer;
`;

const MenuSelect = styled(Select)`
    font-size: 1.4em;
    @media (max-width: 1200px) {
        font-size: 1.2em;
    }
    @media (max-width: 800px) {
        font-size: 0.9em;
    }
`;

export const FinishMode: React.FC<FinishModeProps> = ({isOpen, onExit, totalTime, trainingId}) => {
    const intl = useIntl();
    const backend = useBackend();
    const [quality, setQuality] = React.useState(4);
    const [difficulty, setDifficulty] = React.useState(2);
    const [feedback, setFeedback] = useInputValue();
    const [feedbackMode, setFeebackMode] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleDifficulty = React.useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setDifficulty(parseInt(e.target.value));
    }, []);
    const handleExit = React.useCallback(async () => {
        try {
            setIsLoading(true);
            await backend.createActivity({
                id: '',
                trainingId: trainingId,
                difficulty: difficulty + 1,
                rating: quality + 1,
                duration: Math.floor(totalTime),
                feedback,
                drop: false,
                manual: false
            });
            amplitude.getInstance().logEvent('complete_training', {
                trainingId: trainingId,
                difficulty: difficulty + 1,
                rating: quality + 1,
                duration: Math.floor(totalTime),
                feedback,
                page: 'play_view'
            });
            ReactGA.event('complete_training', {trainingId: trainingId});
            notify(intl.formatMessage({id: 'app.play.statSuccess'}));
        } catch (e) {
            notify(intl.formatMessage({id: 'app.play.failedToSaveStat'}));
        }
        setIsLoading(false);
        onExit();
    }, [backend, difficulty, feedback, intl, onExit, quality, totalTime, trainingId]);
    const handleFeedback = React.useCallback(() => {
        if (quality === -1 || difficulty === -1) {
            return;
        }
        setFeebackMode(true);
    }, [difficulty, quality]);
    return (
        <MenuLayout direction="y" isOpen={isOpen} align="center" distribute="center">
            <Topbar align="center" distribute="start" spacing={20} width="100%">
                <TimerLabel>{formatSeconds(Math.floor(totalTime))}</TimerLabel>
            </Topbar>
            <Content direction="y" grow={1} spacing={30} distribute="center">
                <Section direction="y" distribute="center" align="center">
                    <Check>
                        <circle className="checkCircle" cx="60" cy="60" r="60" />
                        <path className="check" d="M 35,60 L 55,80 L 85,50" />
                    </Check>
                    <MenuTitle>
                        <FormattedMessage id="app.play.wellDone" />
                    </MenuTitle>
                </Section>
                <Section direction="y" distribute="center" align="center" spacing={10}>
                    {!feedbackMode && (
                        <>
                            <MenuLabel delay={0.2}>
                                <FormattedMessage id="app.play.qualityLabel" />
                            </MenuLabel>
                            <Section spacing={5}>
                                <StarItem selected={quality >= 0} onClick={() => setQuality(0)}>
                                    <Star />
                                </StarItem>
                                <StarItem selected={quality >= 1} onClick={() => setQuality(1)}>
                                    <Star />
                                </StarItem>
                                <StarItem selected={quality >= 2} onClick={() => setQuality(2)}>
                                    <Star />
                                </StarItem>
                                <StarItem selected={quality >= 3} onClick={() => setQuality(3)}>
                                    <Star />
                                </StarItem>
                                <StarItem selected={quality >= 4} onClick={() => setQuality(4)}>
                                    <Star />
                                </StarItem>
                            </Section>
                            <MenuLabel delay={0.3}>
                                <FormattedMessage id="app.play.difficultyLabel" />
                            </MenuLabel>
                            <MenuSelect value={difficulty} onChange={handleDifficulty}>
                                <option value={-1} disabled>
                                    {intl.formatMessage({id: 'app.play.chooseDifficulty'})}
                                </option>
                                <option value={0}>{intl.formatMessage({id: 'app.play.tooHard'})}</option>
                                <option value={1}>{intl.formatMessage({id: 'app.play.littleTooHard'})}</option>
                                <option value={2}>{intl.formatMessage({id: 'app.play.perfectForMe'})}</option>
                                <option value={3}>{intl.formatMessage({id: 'app.play.littleTooEasy'})}</option>
                                <option value={4}>{intl.formatMessage({id: 'app.play.tooEasy'})}</option>
                            </MenuSelect>
                            <FeedbackLink disabled={quality === -1 || difficulty === -1} onClick={handleFeedback}>
                                <FormattedMessage id="app.play.feedback" />
                            </FeedbackLink>
                        </>
                    )}
                    {feedbackMode && (
                        <>
                            <TextArea value={feedback} onChange={setFeedback} placeholder={intl.formatMessage({id: 'app.play.feedbackPlaceholder'})}></TextArea>
                        </>
                    )}
                </Section>
            </Content>
            <MenuButton delay={0.4} style={{marginBottom: 40}} disabled={quality === -1 || difficulty === -1 || isLoading} onClick={handleExit}>
                {isLoading ? <DotsSpinner size={18} /> : <FormattedMessage id={'app.play.confirm'} />}
            </MenuButton>
        </MenuLayout>
    );
};
