import styled from '@emotion/styled';

import {Section} from '../Section';
import {animData} from './lightboxAnimations';
import type {AnimType} from './lightboxAnimations';

interface LightboxSectionProps {
    delay?: number;
    isOpen: boolean;
    easing?: string;
    duration?: number;
    animation?: AnimType;
}

export const LightboxSection = styled(Section)<LightboxSectionProps>`
    animation: ${props => props.duration || 0.3}s ${props => props.easing || 'ease-out'} ${props => props.delay || 0}s
        ${props => (props.isOpen ? animData[props.animation || 'center'].in : animData[props.animation || 'center'].out)} forwards;
`;
