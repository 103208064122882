// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'app.loading': 'Loading...',
    'app.toInstall': 'To install, tap',
    'app.addToHomeScreen': "then 'Add to Home screen'",
    'app.reloadApp' : 'TrainingQuest has been updated {reloadAppLink} to get the latest version!',
    'app.reloadAppLink' : 'click here',


    //
    'app.yes': 'Yes',
    'app.no': 'No',

    //
    'app.errorTitle': 'Oops! An error occured 😬',
    'app.backToLogin': 'Back to login',

    //
    'app.today': 'today',
    'app.tomorrow': 'tomorrow',
    'app.yesterday': 'yesterday',

    //
    'app.visibility.PUBLIC': 'Public',
    'app.visibility.PRIVATE': 'Private',
    'app.visibility.HIDDEN': 'Hidden',

    //
    'app.mon': 'Monday',
    'app.tue': 'Tuesday',
    'app.wed': 'Wednesday',
    'app.thu': 'Thursday',
    'app.fri': 'Friday',
    'app.sat': 'Saturday',
    'app.sun': 'Sunday',

    // Dialog
    'app.dialog.confirm': `Confirm`,
    'app.dialog.cancel': `Cancel`,

    // Image Picker
    'widgets.imagePicker.dragImage': 'Click or Drag to upload',
    'widgets.imagePicker.uploadFile': 'Upload image',
    'widgets.richText.imgPlaceholder': 'Image URL',
    'widgets.richText.videoPlaceholder': 'https://youtu.be/video_id',
    'widgets.richText.linkPlaceholder': 'Link URL',
    'widgets.richText.add': 'Add',

    // Youtube Link
    'app.youtubeLink.howTo': `How do I get a YouTube link?`,

    // Navbar
    'app.navbar.explore': `Explore`,
    'app.navbar.home': `Home`,
    'app.navbar.activities': `My activities`,
    'app.navbar.tools': `Tools`,

    // RouteNotFound
    'app.appRouter.routeNotFound.title': `Page not found 😱`,
    'app.appRouter.routeNotFound.subTitle': `Oups! This page doesn't seem to exists or you're not allowed to access, sorry..`,
    'app.appRouter.routeNotFound.goBack': `Back to home`,

    // Login
    'app.auth.login.title': `Welcome back 🎉`,
    'app.auth.login.noAccount': `No account yet? <a>Create an account</a>`,
    'app.auth.login.email': `Email`,
    'app.auth.login.password': `Password`,
    'app.auth.login.login': `Let's go!`,
    'app.auth.login.forgotPassword': `Forgot password?`,
    'app.auth.login.showPassword': `Show password`,
    'app.auth.login.wrongEmailFormat': `Error in email`,
    'app.auth.login.serverError': `Something went wrong! check your entries! 🧐`,
    'app.auth.login.userProgram': `You already subscribed to this program, login and edit your config !`,
    'app.auth.login.noUserProgram': `We already know each other 😬, login and subscribe directly from program's page !`,

    // Register
    'app.auth.register.subtitle': `Create your account and start training now!`,
    'app.auth.register.username': `Username`,
    'app.auth.register.email': `Email`,
    'app.auth.register.password': `Password`,
    'app.auth.register.confirmPassword': `Confirm password`,
    'app.auth.register.register': `Let's go!`,
    'app.auth.register.gotAccount': `I already have an account`,
    'app.auth.register.serverError': `Something went wrong! check your entries! 🧐`,
    'app.auth.register.emailInvalid': `Invalid email`,
    'app.auth.register.emailTaken': `Email already taken, please login or generate new password 😉`,
    'app.auth.register.usernameTaken': `Username already taken`,
    'app.auth.register.usernameBlank': `Please provide a username`,
    'app.auth.register.usernameLengthInvalid': `Username length must be between 3 and 30 chars.`,
    'app.auth.register.usernameFormatInvalid': `Usernames can only contain letters, numbers, underscores and points.`,
    'app.auth.register.usernameBadFormat': `Usernames can only contain letters, numbers, underscores and points.`,
    'app.auth.register.passwordMustBeSixChars': `Password must be at least 6 chars.`,
    'app.auth.register.knowYouBetter': 'Let\'s meet...',
    'app.auth.register.interests': 'What kind of sport or skill are you interested in?',
    'app.auth.register.otherSuggestions': 'Is there any other sport or skill you’d like to get better at?',
    'app.auth.register.climbing': 'Climbing',
    'app.auth.register.fitness': 'Fitness',
    'app.auth.register.flexibility': 'Flexibility',
    'app.auth.register.handstand': 'Handstand',
    'app.auth.register.martialArts': 'Martial Arts',
    'app.auth.register.streetworkout': 'Street Workout',

    // Forgot password
    'app.auth.forgotPassword.subtitle': `Forgot password?\nSend an email to create a new password`,
    'app.auth.forgotPassword.email': `Email`,
    'app.auth.forgotPassword.sendEmail': `Send email`,
    'app.auth.forgotPassword.backToLogin': `Back to login`,
    'app.auth.forgotPassword.serverError': `An error occured`,
    'app.auth.forgotPassword.emailInvalid': `Invalid email`,
    'app.auth.forgotPassword.emailSent': `Check your emails! 😉`,

    // Reset password
    'app.auth.resetPassword.subtitle': `Type in your new password (at least 6 chars.)`,
    'app.auth.resetPassword.passwordMustBeSixChars': `Password must be at least 6 chars.`,
    'app.auth.resetPassword.passwordsDontMatch': `Password and confirmation don't match`,
    'app.auth.resetPassword.password': `Password`,
    'app.auth.resetPassword.passwordConfirmation': `Password confirmation`,
    'app.auth.resetPassword.ResetPassword': `Reset`,
    'app.auth.resetPassword.backToLogin': `Back to login`,
    'app.auth.resetPassword.passwordSaved': `Password save!`,
    'app.auth.resetPassword.serverError': `Something went wrong, check your entries! 🧐`,

    // Subscription activation
    'app.auth.subscriptionActivation.title': `Activate your account 💪`,
    'app.auth.subscriptionActivation.subtitle': `Get access to your trial`,
    'app.auth.subscriptionActivation.username': `Username`,
    'app.auth.subscriptionActivation.password': `Password`,
    'app.auth.subscriptionActivation.usernameLengthInvalid': `Username length must be between 3 and 30 chars.`,
    'app.auth.subscriptionActivation.usernameFormatInvalid': `All username characters must be alphanumeric`,
    'app.auth.subscriptionActivation.passwordMustBeSixChars': `Password must be at least 6 chars.`,
    'app.auth.subscriptionActivation.serverError': `Server error, we have been notified, we will come back to you ASAP`,
    'app.auth.subscriptionActivation.activate': `GO`,

    // User
    'app.user.myTrainings': 'My trainings',
    'app.user.myQuests': 'My quests',
    'app.user.trainings': 'Trainings',
    'app.user.quests': 'Quests',

    // trainings
    'app.training.difficulty.-1': 'All levels',
    'app.training.difficulty.0': 'Beginner',
    'app.training.difficulty.1': 'Intermediate',
    'app.training.difficulty.2': 'Advanced',
    'app.training.difficulty.3': 'Expert',

    'app.training.RECENTLY_CREATED': 'Recently created',
    'app.training.RECENTLY_UPDATED': 'Recently updated',
    'app.training.MOST_PLAYED': 'Most played',
    'app.training.MOST_BOOKMARKED': 'Most bookmarked',
    'app.training.MOST_DIFFICULT': 'Most difficult',
    'app.training.LEAST_DIFFICULT': 'Least difficult',

    'app.training.premium': 'Buy',
    'app.training.quest': 'Quest',

    // Exercises
    'app.exercises.searchPlaceholder': `Search exercice...`,
    'app.exercises.addButton': `Create`,
    'app.exercises.loading': `Loading exercises...`,
    'app.exercises.noExercisesFound': `No exercise found for this search, create one 👆`,
    'app.exercises.addExercise': `Create an exercise`,

    // Exercise
    'app.exercise.newExercise': 'New exercise',
    'app.exercise.create': `Create exercise`,
    'app.exercise.edit': `Edit exercise`,
    'app.exercise.remove': `Remove exercise`,
    'app.exercise.saveToast': `Exercise saved!`,
    'app.exercise.confirmRemoveTitle': 'Are you sure?',
    'app.exercise.confirmRemoveMessage': `Once you delete this exercise, it can't be undone...`,
    'app.exercise.descriptionPlaceholder': `Exercise description...`,
    'app.exercise.namePlaceholder': `Exercise name...`,
    'app.exercise.createSuccess': 'Exercise created successfuly!',
    'app.exercise.updateSuccess': 'Exercise updated successfuly!',
    'app.exercise.exercisePlaceholder': 'Exercise video',
    'app.exercise.explanationPlaceholder': 'Explanation video',
    'app.exercise.noExplanations':
        "There is no explanations for this exercise. If you think the exercise is not self explanatory, don't hesitate to send a message to the author 😁",

    // Search exercise
    'app.exercises.searchExercise.emptyState': `No exercises yet. You must create some exercises to use it in your trainings!`,

    // Library
    'app.library.trainings': `Trainings`,
    'app.library.exercises': `Exercises`,
    'app.library.programs': `Programs`,

    // Explore
    'app.explore.questTitle': 'Choose your quest',
    'app.explore.questDescription': 'Quests are interactive training programs designed with progressive trainings to make you achieve your goals 🤙',
    'app.explore.learnMore': 'Learn more',
    'app.explore.recentlyPlayed': 'Recently played',
    'app.explore.noPublicWorkouts': `There are no public trainings yet, but it won't be long 😉`,
    'app.explore.programs': 'Choose your quest',
    'app.explore.programsSubtitle': 'Quests are interactive training programs designed with progressive trainings to make you achieve your goals',
    'app.explore.allTrainings': 'All trainings',
    'app.explore.allTrainingsSubtitle': 'Discover the trainings created by our community ✨',
    'app.explore.welcomeTitle': 'Hi, {username} 👋',
    'app.explore.welcomeSubtitle':
        'Welcome aboard! Training Quest allows you to track your physical activity, create your own trainings, and also follow training programs (which we call quests) proposed by the community. What will be your first action? 😎',
    'app.explore.startExploring': 'Start exploring',
    'app.explore.createTraining': 'Create training',
    'app.explore.trainings': 'Trainings',
    'app.explore.quests': 'Quests',

    'app.create.title': 'Create',
    'app.create.myTrainings': 'My trainings',
    'app.create.userTrainings': "{user}'s trainings",
    'app.create.myQuests': 'My quests',
    'app.create.userQuests': "{user}'s quests",
    'app.create.exercisesLibrary': 'Exercises Library',
    'app.create.subTitle': 'Training Quest is an open platform, which means that anyone can create content and share their knowledge. Start creating now!',
    'app.create.viewQuest': 'View',
    'app.create.editQuest': 'Edit',

    // Quests
    'app.quest.difficulty.-1': 'All levels',
    'app.quest.difficulty.0': 'Beginner',
    'app.quest.difficulty.1': 'Intermediate',
    'app.quest.difficulty.2': 'Advanced',
    'app.quest.difficulty.3': 'Expert',
    'app.quest.overview': 'Overview',
    'app.quest.instructions': 'Instructions',
    'app.quest.trainings': 'Trainings',
    'app.quest.activity': 'Activity',
    'app.quest.playedBy': 'played by',
    'app.quest.unlockToAccess': '🔒 Unlock this quest to get access to all the content!',
    'app.quest.premiumQuestion': '💎 Premium Question',
    'app.quest.premiumQuestionDescription': 'This is a question asked to the coach by a quest member. To see all the answers and ask your own questions, unlock the quest today using the button… ',
    'app.quest.gotIt': 'Got it!',
    'app.quest.back': 'Back',
    'app.quest.titlePlaceholder': 'Title',
    'app.quest.intro': 'Small text that will be shown in the explorer, should give a few words about the quest.',
    'app.quest.addTraining': 'Add training',
    'app.quest.followed': 'Quest followed!',
    'app.quest.unfollowed': 'Quest unfollowed.',
    'app.quest.newQuest': '+ Create new quest',
    'app.quest.createTitle': 'Create new quest',
    'app.quest.privateDescription': `No one except you can see this is quest. If you want to share this, use hidden or public visibility.`,
    'app.quest.publicDescription': `Everyone can see this quest, it appears on your Profile and in the Explore page.`,
    'app.quest.hiddenDescription': `This quest doesn’t show on your page or the Explore page. However anyone with the link can access it.`,
    'app.quest.private': `Private`,
    'app.quest.public': `Public`,
    'app.quest.hidden': `Hidden`,

    // Trainings
    'app.trainings.loading': `Loading trainings...`,
    'app.trainings.add': `Add`,
    'app.trainings.title': `My trainings`,
    'app.training.shareLinkCopied': `Share link copied!`,
    'app.trainings.searchPlaceholder': `Search training...`,
    'app.trainings.addButton': `Create`,
    'app.trainings.noTrainingYet': `No training here yet 🤷‍♂️`,
    'app.trainings.noSearchResult': `No training matching your search 😬`,

    // Home
    'app.home.myPrograms': 'My programs',
    'app.home.programsEmptyState': "You didn't subscribe to any program yet.",
    'app.home.recentlyPlayed': `Recently played`,
    'app.home.recentlyPlayedEmptyState': `Your training history is empty`,
    'app.home.bookmarks': `My bookmarks`,
    'app.home.totalTrainingDone': 'Trainings done',
    'app.home.totalTrainingTime': 'Training time',
    'app.home.avgTrainingTimePerDay': 'Average training time',
    'app.home.TrainingTimePerDay': 'Training time per day',
    'app.home.otherTrainings': 'Other trainings',
    'app.home.activitiesStats': 'Training stats',
    'app.home.emptyActivitiesStats' : 'No recently played trainings yet. Start {exploreLink} trainings and quests, or {createTrainingLink} of your own and play it!',
    'app.home.exploreLink' : 'exploring',
    'app.home.createTrainingLink' : 'create a training',
    'app.home.followedQuests' : 'Followed quests',
    'app.home.emptyFollowedQuests' : "No quest followed. Start {exploreLink} trainings and quests then click on the dumbbell icon to see them here, or {handleInterestsLink} what you want to learn!",
    'app.home.handleInterestsLink' : 'tell us',

    // Bookmarks
    'app.bookmarks.loading': `Loading trainings...`,
    'app.bookmarks.title': `My bookmarks`,
    'app.bookmarks.searchPlaceholder': `Search bookmarks...`,
    'app.bookmarks.noBookmarks': `Go to the Explore page and find some trainings to bookmark! 😉`,

    // Activity
    'app.activity.activities': `Activities`,
    'app.activity.history': `History`,
    'app.activity.statistics': `Statistics`,
    'app.activity.bookmarks': `Bookmarks`,
    'app.activity.playedThe': 'Played the ',
    'app.activity.createTitle': 'Create new activity',
    'app.activity.titlePlaceholder': 'Title (optional)',
    'app.activity.create': 'Create',
    'app.activity.cancel': 'Cancel',
    'app.activity.duration': 'Activity duration : ',
    'app.activity.minutes': ' minutes',
    'app.activity.choseSport': 'Chose sport',
    'app.activity.choseType': 'Chose activity type',
    'app.activity.search': 'Curling',
    'app.activity.notes': 'Personal notes',
    'app.activity.notesPlaceholder': 'Content of the session, how you felt...',
    'app.activity.remove': 'Delete',
    'app.activity.confirmRemoveActivityTitle': 'Sure ?',
    'app.activity.confirmRemoveActivityMessage': 'Once deleted this activity is gone for good.',
    'app.activity.wellDone': 'Well done!',

    // Sports
    'app.sports.searchPlaceholder': 'Curling',

    // Training
    'app.training.newTraining': 'New training',
    'app.training.createTitle': 'Create a new training',
    'app.training.titleLabel': 'Title',
    'app.training.youtubeIntro': 'Intro video',
    'app.training.youtubeOutro': 'Outro video',
    'app.training.thumbnailLabel': 'Image',
    'app.training.descriptionLabel': 'Description',
    'app.training.tagsLabel': 'Tags',
    'app.training.setsLabel': 'Sets',
    'app.training.titlePlaceholder': `Training title...`,
    'app.training.start': `Start training`,
    'app.training.edit': `Edit`,
    'app.training.remove': `Remove`,
    'app.training.duplicate': `Duplicate`,
    'app.training.descriptionPlaceholder': `Training description...`,
    'app.training.newSet': `Set`,
    'app.training.addSet': `Add set`,
    'app.training.addExercise': `Add exercise`,
    'app.training.save': `Save`,
    'app.training.saved': `Saved`,
    'app.training.cancel': `Cancel`,
    'app.training.create': `Create`,
    'app.training.back': `Back`,
    'app.training.private': `Private`,
    'app.training.public': `Public`,
    'app.training.hidden': `Hidden`,
    'app.training.bookmark': `bookmark`,
    'app.training.bookmarks': `bookmarks`,
    'app.training.trainingSaved': `Training saved!`,
    'app.training.privateDescription': `No one except you can see this is training. If you want to share this, use hidden or public visibility.`,
    'app.training.publicDescription': `Everyone can see this training, it appears on your Profile and in the Explore page.`,
    'app.training.hiddenDescription': `This training doesn’t show on your page or the Explore page. However anyone with the link can access it.`,
    'app.training.confirmRemoveTitle': `Are you sure?`,
    'app.training.confirmRemoveMessage': `Once this training is deleted, it's lost forever...`,
    'app.training.DURATION': `seconds`,
    'app.training.REPS': `reps`,
    'app.training.DISTANCE': `meters`,
    'app.training.MAX_HOLD': `max hold`,
    'app.training.MAX_REPS': `max reps`,
    'app.training.MAX_DISTANCE': `max distance`,
    'app.training.REST': `rest`,
    'app.training.NO_REST': `not rest`,
    'app.training.copy': 'Copy',
    'app.training.share': 'Share',
    'app.training.saveActivity': 'Save activity',

    // Workout row
    'app.workoutRow.bookmark': `bookmark`,
    'app.workoutRow.bookmarks': `bookmarks`,
    'app.workoutRow.by': 'by',

    // Price
    'app.price.title': 'Configure pricing',
    'app.price.description': `You can specify prices and intervals, if the price value is zero it won't be used. If you already have customers for a given price, they will keep the old price and it will be added to your pricing history.`,
    'app.price.currency': 'Currency',
    'app.price.freePlays': 'Free plays',
    'app.price.prices': 'Prices',
    'app.price.history': 'Payment history',
    'app.price.historyEmpty': 'No pricing yet, this training is free.',
    'app.price.once': 'once',
    'app.price.every': 'every',
    'app.price.week': 'week',
    'app.price.month': 'month',
    'app.price.year': 'year',
    'app.price.priceOnce': '{price}{symbol} once',
    'app.price.pricePerWeek': '{price}{symbol} every {interval} week',
    'app.price.pricePerMonth': '{price}{symbol} every {interval} month',
    'app.price.pricePerYear': '{price}{symbol} every {interval} year',
    'app.price.added': 'Added the {date}',
    'app.price.replacePrice': 'Replace price',
    'app.price.createTrainingPrice': 'Create price',
    'app.price.removePrice': 'Remove price',

    // Payment
    'app.payment.trainingTitle': 'Unlock this training',
    'app.payment.questTitle': 'Unlock the quest',
    'app.payment.trainingDescription': `Once unlocked, you can play this training as much as you want!`,
    'app.payment.questDescription': `Once unlocked you can play all trainings of the quest as much as you want!`,
    'app.payment.subDescription': `80% of your payment will go to your coach, the rest to fund TrainingQuest development.`,
    'app.payment.once': 'unlock forever',
    'app.payment.buy': 'Buy',
    'app.payment.everyNWeek': 'every {n} week',
    'app.payment.everyNMonth': 'every {n} month',
    'app.payment.everyNYear': 'every {n} year',
    'app.payment.WEEK': ' / {n} week',
    'app.payment.MONTH': ' / {n} month',
    'app.payment.YEAR': ' / {n} year',
    'app.payment.subscribe': 'Subscribe',
    'app.payment.freePlays': 'free plays',
    'app.payment.noFreePlays': 'No free plays, unlock it to play',
    'app.payment.questPublicTraining': 'This free training is part of a quest, unlock it to access all trainings',
    'app.payment.noFreePlaysQuest': 'No free plays, unlock the quest',
    'app.payment.noMoreFreePlays': 'No more free plays on this training, unlock it to play',
    'app.payment.noMoreFreePlaysQuest': 'This training belongs to a quest, discover ',
    'app.payment.unlockDescription': 'on this training, unlock it for unlimited plays',
    'app.payment.unlock': 'Unlock',
    'app.payment.unlockQuest': 'Unlock quest',
    'app.payment.cancelled': 'Your payment has been canceled',
    'app.payment.expired': 'Your payment has been expired',
    'app.payment.error': 'There is an error with your payment',
    'app.payment.locked': 'Unlock this quest to get access to all the content',
    'app.payment.lockedTraining': 'Unlock this training to get access to all the content',
    'app.payment.openPortal': 'Open portal',
    'app.payment.safariWarning': 'If nothing happens, you probably have to allow popups (Settings > Safari > Block Pop-ups)',

    // Play
    'app.play.getReadyFor': 'Get ready for',
    'app.play.restFor': 'Rest for ',
    'app.play.trainingStartsIn': 'Training starts in ',
    'app.play.done': 'Done',
    'app.play.rest': 'Rest',
    'app.play.gotIt': 'Got it!',
    'app.play.skip': 'Skip',
    'app.play.ready': 'Ready?',
    'app.play.timeToRest': 'Time to rest',
    'app.play.watchIntro': 'Watch intro',
    'app.play.startTraining': 'Start training',
    'app.play.wellDone': '🏆 Well done!',
    'app.play.exerciseExplanation': 'Exercise explanation',
    'app.play.totalTime': 'Total time: ',
    'app.play.exit': 'Exit',
    'app.play.confirm': 'Confirm',
    'app.play.continue': 'Continue',
    'app.play.restart': 'Restart',
    'app.play.wantToQuit': 'Are you sure you want to quit? 🤨',
    'app.play.qualityLabel': 'How much did you enjoy this training?',
    'app.play.difficultyLabel': 'How difficult was it for you?',
    'app.play.feedback': 'More feedback?',
    'app.play.feedbackPlaceholder': 'Enter your feedbacks...',
    'app.play.tooHard': 'Too hard for me 🥵',
    'app.play.littleTooHard': 'A little bit too hard 😬',
    'app.play.perfectForMe': 'Perfect for me 👌',
    'app.play.littleTooEasy': 'A little bit too easy 🤷‍♂️',
    'app.play.tooEasy': 'Way to easy for me 🤪',
    'app.play.chooseDifficulty': 'Select a difficulty',
    'app.play.failedToSaveStat': 'Failed to save your stat 😔',
    'app.play.statSuccess': '🏆 Well done!',
    'app.play.pauseTitle': 'Training paused',
    'app.play.pauseDescription': 'If you quit you will loose your progress 😬',

    // Programs
    'app.program.infos': 'Infos',
    'app.program.config': `General`,
    'app.program.configuration': `Configuration`,
    'app.program.startTrialMessage': `✅ {count} free trainings, then {price}/month<br>✅ No credit card required<br>✅ Unsubscribe anytime`,
    'app.program.expiredMessage': `Your trial period has expired, buy this program to unlock more trainings.`,
    'app.program.startTrial': `Try this program for free`,
    'app.program.startTrialConfirm': `Let's go!`,
    'app.program.payments': `Manage subscriptions`,
    'app.program.paymentsMessage': `A payment error occured. Update your payment informations by clicking the button above.`,
    'app.program.buyThisProgram': `Subscribe to this program for {price}/month`,
    'app.program.rebuyThisProgram': `Resume your subscription for {price}/month`,
    'app.program.canceledMessage': `You've cancelled your subscription`,
    'app.program.trialMessage': `You have <strong>{remaining} out of {count} trainings left</strong>, subscribe to this program to unlock all trainings`,
    'app.program.howLong': `How long do your daily training last in general?`,
    'app.program.whichDays': `Which day(s) of the week do you want to train?`,

    'app.program.update': 'Update',
    'app.program.complete': 'Complete',
    'app.program.replace': 'Replace',
    'app.program.paymentSuccess': 'Payment success, you will receive a confirmation email.',
    'app.program.paymentError': "Payment cancel, you haven't been charged.",

    // Settings

    'app.settings.title': `Settings`,
    'app.settings.general': `General`,
    'app.settings.banner': 'Banner',
    'app.settings.language': `Language`,
    'app.settings.userAccount': `User account`,
    'app.settings.userProfile': 'Profile',
    'app.settings.updateUsername': 'Update username',
    'app.settings.updateEmail': 'Update email',
    'app.settings.updatePassword': 'Update password',
    'app.settings.fullname': 'Full name',
    'app.settings.newUsername': `Your username must be unique, only letters, numbers, - and _ and between 2 and 20 characters.`,
    'app.settings.newEmail': `New email`,
    'app.settings.newPassword': `New password`,
    'app.settings.update': `Update`,
    'app.settings.logout': `Logout`,
    'app.settings.fr-FR': `🇫🇷 French`,
    'app.settings.en-US': `🇺🇸 English`,
    'app.settings.theme': 'Theme',
    'app.settings.lightTheme': '☀️ Light mode',
    'app.settings.darkTheme': '🌙 Dark mode',
    'app.settings.bio': `Bio`,
    'app.settings.bioPlaceholder': `Some info about you or your studio...`,
    'app.settings.websiteUrl': `Website Url`,
    'app.settings.portalNavigate': `My subscriptions`,
    'app.settings.removeAccount': `Remove account`,
    'app.settings.confirmRemoveTitle': `Are you sure to want to remove your account?`,
    'app.settings.confirmRemoveMessage': `Once your account is removed you can't get it back!`,
    'app.settings.userSaved': `User saved!`,
    'app.settings.passwordChanged': `Password changed successfully!`,
    'app.settings.soundTheme': 'Sound theme',
    'app.settings.default': '🎵 Default',
    'app.settings.boxing': '🥊 Boxing',
    'app.settings.digital': '🤖 Digital',
    'app.settings.gong': '🧘‍♂️ Gong',
    'app.settings.magic': '🧙‍♂️ Magic',
    'app.settings.sonar': '🦇 Sonar',
    'app.settings.win': '🥇 Win',
    'app.settings.wtf': '👽 WTF',
    'app.settings.payment': 'Payments and bills',

    'tags.addTag': `Tag's name`,
    'tags.newTag': 'New tag...',

    // Interests
    'app.interests.updateInterests': 'Update my interests',
    'app.interests.update': 'Update',

    // Tools
    'app.tools.newActivity': 'New activity',
    'app.tools.newTraining': 'New training',
    'app.tools.stopwatch': 'Stopwatch',
    'app.tools.startStopwatchTitle': '⏱️ Coming soon!',
    'app.tools.startStopwatchSubtitle': 'Click the button below to upvote this feature.',
    'app.tools.startStopwatchUpvote': 'Upvote 👍',
    'app.tools.upvotedStartStopwatch': 'Upvoted!',

    // Errors
    'app.error.unknown': 'Internal server error',
    'app.error.serverError': 'Internal server error',
    'app.error.badEmailOrPassword': 'Invalid email or password',
    'app.error.wrongEmailFormat': 'Invalid email format',
    'app.error.emailAlreadyTaken': 'Email already taken',
    'app.error.invalidEmail': 'Invalid email format',
    'app.error.usernameAlreadyTaken': 'Username already taken',
    'app.error.usernameBlank': "Please provide a username",
    'app.error.emailBlank': "Please provide an email",
    'app.error.usernameFormatInvalid': 'Username only letters, numbers, - and _ and between 2 and 20 characters',
    'app.error.passwordMustBeSixChars': 'Password must be at least 6 characters',
    'app.error.missingEmail': 'Email must be provided',
    'app.error.missingUsername': 'Username must be provided',
    'app.error.userNotFound': "User doesn't exist",
    'app.error.unprocessableEmail': 'Invalid email',
    'app.error.passwordsDontMatch': "Passwords don't match",
    'app.error.passwordMissing': 'Password must be provided',
    'app.error.userNotAuthenticated': 'You are disconnected',
    'app.error.userNotAccessible': "You don't have access to this user",
    'app.error.fileMustBeJpgJpegPng': 'Images must be jpg or png',
    'app.error.fileIsTooBig': "Images can't be over 3MB",
    'app.error.nameAlreadyTaken': 'This name is already taken',
    'app.error.exerciseNotFound': "This exercise doesn't exists or has been removed",
    'app.error.trainingNotFound': "This training doesn't exists or has been removed",
    'app.error.userNotAllowed': "You're not allowed",
    'app.error.objectTypeBadFormat': 'Bad object type format',
    'app.error.priceIsMissing': 'Price is missing',
    'app.error.missingObjectTypeOrId': 'Missing object type or id',
    'app.error.objectNotFound': 'Object not found',
    'app.error.unprocessablePrice': 'Unprocessable price',
    'app.error.missingPriceType': 'Missing price type',
    'app.error.priceTypeBadFormat': 'Invalid price type format',
    'app.error.objectPrivate': 'Object private',
    'app.error.objectNotPayable': 'Object not payable',
    'app.error.authorDoesntNeedToPay': "Author doesn't need to pay",
    'app.error.productHasNoCurrentPrice': 'Product has no current price',
    'app.error.selectedPricingNotPositive': 'Selected pricing not positive',
    'app.error.alreadyPaying': 'Already paying',
    'app.error.currentPaymentErrored': 'There is an issue with your payment, go to your stripe portal from settings to fix it',
    'app.error.errorPleaseRetry': 'Unexpected error, please retry',
};
