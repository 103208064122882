import React from 'react';
import AutosizeInput from 'react-input-autosize';
import {useIntl} from 'react-intl';
import styled from '@emotion/styled';

import {Section} from '../ui/Section';
import {useInputState} from '../widgets/Input';

import {ReactComponent as CloseIcon} from './assets/close.svg';

const TagsSection = styled(Section)`
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0px 10px 0px;
    margin: 0px 10px;
`;

const Tag = styled(Section)`
    cursor: default;
    color: ${props => props.theme.background};
    padding: 0 8px;
    font-size: 0.8em;
    height: 25px;
    font-family: ${props => props.theme.title};
    border-radius: 10px;
    background-color: ${props => props.theme.foreground};
    margin-bottom: 8px;
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

interface TagsProps {
    tags: string[];
}

export const Tags: React.FC<TagsProps> = ({tags}) => {
    return (
        <TagsSection>
            {tags.map(tag => (
                <Tag key={tag}>{tag}</Tag>
            ))}
        </TagsSection>
    );
};

interface SelectableTagsProps {
    selectedTags: string[];
    tags: string[];
    labels?: string[];
    onSelect: (tag: string, isSelected: boolean) => void;
}

const SelectableTag = styled(Tag)<{selected: boolean}>`
    cursor: pointer;
    color: ${props => (props.selected ? props.theme.background : props.theme.subForeground)};
    background-color: ${props => (props.selected ? props.theme.foreground : props.theme.subBackground)};
`;

export const SelectableTags: React.FC<SelectableTagsProps> = ({selectedTags, tags, labels, onSelect}) => {
    return (
        <TagsSection>
            {tags.map((tag, i) => (
                <SelectableTag
                    align="center"
                    onClick={() => {
                        onSelect(tag, selectedTags.indexOf(tag) === -1);
                    }}
                    selected={selectedTags.indexOf(tag) !== -1}
                    key={tag}
                >
                    {labels ? labels[i] : tag}
                </SelectableTag>
            ))}
        </TagsSection>
    );
};

interface EditableTagsProps {
    value: string[];
    onChange: (value: string[]) => void;
}

const CloseButton = styled(CloseIcon)`
    cursor: pointer;
    * {
        fill: ${props => props.theme.primaryForeground};
    }
`;

const TagInput = styled(AutosizeInput)`
    input {
        color: ${props => props.theme.foreground} !important;
        font-family: ${props => props.theme.title};
        font-size: 0.7em;
        background-color: ${props => props.theme.subBackground};
        border: none;
        outline: none;
        padding: 0 8px !important;
        height: 25px;
        transition: all 0.2s;
        border-radius: ${props => props.theme.borderRadius};
    }
    input:hover {
        background-color: ${props => props.theme.selectedBackground};
    }
    input:focus {
        background-color: ${props => props.theme.selectedBackground};
    }
`;

export const EditableTags: React.FC<EditableTagsProps> = ({value, onChange}) => {
    const {formatMessage} = useIntl();
    const [tags, setTags] = React.useState<string[]>(value);
    const [newTag, setNewTag] = useInputState('');
    const removeTag = React.useCallback(
        (oldTag: string) => {
            const newTags = tags.filter(tag => tag !== oldTag);
            setTags(newTags);
            onChange(newTags);
        },
        [onChange, tags],
    );
    const handleAddTag = React.useCallback(() => {
        if (tags.find(t => t === newTag) === undefined && /^[a-zA-Z0-9 ]+$/.test(newTag)) {
            const trimTag = newTag.trim();
            if (trimTag.length > 0) {
                const newTags = [...tags, trimTag];
                setTags(newTags);
                onChange(newTags);
            }
        }
        setNewTag('');
    }, [newTag, onChange, setNewTag, tags]);
    const handleKeyDown = React.useCallback(
        (e: { key: string; }) => {
            if (e.key === 'Enter') {
                handleAddTag();
            }
        },
        [handleAddTag],
    );
    React.useEffect(() => {
        setTags(value);
    }, [value]);
    return (
        <TagsSection>
            {tags.map(tag => (
                <Tag spacing={5} align="center" key={tag}>
                    <div>{tag}</div>
                    <CloseButton onClick={() => removeTag(tag)} />
                </Tag>
            ))}
            {tags.length < 10 && (
                <TagInput
                    // FIXME: why warning?
                    placeholder={formatMessage({id: 'tags.newTag'})}
                    value={newTag}
                    onChange={setNewTag}
                    onKeyDown={handleKeyDown}
                    onBlur={handleAddTag}
                />
            )}
        </TagsSection>
    );
};
