import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {useMedia} from 'react-use';
import {useTheme} from '@emotion/react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {Play} from '../play/Play';
import {TextLayout} from '../../widgets/Text';
import {Layout} from '../../ui/Layout';
import {Input} from '../../widgets/Input';
import {SetRow} from './SetRow';
import {notify} from '../../components/toast/Toast';
import {Section} from '../../ui/Section';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {Thumbnail} from '../../widgets/Thumbnail';
import {LightboxCard} from '../../widgets/LightboxCard';
import {Icon, IconName} from '../../ui/Icon';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {YoutubeResponsive} from '../../components/YoutubeResponsive';
import {Button, GoldButton, PrimaryButton} from '../../widgets/Button';

import mailIcon from './assets/mail.png';
import redditIcon from './assets/reddit.png';
import twitterIcon from './assets/twitter.png';
import whatsappIcon from './assets/whatsapp.png';
import {TrainingSubtitle} from './TrainingSubtitle';
import {TrainingBookmark} from './TrainingBookmark';
import {RouteNotFound} from '../appRouter/RouteNotFound';
import {TrainingModel} from '../backend/models/TrainingModel';
import {useBackend} from '../backend/useBackend';
import {LoadingPage} from '../../widgets/LoadingPage';
import {ErrorPage} from '../../widgets/ErrorPage';
import {RichTextRenderer} from '../../widgets/RichText/RichTextRender';
import {EditTraining} from './EditTraining';
import {PriceRowModel} from '../backend/models/PriceModel';
import {PaymentLightbox} from './PaymentLightbox';
import {Link} from '../../components/Link';
import NoSleep from 'nosleep.js';
import { CreateActivityDialog } from './CreateActivityDialog';

import { useTrackers } from '../tracking/useTrackers';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';

const Title = styled(Section)`
    font-family: ${props => props.theme.title};
    font-size: 2.5em;
    @media (max-width: 800px) {
        font-size: 2em;
        text-align: center;
    }
`;

const ShareIcon = styled.a`
    color: ${props => props.theme.subForeground};
    display: flex;
    font-size: 0.9em;
    transition: all 0.3s;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    img {
        width: 64px;
        margin-bottom: 5px;
        transform: scale(0.9);
        transition: all 0.3s;
    }
    &:hover {
        color: ${props => props.theme.foreground};
        img {
            transform: scale(1);
        }
    }
`;

const SubscribeSection = styled(Section)`
    background-color: ${props => props.theme.subBackground};
    .golden {
        color: #e8c27b;
        font-family: ${props => props.theme.title};
    }
`;

const QuestLink = styled(Link)`
    padding-left: 5px;
    color: ${props => props.theme.primary};
    text-decoration: none;
    font-family: ${props => props.theme.title};
`;

export const Training: React.FC = () => {
    const noSleep = useMemo(() => new NoSleep(), []);
    const backend = useBackend();
    const share = useLightboxState();
    const createActivityLightBox = useLightboxState();
    const {id} = useParams<{id: string}>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const intl = useIntl();
    const navigate = useNavigate();
    useTrackers();
    const theme = useTheme();
    const matches = useMedia('(max-width: 800px)');
    const shareUrl = useMemo(() => `https://trainingquest.co/trainings/${id}`, [id]);
    const editParam = new URLSearchParams(useLocation().search).get('edit');
    const payment = useLightboxState();
    const [playIsOpen, setPlayIsOpen] = React.useState<boolean>(false);
    const [training, setTraining] = React.useState<TrainingModel>();
    const successPaymentParam = new URLSearchParams(useLocation().search).get('success');
    const cancelPaymentParam = new URLSearchParams(useLocation().search).get('cancelled');
    React.useEffect(() => {
        if (successPaymentParam === 'true' && training) {
            amplitude.getInstance().logEvent('paid_training', {trainingId: training?.id, page: 'training'});
            ReactGA.event('purchase', {trainingId: training?.id});
        } else if (cancelPaymentParam === 'true' && training) {
            amplitude.getInstance().logEvent('cancel_training_payment', {questId: training?.id, page: 'training'});
        }
    }, [training, successPaymentParam, cancelPaymentParam]);
    const currentPrice = useMemo<PriceRowModel | undefined>(() => {
        return training?.prices && training?.prices.filter(p => p.current)[0];
    }, [training?.prices]);
    const handleStartWorkout = React.useCallback(async () => {
        if (!noSleep.isEnabled) {
            noSleep.enable();
        }
        try {
            if (currentPrice) {
                if (training) {
                    const result = await backend.verifyPayment(training?.id);
                    setTraining(result);
                    await backend.createNewPlay({
                        trainingId: training?.id
                    });
                }
                setPlayIsOpen(true);
            } else {
                setPlayIsOpen(true);
                if (training) {
                    await backend.createNewPlay({
                        trainingId: training?.id
                    });
                }
            }
        } catch (e) {
            console.log('Verify payment error: ', e);
        }
    }, [backend, currentPrice, noSleep, training]);
    const copyLinkRef = useRef<HTMLInputElement>(null);
    const handleShare = React.useCallback(() => {
        navigator.clipboard.writeText(shareUrl);
        copyLinkRef.current?.select();
        notify(intl.formatMessage({id: 'app.training.shareLinkCopied'}));
    }, [intl, shareUrl]);
    const handleOpenPortal = useCallback(async () => {
        const {portalUrl} = await backend.paymentPortal();
        if (portalUrl) {
            window.open(portalUrl);
        }
    }, [backend]);
    const playsLeft = useMemo(() => {
        return training?.payment ? training.payment.freePlays : currentPrice?.freePlays ? currentPrice.freePlays : null;
    }, [currentPrice, training]);
    const renderBanner = useCallback(() => {
        switch (training?.payment?.status || 'TRIAL') {
            case 'TRIAL':
                return (
                    <>
                        {playsLeft === null ? (
                            <FormattedMessage id={currentPrice?.objectType === 'QUEST' ? (training?.visibility === 'PUBLIC' ? 'app.payment.questPublicTraining' : 'app.payment.noFreePlaysQuest') : 'app.payment.noFreePlays'} />
                        ) : playsLeft === 0 ? (
                            <FormattedMessage id={currentPrice?.objectType === 'QUEST' ? 'app.payment.noMoreFreePlaysQuest' : 'app.payment.noMoreFreePlays'} />
                        ) : (
                            <>
                                <span className="golden">
                                    {playsLeft}/{currentPrice?.freePlays} <FormattedMessage id="app.payment.freePlays" />
                                </span>{' '}
                                <FormattedMessage id="app.payment.unlockDescription" />
                            </>
                        )}
                        {currentPrice?.objectType === 'QUEST' && <QuestLink to={`/quests/${training?.quest?.id}`}>{training?.quest?.title}</QuestLink>}
                    </>
                );
            case 'CANCELLED':
                return <FormattedMessage id="app.payment.cancelled" />;
            // case 'EXPIRED':
            //     return <FormattedMessage id="app.payment.expired" />;
            case 'ERROR':
                return <FormattedMessage id="app.payment.error" />;
            default:
                return <FormattedMessage id="app.payment.lockedTraining" />;
        }
    }, [currentPrice?.freePlays, currentPrice?.objectType, playsLeft, training?.payment?.status, training?.quest?.id, training?.quest?.title, training?.visibility]);
    React.useEffect(() => {
        setIsLoading(true);
        if (id) {
            backend
                .getTraining(id)
                .then(t => {
                    setTraining(t);
                })
                .catch(err => {
                    setError(err.message);
                })
                .finally(() => setIsLoading(false));
        }
    }, [backend, id, intl]);
    if (isLoading) {
        return <LoadingPage />;
    }
    if (error === 'notFound' || training === undefined) {
        return <RouteNotFound />;
    } else if (error) {
        return <ErrorPage error={error} />;
    }
    if (editParam === 'true') {
        return <EditTraining training={training} onChange={setTraining} onBack={() => navigate(`/trainings/${training.id}`, {replace: true})} />;
    }
    return (
        <Section direction="y" grow={1}>
            <Section align="center" height="60px" padding="0 20px" shrink={0} spacing={20}>
                <TrainingBookmark training={training as any} currentPage='training' />
                <Section spacing={10} align="center">
                    <Icon name={training.visibility.toLowerCase() as IconName} />
                    <TextLayout color={theme.subForeground}>
                        <FormattedMessage id={`app.visibility.${training.visibility}`} />
                    </TextLayout>
                </Section>
                {(backend.authUser?.id === training.author.id || ['nguyen.hl.b@gmail.com', 'nmvevo@gmail.com'].includes(backend.authUser?.email || '')) && (
                    <Icon
                        name="edit"
                        title={intl.formatMessage({id: `app.training.edit`})}
                        onClick={() => {
                            navigate(`/trainings/${training.id}?edit=true`, {replace: true});
                            amplitude.getInstance().logEvent('click_on_edit_training', {trainingId: training.id, page: 'training'});
                        }}
                    />
                )}
                <Button onClick={() => {
                        share.open();
                        amplitude.getInstance().logEvent('click_on_share_training', {trainingId: training.id, page: 'training'});
                    }}
                >
                    <FormattedMessage id="app.training.share" />
                </Button>
            </Section>
            <Section direction={matches ? 'y' : 'x'} width="100%" grow={1} margin="0 auto" scroll={true}>
                <Section direction="y" grow={matches ? undefined : 1} spacing={20} scroll={true}>
                    <Layout padding={matches ? '0' : '0 15%'}>
                        {training.introYoutubeId ? (
                            <YoutubeResponsive youtubeId={training.introYoutubeId} />
                        ) : (
                            <Thumbnail radius={matches ? '0' : '10px'} width="100%" src={training.thumbnail} isLoading={false} />
                        )}
                    </Layout>
                    <Section direction="y" align={'center'} spacing={10}>
                        <Title padding="0 20px" style={{textAlign: 'center'}}>
                            {training.title}
                        </Title>
                        <TrainingSubtitle training={training as any} currentPage={'training'} />
                        <Layout padding="0 20%" width="100%">
                            <PrimaryButton
                                style={{width: '100%'}}
                                size="big"
                                disabled={
                                    !(training.sets.length >= 1 && training.sets[0].steps.length >= 1) ||
                                    (training.prices &&
                                        (training.visibility !== 'PUBLIC' && training.quest !== null) &&
                                        training.prices.length > 0 &&
                                        (playsLeft === 0 || playsLeft === null) &&
                                        training.payment?.status !== 'PAYING')
                                }
                                onClick={() => {
                                    handleStartWorkout();
                                    amplitude.getInstance().logEvent('start_training', {trainingId: training.id, page: 'training'});
                                    ReactGA.event('start_training', {trainingId: training.id});
                                }}
                            >
                                <FormattedMessage id="app.training.start" />
                            </PrimaryButton>
                        </Layout>
                        <Layout padding="0 20%" width="100%">
                            <PrimaryButton
                                style={{width: '100%'}}
                                size="big"
                                disabled={
                                    !(training.sets.length >= 1 && training.sets[0].steps.length >= 1) ||
                                    (training.prices &&
                                        (training.visibility !== 'PUBLIC' && training.quest !== null) &&
                                        training.prices.length > 0 &&
                                        (playsLeft === 0 || playsLeft === null) &&
                                        training.payment?.status !== 'PAYING')
                                }
                                onClick={() => {
                                    createActivityLightBox.open();
                                    amplitude.getInstance().logEvent('click_on_save_activity_training', {trainingId: training.id, page: 'training'});
                                    ReactGA.event('click_on_save_activity_training', {trainingId: training.id});
                                }}
                            >
                                <FormattedMessage id="app.training.saveActivity" />
                            </PrimaryButton>
                        </Layout>
                    </Section>
                </Section>

                <Section direction="y" grow={matches ? undefined : 1} spacing={20} padding="0 20px 0 0" scroll={true}>
                    {training.description && (
                        <Section direction="y" padding={matches ? '40px 20px 0 20px' : '0'}>
                            <RichTextRenderer description={training.description} />
                        </Section>
                    )}
                    <Section direction="y" padding={matches ? '20px' : '0 0 20px 0'} spacing={20}>
                        {training.sets.map((set, i) => (
                            <SetRow key={i} set={set} />
                        ))}
                    </Section>
                </Section>
            </Section>
            {training.prices && training.prices.length > 0 && (!training.payment || training.payment?.status !== 'PAYING') ? (
                <SubscribeSection direction={matches ? 'y' : 'x'} padding="20px" align="center" distribute="center" width="100%" spacing={15}>
                    <TextLayout color={theme.subForeground}>{renderBanner()}</TextLayout>
                    {['TRIAL', 'CANCEL', 'EXPIRED'].indexOf(training.payment?.status || 'TRIAL') === -1 ? null : (
                        <GoldButton
                            onClick={() => {
                                payment.open();
                                if (currentPrice?.objectType === 'QUEST') {
                                    amplitude.getInstance().logEvent('check_quest_price_from_training', {trainingId: training.id, questId: currentPrice.objectId, page: 'training'});
                                } else {
                                    amplitude.getInstance().logEvent('check_training_price', {trainingId: training.id, page: 'training'});
                                }
                            }}
                        >
                            <FormattedMessage id={currentPrice?.objectType === 'QUEST' ? 'app.payment.unlockQuest' : 'app.payment.unlock'} />
                        </GoldButton>
                    )}
                    {training.payment?.status === 'ERROR' ? (
                        <PrimaryButton onClick={() => {
                                handleOpenPortal();
                                amplitude.getInstance().logEvent('open_portal_from_training_payment_error', {trainingId: training.id, page: 'training'});
                            }}
                        >
                            <FormattedMessage id="app.payment.openPortal" />
                        </PrimaryButton>
                    ) : null}
                </SubscribeSection>
            ) : null}
            {playIsOpen && <Play training={training} onExit={() => setPlayIsOpen(false)} />}
            <Lightbox isOpen={payment.isOpen} onRequestClose={payment.close}>
                <PaymentLightbox isOpen={payment.isOpen} id={currentPrice?.objectId || training.id} objectType={currentPrice?.objectType || 'TRAINING'} prices={training.prices} />
            </Lightbox>
            <Lightbox isOpen={share.isOpen} onRequestClose={share.close}>
                <LightboxCard direction="y" isOpen={share.isOpen} padding="20px" width="400px" maxWidth="90%" spacing={10}>
                    <h2>
                        <FormattedMessage id="app.training.share" />
                    </h2>
                    <Section distribute="evenly">
                        <ShareIcon onClick={() => amplitude.getInstance().logEvent('click_share_training_wa', {trainingId: training.id, page: 'training'})} rel="noreferrer" target="_blank" href={`https://api.whatsapp.com/send/?phone&text=${shareUrl}&app_absent=0`}>
                            <img src={whatsappIcon} alt="" />
                            <div>Whatsapp</div>
                        </ShareIcon>
                        <ShareIcon
                            onClick={() => amplitude.getInstance().logEvent('click_share_training_tter', {trainingId: training.id, page: 'training'})}
                            rel="noreferrer"
                            target="_blank"
                            href={`https://twitter.com/intent/tweet?url=${encodeURI(shareUrl)}&text=${encodeURIComponent(
                                training.title,
                            )}&via=trnquest&related=TrainingQuest,TQuest`}
                        >
                            <img src={twitterIcon} alt="" />
                            <div>Twitter</div>
                        </ShareIcon>
                        <ShareIcon onClick={() => amplitude.getInstance().logEvent('click_share_training_rdit', {trainingId: training.id, page: 'training'})} rel="noreferrer" target="_blank" href={`https://www.reddit.com/submit?url=${encodeURI(shareUrl)}&title=${training.title}`}>
                            <img src={redditIcon} alt="" />
                            <div>Reddit</div>
                        </ShareIcon>
                        <ShareIcon onClick={() => amplitude.getInstance().logEvent('click_share_training_mail', {trainingId: training.id, page: 'training'})} href={`mailto:?subject=${training.title}&body=${encodeURI(shareUrl)}`}>
                            <img src={mailIcon} alt="" />
                            <div>Mail</div>
                        </ShareIcon>
                    </Section>
                    <Section spacing={10}>
                        <Input ref={copyLinkRef} onFocus={e => e.target.select()} style={{flex: 1}} value={shareUrl} onChange={() => {}} />
                        <PrimaryButton onClick={() => {
                                handleShare();
                                amplitude.getInstance().logEvent('click_share_copy_link', {trainingId: training.id, page: 'training'});
                            }}
                        >
                            <FormattedMessage id="app.training.copy" />
                        </PrimaryButton>
                    </Section>
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={createActivityLightBox.isOpen} onRequestClose={createActivityLightBox.close}>
                <LightboxCard isOpen={createActivityLightBox.isOpen} maxWidth="90%">
                    <CreateActivityDialog onClose={createActivityLightBox.close} trainingId={training.id} />
                </LightboxCard>
            </Lightbox>
        </Section>
    );
};
