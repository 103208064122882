import styled from '@emotion/styled';
import React, {useCallback, useMemo, useState} from 'react';
import {useTheme} from '@emotion/react';
import {FormattedMessage} from 'react-intl';

import {Button, PrimaryButton} from '../../widgets/Button';
import {Section} from '../../ui/Section';
import {TextLayout} from '../../widgets/Text';
import {Trainings} from '../trainings/Trainings';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {Exercises} from '../exercises/Exercises';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';

import {useParams} from 'react-router-dom';
import {Thumbnail} from '../../widgets/Thumbnail';
import {Icon} from '../../ui/Icon';
import {LightboxCard} from '../../widgets/LightboxCard';
import {Settings} from '../settings/Settings';
import {useMedia} from 'react-use';
import {useLoadData} from '../backend/useLoadData';
import {LoadingPage} from '../../widgets/LoadingPage';
import {useBackend} from '../backend/useBackend';
import type {UserModel} from '../backend/models/UserModel';
import type {TrainingOrderBy, TrainingRowModel} from '../backend/models/TrainingModel';
import {useLocale} from '../locale/Locale';
import {useThemeSelector} from '../theme/Theme';
import {ErrorPage} from '../../widgets/ErrorPage';
import {RichTextRenderer} from '../../widgets/RichText/RichTextRender';
import amplitude from 'amplitude-js';
import { QuestRowModel } from '../backend/models/QuestModel';
import { PremiumLabel } from '../trainings/TrainingCard';
import ReactGA from 'react-ga4';
import {useNavigate} from 'react-router-dom';
import { InlineButton } from '../../components/Button';
import { NewQuestRow } from './NewQuestRow';
import { CreateQuestDialog } from './createQuestDialog';
import { SegmentedControl } from '../../widgets/SegmentedControl';

const UserBanner = styled(Thumbnail)`
    height: 350px;
    object-fit: cover;
    @media (max-width: 800px) {
        height: 250px;
    }
`;

const UserThumbnail = styled(Thumbnail)`
    background-color: ${props => props.theme.background};
    border: 5px solid ${props => props.theme.background};
`;

const UserSection = styled(Section)`
    margin-top: -100px;
    z-index: 2;
    @media (max-width: 600px) {
        flex-direction: column;
        margin-top: -120px;
    }
`;

const QuestTitle = styled.div`
    font-size: 1.5em;
    font-family: ${props => props.theme.title};
`;
const QuestIntro = styled.div`
    color: ${props => props.theme.subForeground};
    line-height: 28px;
`;

export const User: React.FC = () => {
    const theme = useTheme();
    const backend = useBackend();
    const {userId} = useParams<{userId: string}>();
    const {changeLocale} = useLocale();
    const {changeTheme} = useThemeSelector();
    const settings = useLightboxState();
    const newQuest = useLightboxState();
    const exercises = useLightboxState();
    const isMobile = useMedia('(max-width: 600px)');
    const [user, setUser] = React.useState<UserModel>();
    const {tab} = useParams<{tab: string}>();
    const navigate = useNavigate();
    const loadData = useLoadData<TrainingRowModel>((search, page, perPage, orderBy) => {
        return backend.searchTrainings({
            search,
            page,
            perPage,
            authorId: userId,
            orderBy: orderBy as TrainingOrderBy,
        });
    });
    const [quests, setQuests] = useState<QuestRowModel[]>();

    const [error, setError] = useState('');

    React.useEffect(() => {
        if (userId !== undefined) {
            setError('');
            backend
                .getUser(userId)
                .then(newUser => {
                    setUser(newUser);
                    if (backend.authUser?.id === newUser.id) {
                        changeLocale?.(newUser.settings.locale);
                        changeTheme?.(newUser.settings.theme);
                    }
                })
                .catch(err => {
                    setError(err.message);
                });
        }
    }, [backend, changeLocale, changeTheme, userId]);

    React.useEffect(() => {
        backend
            .listQuests(1, 10, userId)
            .then(setQuests)
            .catch(e => {
                console.log(e.message);
                if (e.message === 'userNotAuthenticated') {
                    localStorage.clear();
                    window.location.reload();
                }
            });
    }, [backend, userId]);

    // tabs
    const tabs = useMemo(() => ([
        {value: 'trainings', label: <FormattedMessage id={backend.authUser?.id === userId ? "app.user.myTrainings" : "app.user.trainings"} />},
        {value: 'quests', label: <FormattedMessage id={backend.authUser?.id === userId ? "app.user.myQuests" : "app.user.quests"} />}
    ]), [backend.authUser?.id, userId]);

    const handleChangeTab = useCallback((newTab: any) => {
        navigate(`/user/${userId}/${newTab}`);
        amplitude.getInstance().logEvent(`check_user_${newTab}`, {page: 'user'});
    }, [navigate, userId]);

    if (error) {
        return <ErrorPage error={error} />;
    }

    if (!user) {
        return <LoadingPage />;
    }

    return (
        <Section direction="y" grow={1} scroll={true}>
            <UserBanner src={user.banner} kind="empty" radius="0" gradient={true} />
            <UserSection padding="20px" align={isMobile ? 'center' : 'start'}>
                <UserThumbnail src={user.avatar} kind="avatar" radius="50%" width="150px" height="150px" />
                <Section
                    direction="y"
                    grow={isMobile ? undefined : 1}
                    padding={isMobile ? '0' : '20px 0 20px 20px'}
                    align={isMobile ? 'center' : 'start'}
                    spacing={10}
                >
                    <Section direction={isMobile ? 'y' : 'x'} spacing={10} align={isMobile ? 'center' : 'start'} width="100%">
                        <TextLayout align="left" grow={isMobile ? undefined : 1} weight="bold" size={2}>
                            {user.fullname || user.username.toLowerCase()}
                        </TextLayout>
                        <Section spacing={20}>
                            {backend.authUser?.id === user.id && (
                                <>
                                    <Button onClick={() => {
                                        exercises.open();
                                        amplitude.getInstance().logEvent('click_on_exercise_library', {page: 'profile'});
                                    }}>
                                        <FormattedMessage id="app.create.exercisesLibrary" />
                                    </Button>
                                    <Icon name="settings" onClick={() => {
                                        settings.open();
                                        amplitude.getInstance().logEvent('click_on_settings_profile', {page: 'profile'});
                                    }} />
                                </>
                            )}
                        </Section>
                    </Section>
                    <TextLayout align="left" color={theme.subForeground}>
                        <RichTextRenderer description={user.bio || ''} />
                    </TextLayout>
                    {user.websiteUrl && (
                        <a style={{color: theme.foreground}} href={user.websiteUrl} target="_blank" rel="noreferrer">
                            {user.websiteUrl}
                        </a>
                    )}
                </Section>
            </UserSection>


            <Section direction="y" align="center" padding="20px 40px 10px 40px" spacing={5}>
                <Section direction="x" align="center">
                    <SegmentedControl value={tab} onChange={handleChangeTab} options={tabs} defaultValue="trainings" />
                </Section>
            </Section>
            <Section direction="y" padding="20px 0 0 20px" width="100%" maxWidth="1000px" margin="0 auto">
                {(tab === 'trainings' || !tab) && (
                    <Trainings canCreate={backend.authUser?.id === user.id} currentPage={'profile'} {...loadData} />
                )}
                {tab === 'quests' && (
                    <>
                        { backend.authUser?.id === user.id &&
                            <NewQuestRow
                                onClick={ () => {
                                    newQuest.open();
                                    amplitude.getInstance().logEvent('click_on_create_new_quest', {page: 'profile'});
                                }}
                            />
                        }
                        {quests && quests?.length !== 0 && (
                            <Section direction="y" spacing={20}>
                                {quests.map(quest => {
                                    return (
                                        <Section key={quest.id} direction="y" width="100%" maxWidth="1000px" padding="20px">
                                            <div style={{ position: 'relative' }}>
                                                <img style={{ borderRadius: '15px', maxWidth: '100%' }} src={quest.banner} alt="" />
                                                {quest.price && <PremiumLabel style={{ bottom: 20 }}>
                                                    {quest.price.value}{quest.price.currency === 'EUR' ? '€' : '$'}
                                                    {quest.price.period && <FormattedMessage
                                                        id={`app.payment.${quest.price.period}`}
                                                        values={{ n: quest.price.interval === 1 ? '' : quest.price.interval }} />}
                                                </PremiumLabel>}
                                            </div>
                                            <Section direction="y" padding="20px" spacing={10} align="start">
                                                <QuestTitle>{quest.title}</QuestTitle>
                                                <QuestIntro>{quest.intro}</QuestIntro>
                                                <Section spacing={10} align="center">
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            amplitude.getInstance().logEvent(`check_quest_from_profile`, { questId: quest.id });
                                                            ReactGA.event('check_quest', { questId: quest.id });
                                                            navigate('/quests/' + quest.id);
                                                        }}
                                                    >
                                                        {backend.authUser?.id === userId ? (
                                                            <FormattedMessage id="app.create.viewQuest" />
                                                        ) : (
                                                            <FormattedMessage id="app.explore.learnMore" />
                                                        )}
                                                    </PrimaryButton>
                                                    {backend.authUser?.id === userId && (
                                                        <InlineButton
                                                            onClick={() => {
                                                                amplitude.getInstance().logEvent(`edit_quest_from_profile`, { questId: quest.id });
                                                                ReactGA.event('edit_quest', { questId: quest.id });
                                                                navigate('/quests/' + quest.id + '?edit=true' )
                                                            }}
                                                        >
                                                            <FormattedMessage id="app.create.editQuest" />
                                                        </InlineButton>
                                                    )}
                                                    </Section>
                                            </Section>
                                        </Section>
                                    );
                                })}
                            </Section>
                        )}
                    </>
                )}
            </Section>


            {/* Modals */}
            <Lightbox isOpen={exercises.isOpen} onRequestClose={exercises.close}>
                <LightboxCard sheet={true} isOpen={exercises.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Exercises isOpen={exercises.isOpen} onCancel={exercises.close} />
                </LightboxCard>
            </Lightbox>
            <Lightbox
                isOpen={settings.isOpen}
                onRequestClose={settings.close}
            >
                <LightboxCard sheet={true} isOpen={settings.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Settings onClose={settings.close} onSave={setUser} />
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={newQuest.isOpen} onRequestClose={newQuest.close}>
                <LightboxCard isOpen={newQuest.isOpen} maxWidth="90%">
                    <CreateQuestDialog onClose={newQuest.close} />
                </LightboxCard>
            </Lightbox>
        </Section>

    );
};
