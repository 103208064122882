import {Link as RouterLink} from 'react-router-dom';
import styled from '@emotion/styled';

export const Link = styled(RouterLink)`
    color: ${props => props.theme.primary};
    text-decoration: none;
`;

export const InvisibleLink = styled(RouterLink)<{scale?: number, disabled?: boolean}>`
    color: ${props => props.theme.foreground};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    text-decoration: none;

    transition: all 0.2s;
    &:hover {
        transform: scale(${props => props.scale || 1});
    }
    &:active {
        transform: scale(1);
    }
`;
