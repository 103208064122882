import React, { useCallback, useEffect, useMemo, useState } from "react";

import {Section} from "../../ui/Section";
import { Icon } from "../../ui/Icon";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useLightboxState } from "../../ui/Lightbox/useLightboxState";
import { Lightbox } from "../../ui/Lightbox/Lightbox";
import { LightboxCard } from "../../widgets/LightboxCard";
import { PrimaryButton } from "../../widgets/Button";
import { FormattedMessage } from "react-intl";

interface QuestionModel {
    user_email: string;
    question: string;
    answer: string;
    premium: boolean;
}

const CollapseIcon = styled(Icon)<{isOpen: boolean; name: string}>`
    transform: ${props => props.name === 'private' ? 'none' : props.isOpen ? 'rotate(270deg)' : 'rotate(180deg)'};
    transition: transform .3s;
    margin-right: 20px;
`;

export const Question: React.FC<{model: QuestionModel, isPaying: boolean}> = ({model, isPaying}) => {
    const {isOpen, open, close} = useLightboxState();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const theme = useTheme();

    const isPremium = useMemo(() => {
        return model.premium && !isPaying;
    }, [isPaying, model.premium]);
    const handleClick = useCallback(() => {
        if (!isPremium) {
            setIsCollapsed((v) => !v);
        } else {
            open();
        }
    }, [isPremium, open]);
    return <Section direction="y">
        <h3 style={{cursor: 'pointer'}} onClick={handleClick}><Section><CollapseIcon isOpen={!isCollapsed} name={isPremium ? "private" : "back"} color={theme.foreground} />{model.question}</Section></h3>
        {!isCollapsed && <p style={{marginLeft: '35px'}}>{model.answer}</p>}
        <Lightbox isOpen={isOpen} onRequestClose={close}>
            <LightboxCard isOpen={isOpen} direction="y" padding="20px" maxWidth="600px">
                <h2><FormattedMessage id="app.quest.premiumQuestion" /></h2>
                <p><FormattedMessage id="app.quest.premiumQuestionDescription" /><strong style={{fontFamily: theme.title}}><FormattedMessage id="app.payment.unlock" /></strong> 😁</p>
                <Section distribute="end">
                    <PrimaryButton onClick={close}><FormattedMessage id="app.quest.gotIt" /></PrimaryButton>
                </Section>
            </LightboxCard>
        </Lightbox>
    </Section>
};

export const QuestFAQ: React.FC<{isPaying: boolean}> = ({isPaying}) => {
    const [questions, setQuestions] = useState<QuestionModel[]>([]);
    useEffect(() => {
        fetch("https://docs.google.com/spreadsheets/d/1VKdcjyDpj1HetBJmmV-NtYV5aAscGKMWHJkl71P8xBg/pub").then(async (value) => {
            const el = document.createElement('html');
            el.innerHTML = await value.text();
            const td = el.querySelectorAll('td:not(.freezebar-cell)');
            let texts: string[] = [];
            td.forEach((e) => {
                texts.push(e.textContent || '');
            });
            let tmpResult: {user_email: string; question: string; answer: string; premium: boolean}[] = [];
            let i = 4;
            while (i < texts.length - 3) {
                tmpResult.push({
                    user_email: texts[i],
                    question: texts[i + 1],
                    answer: texts[i + 2],
                    premium: texts[i + 3] === 'TRUE'
                });
                i += 4;
            }
            setQuestions(tmpResult);
        });
    }, []);

    return <Section direction="y" padding="20px" maxWidth="800px" spacing={20}>
        {questions.map((q, i) => <Question key={i} model={q} isPaying={isPaying} />)}
    </Section>
};