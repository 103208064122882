import styled from '@emotion/styled';
import {lighten} from 'polished';
import {keyframes} from '@emotion/react';

import {Layout} from '../ui/Layout';

const animatedGradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const LoadingPart = styled(Layout)<{borderRadius?: string; noBackground?: boolean}>`
    cursor: default;
    animation: ${animatedGradient} 2s ease infinite;
    border-radius: ${props => props.borderRadius || '10px'};
    pointer-events: none;
    background-size: 400% 400%;
    background-color: ${props => (props.noBackground ? undefined : props.theme.subBackground)};
    background-image: ${props => `linear-gradient(45deg, ${props.theme.subBackground}, transparent, ${lighten(0.7, props.theme.subBackground)})`};
    background-position: 20% 50%;
`;
