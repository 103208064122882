import styled from '@emotion/styled';
import {transparentize} from 'polished';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {Section} from '../../ui/Section';

const RichTextRender = styled(Section)`
    color: ${props => props.theme.subForeground};
    line-height: 28px;
    h1 {
        font-size: 1.3em;
        color: ${props => props.theme.foreground};
        padding: 5px 0;
    }
    p {
        margin: 15px 0;
    }
    ul,
    ol {
        margin: 0;
    }
    img {
        display: flex;
        margin: 10px auto;
        max-width: 100%;
        border-radius: ${props => props.theme.borderRadius};
    }
    strong {
        color: ${props => props.theme.foreground};
        font-weight: normal;
        font-family: ${props => props.theme.title};
    }
    blockquote {
        color: ${props => props.theme.primary};
        background-color: ${props => transparentize(0.8, props.theme.primary)};
        padding: 10px 10px 5px;
        border-radius: 10px;
        margin: 0;
    }
    .youtubeWrapper {
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            margin: auto;
            border-radius: 10px;
        }
    }
    a {
        color: ${props => props.theme.primary};
    }
    .info {
        color: ${props => props.theme.primary};
        background-color: ${props => transparentize(0.8, props.theme.primary)};
        padding: 10px;
        border-radius: 10px;
    }
`;

const LinkRenderer: React.FC = (props: any) => {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
};

export const RichTextRenderer: React.FC<{description: string}> = ({description}) => {
    return (
        <RichTextRender direction="y">
            <ReactMarkdown components={{a: LinkRenderer}} rehypePlugins={[rehypeRaw]}>
                {description.replaceAll(/\[\[ embed url=(.+) \]\]/g, function (full, url) {
                    const id = url.substr(url.lastIndexOf('/') + 1);
                    return `<div class='youtubeWrapper'><iframe src="https://www.youtube.com/embed/${id}" frameborder="0" allowfullscreen></iframe></div>`;
                })}
            </ReactMarkdown>
        </RichTextRender>
    );
};
