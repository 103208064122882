import React from 'react';

import {Trainings} from '../trainings/Trainings';
import {useBackend} from '../backend/useBackend';
import {useLoadData} from '../backend/useLoadData';
import { useTrackers } from '../tracking/useTrackers';
import type {TrainingOrderBy, TrainingRowModel} from '../backend/models/TrainingModel';

export const Bookmarks: React.FC = () => {
    const backend = useBackend();
    const loadData = useLoadData<TrainingRowModel>((search, page, perPage, orderBy) => {
        return backend.searchTrainingsBookmarks({search, page, perPage, orderBy: orderBy as TrainingOrderBy});
    });

    useTrackers();

    return (
        <Trainings currentPage={"bookmarks"} {...loadData} />
    );
};
