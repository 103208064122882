import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import amplitude from "amplitude-js";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { Section } from "../../ui/Section";
import { PrimaryButton } from "../../widgets/Button";
import { TextArea, useInputState } from "../../widgets/Input";
import { DotsSpinner } from "../../widgets/Spinner/DotSpinner";
import { TextLayout } from "../../widgets/Text";
import { UserModel } from "../backend/models/UserModel";
import { useBackend } from "../backend/useBackend";

import {ReactComponent as Climbing} from './interests/climbing.svg';
import {ReactComponent as Fitness} from './interests/fitness.svg';
import {ReactComponent as Flexibility} from './interests/flexibility.svg';
import {ReactComponent as Handstand} from './interests/handstand.svg';
import {ReactComponent as MartialArts} from './interests/martialArts.svg';
import {ReactComponent as StreetWorkout} from './interests/streetworkout.svg';

const Interest = styled(Section)<{selected: boolean}>`
    width: 120px;
    height: 140px;
    margin: 10px;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
    background-color: ${props => props.selected ? props.theme.primary : props.theme.subBackground};
    color: ${props => props.selected ? props.theme.primaryForeground : props.theme.foreground};
    [fill='black'] {
        fill: ${props => props.selected ? props.theme.primaryForeground : props.theme.foreground};
    }
    transition: .3s all;
    &:hover {
        transform: scale(1.05);
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.primaryForeground };
        [fill='black'] {
            fill: ${props => props.theme.primaryForeground};
        }
    }
    &:active {
        transform: scale(1);
    }
`;

type RegisterInterestsProps = {
    update?: boolean;
    onClose?: () => void;
}

export const RegisterInterests: React.FC<RegisterInterestsProps> = ({update = false, onClose}) => {
    const theme = useTheme();
    const backend = useBackend();
    const navigate = useNavigate();
    const [suggestions, setSuggestions] = useInputState('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [interests, setInterests] = useState({
        'climbing': backend.authUser?.interests.includes('climbing') || false,
        'fitness': backend.authUser?.interests.includes('fitness') || false,
        'flexibility': backend.authUser?.interests.includes('flexibility') || false,
        'handstand': backend.authUser?.interests.includes('handstand') || false,
        'martial_arts': backend.authUser?.interests.includes('martial_arts') || false,
        'street_workout': backend.authUser?.interests.includes('street_workout') || false
    });
    const trainingId = new URLSearchParams(useLocation().search).get('training');
    const questId = new URLSearchParams(useLocation().search).get('quest');
    const userId = new URLSearchParams(useLocation().search).get('user');
    const handleInterests = useCallback(async () => {
        setIsLoading(true);
        const interestList = Object.keys(interests).filter((key) => interests[key as keyof typeof interests]);
        await backend.patchUser({id: backend.authUser!.id, interests: interestList} as UserModel);
        await backend.createSuggestion(suggestions);
        amplitude.getInstance().logEvent('validate_interests', {page: 'interests', interestKeys: interestList, suggestion: suggestions});
        if(update) {
            if (onClose) {
                onClose();
            }
        } else {
            navigate(trainingId ? `/trainings/${trainingId}` : questId ? `/quests/${questId}` : userId ? `/user/${userId}` : '/home?showWelcome=true');
            setIsLoading(false);
        }
    }, [backend, navigate, interests, onClose, questId, suggestions, trainingId, userId, update]);
    const selectInterest = useCallback((interest: keyof typeof interests) => {
        setInterests((v) => ({...v, [interest]: !v[interest]}));
        amplitude.getInstance().logEvent('chose_interest', {page: 'interests', interestKey: interest});
    }, []);
    return <Section width="100%" height="100%" align="center" distribute="center" scroll={true}>
    <Section direction="y" align="center" maxWidth="500px" width="100%" height="100%" scroll={true} spacing={20} padding="20px">
        <TextLayout weight="bold" size={2}>
            <FormattedMessage id={ update ? "app.interests.updateInterests" : "app.auth.register.knowYouBetter" } />
        </TextLayout>
        <TextLayout color={theme.subForeground}>
            <FormattedMessage id="app.auth.register.interests" />
        </TextLayout>
        <Section style={{flexWrap: 'wrap'}} distribute="center">
            <Interest direction="y" distribute="center" align="center" spacing={20} selected={interests['climbing'] === true} onClick={() => selectInterest('climbing')}><Climbing /><span><FormattedMessage id="app.auth.register.climbing" /></span></Interest>
            <Interest direction="y" distribute="center" align="center" spacing={20} selected={interests['fitness'] === true} onClick={() => selectInterest('fitness')}><Fitness /><span><FormattedMessage id="app.auth.register.fitness" /></span></Interest>
            <Interest direction="y" distribute="center" align="center" spacing={20} selected={interests['flexibility'] === true} onClick={() => selectInterest('flexibility')}><Flexibility /><span><FormattedMessage id="app.auth.register.flexibility" /></span></Interest>
            <Interest direction="y" distribute="center" align="center" spacing={20} selected={interests['handstand'] === true} onClick={() => selectInterest('handstand')}><Handstand /><span><FormattedMessage id="app.auth.register.handstand" /></span></Interest>
            <Interest direction="y" distribute="center" align="center" spacing={20} selected={interests['martial_arts'] === true} onClick={() => selectInterest('martial_arts')}><MartialArts /><span><FormattedMessage id="app.auth.register.martialArts" /></span></Interest>
            <Interest direction="y" distribute="center" align="center" spacing={20} selected={interests['street_workout'] === true} onClick={() => selectInterest('street_workout')}><StreetWorkout /><span><FormattedMessage id="app.auth.register.streetworkout" /></span></Interest>
        </Section>
        <TextLayout color={theme.subForeground}>
            <FormattedMessage id="app.auth.register.otherSuggestions" />
        </TextLayout>
        <TextArea value={suggestions} onChange={setSuggestions} placeholder="Golf, front lever..."></TextArea>
        <PrimaryButton style={{width: '100%'}} onClick={handleInterests}>
            {isLoading ? <DotsSpinner color={theme.primaryForeground} size={16} /> : <FormattedMessage id={update ? "app.interests.update" : "app.auth.register.register" } />}
        </PrimaryButton>
    </Section>
</Section>
};