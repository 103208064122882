import React from 'react';
import type {UserModel} from './models/UserModel';

interface AuthUserContextProps {
    authUser: UserModel | undefined;
    setAuthUser: React.Dispatch<React.SetStateAction<UserModel | undefined>>;
}

export const AuthUserContext = React.createContext<Partial<AuthUserContextProps>>({});

export const useAuthUser = () => React.useContext(AuthUserContext);

export const getAuthUser = (): UserModel | undefined => {
    const data = localStorage.getItem('authUser');
    return data && JSON.parse(data);
};
