import styled from '@emotion/styled';
import {useMedia} from 'react-use';
import {useTheme} from '@emotion/react';
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Section} from '../../ui/Section';
import {Thumbnail} from '../../widgets/Thumbnail';
import {GoldButton, PrimaryButton} from '../../widgets/Button';
import {TextLayout} from '../../widgets/Text';
import {QuestSubtitle} from './QuestSubtitle';
// import {QuestTimeline} from './QuestTimeline';
import {SegmentedControl} from '../../widgets/SegmentedControl';
// import {TrainingRow} from '../workouts/TrainingRow';
import {QuestModel} from '../backend/models/QuestModel';
import {FormattedMessage, useIntl} from 'react-intl';
import {useBackend} from '../backend/useBackend';
import {RichTextRenderer} from '../../widgets/RichText/RichTextRender';
import {Grid} from '../../ui/Grid';
import {TrainingCard} from '../trainings/TrainingCard';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {PaymentLightbox} from '../trainings/PaymentLightbox';
import { useTrackers } from '../tracking/useTrackers';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';
import { ActivityModel } from '../backend/models/ActivityModel';
import { useLoadData } from '../backend/useLoadData';
import { InvisibleLink } from '../../components/Link';
import { QuestFAQ } from './QuestFAQ';
import { EditQuest } from './EditQuest';
import { QuestFollow } from './QuestFollow';

const SHOW_ACTIVITY_IDS: string[] = [];
const SHOW_FAQ_IDS: string[] = ['wMVudHHOLKd'];


const MainSection = styled(Section)``;

const BannerSection = styled(Section)`
    position: relative;
    border: none;
    background-color: transparent;
    @media (max-width: 800px) {
        width: 100%;
    }
`;

const QuestBanner = styled(Thumbnail)`
    position: relative;
    /* height: 350px;
    object-fit: cover;
    @media (max-width: 800px) {
        height: 250px;
    } */
    max-width: 100%;
    box-sizing: border-box;
    @media (max-width: 1046px) {
        img {
            border-radius: 0;
        }
    }
`;

const InfoSection = styled(Section)`
    margin-top: -10px;
    z-index: 1;
`;

const SubscribeSection = styled(Section)`
    background-color: ${props => props.theme.subBackground};
    .golden {
        color: #e8c27b;
        font-family: ${props => props.theme.title};
    }
`;

const UnlockMessage = styled.div`
    color: ${props => props.theme.subForeground};
    padding: 20px;
`;

// Activity

const StyledLink = styled(Link)`
    color: ${props => props.theme.foreground};
    transition: all 0.3s;
    &:hover {
        color: ${props => props.theme.primary};
    }
`;

const AvatarThumbnail = styled(Thumbnail)`
    width: 20px;
    border-radius: 50%;
`;

const TitleSection = styled(Section)`
    font-family: ${props => props.theme.title};
    font-size: 1.1em;
    a {
        text-decoration: none;
    }
`;

const SubTitleSection = styled(Section)`
    font-family: ${props => props.theme.title};
    color: ${props => props.theme.subForeground};
`;

// Unused for now, deletable
const ActivityRow: React.FC<{activity: ActivityModel}> = ({activity}) => {
    const match600 = useMedia('(max-width: 600px)');
    const intl = useIntl();
    const difficulties = useMemo(() => [
        intl.formatMessage({id: 'app.play.tooHard'}),
        intl.formatMessage({id: 'app.play.littleTooHard'}),
        intl.formatMessage({id: 'app.play.perfectForMe'}),
        intl.formatMessage({id: 'app.play.littleTooEasy'}),
        intl.formatMessage({id: 'app.play.tooEasy'}),
    ], [intl]);
    return <Section spacing={10} align="center">
        <InvisibleLink to={`/trainings/${activity.training?.id}`}>
            <Thumbnail width={match600 ? '80px' : '120px'} src={activity.training?.thumbnail} />
        </InvisibleLink>
        <Section direction="y" spacing={5}>
            <TitleSection spacing={5}>
            <StyledLink
                    to={`/trainings/${activity.training?.id}`}
                >{activity.training?.title}</StyledLink>
                <span><FormattedMessage id="app.quest.playedBy" /></span>
                <AvatarThumbnail kind="avatar" src={activity.user.avatar} />
                <StyledLink
                    to={`/user/${activity.user.id}`}
                >
                        {activity.user.username.toLowerCase()}
                </StyledLink>
            </TitleSection>
            <SubTitleSection spacing={5}>
                <span>{Math.round(activity.duration / 60)}min</span><span>•</span>
                {activity.difficulty && <><span>{difficulties[activity.difficulty - 1]}</span><span>•</span></>}
                <span>{"⭐️".repeat(activity.rating || 0)}</span>
            </SubTitleSection>
        </Section>
    </Section>
}

export const Quest: React.FC = () => {
    const navigate = useNavigate();
    useTrackers();
    const {id, tab} = useParams<{id: string; tab: string}>();
    const theme = useTheme();
    const matches850 = useMedia('(max-width: 850px)');
    const backend = useBackend();
    const [quest, setQuest] = useState<QuestModel>();

    // tabs
    const tabs = useMemo(
        () => {
            let tmp = [
                {value: 'overview', label: <FormattedMessage id="app.quest.overview" />},
                {value: 'instructions', label: <FormattedMessage id="app.quest.instructions" />},
                {value: 'trainings', label: <FormattedMessage id="app.quest.trainings" />},
            ];
            console.log(SHOW_FAQ_IDS, quest?.id || '');
            if (SHOW_FAQ_IDS.indexOf(quest?.id || '') !== -1) {
                tmp.push({value: 'faq', label: <>FAQ</>});
            }
            if (SHOW_ACTIVITY_IDS.indexOf(quest?.id || '') !== -1) {
                tmp.push({value: 'activity', label: <FormattedMessage id="app.quest.activity" />});
            }
            return tmp;
        }, [quest?.id]);
    const handleChangeTab = useCallback((newTab: any) => {
        navigate(`/quests/${id}/${newTab}`);
        amplitude.getInstance().logEvent(`check_quest_${newTab}`, {questId: quest!.id, page: 'quest'});
    }, [navigate, id, quest]);

    // activity
    const activities = useLoadData<ActivityModel>(
        async (search, page, perPage) => {
            if (!backend.authUser) {
                throw new Error('userNotAuthenticated');
            }
            return backend.getActivities({objectType: 'QUEST', objectId: id, page, perPage, drop: false, manual: true});
        },
        {direction: 'x', perPage: 100},
    );

    // Payment
    const payment = useLightboxState();
    const handleOpenPortal = useCallback(async () => {
        const {portalUrl} = await backend.paymentPortal();
        if (portalUrl) {
            window.open(portalUrl);
        }
    }, [backend]);
    const renderBanner = useCallback(() => {
        switch (quest?.payment?.status) {
            case 'CANCELLED':
                return <FormattedMessage id="app.payment.cancelled" />;
            // case 'EXPIRED':
            //     return <FormattedMessage id="app.payment.expired" />;
            case 'ERROR':
                return <FormattedMessage id="app.payment.error" />;
            default:
                return <FormattedMessage id="app.payment.locked" />;
        }
    }, [quest?.payment?.status]);

    useEffect(() => {
        if (id) {
            backend.getQuest(id).then(setQuest);
        }
    }, [backend, id]);

    const successPaymentParam = new URLSearchParams(useLocation().search).get('success');
    const cancelPaymentParam = new URLSearchParams(useLocation().search).get('cancelled');
    useEffect(() => {
        if (successPaymentParam === 'true' && quest) {
            amplitude.getInstance().logEvent('paid_quest', {questId: quest?.id, page: 'quest'});
            ReactGA.event('purchase', {questId: quest.id});
        } else if (cancelPaymentParam === 'true' && quest) {
            amplitude.getInstance().logEvent('cancel_quest_payment', {questId: quest?.id, page: 'quest'});
        }
    }, [quest, successPaymentParam, cancelPaymentParam]);
    const editParam = new URLSearchParams(useLocation().search).get('edit');
    if (!quest) {
        return null;
    };
    if (editParam === 'true' && backend.authUser?.id === quest?.author.id) {
        return <EditQuest quest={quest} onChange={setQuest} />;
    }
    return (
        <MainSection direction="y" grow={1}>
            <Section direction="y" grow={1} spacing={10} align="center" scroll padding="0 0 20px 0">
                <BannerSection>
                    <QuestBanner src={quest.banner} kind="empty" radius="20px" gradient={true} width="1000px" />
                    <QuestFollow quest={quest as any} currentPage='quest' />
                </BannerSection>
                <InfoSection direction="y" align="center" width="100%">
                    <TextLayout weight="bold" size={2.5} padding="0 0 10px 0">
                        {quest.title}
                    </TextLayout>
                    <QuestSubtitle quest={quest as any} />
                </InfoSection>
                <Section direction="x" align="center">
                    <SegmentedControl value={tab} onChange={handleChangeTab} options={tabs} defaultValue="overview" />
                </Section>
                {(tab === 'overview' || !tab) && (
                    <Section direction="y" padding="0 20px" maxWidth="800px">
                        <RichTextRenderer description={quest.description} />
                    </Section>
                )}
                {tab === 'instructions' && quest.instructions !== null && (
                    <Section direction="y" padding="0 20px" maxWidth="800px">
                        <RichTextRenderer description={quest.instructions || ''} />
                    </Section>
                )}
                {tab === 'trainings' && quest.trainings !== null && (
                    <Grid limit={300} spacing={20} padding="20px" width="100%">
                        {quest.trainings.map((training, i) => (
                            <TrainingCard key={i} training={training} currentPage='quest' />
                        ))}
                    </Grid>
                )}
                {tab === 'activity' && (
                    <Section direction="y" padding="20px" maxWidth="800px" spacing={20}>
                        {activities.data.map((activity) => <ActivityRow activity={activity} />)}
                    </Section>
                )}
                {tab === 'faq' && (
                    <QuestFAQ isPaying={quest.instructions !== null} />
                )}
                {(tab === 'instructions' || tab === 'trainings') && (quest.trainings === null || quest.instructions === null) && (quest.author.id !== backend.authUser?.id) && (
                    <UnlockMessage>
                        <FormattedMessage id="app.quest.unlockToAccess" />
                    </UnlockMessage>
                )}
            </Section>
            {(quest.prices.length !== 0 && quest.payment?.status !== 'PAYING' && quest.author.id !== backend.authUser?.id) || quest.trainings === null || quest.instructions === null ? (
                <SubscribeSection direction={matches850 ? 'y' : 'x'} padding="20px" align="center" distribute="center" width="100%" spacing={15}>
                    <TextLayout color={theme.subForeground}>{renderBanner()}</TextLayout>
                    {['TRIAL', 'CANCEL', 'EXPIRED'].indexOf(quest.payment?.status || 'TRIAL') === -1 ? null : (
                        <GoldButton onClick={() => {
                            payment.open();
                            amplitude.getInstance().logEvent('check_quest_price', {questId: quest.id, page: 'quest'});
                        }}>
                            <FormattedMessage id="app.payment.unlock" />
                        </GoldButton>
                    )}
                    {quest.payment?.status === 'ERROR' ? (
                        <PrimaryButton onClick={() => {
                            handleOpenPortal();
                            amplitude.getInstance().logEvent('open_portal_from_quest_payment_error', {questId: quest.id, page: 'quest'});
                        }}>
                            <FormattedMessage id="app.payment.openPortal" />
                        </PrimaryButton>
                    ) : null}
                </SubscribeSection>
            ) : null}
            <Lightbox
                isOpen={payment.isOpen}
                onRequestClose={() => {
                    payment.close();
                    amplitude.getInstance().logEvent('close_quest_price', {questId: quest.id, page: 'quest'});
                }}
            >
                <PaymentLightbox isOpen={payment.isOpen} id={quest.id} objectType="QUEST" prices={quest.prices} />
            </Lightbox>
        </MainSection>
    );
};
