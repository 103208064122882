import amplitude from 'amplitude-js';
import {useCallback, useMemo, useState} from 'react';
import {useInterval} from 'react-use';

export interface Step<T> {
    name: string;
    data: T;
    duration?: number;
}
interface Config {
    introTime: number;
    transitionTime: number;
}

const DEFAULT_CONFIG: Config = {
    introTime: 0,
    transitionTime: 0,
};

export const useSteps = <T>(steps: Step<T>[], config?: Config) => {
    const {introTime, transitionTime} = {...DEFAULT_CONFIG, ...(config || {})};
    const [totalTime, setTotalTime] = useState(-introTime);
    const [pause, setPause] = useState(false);

    const [progress, setProgress] = useState(0);

    const [stepIndex, setStepIndex] = useState(0);
    const step = useMemo<Step<T> | undefined>(() => steps[stepIndex], [steps, stepIndex]);

    useInterval(
        () => {
            setTotalTime(i => i + 1);
            if (totalTime >= 0) {
                if (step && step.duration && progress >= step.duration) {
                    setProgress(-transitionTime);
                    setStepIndex(i => i + 1);
                } else {
                    setProgress(i => i + 1);
                }
            }
        },
        pause || !step ? null : 1000,
    );

    return {
        steps,
        step,
        stepIndex,
        progress,
        totalTime,
        isPaused: pause,
        pause: useCallback(() => setPause(true), []),
        play: useCallback(() => setPause(false), []),
        prevStep: useCallback(() => {
            setStepIndex(i => Math.max(0, i - 1));
            setProgress(stepIndex === 0 ? -introTime : -transitionTime);
        }, [introTime, stepIndex, transitionTime]),
        nextStep: useCallback(() => {
            setStepIndex(i => i + 1);
            setProgress(-transitionTime);
        }, [transitionTime]),
        changeStep: useCallback(
            (index: number) => {
                setStepIndex(Math.max(0, index));
                setProgress(stepIndex === 0 ? -introTime : -transitionTime);
                amplitude.getInstance().logEvent('click_on_navigate_to_exercise', {page: 'play_view'});
            },
            [introTime, stepIndex, transitionTime],
        ),
    };
};
