import React from 'react';
import styled from '@emotion/styled';
import {v4 as uuid} from 'uuid';
import {useEffectOnce} from 'react-use';

import {EditStepRow} from './EditStepRow';
import {ResizeInput} from '../../components/inputs/ResizeInput';
import {Exercises} from '../exercises/Exercises';
import {moveItem} from '../../components/SortableList';
import {InlineButton} from '../../components/Button';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

import {ReactComponent as PlusIcon} from './assets/plus.svg';
import {FormattedMessage, useIntl} from 'react-intl';
import {Section} from '../../ui/Section';
import {Icon} from '../../ui/Icon';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {DragHandle} from '../../widgets/DragHandle';
import {LightboxCard} from '../../widgets/LightboxCard';
import {SetModel, StepModel} from '../backend/models/TrainingModel';
import {Popover, usePopoverState} from '../../widgets/Popover';
import {Menu, MenuItem} from '../../widgets/Menu';

const SetLayout = styled(Section)<{active: boolean}>`
    user-select: none;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => props.theme.background};
    @media (max-width: 500px) {
        font-size: 0.9em;
    }
`;

const Title = styled(Section)`
    input,
    .times {
        font-size: 1.2em;
        font-family: ${props => props.theme.title};
        font-family: ${props => props.theme.title};
    }
`;

interface EditSetRowProps {
    set: SetModel;
    index: number;
    onEdit: (set: SetModel) => void;
    onDuplicate: (set: SetModel) => void;
    onRemove: (set: SetModel) => void;
}

export const EditSetRow: React.FC<EditSetRowProps> = ({set, index, onEdit, onDuplicate, onRemove}) => {
    const [active, setActive] = React.useState<boolean>(false);
    const intl = useIntl();
    const {isOpen, setIsOpen} = usePopoverState();
    const searchExercise = useLightboxState();
    const handleActive = React.useCallback(() => setActive(true), []);
    const handleDesactive = React.useCallback(() => setActive(false), []);
    const handleEditTitle = React.useCallback((title: any) => onEdit({...set, title: title}), [onEdit, set]);
    const handleEditReps = React.useCallback((reps: string) => onEdit({...set, reps: parseInt(reps)}), [onEdit, set]);
    const handleValidNumber = React.useCallback((value: string) => parseInt(value) >= 1 && parseInt(value) < 1000, []);
    const handleEditStep = React.useCallback(
        (index: number) => (newStep: StepModel) =>
            onEdit({
                ...set,
                steps: set.steps.map((step, i) => {
                    return i === index ? newStep : step;
                }),
            }),
        [onEdit, set],
    );
    const handleRemoveStep = React.useCallback((index: number) => () => onEdit({...set, steps: set.steps.filter((_, i) => i !== index)}), [onEdit, set]);
    const handleDuplicateStep = React.useCallback(
        (index: number | undefined) => (step: StepModel) => onEdit({...set, steps: [...set.steps.slice(0, index), {...step, id: uuid()}, ...set.steps.slice(index)]}),
        [onEdit, set],
    );
    const handleConfirmAddStep = React.useCallback(
        (exercise: any) => {
            onEdit({
                ...set,
                steps: [
                    ...set.steps,
                    {
                        id: uuid(),
                        exercise: exercise,
                        effortLabel: '',
                        effortValue: 30,
                        effortType: 'DURATION',
                        rest: 0,
                    },
                ],
            });
            searchExercise.close();
        },
        [onEdit, searchExercise, set],
    );
    const handleDuplicate = React.useCallback(() => {
        onDuplicate(set);
        setIsOpen(false);
    }, [onDuplicate, set, setIsOpen]);
    const handleRemove = React.useCallback(() => {
        onRemove(set);
        setIsOpen(false);
    }, [onRemove, set, setIsOpen]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        onEdit({...set, steps: moveItem(set.steps, result.source.index, result.destination.index)});
    };

    useEffectOnce(() => {
        const t = setTimeout(() => {
            // ugly trick to render AutosizeInput properly
            onEdit({...set, title: set.title, reps: set.reps});
        });
        return () => clearTimeout(t);
    });
    return (
        <Draggable key={set.id} draggableId={set.id!.toString()} index={index}>
            {provided => (
                <SetLayout
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    direction="y"
                    spacing={10}
                    active={active}
                    onMouseEnter={handleActive}
                    onMouseLeave={handleDesactive}
                >
                    <Title align="center" spacing={10}>
                        <div {...provided.dragHandleProps}>
                            <DragHandle />
                        </div>
                        <ResizeInput
                            placeholder={intl.formatMessage({id: 'app.training.newSet'})}
                            isBig={true}
                            onMouseEnter={handleDesactive}
                            onMouseLeave={handleActive}
                            value={set!.title}
                            onValue={handleEditTitle}
                        />
                        <div className="times">x</div>
                        <ResizeInput
                            isBig={true}
                            onMouseEnter={handleDesactive}
                            onMouseLeave={handleActive}
                            value={set!.reps.toString()}
                            onValue={handleEditReps}
                            validate={handleValidNumber}
                        />
                        <Popover isOpen={isOpen} onToggle={setIsOpen} node={<Icon name="contextMenu" />}>
                            <Menu direction="y">
                                <MenuItem selected={false} onClick={handleDuplicate}>
                                    <FormattedMessage id="app.training.duplicate" />
                                </MenuItem>
                                <MenuItem selected={false} onClick={handleRemove}>
                                    <FormattedMessage id="app.training.remove" />
                                </MenuItem>
                            </Menu>
                        </Popover>
                    </Title>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <Section direction="y" spacing={10} ref={provided.innerRef} {...provided.droppableProps}>
                                    {set.steps.length > 0 &&
                                        set.steps.map((step, i) => (
                                            <EditStepRow
                                                isDragging={false}
                                                key={i}
                                                index={i}
                                                step={step}
                                                onActive={handleDesactive}
                                                onDesactive={handleActive}
                                                onEdit={handleEditStep(i)}
                                                onDuplicate={handleDuplicateStep(i)}
                                                onRemove={handleRemoveStep(i)}
                                            />
                                        ))}
                                    {provided.placeholder}
                                </Section>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div style={{paddingBottom: 20}}>
                        <InlineButton onMouseEnter={handleDesactive} onMouseLeave={handleActive} onClick={searchExercise.open}>
                            <PlusIcon className="icon" />
                            <span>
                                <FormattedMessage id="app.training.addExercise" />
                            </span>
                        </InlineButton>
                    </div>
                    <Lightbox isOpen={searchExercise.isOpen} onRequestClose={searchExercise.close}>
                        <LightboxCard sheet={true} isOpen={searchExercise.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                            <Exercises isOpen={searchExercise.isOpen} onCancel={searchExercise.close} onSelect={handleConfirmAddStep} />
                        </LightboxCard>
                    </Lightbox>
                </SetLayout>
            )}
        </Draggable>
    );
};
