import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {IntlProvider} from 'react-intl';

import enUS from './translations/en-US';
import frFR from './translations/fr-FR';

export const locales: {[key: string]: Record<string, string>} = {
    'en-US': enUS,
    'fr-FR': {...enUS, ...frFR},
};

interface LocaleContextProps {
    locale: string;
    changeLocale: (newLocale: string) => void;
}

interface LocaleProviderProps {
    children: React.ReactNode;
}

export const LocaleContext = React.createContext<Partial<LocaleContextProps>>({});
export const useLocale = () => React.useContext(LocaleContext);

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
    const [locale, setLocale] = React.useState<string>(
        locales[localStorage.getItem('locale') || window.navigator.language] ? localStorage.getItem('locale') || window.navigator.language : 'en-US',
    );
    const changeLocale = React.useCallback((newLocale: string) => {
        setLocale(newLocale);
        localStorage.setItem('locale', newLocale);
    }, []);
    useEffect(() => {
        dayjs.locale(locale.split('-')[0]);
    }, [locale]);
    return (
        <LocaleContext.Provider value={{locale, changeLocale}}>
            <IntlProvider locale={locale!} messages={locales[locale!]}>
                {children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
};
