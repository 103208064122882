import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import {transparentize} from 'polished';
import {Section} from '../../ui/Section';
import {Button, PrimaryButton} from '../../widgets/Button';
import {LightboxCard} from '../../widgets/LightboxCard';

const appear = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const disappear = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const itemAppear = keyframes`
    0% { opacity: 0; transform: scale(0.9); }
    100% { opacity: 1; transform: scale(1); }
`;

export const MenuLayout = styled(Section)<{isOpen: boolean}>`
    animation: ${props => (props.isOpen ? appear : disappear)} 0.6s;
    color: ${props => props.theme.foreground};
    background-color: ${props => props.theme.background};
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const OverlayLayout = styled(Section)<{isOpen: boolean}>`
    animation: ${props => (props.isOpen ? appear : disappear)} 0.6s;
    color: ${props => props.theme.foreground};
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const OverlayContent = styled(LightboxCard)`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 20px 20px 0 0;
`;

export const Topbar = styled(Section)`
    z-index: 2;
    font-size: 0.8em;
    padding: 20px 20px 0 20px;
    @media (max-width: 800px) {
        padding: 10px 20px 0 20px;
    }
`;

export const Content = styled(Section)<{flip?: boolean}>`
    flex-direction: column;
    @media (orientation: landscape) {
        flex-direction: ${props => (props.flip ? 'row-reverse' : 'row')};
        > :not(:last-child) {
            margin-right: 20px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
`;

export const MenuButton = styled(PrimaryButton)<{delay?: number}>`
    opacity: 0;
    animation: ${itemAppear} 0.5s ease-out ${props => props.delay || 0}s forwards;
    padding: 15px 30px;
    font-size: 2em;
    border-radius: 20px;
    width: 400px;
    transition: transform 0.3s;
    &:active {
        transform: scale(0.95);
    }
    @media (max-width: 1200px) {
        padding: 12px 25px;
        font-size: 1.5em;
        border-radius: 15px;
        width: 300px;
    }
    @media (max-width: 800px) {
        padding: 10px 20px;
        font-size: 1.2em;
        border-radius: 10px;
        width: 250px;
    }
`;

export const MenuSubButton = styled(Button)<{delay?: number}>`
    opacity: 0;
    animation: ${itemAppear} 0.5s ease-out ${props => props.delay || 0}s forwards;
    padding: 15px 30px;
    font-size: 2em;
    border-radius: 20px;
    width: 400px;
    transition: transform 0.3s;
    &:active {
        transform: scale(0.95);
    }
    @media (max-width: 1200px) {
        padding: 12px 25px;
        font-size: 1.5em;
        border-radius: 15px;
        width: 300px;
    }
    @media (max-width: 800px) {
        padding: 10px 20px;
        font-size: 1.2em;
        border-radius: 10px;
        width: 250px;
    }
`;

export const MenuTitle = styled.div<{delay?: number}>`
    opacity: 0;
    animation: ${itemAppear} 0.5s ease-out ${props => props.delay || 0}s forwards;
    color: ${props => props.theme.foreground};
    font-size: 4em;
    font-family: ${props => props.theme.title};
    text-align: center;
    padding: 0 20px;
    @media (max-width: 1200px) {
        font-size: 3em;
    }
    @media (max-width: 800px) {
        font-size: 1.8em;
    }
`;

export const MenuSubtitle = styled.div<{delay?: number}>`
    opacity: 0;
    color: ${props => props.theme.subForeground};
    animation: ${itemAppear} 0.5s ease-out ${props => props.delay || 0}s forwards;
    font-size: 2em;
    text-align: center;
    padding: 0 20px 20px 20px;
    @media (max-width: 1200px) {
        font-size: 1.5em;
    }
    @media (max-width: 800px) {
        font-size: 1.2em;
    }
`;

export const MenuLabel = styled.div<{delay?: number}>`
    opacity: 0;
    animation: ${itemAppear} 0.5s ease-out ${props => props.delay || 0}s forwards;
    display: flex;
    font-family: ${props => props.theme.body};
    color: ${props => props.theme.subForeground};
    font-size: 1.8em;
    text-align: center;
    padding: 0 20px;
    padding-bottom: 10px;
    @media (max-width: 1200px) {
        font-size: 1.3em;
    }
    @media (max-width: 800px) {
        font-size: 1em;
    }
`;

const menuAppear = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const MenuOverlay = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    position: absolute;
    animation: ${menuAppear} 0.3s;
    backdrop-filter: blur(5px);
    background-color: ${props => transparentize(0.1, props.theme.background)};
`;

export const MenuIcon = styled.div<{color?: string}>`
    svg {
        [fill='black'] {
            fill: ${props => (props.color ? props.color : props.theme.foreground)};
        }
        [stroke='black'] {
            stroke: ${props => (props.color ? props.color : props.theme.foreground)};
        }
    }
    cursor: pointer;
    transition: all 0.3s;
    transform: scale(1.5);
    @media (max-width: 1200px) {
        transform: scale(1.2);
    }
    @media (max-width: 800px) {
        transform: scale(1);
    }
`;

export const SetLabel = styled.div`
    font-size: 3em;
    @media (max-width: 1200px) {
        font-size: 2.5em;
    }
    @media (max-height: 750px) {
        font-size: 2.5em;
    }
    @media (max-width: 800px) {
        font-size: 2em;
    }
    @media (max-height: 600px) {
        font-size: 2em;
    }
    @media (max-width: 600px) {
        font-size: 1.5em;
    }
    @media (max-height: 400px) {
        font-size: 1.5em;
    }
`;

export const NextExerciseLabel = styled.div`
    color: ${props => props.theme.subForeground};
    padding: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    font-size: 1.6em;
    bottom: 100px;
    @media (max-width: 800px) {
        bottom: 80px;
    }
    right: 20px;
    img {
        margin-right: 10px;
        width: 60px;
        border-radius: 10px;
    }
    @media (max-width: 1200px) {
        font-size: 1.2em;
    }
    @media (max-height: 750px) {
        font-size: 1.2em;
    }
    @media (max-width: 600px) {
        font-size: 1em;
    }
    @media (max-height: 500px) {
        font-size: 1em;
    }
`;

export const TimerLabel = styled.div`
    font-family: ${props => props.theme.title};
    font-size: 3em;
    width: 110px;
    @media (max-width: 1200px) {
        font-size: 2.5em;
        width: 90px;
    }
    @media (max-height: 750px) {
        font-size: 2.5em;
        width: 90px;
    }
    @media (max-width: 800px) {
        font-size: 2em;
        width: 70px;
    }
    @media (max-height: 600px) {
        font-size: 2em;
        width: 70px;
    }
    @media (max-width: 600px) {
        font-size: 1.5em;
        width: 50px;
    }
    @media (max-height: 400px) {
        font-size: 1.5em;
        width: 50px;
    }
`;
