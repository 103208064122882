import styled from '@emotion/styled';
import React, {CSSProperties, useCallback} from 'react';

import {Icon} from '../../ui/Icon';
import {Choices} from '../../components/Choices';
import {Section} from '../../ui/Section';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {Draggable} from 'react-beautiful-dnd';
import {Exercises} from '../exercises/Exercises';
import {DragHandle} from '../../widgets/DragHandle';
import {TextLayout} from '../../widgets/Text';
import {ResizeInput} from '../../components/inputs/ResizeInput';
import {LightboxCard} from '../../widgets/LightboxCard';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {ExerciseThumbnail} from '../exercises/ExerciseThumbnail';
import {FormattedMessage, useIntl} from 'react-intl';
import {StepModel} from '../backend/models/TrainingModel';
import {Popover, usePopoverState} from '../../widgets/Popover';
import {Menu, MenuItem} from '../../widgets/Menu';

const StepLayout = styled(Section)<{active: boolean; isDragging: boolean}>`
    color: ${props => props.theme.subForeground};
    background: ${props => props.theme.background};
    border-radius: ${props => props.theme.borderRadius};
`;

const effortTypeValues = ['DURATION', 'REPS', 'DISTANCE', 'MAX_HOLD', 'MAX_REPS', 'MAX_DISTANCE'];

interface EditStepRowProps {
    index: number;
    step: StepModel;
    isDragging: boolean;
    onActive?: () => void;
    onDesactive?: () => void;
    onEdit: (step: StepModel) => void;
    onDuplicate: (step: StepModel) => void;
    onRemove: () => void;
}

const cursorPointer: CSSProperties = {cursor: 'pointer'};

export const EditStepRow: React.FC<EditStepRowProps> = ({index, step, isDragging, onActive, onDesactive, onEdit, onDuplicate, onRemove}) => {
    const intl = useIntl();
    const [active, setActive] = React.useState<boolean>(false);
    const {isOpen, setIsOpen} = usePopoverState();
    const searchExercise = useLightboxState();
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const handleEditDuration = React.useCallback((duration: string) => onEdit({...step, effortValue: parseInt(duration)}), [onEdit, step]);
    const handleEditDistance = React.useCallback((distance: string) => onEdit({...step, effortValue: parseInt(distance)}), [onEdit, step]);
    const handleEditReps = React.useCallback((reps: string) => onEdit({...step, effortValue: parseInt(reps)}), [onEdit, step]);
    const handleEditEffortType = React.useCallback((newValue: any) => onEdit({...step, effortType: newValue}), [onEdit, step]);
    const handleEditRest = React.useCallback((rest: string) => onEdit({...step, rest: parseInt(rest)}), [onEdit, step]);
    const handleSelectExercise = React.useCallback(
        (exercise: any) => {
            onEdit({...step, exercise: exercise});
            searchExercise.close();
        },
        [onEdit, searchExercise, step],
    );
    const handleValidNumber = React.useCallback((value: string) => parseInt(value) >= 1 && parseInt(value) < 86400, []);
    const handleActive = () => {
        setActive(true);
        onActive && onActive();
    };
    const handleDesactive = () => {
        setActive(false);
        onDesactive && onDesactive();
    };
    const handleDuplicate = useCallback(() => {
        onDuplicate(step);
        setIsOpen(false);
    }, [onDuplicate, setIsOpen, step]);
    const handleRemove = useCallback(() => {
        onRemove();
        setIsOpen(false);
    }, [onRemove, setIsOpen]);
    return (
        <Draggable key={step.id!.toString()} draggableId={step.id!.toString()} index={index}>
            {provided => (
                <StepLayout
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    active={active}
                    isDragging={isDragging}
                    spacing={10}
                    align="center"
                    onMouseEnter={handleActive}
                    onMouseLeave={handleDesactive}
                >
                    <div {...provided.dragHandleProps}>
                        <DragHandle />
                    </div>
                    <div
                        style={cursorPointer}
                        onMouseEnter={() => {
                            setIsSearching(true);
                        }}
                        onMouseLeave={() => {
                            setIsSearching(false);
                        }}
                        onClick={searchExercise.open}
                    >
                        <ExerciseThumbnail isSearching={isSearching} youtubeId={step?.exercise.playYoutubeId} width={90} />
                    </div>
                    <Section direction="y" grow={1} spacing={5}>
                        <Section>
                            <TextLayout
                                ellipsis={true}
                                weight="bold"
                                grow={1}
                                onMouseEnter={() => {
                                    setIsSearching(true);
                                }}
                                onMouseLeave={() => {
                                    setIsSearching(false);
                                }}
                                style={cursorPointer}
                                onClick={searchExercise.open}
                            >
                                {step?.exercise.name || 'Search exercise...'}
                            </TextLayout>
                        </Section>
                        <Section spacing={5} align="center">
                            {step.effortType === 'DURATION' && (
                                <ResizeInput value={step.effortValue!.toString()} onValue={handleEditDuration} validate={handleValidNumber} />
                            )}
                            {step.effortType === 'DISTANCE' && (
                                <ResizeInput value={step.effortValue!.toString()} onValue={handleEditDistance} validate={handleValidNumber} />
                            )}
                            {step.effortType === 'REPS' && (
                                <ResizeInput value={step.effortValue!.toString()} onValue={handleEditReps} validate={handleValidNumber} />
                            )}
                            <Choices
                                value={step.effortType}
                                values={effortTypeValues}
                                labels={effortTypeValues.map(value => intl.formatMessage({id: `app.training.${value}`}))}
                                onChange={handleEditEffortType}
                            />
                            <span>
                                <FormattedMessage id="app.training.REST" />
                            </span>
                            <ResizeInput value={step.rest?.toString() || '0'} onValue={handleEditRest} />
                            <span>s</span>
                        </Section>
                    </Section>
                    <Lightbox isOpen={searchExercise.isOpen} onRequestClose={searchExercise.close}>
                        <LightboxCard sheet={true} isOpen={searchExercise.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                            <Exercises
                                isOpen={searchExercise.isOpen}
                                currentExercise={step.exercise}
                                onCancel={searchExercise.close}
                                onSelect={handleSelectExercise}
                            />
                        </LightboxCard>
                    </Lightbox>
                    <Popover isOpen={isOpen} onToggle={setIsOpen} node={<Icon name="contextMenu" />}>
                        <Menu direction="y">
                            <MenuItem
                                selected={false}
                                onClick={() => {
                                    searchExercise.open();
                                    setIsOpen(false);
                                }}
                            >
                                <FormattedMessage id="app.training.edit" />
                            </MenuItem>
                            <MenuItem selected={false} onClick={handleDuplicate}>
                                <FormattedMessage id="app.training.duplicate" />
                            </MenuItem>
                            <MenuItem selected={false} onClick={handleRemove}>
                                <FormattedMessage id="app.training.remove" />
                            </MenuItem>
                        </Menu>
                    </Popover>
                </StepLayout>
            )}
        </Draggable>
    );
};
