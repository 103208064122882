import React from 'react';
import styled from '@emotion/styled';

import {ReactComponent as Chevron} from './assets/chevron.svg';

const StyledSelect = styled.div<{dark: boolean}>`
    color: ${props => props.theme.foreground};
    height: auto;
    display: flex;
    position: relative;
    max-width: 100%;
    align-items: stretch;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => props.theme.subBackground};
    select {
        padding: 8px 30px 8px 15px;
    }
`;

const StyledSelectInput = styled.select<{dark: boolean}>`
    flex: 1;
    outline: none;
    color: ${props => props.theme.foreground};
    border: none;
    cursor: pointer;
    margin: auto;
    display: flex;
    padding: 10px 0;
    overflow: hidden;
    font-size: 1em;
    max-width: 100%;
    appearance: none;
    font-family: ${props => props.theme.body};
    border-radius: ${props => props.theme.borderRadius};
    background-color: transparent;
`;

const StyledIcon = styled(Chevron)`
    right: 15px;
    position: absolute;
    align-self: center;
    pointer-events: none;
    background-color: transparent;
`;

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    dark?: boolean;
}

export const Select: React.FC<SelectProps> = ({dark = false, children, ...props}) => {
    return (
        <StyledSelect dark={dark} className={props.className}>
            <StyledSelectInput dark={dark} {...props}>
                {children}
            </StyledSelectInput>
            <StyledIcon />
        </StyledSelect>
    );
};
