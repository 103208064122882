import React, {ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {Input} from '../../components/inputs/Input';
import {Section} from '../../ui/Section';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import {ActivityModel} from '../backend/models/ActivityModel';
import {Button, PrimaryButton} from '../../widgets/Button';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {useTheme} from '@emotion/react';
import amplitude from 'amplitude-js';
import { ResizeInput } from '../../components/inputs/ResizeInput';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { LightboxCard } from '../../widgets/LightboxCard';
import { Sports } from '../sports/Sports';
import { useLightboxState } from '../../ui/Lightbox/useLightboxState';
import { useLocale } from '../locale/Locale';
import { SecondaryButton } from '../../components/Button';
import { notify } from '../../components/toast/Toast';

export const CreateActivityDialog: React.FC<{onClose: () => void}> = ({onClose}) => {
    const intl = useIntl();
    const theme = useTheme();
    const locale = useLocale();
    const navigate = useNavigate();
    const backend = useBackend();
    const choseSport = useLightboxState();
    const handleValidNumber = React.useCallback((value: string) => parseInt(value) >= 1 && parseInt(value) < 1000, []);
    const [activity, setActivity] = React.useState<ActivityModel>({
        id: '',
        user: backend.authUser!,
        sport: undefined,
        title: '',
        duration: 0,
        drop: false,
        manual: true
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const handleEditTitle = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setActivity(a => ({...a, title: e.target.value}));
    }, []);
    const handleEditDuration = React.useCallback((duration: string) => {
        setActivity(a => ({...a, duration: parseInt(duration) * 60}));
    }, []);
    const handleSelectSport = React.useCallback((value: any) => {
        choseSport.close();
        setActivity(a => ({...a, sport: value}));
    }, [choseSport]);
    const handleCreate = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const a = await backend.createActivity(activity);
            navigate(`/activities/${a.id}`);
            onClose();
            notify(intl.formatMessage({id: 'app.activity.wellDone'}));
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }, [activity, backend, navigate, onClose]);

    return (
        <Section direction="y" padding="20px" spacing={20} width="100%">
            <TextLayout size={1.5} weight="bold" margin="0 auto" padding="0 40px">
                <FormattedMessage id="app.activity.createTitle" />
            </TextLayout>
            <Input placeholder={intl.formatMessage({id: 'app.activity.titlePlaceholder'})} value={activity.title} onChange={handleEditTitle} />
            {/* duration */}
            <div style={{marginLeft: '6px'}}>
            <TextLayout weight="bold" size={1.1}>
                <FormattedMessage id="app.activity.duration" />
                <ResizeInput isBig={true} value={activity.duration.toString()} onValue={handleEditDuration} validate={handleValidNumber} />
                <FormattedMessage id="app.activity.minutes" />
            </TextLayout>
            </div >
            {/* sport */}
            {(!activity.sport) ? (
                <SecondaryButton onClick={choseSport.open}>
                    <FormattedMessage id="app.activity.choseSport" />
                </SecondaryButton>
            ) : (
                <PrimaryButton onClick={choseSport.open}>
                    {locale.locale === 'fr-FR' ? activity.sport.frValue : activity.sport.enValue}
                </PrimaryButton>
            )}
            <Section distribute="between">
                <Button onClick={() => {
                        onClose();
                        amplitude.getInstance().logEvent('cancel_create_new_activity', {activityTitle: activity.title, page: 'tools'});
                    }}
                >
                    <FormattedMessage id="app.activity.cancel" />
                </Button>
                <PrimaryButton
                    disabled={isLoading || activity.sport === undefined || activity.duration === 0}
                    onClick={() => {
                        handleCreate();
                        amplitude.getInstance().logEvent('create_new_activity', {activityTitle: activity.title, page: 'tools'});
                    }}
                >
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={18} /> : <FormattedMessage id={'app.activity.create'} />}
                </PrimaryButton>
            </Section>
            <Lightbox isOpen={choseSport.isOpen} onRequestClose={choseSport.close}>
                <LightboxCard sheet={true} isOpen={choseSport.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Sports isOpen={choseSport.isOpen}  onSelect={handleSelectSport} onCancel={choseSport.close} />
                </LightboxCard>
            </Lightbox>
        </Section>
    );
};
