import React from 'react';
import styled from '@emotion/styled';
import {forwardRef, memo} from 'react';
import type {MouseEvent} from 'react';

import {ReactComponent as Add} from './assets/add.svg';
import {ReactComponent as Back} from './assets/back.svg';
import {ReactComponent as Bold} from './assets/bold.svg';
import {ReactComponent as Bookmark} from './assets/bookmark.svg';
import {ReactComponent as Close} from './assets/close.svg';
import {ReactComponent as ContextMenu} from './assets/contextMenu.svg';
import {ReactComponent as Copy} from './assets/copy.svg';
import {ReactComponent as Edit} from './assets/edit.svg';
import {ReactComponent as Heading} from './assets/heading.svg';
import {ReactComponent as Hidden} from './assets/hidden.svg';
import {ReactComponent as Go} from './assets/go.svg';
import {ReactComponent as Image} from './assets/image.svg';
import {ReactComponent as Info} from './assets/info.svg';
import {ReactComponent as Italic} from './assets/italic.svg';
import {ReactComponent as Link} from './assets/link.svg';
import {ReactComponent as NewActivity} from './assets/newActivity.svg';
import {ReactComponent as NewTraining} from './assets/newTraining.svg';
import {ReactComponent as Ol} from './assets/ol.svg';
import {ReactComponent as Payment} from './assets/payment.svg';
import {ReactComponent as Play} from './assets/play.svg';
import {ReactComponent as Private} from './assets/private.svg';
import {ReactComponent as Public} from './assets/public.svg';
import {ReactComponent as Quote} from './assets/quote.svg';
import {ReactComponent as Remove} from './assets/remove.svg';
import {ReactComponent as Save} from './assets/save.svg';
import {ReactComponent as Saved} from './assets/saved.svg';
import {ReactComponent as Schedule} from './assets/schedule.svg';
import {ReactComponent as Search} from './assets/search.svg';
import {ReactComponent as Settings} from './assets/settings.svg';
import {ReactComponent as Strike} from './assets/strike.svg';
import {ReactComponent as Stopwatch} from './assets/stopwatch.svg';
import {ReactComponent as Ul} from './assets/ul.svg';
import {ReactComponent as Unbookmark} from './assets/unbookmark.svg';
import {ReactComponent as Underline} from './assets/underline.svg';
import {ReactComponent as Warning} from './assets/warning.svg';
import {ReactComponent as Youtube} from './assets/youtube.svg';
import {ReactComponent as Home} from './assets/home.svg';
import {ReactComponent as Follow} from './assets/follow.svg';
import {ReactComponent as Unfollow} from './assets/unfollow.svg';
import {ReactComponent as Activity} from './assets/activity.svg';
import {ReactComponent as ToolsButton} from './assets/tools.svg';

export type IconName = keyof typeof icons;

interface IconProps {
    name: IconName;
    color?: string;
    title?: string;
    disabled?: boolean;
    className?: string;
    //
    width?: number;
    height?: number;
    //
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
}

const IconWrapper = styled.span<Omit<IconProps, 'name' | 'className'>>`
    display: inline-flex;
    align-self: center;
    cursor: ${props => (props.onClick && !props.disabled ? 'pointer' : undefined)};
    opacity: ${props => (props.disabled ? '50%' : '100%')};
    pointer-events: ${props => (props.disabled ? 'none' : undefined)};
    svg {
        width: ${props => (props.width ? `${props.width}px` : 'initial')};
        height: ${props => (props.height ? `${props.height}px` : 'initial')};
    }
    [fill='black'] {
        transition: all 0.3s;
        fill: ${props => props.color || props.theme.subForeground};
    }
    [stroke='black'] {
        transition: all 0.3s;
        stroke: ${props => props.color || props.theme.subForeground};
    }
    [stop-color='black'] {
        transition: all 0.3s;
        stop-color: ${props => props.color || props.theme.subForeground};
    }
    ${props =>
        props.onClick &&
        `
        transition: transform 0.3s;
        &:hover {
            [fill='black'] {
                transition: all 0.3s;
                fill: ${props.color || props.theme.foreground};
            }
            [stroke='black'] {
                transition: all 0.3s;
                stroke: ${props.color || props.theme.foreground};
            }
            [stop-color='black'] {
                transition: all 0.3s;
                stop-color: ${props.color || props.theme.foreground};
            }
        }
        &:active {
            transform: scale(0.9);
            [fill='black'] {
                transition: all 0.3s;
                fill: ${props.color || props.theme.foreground};
            }
            [stroke='black'] {
                transition: all 0.3s;
                stroke: ${props.color || props.theme.foreground};
            }
            [stop-color='black'] {
                transition: all 0.3s;
                stop-color: ${props.color || props.theme.foreground};
            }
        }
    `}
`;

const icons = {
    add: <Add />,
    activity: <Activity />,
    back: <Back />,
    bookmark: <Bookmark />,
    bold: <Bold />,
    close: <Close />,
    contextMenu: <ContextMenu />,
    copy: <Copy />,
    edit: <Edit />,
    follow: <Follow />,
    go: <Go />,
    hidden: <Hidden />,
    heading: <Heading />,
    home: <Home />,
    italic: <Italic />,
    image: <Image />,
    info: <Info />,
    link: <Link />,
    newActivity: <NewActivity />,
    newTraining: <NewTraining />,
    ol: <Ol />,
    play: <Play />,
    private: <Private />,
    public: <Public />,
    payment: <Payment />,
    quote: <Quote />,
    remove: <Remove />,
    save: <Save />,
    saved: <Saved />,
    strike: <Strike />,
    schedule: <Schedule />,
    search: <Search />,
    settings: <Settings />,
    stopwatch: <Stopwatch />,
    toolsButton: <ToolsButton />,
    unfollow: <Unfollow />,
    unbookmark: <Unbookmark />,
    underline: <Underline />,
    ul: <Ul />,
    warning: <Warning />,
    youtube: <Youtube />
};

export const iconNames = Object.keys(icons) as IconName[];

export const Icon = memo(
    forwardRef<HTMLSpanElement, IconProps>(({name, color, title, disabled, className, width, height, onClick}, ref) => {
        return (
            <IconWrapper ref={ref} disabled={disabled} color={color} title={title} className={className} width={width} height={height} onClick={onClick}>
                {icons[name]}
            </IconWrapper>
        );
    }),
);
