import styled from '@emotion/styled';
import React, {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useMedia} from 'react-use';
import {useTheme} from '@emotion/react';
import {v4 as uuid} from 'uuid';
import {useNavigate, useParams} from 'react-router-dom';
import {DropResult, DragDropContext, Droppable} from 'react-beautiful-dnd';

import {Input} from '../../components/inputs/Input';
import {notify} from '../../components/toast/Toast';
import {moveItem} from '../../components/SortableList';
import {EditSetRow} from './EditSetRow';
import {useShortcut} from '../../utils/useShortcut';
import {TrainingVisibilityPopover} from './TrainingVisibilityPopover';

import {Icon} from '../../ui/Icon';
import {Layout} from '../../ui/Layout';
import {Section} from '../../ui/Section';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {ImagePicker} from '../../widgets/ImagePicker';
import {LoadingPage} from '../../widgets/LoadingPage';
import {YoutubeLink} from '../../components/YoutubeLink';
import {PrimaryButton} from '../../widgets/Button';
import {LightboxConfirm} from '../../widgets/LightboxConfirm';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {useBackend} from '../backend/useBackend';
import {SetModel, TrainingModel} from '../backend/models/TrainingModel';
import {Select} from '../../components/Select';
import {FixedAspect} from '../../ui/FixedAspect';
import {ErrorPage} from '../../widgets/ErrorPage';
import {RichText} from '../../widgets/RichText/RichText';
import {PriceLightbox} from './PriceLightbox';
import amplitude from 'amplitude-js';
import { SecondaryButton } from '../../components/Button';
import { useLocale } from '../locale/Locale';
import { LightboxCard } from '../../widgets/LightboxCard';
import { Sports } from '../sports/Sports';
import { Types } from '../sports/Types';
import { SelectableTags } from '../../components/Tags';

const TitleInput = styled(Input)`
    font-family: ${props => props.theme.title};
    font-size: 1.6em;
    box-sizing: border-box;
    max-width: 600px;
    width: 100%;
`;

const AddSetButton = styled(PrimaryButton)`
    margin: 10px;
    align-self: flex-start;
`;

export const EditTraining: React.FC<{training: TrainingModel; onChange: (value: TrainingModel) => void; onBack: () => void}> = ({
    training,
    onChange,
    onBack,
}) => {
    const navigate = useNavigate();
    const locale = useLocale();
    const theme = useTheme();
    const matches = useMedia('(max-width: 800px)');
    const {id} = useParams<{id: string}>();
    const intl = useIntl();
    const backend = useBackend();
    const [saved, setSaved] = useState(true);
    const [error, setError] = useState('');
    const choseSport = useLightboxState();
    const choseType = useLightboxState();

    const [thumbnailIsLoading, setThumbnailIsLoading] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleEditTitle = React.useCallback(
        (e: { target: { value: any; }; }) => {
            onChange({...training, title: e.target.value});
            setSaved(false);
        },
        [onChange, training],
    );
    const handleEditDifficulty = React.useCallback(
        (e: { target: { value: any; }; }) => {
            onChange({...training, difficulty: e.target.value});
            setSaved(false);
        },
        [onChange, training],
    );
    const handleSelectSport = React.useCallback((value: any) => {
        choseSport.close();
        if (training) {
            onChange({...training, sport: value});
            setSaved(false);
        }
    }, [choseSport, onChange, training])
    const handleSelectType = React.useCallback((value: any) => {
        choseType.close();
        if (training) {
            onChange({...training, type: value});
            setSaved(false);
        }
    }, [choseType, onChange, training])
    const handleSelectTag = React.useCallback((tagKey: string) => {
        if (training) {
            let newTags;
            const isAlreadySelected = training.tags.some(tag => tag.key === tagKey);

            if (isAlreadySelected) {
                newTags = training.tags.filter(tag => tag.key !== tagKey);
            } else {
                const tagToAdd = training.availableTags.find(tag => tag.key === tagKey);
                if (tagToAdd) {
                    newTags = [...training.tags, tagToAdd];
                } else {
                    newTags = [...training.tags];
                }
            }
            onChange({
                ...training,
                tags: newTags
            });
            setSaved(false);
        }
    }, [onChange, training]);
    const handleEditDescription = React.useCallback(
        (value: any) => {
            onChange({...training, description: value});
            setSaved(false);
        },
        [onChange, training],
    );
    const handleEditSet = React.useCallback(
        (index: number) => (newSet: SetModel) => {
            onChange({
                ...training,
                sets: training.sets.map((set, i) => {
                    return i === index ? newSet : set;
                }),
            });
            setSaved(false);
        },
        [onChange, training],
    );
    const handleDuplicateSet = React.useCallback(
        (set: SetModel) => {
            onChange({
                ...training,
                sets: [...training.sets.slice(0, training.sets.indexOf(set)), {...set, id: uuid()}, ...training.sets.slice(training.sets.indexOf(set))],
            });
            setSaved(false);
        },
        [onChange, training],
    );
    const handleRemoveSet = React.useCallback(
        (index: number) => (set: SetModel) => {
            onChange({...training, sets: training.sets.filter((set, i) => i !== index)});
            setSaved(false);
        },
        [onChange, training],
    );
    const handleAddSet = React.useCallback(() => {
        onChange({
            ...training,
            sets: [
                ...training.sets,
                {
                    id: uuid(),
                    title: '',
                    reps: 1,
                    steps: [],
                } as SetModel,
            ],
        });
        setSaved(false);
    }, [onChange, training]);
    const handleDuplicate = React.useCallback(async () => {
        const newTraining = await backend.createTraining({
            ...training,
            title: `${training.title} (${intl.formatMessage({id: 'app.training.copy'})})`,
            sets: training.sets.map(set => {
                return {...set, id: '', steps: set.steps.map(step => ({...step, id: ''}))};
            }),
        });
        navigate(`/trainings/${newTraining.id}`);
    }, [backend, navigate, intl, training]);
    const handleSave = React.useCallback(async () => {
        setIsLoading(true);
        if (id === undefined) {
            setError('notFound');
            setIsLoading(false);
        } else {
            backend
                .updateTraining({...training, id})
                .then(() => setSaved(true))
                .catch(err => setError(err.message))
                .finally(() => setIsLoading(false));
        }
    }, [backend, id, training]);
    const handleBack = useCallback(async () => {
        await handleSave();
        amplitude.getInstance().logEvent('click_on_back_edit_training', {trainingId: training.id, page: 'edit_training'});
        onBack();
    }, [handleSave, onBack, training]);
    const handleChangeVisibility = React.useCallback(
        async (visibility: any) => {
            const newTraining = {...training, visibility: visibility};
            onChange(newTraining);
            if (id !== undefined) {
                try {
                    await backend.updateTraining(newTraining);
                } catch (err) {
                    notify(intl.formatMessage({id: (err as Error).message}), 'error');
                }
            }
        },
        [backend, id, intl, onChange, training],
    );
    const handleChangeIntroYoutubeId = React.useCallback(
        (introYoutubeId: string) => {
            if (training) {
                onChange({...training, introYoutubeId});
                setSaved(false);
            }
        },
        [onChange, training],
    );
    const price = useLightboxState();
    const remove = useLightboxState();
    const handleConfirmRemove = React.useCallback(async () => {
        if (training) {
            backend
                .deleteTraining(training.id)
                .then(() => navigate(`/user/${training.author.id}`))
                .catch(err => setError(err.message));
        }
    }, [backend, navigate, training]);
    const handleThumbnailUpload = React.useCallback(
        async (file: File) => {
            if (!training) {
                return;
            }
            setThumbnailIsLoading(true);
            if (training.id) {
                try {
                    const t = await backend.uploadTrainingThumbnail(training.id, file);
                    onChange(t);
                } catch (err) {
                    notify(intl.formatMessage({id: (err as Error).message}), 'error');
                }
            }
            setThumbnailIsLoading(false);
        },
        [backend, intl, onChange, training],
    );
    const onDragEnd = (result: DropResult) => {
        if (!training || !result.destination || result.destination.index === result.source.index) {
            return;
        }
        onChange({...training, sets: moveItem(training.sets, result.source.index, result.destination.index)});
        setSaved(false);
    };
    // React.useEffect(() => {
    //     if (id !== undefined) {
    //         backend
    //             .getTraining(id)
    //             .then(setTraining)
    //             .catch(err => setError(err.message));
    //     }
    // }, [backend, id, intl]);
    useShortcut('mod+s', handleSave, true);

    if (error) {
        return <ErrorPage error={error} />;
    }

    return (
        <Section direction="y" grow={1}>
            <Section align="center" padding="0 20px" spacing={15} height="60px">
                <Icon name="back" title={intl.formatMessage({id: 'app.training.back'})} onClick={handleBack} />
                <TrainingVisibilityPopover value={training.visibility} onChange={handleChangeVisibility} trainingId={training.id} />
                {id !== undefined &&
                    <Icon
                        name="remove"
                        onClick={() => {
                            remove.open();
                            amplitude.getInstance().logEvent('click_on_delete_training', {trainingId: training.id, page: 'edit_training'});
                        }}
                    />
                }
                <Icon name="payment"
                    onClick={() => {
                        price.open();
                        amplitude.getInstance().logEvent('click_on_payments_button', {trainingId: training.id, page: 'edit_training'});
                    }}
                />
                <Layout margin="0 auto 0 0 !important">
                    <Icon
                        name="copy"
                        title={intl.formatMessage({id: 'app.training.duplicate'})}
                        onClick={() => {
                            handleDuplicate();
                            amplitude.getInstance().logEvent('copy_training', {trainingId: training.id, page: 'edit_training'});
                        }}
                    />
                </Layout>
                {isLoading ? (
                    <DotsSpinner color={theme.subForeground} size={16} />
                ) : (
                    <Icon
                        disabled={!training.title || saved}
                        name={saved ? 'saved' : 'save'}
                        color={saved ? theme.foreground : theme.primary}
                        onClick={() => {
                            handleSave();
                            amplitude.getInstance().logEvent('save_training', {trainingId: training.id, page: 'edit_training'});
                        }}
                    />
                )}
            </Section>
            <Section direction={matches ? 'y' : 'x'} grow={1} scroll={true}>
                {training.id === undefined && <LoadingPage />}
                {training.id !== undefined && (
                    <>
                        <Section direction="y" grow={matches ? undefined : 0.8} spacing={20} padding="0 20px 20px 20px" scroll={true}>
                            <TitleInput
                                placeholder={intl.formatMessage({id: 'app.training.titlePlaceholder'})}
                                value={training.title}
                                onChange={handleEditTitle}
                            />
                            <Select value={training.difficulty} onChange={handleEditDifficulty}>
                                <option value={-1}>{intl.formatMessage({id: 'app.training.difficulty.-1'})}</option>
                                <option value={0}>{intl.formatMessage({id: 'app.training.difficulty.0'})}</option>
                                <option value={1}>{intl.formatMessage({id: 'app.training.difficulty.1'})}</option>
                                <option value={2}>{intl.formatMessage({id: 'app.training.difficulty.2'})}</option>
                                <option value={3}>{intl.formatMessage({id: 'app.training.difficulty.3'})}</option>
                            </Select>
                            {/* sport */}
                            {(!training.sport) ? (
                                <SecondaryButton onClick={choseSport.open}>
                                    <FormattedMessage id="app.activity.choseSport" />
                                </SecondaryButton>
                            ) : (
                                <PrimaryButton onClick={choseSport.open}>
                                    {locale.locale === 'fr-FR' ? training.sport.frValue : training.sport.enValue}
                                </PrimaryButton>
                            )}
                            {/* practice type */}
                            {(!training.type) ? (
                                <SecondaryButton onClick={choseType.open}>
                                    <FormattedMessage id="app.activity.choseType" />
                                </SecondaryButton>
                            ) : (
                                <PrimaryButton onClick={choseType.open}>
                                    {locale.locale === 'fr-FR' ? training.type.frValue : training.type.enValue}
                                </PrimaryButton>
                            )}
                            {/* tags */}
                            { ['nguyen.hl.b@gmail.com', 'nmvevo@gmail.com'].includes(backend.authUser!.email) &&
                                <SelectableTags
                                    selectedTags={training.tags.map(tag => tag.key)}
                                    tags={training.availableTags.map(tag => tag.key)}
                                    labels={locale.locale === 'fr-FR' ? training.availableTags.map(tag => tag.frValue) : training.availableTags.map(tag => tag.enValue)}
                                    onSelect={handleSelectTag}
                                />
                            }
                            <FixedAspect ratio={9 / 16}>
                                <ImagePicker
                                    isLoading={thumbnailIsLoading}
                                    imageSrc={training.thumbnail}
                                    width="100%"
                                    height="100%"
                                    isAbsolute={true}
                                    onImagePick={handleThumbnailUpload}
                                />
                            </FixedAspect>
                            <YoutubeLink value={training.introYoutubeId} onChange={handleChangeIntroYoutubeId} />
                            <RichText value={training.description} onChange={handleEditDescription} />
                        </Section>
                        <Section direction="y" padding="0 20px" grow={matches ? undefined : 1} scroll={true}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {training.sets.map((set, i) => (
                                                <EditSetRow
                                                    key={i}
                                                    index={i}
                                                    set={set}
                                                    onEdit={handleEditSet(i)}
                                                    onDuplicate={handleDuplicateSet}
                                                    onRemove={handleRemoveSet(i)}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <AddSetButton onClick={handleAddSet}>
                                <Section spacing={10}>
                                    <Icon name="add" color={theme.primaryForeground} />
                                    <span>
                                        <FormattedMessage id="app.training.addSet" />
                                    </span>
                                </Section>
                            </AddSetButton>
                        </Section>
                    </>
                )}
            </Section>
            <Lightbox isOpen={price.isOpen} onRequestClose={price.close}>
                <PriceLightbox isOpen={price.isOpen} training={training} onChange={onChange} />
            </Lightbox>
            <Lightbox isOpen={remove.isOpen} onRequestClose={remove.close}>
                <LightboxConfirm
                    isOpen={remove.isOpen}
                    onCancel={() => {
                        remove.close();
                        amplitude.getInstance().logEvent('cancel_delete_training', {trainingId: training.id, page: 'edit_training'});
                    }}
                    onConfirm={() => {
                        handleConfirmRemove();
                        amplitude.getInstance().logEvent('confirm_delete_training', {trainingId: training.id, page: 'edit_training'});
                    }}
                    title={intl.formatMessage({id: 'app.training.confirmRemoveTitle'})}
                    message={intl.formatMessage({id: 'app.training.confirmRemoveMessage'})}
                />
            </Lightbox>
            <Lightbox isOpen={choseSport.isOpen} onRequestClose={choseSport.close}>
                <LightboxCard sheet={true} isOpen={choseSport.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Sports isOpen={choseSport.isOpen}  onSelect={handleSelectSport} onCancel={choseSport.close} />
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={choseType.isOpen} onRequestClose={choseType.close}>
                <LightboxCard sheet={true} isOpen={choseType.isOpen} height="90%" width="90%" maxWidth="600px" animation="bottom">
                    <Types isOpen={choseType.isOpen}  onSelect={handleSelectType} onCancel={choseType.close} />
                </LightboxCard>
            </Lightbox>
        </Section>
    );
};
