import React, { useCallback, useState } from 'react';

import {Section} from '../../ui/Section';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {useBackend} from '../backend/useBackend';
import styled from '@emotion/styled';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { LightboxCard } from '../../widgets/LightboxCard';
import { CreateTrainingDialog } from './CreateTrainingDialog';

type InlineLinkProps = {
  children: React.ReactNode;
};

type CreateTrainingLinkProps = {
  onClick?: () => void;
  children: React.ReactNode;
}

const InlineLink = ({ children }: InlineLinkProps) => {
  return <p>{children}</p>;
};

const CreateTrainingLink = ({ onClick, children }: CreateTrainingLinkProps) => (
  <span onClick={onClick}>{children}</span>
);

const EmptyActivityCardSection = styled(Section)`
  color: ${props => props.theme.subForeground};
  a, span {
    color: ${props => props.theme.primary}
  }
  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const EmptyActivityCard: React.FC = () => {
  const backend = useBackend();
  const [isOpen, setIsOpen] = useState(false);

  const handleCreateTraining = useCallback(() => {
    setIsOpen(true);
  }, []);

  const exploreLink = <Link to="/explore" ><FormattedMessage id="app.home.exploreLink"/></Link>;
  const createTrainingLink =  <CreateTrainingLink onClick={handleCreateTraining}><FormattedMessage id="app.home.createTrainingLink"/></CreateTrainingLink>
  return (
    <>
      {backend.authUser ? (
        <EmptyActivityCardSection direction="y" width="100%">
          <FormattedMessage
            id="app.home.emptyActivitiesStats"
            tagName={InlineLink}
            values={{
              exploreLink: exploreLink,
              createTrainingLink: createTrainingLink
            }}
          />
          <Lightbox isOpen={isOpen} onRequestClose={() => { setIsOpen(false) }}>
              <LightboxCard isOpen={isOpen} maxWidth="90%">
                  <CreateTrainingDialog onClose={() => { setIsOpen(false) }} />
              </LightboxCard>
          </Lightbox>
        </EmptyActivityCardSection>
      ) : (
        null
      )}
    </>
  );
};
