import {css} from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import type {StyledOptions} from '@emotion/styled';

import {Layout} from './Layout';
import type {LayoutProps} from './Layout';

const alignCss = {
    end: 'flex-end',
    start: 'flex-start',
    center: 'center',
    stretch: 'stretch',
};

const distributeCss = {
    end: 'flex-end',
    start: 'flex-start',
    center: 'center',
    around: 'space-around',
    evenly: 'space-evenly',
    between: 'space-between',
};

export interface SectionProps extends LayoutProps {
    direction?: 'x' | 'y';
    //
    align?: 'start' | 'end' | 'center' | 'stretch';
    reverse?: boolean;
    spacing?: number;
    distribute?: 'start' | 'end' | 'center' | 'around' | 'evenly' | 'between';
    sticky?: boolean;
}

const VerticalSpacing = (spacing?: number) => css`
    & > *:not(:last-child) {
        margin-bottom: ${spacing || 0}px;
    }
`;

const VerticalSpacingReverse = (spacing?: number) => css`
    & > *:not(:last-child) {
        margin-top: ${spacing || 0}px;
    }
`;

const HorizontalSpacing = (spacing?: number) => css`
    & > *:not(:last-child) {
        margin-right: ${spacing || 0}px;
    }
`;

const HorizontalSpacingReverse = (spacing?: number) => css`
    & > *:not(:last-child) {
        margin-left: ${spacing || 0}px;
    }
`;

const sectionOptions: StyledOptions<SectionProps> = {
    shouldForwardProp: prop =>
        isPropValid(prop) && prop !== 'wrap' && prop !== 'align' && prop !== 'reverse' && prop !== 'spacing' && prop !== 'direction' && prop !== 'distribute',
};

export const Section = styled(Layout, sectionOptions)<SectionProps>`
    display: ${props => (props.hidden ? 'none' : 'flex')};
    flex-direction: ${props => `${props.direction === 'y' ? 'column' : 'row'}${props.reverse ? '-reverse' : ''}`};

    align-items: ${props => (props.align !== undefined ? alignCss[props.align] : 'stretch')};
    justify-content: ${props => (props.distribute !== undefined ? distributeCss[props.distribute] : 'flex-start')};

    ${props => props.sticky && `position: sticky; top: 0; left: 0;`}

    ${props => {
        if (props.spacing === 0 || props.spacing === undefined) {
            return undefined;
        }
        return props.direction === 'y'
            ? props.reverse
                ? VerticalSpacingReverse(props.spacing)
                : VerticalSpacing(props.spacing)
            : props.reverse
            ? HorizontalSpacingReverse(props.spacing)
            : HorizontalSpacing(props.spacing);
    }}
`;
