import React from 'react';
import {useClickAway} from 'react-use';
import {Manager, Reference, Popper} from 'react-popper';

import styled from '@emotion/styled';
import {InvisibleButton} from './Button';

import {ReactComponent as Chevron} from './assets/chevron.svg';
import {ReactComponent as Check} from './assets/check.svg';
import {ReactComponent as Options} from './assets/options.svg';
import {Section} from '../ui/Section';
import {Menu, MenuItem} from '../widgets/Menu';

const ChoiceButton = styled.div<{active: boolean}>`
    align-self: flex-start;
    cursor: pointer;
    padding: 5px 10px;
    color: ${props => props.theme.foreground};
    background-color: ${props => props.theme.subBackground};
    border-radius: ${props => props.theme.borderRadius};
    transition: 0.3s all;
    svg {
        padding-left: 5px;
    }
    &:hover {
        background-color: ${props => props.theme.selectedBackground};
    }
`;

interface ChoicesProps {
    value: string;
    values: string[];
    onChange: (value: string) => void;
    labels?: (string | React.ReactNode)[];
    isOptions?: boolean;
}

export const Choices: React.FC<ChoicesProps> = ({value, values, onChange, labels, isOptions}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isOverElement, setIsOverElement] = React.useState(false);
    const menuRef = React.useRef<HTMLDivElement>(null);
    useClickAway(menuRef, () => {
        if (!isOverElement) {
            setIsOpen(false);
        }
    });
    const handleSelect = React.useCallback(
        (currentValue: string) => {
            return () => {
                onChange(currentValue);
                setIsOpen(false);
            };
        },
        [onChange],
    );
    const handleMouseEnter = React.useCallback(() => {
        setIsOverElement(true);
    }, []);
    const handleMouseLeave = React.useCallback(() => {
        setIsOverElement(false);
    }, []);
    const handleClick = React.useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    return (
        <Section>
            <Manager>
                <Reference>
                    {({ref}) =>
                        isOptions ? (
                            <InvisibleButton
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                style={{padding: '0 20px'}}
                                ref={ref}
                                onClick={handleClick}
                            >
                                <Options />
                            </InvisibleButton>
                        ) : (
                            <ChoiceButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} active={isOpen} ref={ref} onClick={handleClick}>
                                {labels ? labels[values.indexOf(value)] : value}
                                <Chevron />
                            </ChoiceButton>
                        )
                    }
                </Reference>
                <Popper placement={'bottom-start'}>
                    {({ref, style, placement}) =>
                        isOpen ? (
                            <div ref={menuRef}>
                                <Menu direction="y" spacing={5} ref={ref} style={style} data-placement={placement}>
                                    {values.map((currentValue, i) => (
                                        <MenuItem key={i} onClick={handleSelect(currentValue)} selected={currentValue === value}>
                                            <Check /> {labels ? labels[i] : value}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        ) : (
                            ''
                        )
                    }
                </Popper>
            </Manager>
        </Section>
    );
};
