import {ThemeModel} from '../models/ThemeModel';

const primaryColor = '#65FFBE';
const subBackground = 'rgba(255, 255, 255, 0.15)';
const selectedBackground = 'rgba(255, 255, 255, 0.3)';
const cardBackground = '#333333';

export const darkTheme: ThemeModel = {
    name: 'dark',
    title: 'centra_no2bold, sans-serif',
    body: 'centra_no2book, sans-serif',

    primary: primaryColor,
    explanation: '#52CEFF',
    rest: '#FFDB87',
    primaryForeground: '#121212',
    foreground: '#F2F2F2',
    subForeground: '#999999',
    background: '#121212',
    subBackground: subBackground,
    selectedBackground: selectedBackground,
    cardBackground: cardBackground,
    disabled: '#888888',
    input: subBackground,
    inputActive: selectedBackground,
    error: '#FE5F55',
    scrollbar: subBackground,
    scrollbarBackground: 'transparent',
    focus: `inset 0px 0px 0px 2px ${primaryColor}`,
    borderRadius: `10px`,
};
