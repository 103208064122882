import axios from 'axios';
import {useCallback, useMemo} from 'react';
import type {AxiosError, AxiosPromise, AxiosResponse} from 'axios';


import {useAuthUser} from './AuthUser';
import type {Backend} from './Backend';
import type {UserModel} from './models/UserModel';


// interface AuthHeader {
//     'access-token': string;
//     'cache-control': string;
//     client: string;
//     'content-type': string;
//     expiry: string;
//     'token-type': string;
//     uid: string;
// }

const backend = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});

backend.interceptors.request.use(config => {
    const headersString = localStorage.getItem('authHeader');
    if (headersString === null) {
        return config;
    }
    const headers = JSON.parse(headersString);
    config.headers = { ...config.headers, ...headers } ;
    return config;
});

export const useBackend = () => {
    const {authUser, setAuthUser} = useAuthUser();
    const forwardRequest = useCallback(async <ResponseType extends {}>(promise: AxiosPromise<ResponseType>) => {
        try {
            const response = await promise;
            // Only save access-token if route is private
            if (response.headers['access-token']) {
                localStorage.setItem('authHeader', JSON.stringify(response.headers));
            }
            return response.data;
        } catch (err) {
            const response = (err as AxiosError).response as AxiosResponse<any>;
            if (response?.headers['access-token']) {
                localStorage.setItem('authHeader', JSON.stringify(response?.headers));
            }
            throw new Error(response?.data.error || 'unknown');
        }
    }, []);


    return useMemo<Backend>(
        () => ({
            authUser,
            register: async (username, email, password) => {
                const user = await forwardRequest(backend.post<UserModel>('/auth', {username, email, password}));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            login: async (email, password) => {
                const user = await forwardRequest(backend.post<UserModel>('/auth/sign_in', {email, password}));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            logout: async () => {
                await forwardRequest(backend.delete('/auth/sign_out'));
                localStorage.removeItem('authUser');
                localStorage.removeItem('authHeader');
                setAuthUser?.(undefined);
            },
            sendResetPasswordEmail: async (email, redirectUrl) => {
                await forwardRequest(backend.post<UserModel>('/auth/password', {email, redirectUrl}));
            },
            resetPassword: async (password, passwordConfirmation, headers) => {
                const authHeaders = Object.fromEntries(new URLSearchParams(headers));
                localStorage.setItem('authHeader', JSON.stringify(authHeaders));
                const user = await forwardRequest(backend.put<UserModel>('/auth/password', {password, passwordConfirmation}));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
            },
            loginAs: async userId => {
                const user = await forwardRequest(backend.post<UserModel>('/auth/login_as', {userId}));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            // users
            searchUsers: params => {
                return forwardRequest(backend.get('/users', {params}));
            },
            getUser: id => {
                return forwardRequest(backend.get(`/users/${id}`));
            },
            updateUser: async data => {
                const user = await forwardRequest(backend.put<UserModel>(`/users/${data.id}`, {data}));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            patchUser: async data => {
                const user = await forwardRequest(backend.patch<UserModel>(`/users/${data.id}`, {data}));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            deleteUser: async id => {
                await forwardRequest(backend.delete(`/users/${id}`));
                localStorage.removeItem('authUser');
                setAuthUser?.(undefined);
            },
            updateUserPassword: async (id, password, passwordConfirmation) => {
                return await forwardRequest(backend.patch<UserModel>(`/users/${id}/update_password`, {password, passwordConfirmation}));
            },
            uploadUserAvatar: async (id, file) => {
                var formData = new FormData();
                formData.append('file', file);
                // Beware of axios (needed to add transformRequest) see https://github.com/axios/axios/issues/4885
                // Maybe it is rewriting formdata during the "forwardRequest" ?
                const user = await forwardRequest(backend.patch<UserModel>(`/users/${id}/upload_avatar`, formData, {
                    transformRequest: formData => formData
                }));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            uploadUserBanner: async (id, file) => {
                var formData = new FormData();
                formData.append('file', file);
                const user = await forwardRequest(backend.patch<UserModel>(`/users/${id}/upload_banner`, formData, {
                    transformRequest: formData => formData
                }));
                localStorage.setItem('authUser', JSON.stringify(user));
                setAuthUser?.(user);
                return user;
            },
            knownExercise: async (exerciseId: string) => {
                await forwardRequest(backend.post(`/known_exercises`, {data: {id: exerciseId}}));
            },
            // trainings
            searchTrainings: params => {
                return forwardRequest(backend.get('/trainings', {params}));
            },
            getTraining: id => {
                return forwardRequest(backend.get(`/trainings/${id}`));
            },
            createTraining: data => {
                return forwardRequest(backend.post('/trainings', {data}));
            },
            updateTraining: data => {
                return forwardRequest(backend.put(`/trainings/${data.id}`, {data}));
            },
            deleteTraining: async id => {
                await forwardRequest(backend.delete(`/trainings/${id}`));
            },
            uploadTrainingThumbnail: async (id, file) => {
                var formData = new FormData();
                formData.append('file', file);
                return forwardRequest(backend.patch(`/trainings/${id}/upload_thumbnail`, formData, {
                    transformRequest: formData => formData
                }));
            },
            bookmarkTraining: id => {
                return forwardRequest(backend.post(`/trainings/${id}/bookmark`));
            },
            unbookmarkTraining: id => {
                return forwardRequest(backend.delete(`/trainings/${id}/bookmark`));
            },
            searchTrainingsBookmarks: params => {
                return forwardRequest(backend.get('/trainings/bookmarks', {params}));
            },
            // quest
            createQuest: data => {
                return forwardRequest(backend.post('/quests', {data}));
            },
            listQuests: (page: number, perPage: number, authorId: string | null | undefined) => {
                return forwardRequest(backend.get('/quests', {params: {page, perPage, authorId}}));
            },
            getQuest: id => {
                return forwardRequest(backend.get(`/quests/${id}`));
            },
            updateQuest: data => {
                return forwardRequest(backend.put(`/quests/${data.id}`, {data}));
            },
            uploadQuestBanner: async (id, file) => {
                var formData = new FormData();
                formData.append('file', file);
                return forwardRequest(backend.patch(`/quests/${id}/upload_banner`, formData, {
                    transformRequest: formData => formData
                }));
            },
            listFollowedQuests: () => {
                return forwardRequest(backend.get('/quests/follows'));
            },
            followQuest: id => {
                return forwardRequest(backend.post(`/quests/${id}/follows`));
            },
            unfollowQuest: id => {
                return forwardRequest(backend.delete(`/quests/${id}/follows`));
            },
            // price
            createTrainingPrice: async data => {
                await forwardRequest(backend.post('/prices', {data}));
            },
            deleteTrainingPrice: async (priceId: string) => {
                await forwardRequest(backend.delete(`/prices/${priceId}`));
            },
            createQuestPrice: async data => {
                await forwardRequest(backend.post('/prices', {data}));
            },
            deleteQuestPrice: async (priceId: string) => {
                await forwardRequest(backend.delete(`/prices/${priceId}`));
            },
            // payment
            verifyPayment: trainingId => {
                return forwardRequest(backend.get(`/payments/verify?trainingId=${trainingId}`));
            },
            checkoutPayment: (objectId, objectType, priceType) => {
                return forwardRequest(backend.post(`/payments/checkout`, {data: {objectId, objectType, priceType}}));
            },
            paymentPortal: () => {
                return forwardRequest(backend.post('/payments/portal'));
            },
            // activities
            createActivity: data => {
                return forwardRequest(backend.post('/activities', {data}));
            },
            createNewPlay: data => {
                return forwardRequest(backend.post('/activities/new_play', {data}));
            },
            getActivities: (params) => {
                return forwardRequest(backend.get(`/activities`, {params}));
            },
            getActivity: id => {
                return forwardRequest(backend.get(`/activities/${id}`));
            },
            updateActivity: data => {
                return forwardRequest(backend.put(`/activities/${data.id}`, {data}));
            },
            deleteActivity: async id => {
                await forwardRequest(backend.delete(`/activities/${id}`));
            },
            getActivitiesStatsSummary: userId => {
                return forwardRequest(backend.get(`/activities/${userId}/summary`, {params: {objectType: 'USER'}}));
            },
            // Sports
            searchSports: (params) => {
                return forwardRequest(backend.get('/sports', {params}));
            },
            // Types
            listTypes: (params) => {
                return forwardRequest(backend.get('/practice_types', {params}));
            },
            // Tags
            listTags: () => {
                return forwardRequest(backend.get('/tags'));
            },
            // exercises
            searchExercises: params => {
                return forwardRequest(backend.get('/exercises', {params}));
            },
            getExercise: id => {
                return forwardRequest(backend.get(`/exercises/${id}`));
            },
            createExercise: data => {
                return forwardRequest(backend.post('/exercises', {data}));
            },
            updateExercise: data => {
                return forwardRequest(backend.put(`/exercises/${data.id}`, {data}));
            },
            deleteExercise: async id => {
                await forwardRequest(backend.delete(`/exercises/${id}`));
            },
            createSuggestion: async (message: string) => {
                await forwardRequest(backend.post('/suggestions', {data: {message}}));
            },
            getVersion: () => {
                return forwardRequest(backend.get('/version'));
            }
        }),
        [authUser, forwardRequest, setAuthUser],
    );
};
