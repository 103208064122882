import React, {InputHTMLAttributes, memo, useState} from 'react';
import styled from '@emotion/styled';
import {useTheme} from '@emotion/react';

import {Section} from '../ui/Section';
import {Icon} from '../ui/Icon';

type InputStringChangeEvent =
    | string
    | React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
export type InputStringChangeCallback = (e: InputStringChangeEvent) => void;

export const useInputState = (initialValue = '', onChange?: (value: string) => void) => {
    const [value, setValue] = useState(initialValue);
    return [
        value,
        (e: InputStringChangeEvent) => {
            const newValue = typeof e === 'string' ? (e as string) : (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
            setValue(newValue);
            onChange && onChange(newValue);
        },
        setValue,
    ] as const;
};

export const Input = styled.input`
    background-color: ${props => props.theme.input};
    font-family: ${props => props.theme.body};
    font-size: 1em;
    padding: 8px 15px;
    flex-shrink: 0;
    color: ${props => props.theme.foreground};
    border-radius: ${props => props.theme.borderRadius};
    border: none;
    outline: none;
    transition: background-color 0.2s;
    &:focus {
        background-color: ${props => props.theme.inputActive};
    }
    &::placeholder {
        color: ${props => props.theme.subForeground};
        opacity: 1; /* Firefox */
    }
`;

const SearchSection = styled(Section)`
    position: relative;
    input {
        flex-grow: 1;
        padding-left: 35px;
    }
`;

const SearchIcon = styled.svg`
    position: absolute;
    left: 10px;
`;

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
    grow?: number;
}

export const SearchInput: React.FC<SearchInputProps> = memo(({className, grow, ...inputProps}) => {
    const theme = useTheme();
    return (
        <SearchSection align="center" className={className} grow={grow}>
            <SearchIcon width="19" height="18" viewBox="0 0 19 18">
                <circle cx="7.98061" cy="7.02944" r="6.02944" stroke={theme.subForeground} fill="transparent" strokeWidth="2" />
                <rect x="11.8594" y="10.5444" width="8.7868" height="1.75736" rx="0.87868" transform="rotate(45 11.8594 10.5444)" fill={theme.subForeground} />
            </SearchIcon>
            <Input {...inputProps} />
        </SearchSection>
    );
});

const PasswordSection = styled(Section)`
    position: relative;
    input {
        flex-grow: 1;
        padding-right: 35px;
    }
`;

const PasswordIcon = styled(Icon)`
    position: absolute;
    right: 10px;
`;

export const PasswordInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = memo(({className, ...inputProps}) => {
    const [show, setShow] = useState(false);
    return (
        <PasswordSection align="center" className={className}>
            <PasswordIcon name={show ? 'hidden' : 'public'} onClick={() => setShow(s => !s)} />
            <Input type={show ? 'text' : 'password'} {...inputProps} />
        </PasswordSection>
    );
});

export const TextArea = styled.textarea`
    background-color: ${props => props.theme.input};
    font-family: ${props => props.theme.body};
    font-size: 0.9em;
    padding: 8px 15px;
    box-sizing: border-box;
    color: ${props => props.theme.foreground};
    border-radius: ${props => props.theme.borderRadius};
    border: none;
    outline: none;
    transition: background-color 0.2s;
    width: 100%;
    min-height: 100px;
    resize: vertical;
    &:focus {
        background-color: ${props => props.theme.inputActive};
    }
    &::placeholder {
        color: ${props => props.theme.subForeground};
        opacity: 1; /* Firefox */
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        cursor: pointer;
    }
    ::-webkit-scrollbar-thumb {
        transition: background-color 0.3s;
        border-radius: 5px;
        background-color: rgba(130, 130, 130, 0.3);
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(130, 130, 130, 0.4);
    }
`;
