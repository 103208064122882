import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Layout} from '../../ui/Layout';
import {Section} from '../../ui/Section';
import {PrimaryButton} from '../../widgets/Button';

import defaultAvatar from '../settings/assets/defaultAvatar.png';
import {UserRowModel} from '../backend/models/UserModel';
import {useBackend} from '../backend/useBackend';
import {LoadingPage} from '../../widgets/LoadingPage';

interface UserRowProps {
    user: UserRowModel;
}

const UserRow: React.FC<UserRowProps> = ({user}) => {
    const backend = useBackend();
    const navigate = useNavigate();
    const handleLogin = React.useCallback(async () => {
        await backend.loginAs(user.id);
        navigate('/user/' + user.id);
        window.location.reload();
    }, [backend, navigate, user.id]);
    return (
        <Section spacing={10} align="center" width="100%">
            <img style={{borderRadius: '50%'}} width={40} src={user.avatar || defaultAvatar} alt="" />
            <Layout grow={1} shrink={1}>
                {user.username} ({user.email})
            </Layout>
            <PrimaryButton onClick={handleLogin}>Login</PrimaryButton>
        </Section>
    );
};

export const SettingsSelectUser: React.FC = () => {
    const backend = useBackend();
    const {id} = useParams<{id: string}>();
    const [users, setUsers] = React.useState<UserRowModel[]>();
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(() => {
        setIsLoading(true);
        backend.searchUsers({}).then(newUsers => {
            setUsers(newUsers);
            setIsLoading(false);
        });
    }, [backend, id]);
    return (
        <Section direction="y" padding="20" width="100%" spacing={20} scroll={true}>
            {isLoading ? (
                <LoadingPage />
            ) : (
                <>
                    <h2>Select a user</h2>
                    {users?.map(user => {
                        return <UserRow key={user.id} user={user} />;
                    })}
                </>
            )}
        </Section>
    );
};
