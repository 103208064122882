import React from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";


export function moveItem<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const newList = list.filter((item, index) => index !== startIndex);
    return [...newList.slice(0, endIndex), list[startIndex], ...newList.slice(endIndex)];
}

interface SortableItemProps {
    id: string;
    index: number;
    children: React.ReactNode
}

export const SortableItem: React.FC<SortableItemProps> = ({id, index, children}) => {
    return <Draggable draggableId={id} index={index}>
        {provided => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {children}
            </div>
        )}
    </Draggable>
}

interface QuoteModel {
    id: string;
    content: string;
}

const initial: QuoteModel[] = Array.from({ length: 10 }, (v, k) => k).map(k => {
    return {
        id: `id-${k}`,
        content: `Quote ${k}`
    };
});

export const QuoteList: React.FC = () => {
    const [quotes, setQuotes] = React.useState(initial);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        setQuotes(moveItem(
            quotes,
            result.source.index,
            result.destination.index
        ));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {quotes.map((quote: QuoteModel, index: number) => (
                            <SortableItem id={quote.id} index={index} key={quote.id}>
                                <div>{quote.content}</div>
                            </SortableItem>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
