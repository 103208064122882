import React from 'react';
import styled from '@emotion/styled';
import {BrowserRouter, Routes, Route, Navigate, useLocation, Outlet} from 'react-router-dom';

import {Navbar} from '../navbar/Navbar';

import {User} from '../user/User';
import {Login} from '../auth/Login';
import {Explore} from '../explore/Explore';
import {Section} from '../../ui/Section';
import {Training} from '../trainings/Training';
import {Home} from '../home/Home';
import {Register} from '../auth/Register';
import {ResetPassword} from '../auth/ResetPassword';
import {RouteNotFound} from './RouteNotFound';
import {ForgotPassword} from '../auth/ForgotPassword';
import {Bookmarks} from '../activity/Bookmarks';
import {Activity} from '../activity/Activity';
import {EditActivity} from '../activity/EditActivity';
import {Quest} from '../quests/Quest';
import {useBackend} from '../backend/useBackend';
import { SplashScreen } from '../splashscreen/SplashScreen';
import { RegisterInterests } from '../auth/RegisterInterests';

const AppSection = styled(Section)`
    overflow: auto;
    color: ${props => props.theme.foreground};
    background-color: ${props => props.theme.background};
    @media (max-width: 600px) {
        flex-direction: column-reverse;
    }
`;

const ProtectedRoute: React.FC = () => {
    const backend = useBackend();
    if (backend.authUser) {
        return <Outlet />;
    }
    return <Navigate to={'/login'} replace />;
};

const AuthRoute: React.FC = () => {
    const backend = useBackend();
    const trainingId = new URLSearchParams(useLocation().search).get('training');
    const questId = new URLSearchParams(useLocation().search).get('quest');
    const userId = new URLSearchParams(useLocation().search).get('user');

    if(!backend.authUser) {
        return <Outlet />;
    }
    return <Navigate to={trainingId ? `/trainings/${trainingId}` : questId ? `/quests/${questId}` : userId ? `/user/${userId}` : '/home'} />
};

export const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <AppSection direction="y" width="100" grow={1}>
                <Navbar hiddenRoutes={['/login', '/register', '/register/interests', '/forgotPassword', '/resetPassword', '/subscriptionActivation', '/splashscreen']} />
                <Routes>
                    <Route path="/splashscreen" element={<SplashScreen />}></Route>
                    <Route element={<AuthRoute/>}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/resetPassword" element={<ResetPassword />} />
                    </Route>
                    <Route element={<ProtectedRoute/>}>
                        <Route path="/register/interests" element={<RegisterInterests />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/user/:userId/:tab" element={<User />} />
                        <Route path="/user/:userId" element={<User />} />
                        <Route path="explore" element={<Explore />} />
                        <Route path="/explore/:tab" element={<Explore />} />
                        <Route path="bookmarks" element={<Bookmarks />} />
                        <Route path="/activity" element={<Activity />} />
                        <Route path="/activity/:tab" element={<Activity />} />
                        <Route path="/activities/:id" element={<EditActivity />} />
                        <Route path="/trainings/:id" element={<Training />} />
                        <Route path="/quests/:id/:tab" element={<Quest />} />
                        <Route path="/quests/:id" element={<Quest />} />
                        <Route path="/register/interests" element={<RegisterInterests />} />
                        <Route path="/" element={<Navigate to="/home" />} />
                    </Route>
                    <Route element={<RouteNotFound />} />
                </Routes>
            </AppSection>
        </BrowserRouter>
    );
};
