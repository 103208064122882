import React from 'react';
import AutosizeInput from 'react-input-autosize';
import styled from '@emotion/styled';
import {useInputValue} from './Input';

const StyledInput = styled(AutosizeInput)`
    input {
        color: ${props => props.theme.foreground} !important;
        background-color: ${props => props.theme.subBackground};
        border: none;
        outline: none;
        padding: 3px 5px;
        transition: background-color 0.3s;
        border-radius: ${props => props.theme.borderRadius};
        border: 2px solid transparent;
    }
    input:hover {
        background-color: ${props => props.theme.selectedBackground};
    }
    input:focus {
        background-color: ${props => props.theme.selectedBackground};
    }
`;

interface ResizeInputProps extends React.HTMLAttributes<HTMLElement> {
    isBig?: boolean;
    value: string;
    onValue: (value: string) => void;
    validate?: (value: string) => boolean;
}

export const ResizeInput: React.FC<ResizeInputProps> = ({value, placeholder, onValue, validate}) => {
    const initialValue = React.useMemo(() => value, [value]); // we want to keep the initial value without update
    const [inputValue, setInputValue] = useInputValue(value);
    const handleBlur = React.useCallback(
        (e: { currentTarget: { value: string; }; }) => {
            if (!validate || validate(e.currentTarget.value)) {
                onValue(inputValue);
            } else {
                setInputValue(initialValue);
            }
        },
        [initialValue, inputValue, onValue, setInputValue, validate],
    );
    const handleKeydown = React.useCallback((e: { key: string; currentTarget: { blur: () => void; }; }) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }, []);
    const handleSelect = React.useCallback((e: { currentTarget: { select: () => any; }; }) => e.currentTarget.select(), []);
    return (
        <StyledInput
            placeholder={placeholder}
            onFocus={handleSelect}
            value={inputValue}
            onChange={setInputValue}
            onBlur={handleBlur}
            onKeyDown={handleKeydown}
        />
    );
};
