import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {LightboxSection} from '../ui/Lightbox/LightboxSection';

export const LightboxCard = styled(LightboxSection)<{sheet?: boolean}>`
    color: ${props => props.theme.foreground};
    overflow: hidden;
    border-radius: 20px;
    background-color: ${props => props.theme.cardBackground};
    ${props =>
        props.sheet &&
        css`
            @media (max-width: 600px) {
                width: 100%;
                max-width: 100%;
                border-radius: 20px 20px 0 0;
                max-height: 100%;
                height: calc(100% - 40px);
                margin-top: auto;
            }
        `}
`;
