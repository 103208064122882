import React from 'react';
import styled from '@emotion/styled';
import {useTheme} from '@emotion/react';
import {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useLocation} from 'react-router-dom';

import {Logo} from '../logo/Logo';
import {notify} from '../../components/toast/Toast';
import {Section} from '../../ui/Section';
import {useGoHome} from '../appRouter/useGoHome';
import {TextLayout} from '../../widgets/Text';
import {useBackend} from '../backend/useBackend';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {ErrorMessage} from './ErrorMessage';
import {Input, useInputState} from '../../widgets/Input';
import {InlineButton, PrimaryButton} from '../../widgets/Button';
import { useTrackers } from '../tracking/useTrackers';
import amplitude from 'amplitude-js';

const LogoSection = styled(Section)`
    cursor: pointer;
`;

export const ForgotPassword: React.FC = () => {
    const theme = useTheme();
    const backend = useBackend();
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const [email, changeEmail, setEmail] = useInputState('');
    const [error, setError] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const Regex = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i);
    const trainingId = new URLSearchParams(useLocation().search).get('training');
    const questId = new URLSearchParams(useLocation().search).get('quest');
    const handleForgotPassword = useCallback(async () => {
        setIsLoading(true);
        setError(undefined);
        try {
            if (!Regex.test(email)) {
                throw new Error('wrongEmailFormat');
            }
            await backend.sendResetPasswordEmail(email, process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL || '');
            setEmail('');
            notify(formatMessage({id: 'app.auth.forgotPassword.emailSent'}), 'success');
            amplitude.getInstance().logEvent('ask_reset_password_email', {email: email, page: 'auth'});
        } catch (err) {
            setError((err as Error).message);
        }
        setIsLoading(false);
    }, [Regex, backend, email, formatMessage, setEmail]);
    const handleLogin = React.useCallback(() => {
        navigate('/login' + (trainingId ? `?training=${trainingId}` : questId ? `?quest=${questId}` : ''));
    }, [navigate, questId, trainingId]);
    const goHome = useGoHome();
    useTrackers();
    return (
        <Section width="100%" height="100%" align="center" distribute="center">
            <Section direction="y" align="stretch" maxWidth="400px" width="100%" spacing={20} padding="20px">
                <LogoSection direction="y" align="center" spacing={20} onClick={goHome}>
                    <Logo width={100} />
                    <TextLayout weight="bold" size={2}>
                        Training Quest
                    </TextLayout>
                </LogoSection>
                <TextLayout margin="0 auto 20px auto" color={theme.subForeground}>
                    <FormattedMessage id="app.auth.forgotPassword.subtitle" />
                </TextLayout>
                <Input type="email" placeholder={formatMessage({id: 'app.auth.forgotPassword.email'})} value={email} onChange={changeEmail} />
                <PrimaryButton style={{width: '100%'}} disabled={isLoading} onClick={handleForgotPassword}>
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={16} /> : <FormattedMessage id="app.auth.forgotPassword.sendEmail" />}
                </PrimaryButton>
                {error && (
                    <ErrorMessage>
                        <FormattedMessage id={`app.error.${error}`} />
                    </ErrorMessage>
                )}
                <InlineButton style={{width: '100%'}} onClick={handleLogin}>
                    <FormattedMessage id="app.auth.forgotPassword.backToLogin" />
                </InlineButton>
            </Section>
        </Section>
    );
};
