import styled from '@emotion/styled';

type LayoutAlign = 'normal' | 'stretch' | 'flex-start' | 'center' | 'flex-end';
type LayoutDistribute = 'normal' | 'space-between' | 'space-around' | 'space-evenly' | 'flex-start' | 'center' | 'flex-end';
type Overflow = 'hidden' | 'initial' | 'auto' | 'visible';

export const BaseLayout = styled.div<{flex?: number | 'initial'; overflow?: Overflow; padding?: string}>`
    display: flex;

    overflow: ${props => props.overflow || 'hidden'};
    box-sizing: border-box;
    padding: ${props => props.padding || 0};
    flex: ${props => props.flex || 'initial'};

    :focus {
        outline: none !important;
        box-shadow: ${props => props.theme.focus};
        transition: box-shadow 0.1s ease-in-out;
    }

    ::-webkit-scrollbar {
        width: 13px;
        height: 13px;
        background-color: red;
    }

    ::-webkit-scrollbar-thumb {
        width: 6px;
        height: 6px;
        border: 3px solid transparent;
        border-radius: 8px;

        background-clip: padding-box;
        background-color: green;
    }

    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

export const VLayout = styled(BaseLayout)<{spacing?: number; valign?: LayoutDistribute; halign?: LayoutAlign}>`
    flex-direction: column;

    align-items: ${props => props.halign || 'normal'};
    justify-content: ${props => props.valign || 'normal'};

    & > * {
        margin-bottom: ${props => props.spacing || 0}px;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const HLayout = styled(BaseLayout)<{spacing?: number; valign?: LayoutAlign; halign?: LayoutDistribute}>`
    flex-direction: row;

    align-items: ${props => props.valign || 'normal'};
    justify-content: ${props => props.halign || 'normal'};

    & > * {
        margin-right: ${props => props.spacing || 0}px;
    }
    & > *:last-child {
        margin-right: 0;
    }
`;

export const ZLayout = styled(BaseLayout)`
    display: flex;
    flex: 1;
    position: relative;
    & > :first-of-type {
        width: 100%;
        height: 100%;
        position: absolute;
    }
`;

export const Spacer = styled.div`
    display: flex;
    flex: 1;
`;
