import React from 'react';
import styled from '@emotion/styled';

import {Layout, LayoutProps} from '../ui/Layout';
import {LoadingPart} from './LoadingPart';
import {useThemeSelector} from '../app/theme/Theme';

import darkThumbnail from './assets/darkThumbnail.png';
import defaultThumbnail from './assets/defaultThumbnail.png';

import darkAvatar from './assets/darkAvatar.png';
import defaultAvatar from './assets/defaultAvatar.png';

import darkEmpty from './assets/darkEmpty.png';
import defaultEmpty from './assets/defaultEmpty.png';

export type Kind = keyof typeof kinds;

const kinds = {
    default: [defaultThumbnail, darkThumbnail],
    avatar: [defaultAvatar, darkAvatar],
    empty: [defaultEmpty, darkEmpty],
};

interface ThumbnailProps extends LayoutProps {
    className?: string;
    src?: string;
    kind?: Kind;
    width?: string;
    radius?: string;
    gradient?: boolean;
    isLoading?: boolean;
    darkGradient?: boolean;
    onClick?: () => void;
}

const ThumbnailLayout = styled(Layout)<{radius: string; isClickable: boolean}>`
    position: relative;
    border-radius: ${props => props.radius};
    cursor: ${props => (props.isClickable ? 'pointer' : undefined)};
    .loading,
    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .gradient {
        background: ${props => `linear-gradient(to top, ${props.theme.background}, transparent, transparent)`};
    }
    .darkGradient {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .darkGradient {
        background: ${props => `linear-gradient(to top, rgba(0, 0, 0, .3), transparent, transparent)`};
    }
    img {
        width: 100%;
        object-fit: cover;
        border-radius: ${props => props.radius};
    }
`;

export const Thumbnail: React.FC<ThumbnailProps> = ({
    className,
    src,
    kind = 'default',
    gradient = false,
    isLoading = false,
    radius = '10px',
    darkGradient = false,
    onClick,
    ...layoutProps
}) => {
    const {theme} = useThemeSelector();
    return (
        <ThumbnailLayout isClickable={onClick !== undefined} className={className} {...layoutProps} radius={radius} onClick={onClick}>
            {gradient && <div className="gradient" />}
            {darkGradient && <div className="darkGradient" />}
            {isLoading && <LoadingPart noBackground={true} className="loading" />}
            <img src={src ? src : theme?.name === 'default' ? kinds[kind][0] : kinds[kind][1]} alt="" />
        </ThumbnailLayout>
    );
};
