import React, { useCallback, useState } from 'react';

import {Section} from '../../ui/Section';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {useBackend} from '../backend/useBackend';
import styled from '@emotion/styled';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { LightboxCard } from '../../widgets/LightboxCard';
import { RegisterInterests } from '../auth/RegisterInterests';

type InlineLinkProps = {
  children: React.ReactNode;
};

type HandleInterestsLinkProps = {
  onClick?: () => void;
  children: React.ReactNode;
}

const InlineLink = ({ children }: InlineLinkProps) => {
  return <p>{children}</p>;
};

const HandleInterestsLink = ({ onClick, children }: HandleInterestsLinkProps) => (
  <span onClick={onClick}>{children}</span>
);

const EmptyFollowedQuestsCardSection = styled(Section)`
  color: ${props => props.theme.subForeground};
  a, span {
    color: ${props => props.theme.primary}
  }
  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const EmptyFollowedQuestsCard: React.FC = () => {
  const backend = useBackend();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleInterests = useCallback(() => {
    setIsOpen(true);
  }, []);

  const exploreLink = <Link to="/explore" ><FormattedMessage id="app.home.exploreLink"/></Link>;
  const handleInterestsLink =  <HandleInterestsLink onClick={handleInterests}><FormattedMessage id="app.home.handleInterestsLink"/></HandleInterestsLink>
  return (
    <>
      {backend.authUser ? (
        <EmptyFollowedQuestsCardSection direction="y" width="100%">
          <FormattedMessage
            id="app.home.emptyFollowedQuests"
            tagName={InlineLink}
            values={{
              exploreLink: exploreLink,
              handleInterestsLink: handleInterestsLink
            }}
          />
          <Lightbox isOpen={isOpen} onRequestClose={() => { setIsOpen(false) }}>
              <LightboxCard isOpen={isOpen} maxWidth="90%">
                  <RegisterInterests update={true} onClose={handleClose}></RegisterInterests>
              </LightboxCard>
          </Lightbox>
        </EmptyFollowedQuestsCardSection>
      ) : (
        null
      )}
    </>
  );
};
