import styled from '@emotion/styled';
import {RichUtils} from 'draft-js';
import {useMemo, useCallback} from 'react';
import type {EditorState} from 'draft-js';
import {useTheme} from '@emotion/react';
import React from 'react';
import {Icon, IconName} from '../../ui/Icon';

interface ToolbarButtonProps {
    type: 'style' | 'block';
    //
    tag: string;
    icon: IconName;
    editorState: EditorState;
    onStateChange: (nextState: EditorState) => void;
}

export const ButtonLayout = styled.div<{isActive: boolean}>`
    display: flex;
    width: 32px;
    cursor: pointer;
    height: 32px;
    margin-bottom: 10px;
    flex-shrink: 0;
    transition: all 0.3s ease-out;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    background-color: ${props => (props.isActive ? props.theme.foreground : props.theme.subBackground)};
    &:hover {
        background-color: ${props => (props.isActive ? props.theme.foreground : props.theme.selectedBackground)};
    }
    &:active {
        transform: scale(0.9);
    }
`;

export const RichTextButton: React.FC<ToolbarButtonProps> = ({type, tag, icon, editorState, onStateChange}) => {
    const theme = useTheme();
    const isActive = useMemo(
        () =>
            type === 'style'
                ? editorState.getCurrentInlineStyle().has(tag)
                : editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === tag,
        [editorState, tag, type],
    );
    const handleMouseDown = useCallback(
        (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            const nextState = type === 'style' ? RichUtils.toggleInlineStyle(editorState, tag) : RichUtils.toggleBlockType(editorState, tag);
            onStateChange(nextState);
            return false;
        },
        [editorState, onStateChange, tag, type],
    );
    return (
        <ButtonLayout isActive={isActive} onMouseDown={handleMouseDown}>
            <Icon name={icon} color={isActive ? theme.background : theme.foreground} />
        </ButtonLayout>
    );
};
