import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {MenuButton, MenuTitle, MenuSubButton, Content, MenuSubtitle, OverlayLayout, OverlayContent} from '../MenuElements';

import {useBackend} from '../../backend/useBackend';
import type {ExerciseModel} from '../../backend/models/ExerciseModel';
import amplitude from 'amplitude-js';

interface PauseModeProps {
    isOpen: boolean;
    exercise: ExerciseModel;
    totalTime: number;
    workoutId: string;
    setTitle: string;
    stepTitle: string;
    stepIndex: number;
    isSoundOn: boolean;
    onContinue: () => void;
    onToggleSound: (newValue: boolean) => void;
    onExit: () => void;
}

export const PauseMode: React.FC<PauseModeProps> = ({
    isOpen,
    exercise,
    totalTime,
    workoutId,
    setTitle,
    stepTitle,
    stepIndex,
    isSoundOn,
    onContinue,
    onExit,
    onToggleSound,
}) => {
    const [showMode, setShowMode] = useState(isOpen);
    const backend = useBackend();

    const handleContinue = React.useCallback(
        (e: { stopPropagation: () => void; }) => {
            onContinue();
            e.stopPropagation();
            amplitude.getInstance().logEvent('click_on_resume_training', {trainingId: workoutId, page: 'play_view'});
        },
        [onContinue, workoutId],
    );
    const handleExit = React.useCallback(async () => {
        await backend.createActivity({
            id: '',
            trainingId: workoutId,
            duration: Math.floor(totalTime),
            drop: true,
            setTitle,
            stepIndex,
            stepTitle,
            manual: false
        });
        amplitude.getInstance().logEvent('drop_training', {
            trainingId: workoutId,
            setTitle,
            stepIndex,
            stepTitle,
            duration: Math.floor(totalTime),
            page: 'play_view'
        });
        onExit();
    }, [backend, onExit, setTitle, stepIndex, stepTitle, totalTime, workoutId]);
    useEffect(() => {
        let ts: NodeJS.Timeout | null = null;
        if (isOpen === false) {
            ts = setTimeout(() => {
                setShowMode(false);
            }, 500);
        } else {
            setShowMode(true);
        }
        return () => {
            if (ts !== null) {
                clearTimeout(ts);
            }
        };
    }, [isOpen]);
    if (!showMode) {
        return null;
    }
    return (
        <OverlayLayout direction="y" isOpen={isOpen} align="center" distribute="center">
            <OverlayContent isOpen={isOpen} animation="bottom" direction="y" padding="20px 0 0 0">
                <MenuTitle delay={0.1}>
                    <FormattedMessage id="app.play.pauseTitle" />
                </MenuTitle>
                <MenuSubtitle dir="y">
                    <FormattedMessage id="app.play.pauseDescription" />
                </MenuSubtitle>
                <Content flip={true} direction="y" spacing={20} align="center" distribute="evenly" padding="20px 40px 40px 40px">
                    <MenuButton delay={0.3} onClick={handleContinue}>
                        <FormattedMessage id="app.play.continue" />
                    </MenuButton>
                    <MenuSubButton delay={0.3} onClick={handleExit}>
                        <FormattedMessage id="app.play.exit" />
                    </MenuSubButton>
                </Content>
            </OverlayContent>
        </OverlayLayout>
    );
};
