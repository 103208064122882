import {useMemo} from 'react';
import {formatEffort, formatEffortSpeech} from '../backend/models/TrainingModel';
import {Step, useSteps} from './useSteps';
import type {ExerciseModel} from '../backend/models/ExerciseModel';
import type {SetModel, StepModel, TrainingModel} from '../backend/models/TrainingModel';
import {useIntl} from 'react-intl';

interface PlayState {
    isRest: boolean;
    setTitle: string;
    setIndex: number;
    stepIndex: number;
    exercise: ExerciseModel;
    effort?: string | null;
    effortSpeech?: string | null;
}

const repeatSets = (training: TrainingModel) => {
    return training.sets
        .reduce((result: SetModel[], set: SetModel) => {
            return set.reps === 1
                ? [...result, set]
                : [...result, ...Array.from({length: set.reps}, (x, i) => i).map((i: number) => ({...set, title: `${set.title} (${i + 1}/${set.reps})`}))];
        }, [])
        .filter(set => set.steps.length > 0);
};

export const usePlaySteps = (training: TrainingModel) => {
    const intl = useIntl();
    return useSteps(
        useMemo<Step<PlayState>[]>(() => {
            const sets = repeatSets(training);
            return sets.reduce((result: Step<PlayState>[], set: SetModel, setIndex: number) => {
                const newSteps = set.steps.reduce((steps: Step<PlayState>[], step: StepModel, stepIndex: number) => {
                    const newStep = {
                        name: step.exercise.name,
                        duration: step.effortType === 'DURATION' ? step.effortValue : undefined,
                        data: {
                            isRest: false,
                            setTitle: set.title,
                            setIndex,
                            stepIndex,
                            exercise: step.exercise,
                            effort: formatEffort(step, intl),
                            effortSpeech: formatEffortSpeech(step, intl),
                        },
                    };
                    if (step.rest > 0 && !(setIndex + 1 === sets.length && stepIndex + 1 === set.steps.length)) {
                        const exercise = set.steps[stepIndex + 1]?.exercise || sets[setIndex + 1]?.steps[0].exercise;
                        const nextStep = set.steps[stepIndex + 1] || sets[setIndex + 1]?.steps[0];
                        const newRest = {
                            name: exercise.name, // TODO: check if works
                            duration: step.rest,
                            data: {
                                isRest: true,
                                setTitle: set.title,
                                setIndex,
                                stepIndex,
                                exercise,
                                effort: formatEffort(nextStep, intl),
                                effortSpeech: formatEffortSpeech(nextStep, intl),
                            },
                        };
                        return [...steps, newStep, newRest];
                    }
                    return [...steps, newStep];
                }, []);
                return [...result, ...newSteps];
            }, []);
        }, [intl, training]),
        {introTime: 5, transitionTime: 0},
    );
};
