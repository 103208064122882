import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Section } from '../../ui/Section';
import { SegmentedControl } from '../../widgets/SegmentedControl';
import { TextLayout } from '../../widgets/Text';
import { ActivityModel, ActivitySummaryModel } from '../backend/models/ActivityModel';
import { useBackend } from '../backend/useBackend';
import { useLoadData } from '../backend/useLoadData';
import { Tools } from '../tools/Tools';
import { useTrackers } from '../tracking/useTrackers';
import { EmptyActivityCard } from '../trainings/EmptyActivityCard';
import { Bookmarks } from './Bookmarks';
import { EmptyHistoryRow, HistoryRow } from './HistoryRow';
import { Statistics } from './Statistics';

const HistorySection = styled(Section)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Activity: React.FC = () => {
  const match600 = useMedia('(max-width: 600px)');
  const navigate = useNavigate();
  useTrackers();
  const {tab} = useParams<{tab: string}>();
  const backend = useBackend();
  const [isLoadingActivitiesStatsSummary, setIsLoadingActivitiesStatsSummary] = useState(true);
  const [activitiesStatsSummary, setActivitiesStatsSummary] = useState<ActivitySummaryModel>();

  // tabs
  const tabs = useMemo(
    () => {
        let tmp = [
          {value: 'history', label: <FormattedMessage id="app.activity.history" />},
          {value: 'statistics', label: <FormattedMessage id="app.activity.statistics" />},
          {value: 'bookmarks', label: <FormattedMessage id="app.activity.bookmarks" />},
        ];
        return tmp;
    }, []);
  const handleChangeTab = useCallback((newTab: any) => {
      navigate(`/activity/${newTab}`);
      amplitude.getInstance().logEvent(`check_activity_${newTab}`, {page: 'activity'});
  }, [navigate]);

  // history
  const activities = useLoadData<ActivityModel>(
    async (search, page, perPage) => {
        if (!backend.authUser) {
          throw new Error('userNotAuthenticated');
        }
        return backend.getActivities({objectType: 'USER', objectId: backend.authUser.id, page, perPage, drop: false});
    },
    {direction: 'x', perPage: 100},
  );

  // stats
  useEffect(() => {
    if (!backend.authUser) {
        return;
    }
    setIsLoadingActivitiesStatsSummary(true);
    backend
        .getActivitiesStatsSummary(backend.authUser.id)
        .then(data => {
          setActivitiesStatsSummary((data as any).data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
          setIsLoadingActivitiesStatsSummary(false);
        });
  }, [backend]);

  return (
      <Section direction="y" grow={1} scroll={true}>
          <Section direction="y" grow={1} spacing={10} align="center" scroll padding="0 0 20px 0">
            <TextLayout weight="bold" size={2} padding="20px 20px 10px 20px" margin="0 auto">
                <FormattedMessage id="app.activity.activities" />
            </TextLayout>
            <Section direction="x" align="center">
              <SegmentedControl value={tab} onChange={handleChangeTab} options={tabs} defaultValue="history" />
            </Section>
            {(tab === 'history' || !tab) && (activities.loadingState === 'loading' ? (
                <HistorySection direction="y" padding="20px" width={match600 ? '100%' : '50%'} maxWidth="1200px" spacing={20}>
                    {[0, 1, 2, 3, 4].map(id => <EmptyHistoryRow key={id} />)}
                </HistorySection>
              ) : (activities.data.length ? (
                <HistorySection direction="y" padding="20px" width={match600 ? '100%' : '50%'} maxWidth="1200px" spacing={20}>
                  {activities.data.map((stat) => <HistoryRow key={stat.id} activity={stat} />)}
                </HistorySection>
              ) : (
                <Section width="300px">
                  <EmptyActivityCard />
                </Section>
              )
            ))}
            {tab === 'statistics' && (
              <Section direction="y" padding="20px" width={match600 ? '100%' : '68%'} maxWidth="1200px" spacing={20}>
                <Statistics isLoadingActivitiesStatsSummary={isLoadingActivitiesStatsSummary} activitiesStatsSummary={activitiesStatsSummary}></Statistics>
              </Section>
            )}
            {tab === 'bookmarks' && (
              <Section direction="y" maxWidth="1200px">
                <Bookmarks></Bookmarks>
              </Section>
            )}
          </Section>
          <Tools displayTools={false}></Tools>
      </Section>
  );
};
