import styled from '@emotion/styled';
import {ReactComponent as DragHandleIcon} from './assets/dragHandle.svg';

export const DragHandle = styled(DragHandleIcon)`
    display: flex;
    circle {
        transition: fill 0.3s;
        fill: ${props => props.theme.subBackground};
    }
    &:hover {
        circle {
            fill: ${props => props.theme.subForeground};
        }
    }
    @media (max-width: 500px) {
        width: 10px;
    }
`;
