import styled from '@emotion/styled';
import React, {FC} from 'react';
import {Section} from '../../ui/Section';
import {transparentize} from 'polished';
import {ActivitySummaryModel} from '../backend/models/ActivityModel';
import { DotsSpinner } from '../../widgets/Spinner/DotSpinner';
import { FormattedMessage } from 'react-intl';
import {useTheme} from '@emotion/react';
import { useMedia } from 'react-use';

import { ResponsiveCalendar } from '@nivo/calendar'
import { CalendarDatum } from '@nivo/calendar';


const StatCard = styled(Section)`
    color: ${props => props.theme.primary};
    text-align: center;
    border-radius: 10px;
    background-color: ${props => transparentize(0.8, props.theme.primary)};
    .number {
      font-family: ${props => props.theme.title};
      font-size: 3em;
      .unit {
        font-size: 0.7em;
      }
    }
    .spinner {
      padding: 10px 0;
    }

    @media (max-width: 600px) {
      font-size: 0.8em;
      padding: 10px;
      .spinner {
        padding: 0;
      }
    }
`;

const GraphTitle = styled.p`
    color: #c5c5c5;
`

const CalendarSection = styled(Section)`
  //  TODO
`

export const Statistics: FC<{isLoadingActivitiesStatsSummary: boolean, activitiesStatsSummary?: ActivitySummaryModel}> = ({isLoadingActivitiesStatsSummary, activitiesStatsSummary}) => {
  const theme = useTheme();
  const match900 = useMedia('(max-width: 900px)');
  const match600 = useMedia('(max-width: 600px)');
  const emptyCalendar: CalendarDatum[] = [] as CalendarDatum[];
  const currentDate = new Date().toISOString().split('T')[0];
  return (
    <>
    <Section direction={match900 ? 'y' : 'x'} width={'100%'} spacing={20}>
      <StatCard direction="y" align="center" distribute="center" padding="20px" grow={1}>
          {isLoadingActivitiesStatsSummary ? (
            <span className="spinner">
              <DotsSpinner color={theme.primary} size={30} />
            </span>
          ) : (
            <span className="number">{activitiesStatsSummary?.totalTrainingCount || 0}</span>
          )}
          <span className="label">
            <FormattedMessage id="app.home.totalTrainingDone" />
          </span>
      </StatCard>
      <StatCard direction="y" align="center" distribute="center" padding="20px" grow={1}>
          {isLoadingActivitiesStatsSummary ? (
            <span className="spinner">
              <DotsSpinner color={theme.primary} size={30} />
            </span>
          ) : (
            <span className="number">
              {activitiesStatsSummary?.totalTrainingTime.hours ? activitiesStatsSummary?.totalTrainingTime.hours : activitiesStatsSummary?.totalTrainingTime.minutes ? activitiesStatsSummary?.totalTrainingTime.minutes : 0}
              <span className="unit">{activitiesStatsSummary?.totalTrainingTime.hours ? 'h' : 'min'}</span>
            </span>
          )}
          <span className="label">
              <FormattedMessage id="app.home.totalTrainingTime" />
          </span>
      </StatCard>
      <StatCard direction="y" align="center" distribute="center" padding="20px" grow={1}>
          {isLoadingActivitiesStatsSummary ? (
            <span className="spinner">
              <DotsSpinner color={theme.primary} size={30} />
            </span>
          ) : (
            <span className="number">
              {activitiesStatsSummary?.averageTrainingTimePerDay || 0}
              <span className="unit">min</span>
            </span>
          )}
          <span className="label">
            <FormattedMessage id="app.home.avgTrainingTimePerDay" />
          </span>
      </StatCard>
    </Section>
    {/* Nivo calendar */}
    <GraphTitle><i><FormattedMessage id="app.home.trainingTimePerDay" /></i></GraphTitle>
      {isLoadingActivitiesStatsSummary ? (
        <span className="spinner">
          <DotsSpinner color={theme.primary} size={30} />
        </span>
      ) : ( activitiesStatsSummary &&
        <CalendarSection height={match600 ? `${activitiesStatsSummary?.numberOfYears * 100}px` : match900 ? `${activitiesStatsSummary?.numberOfYears * 133}px` : `${activitiesStatsSummary?.numberOfYears * 166}px`}>
          <ResponsiveCalendar
            data={activitiesStatsSummary?.trainingTimePerDay.filter(hash => hash.value !== 0) || emptyCalendar}
            from={activitiesStatsSummary?.trainingTimePerDay[0]['day'] || '2021-04-07'}
            to={currentDate}
            emptyColor="#f2f2f2"
            colors={[ '#C0EAAA', '#97E089', '#6AD66C', '#4ACB66', '#2CBF66', '#06888A', '#01365F']}
            margin={{ top: 10, right: 10, bottom: 10, left: 18 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            monthSpacing={5}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left'
                }
            ]}
          />
        </CalendarSection>
      )}
    </>
  )
}