import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useMedia} from 'react-use';
import {Section} from '../../ui/Section';
import {GoldButton} from '../../widgets/Button';

import {LightboxCard} from '../../widgets/LightboxCard';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {TextLayout} from '../../widgets/Text';
import {ObjectType} from '../backend/models/PaymentModel';
import {PriceRowModel} from '../backend/models/PriceModel';
import {useBackend} from '../backend/useBackend';

const PaymentCard = styled(Section)`
    padding: 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.subBackground};
    .price {
        font-family: ${props => props.theme.title};
        font-size: 3em;
    }
    .subtitle {
        color: ${props => props.theme.subForeground};
        margin-bottom: 20px;
    }
`;

const ErrorText = styled.div`
    color: ${props => props.theme.error};
    text-align: center;
`;

export const PaymentLightbox: React.FC<{isOpen: boolean; id: string; objectType: ObjectType; prices?: PriceRowModel[]}> = ({isOpen, id, objectType, prices}) => {
    const theme = useTheme();
    const backend = useBackend();
    const matches800 = useMedia('(max-width: 800px)');
    const matches600 = useMedia('(max-width: 600px)');
    const currentPrice = useMemo(() => {
        return prices && prices.filter(p => p.current)[0];
    }, [prices]);
    const isSafariMobile = useCallback(() => {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        return iOS && webkit && !ua.match(/CriOS/i);
    }, []);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const isQuest = useMemo(() => prices && prices[0] && prices[0].objectType === 'QUEST', [prices]);
    const handleCheckout = useCallback(
        async (priceType: 'SINGLE' | 'WEEK' | 'MONTH' | 'YEAR') => {
            setErrorMessage('');
            setIsLoading(true);
            try {
                const response = await backend.checkoutPayment(id, objectType, priceType);
                setIsLoading(false);
                window.open(response.url);
                amplitude.getInstance().logEvent(`start_paying_${objectType.toLowerCase()}`, JSON.parse(`{"${objectType.toLowerCase()}Id": "${id}", "page": "${objectType.toLowerCase()}", "priceType": "${priceType}"}`));
            } catch (err) {
                setIsLoading(false);
                setErrorMessage((err as Error).message);
            }
        },
        [backend, id, objectType],
    );
    useEffect(() => {
        // console.log();
    }, [prices]);
    return (
        <LightboxCard
            padding="20px"
            align="center"
            spacing={10}
            width="700px"
            maxWidth="90%"
            height={matches800 ? (matches600 ? '90%' : '600px') : '400px'}
            direction="y"
            isOpen={isOpen}
        >
            <TextLayout size={1.5} weight="bold">
                <FormattedMessage id={isQuest ? 'app.payment.questTitle' : 'app.payment.trainingTitle'} />
            </TextLayout>
            <TextLayout color={theme.subForeground} weight="bold">
                <FormattedMessage id={isQuest ? 'app.payment.questDescription' : 'app.payment.trainingDescription'} />
            </TextLayout>
            <TextLayout color={theme.subForeground}>
                <FormattedMessage id="app.payment.subDescription" />
            </TextLayout>
            <Section spacing={10} width={matches800 ? '100%' : undefined} direction={matches800 ? 'y' : 'x'} scroll={true} grow={1} align="stretch">
                {currentPrice && (currentPrice.price || currentPrice.pricePerWeek) ? (
                    <Section spacing={10} direction={matches600 ? 'y' : 'x'} grow={1} align="stretch">
                        {currentPrice.price ? (
                            <PaymentCard direction="y" align="center" distribute="center" grow={1}>
                                <span className="price">
                                    {currentPrice.price}
                                    {currentPrice.currency === 'EUR' ? '€' : '$'}
                                </span>
                                <span className="subtitle">
                                    <FormattedMessage id="app.payment.once" />
                                </span>
                                <GoldButton disabled={isLoading} onClick={() => handleCheckout('SINGLE')}>
                                    {isLoading ? <DotsSpinner color="#222" size={16} /> : <FormattedMessage id="app.payment.buy" />}
                                </GoldButton>
                            </PaymentCard>
                        ) : null}
                        {currentPrice.pricePerWeek ? (
                            <PaymentCard direction="y" align="center" distribute="center" grow={1}>
                                <span className="price">
                                    {currentPrice.pricePerWeek}
                                    {currentPrice.currency === 'EUR' ? '€' : '$'}
                                </span>
                                <span className="subtitle">
                                    <FormattedMessage
                                        id="app.payment.everyNWeek"
                                        values={{n: currentPrice.intervalWeek === 1 ? '' : currentPrice.intervalWeek}}
                                    />
                                </span>
                                <GoldButton disabled={isLoading} onClick={() => handleCheckout('WEEK')}>
                                    {isLoading ? <DotsSpinner color="#222" size={16} /> : <FormattedMessage id="app.payment.subscribe" />}
                                </GoldButton>
                            </PaymentCard>
                        ) : null}
                    </Section>
                ) : null}
                {currentPrice && (currentPrice.pricePerMonth || currentPrice.pricePerYear) ? (
                    <Section spacing={10} direction={matches600 ? 'y' : 'x'} grow={1} align="stretch">
                        {currentPrice.pricePerMonth ? (
                            <PaymentCard direction="y" align="center" distribute="center" grow={1}>
                                <span className="price">
                                    {currentPrice.pricePerMonth}
                                    {currentPrice.currency === 'EUR' ? '€' : '$'}
                                </span>
                                <span className="subtitle">
                                    <FormattedMessage
                                        id="app.payment.everyNMonth"
                                        values={{n: currentPrice.intervalMonth === 1 ? '' : currentPrice.intervalMonth}}
                                    />
                                </span>
                                <GoldButton disabled={isLoading} onClick={() => handleCheckout('MONTH')}>
                                    {isLoading ? <DotsSpinner color="#222" size={16} /> : <FormattedMessage id="app.payment.subscribe" />}
                                </GoldButton>
                            </PaymentCard>
                        ) : null}
                        {currentPrice.pricePerYear ? (
                            <PaymentCard direction="y" align="center" distribute="center" grow={1}>
                                <span className="price">
                                    {currentPrice.pricePerYear}
                                    {currentPrice.currency === 'EUR' ? '€' : '$'}
                                </span>
                                <span className="subtitle">
                                    <FormattedMessage
                                        id="app.payment.everyNYear"
                                        values={{n: currentPrice.intervalYear === 1 ? '' : currentPrice.intervalMonth}}
                                    />
                                </span>
                                <GoldButton disabled={isLoading} onClick={() => handleCheckout('YEAR')}>
                                    {isLoading ? <DotsSpinner color="#222" size={16} /> : <FormattedMessage id="app.payment.subscribe" />}
                                </GoldButton>
                            </PaymentCard>
                        ) : null}
                    </Section>
                ) : null}
            </Section>
            {isSafariMobile() && (
                <TextLayout>
                    <FormattedMessage id="app.payment.safariWarning" />
                </TextLayout>
            )}
            {errorMessage ? (
                <ErrorText>
                    <FormattedMessage id={`app.error.${errorMessage}`} />
                </ErrorText>
            ) : null}
        </LightboxCard>
    );
};
