import {useMemo} from 'react';
// import {useBackend} from '../backend/useBackend';

export const soundPlayer = (src: string) => {
    if (
        !(
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        )
    ) {
        return new Audio(src);
    }
    var context = new AudioContext();
    var myBuffer: AudioBuffer;

    var request = new XMLHttpRequest();

    request.open('GET', src, true);
    request.responseType = 'arraybuffer';

    // Decode asynchronously
    request.onload = function () {
        context.decodeAudioData(
            request.response,
            function (theBuffer) {
                myBuffer = theBuffer;
            },
            err => {
                console.log('ERROR: ', err);
            },
        );
    };
    request.send();

    return {
        play: () => {
            const source = context.createBufferSource(),
                g = context.createGain();
            source.buffer = myBuffer;
            source.start(0);
            g.gain.value = 1.0;
            source.connect(g);
            g.connect(context.destination);
            // TODO: vibreur??
        },
    };
};

export const useSoundPlayers = () => {
    const exerciseSound = useMemo(() => soundPlayer(require(`./assets/sounds/defaultExercise.mp3`)), []);
    const restSound = useMemo(() => soundPlayer(require(`./assets/sounds/defaultRest.mp3`)), []);
    const workoutSound = useMemo(() => soundPlayer(require(`./assets/sounds/defaultExercise.mp3`)), []);
    const transitionSound = useMemo(() => soundPlayer(require(`./assets/sounds/defaultTransition.mp3`)), []);
    return {
        playExercise: () => exerciseSound.play(),
        playRest: () => restSound.play(),
        playWorkout: () => workoutSound.play(),
        playTransition: () => transitionSound.play(),
    };
};

export const playSound = (soundTheme: string) => {
    return soundPlayer(require(`./assets/sounds/${soundTheme}Exercise.mp3`)).play();
};
