import React from 'react';
import styled from '@emotion/styled';
import {useDrop} from 'react-dnd';
import {useTheme} from '@emotion/react';
import {NativeTypes} from 'react-dnd-html5-backend';
import {FormattedMessage} from 'react-intl';
import {useCallback, useEffect, useRef, useState} from 'react';
import type {ChangeEvent} from 'react';

import {Section} from '../ui/Section';
import {Thumbnail} from './Thumbnail';
import {DotsSpinner} from './Spinner/DotSpinner';

import {ReactComponent as ImageIcon} from './assets/image.svg';
import {ReactComponent as UploadIcon} from './assets/uploadFab.svg';

const Upload = styled(UploadIcon)`
    position: absolute;
    //
    right: 10px;
    bottom: 10px;
    //
    width: auto !important;
    height: auto !important;
    transition: all 0.3s;
`;

const MainSection = styled(Section)<{isAbsolute?: boolean}>`
    position: ${props => (props.isAbsolute ? 'absolute' : undefined)};
`;

const ImageSection = styled(Section)<{canDrop: boolean}>`
    cursor: pointer;
    font-family: ${props => props.theme.title};
    border: ${props => (props.canDrop ? `2px solid ${props.theme.primary}` : undefined)};
    border-radius: 10px;
    background-color: ${props => props.theme.subBackground};
    position: relative;
    transition: all 0.3s;
    img {
        border-radius: 10px;
    }
    color: ${props => props.theme.subForeground};
    [fill='black'] {
        fill: ${props => props.theme.subForeground};
    }
    [stroke='black'] {
        stroke: ${props => props.theme.subForeground};
    }
    .overlay {
        color: white;
        opacity: 0;
        transition: 0.3s opacity;
        //
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.6);
        [fill='black'] {
            fill: white;
        }
        [stroke='black'] {
            stroke: white;
        }
    }
    &:hover {
        .overlay {
            opacity: 1;
        }
        .upload {
            opacity: 0;
        }
    }
    &:active {
        transform: scale(0.95);
    }
`;

const FileInput = styled.input`
    display: none;
`;

interface ImagePickerProps {
    imageSrc?: string;
    isLoading: boolean;
    //
    width?: string;
    height?: string;
    isAbsolute?: boolean;
    //
    onImagePick: (file: File) => void;
}

export const ImagePicker: React.FC<ImagePickerProps> = ({isLoading, imageSrc, width, height, isAbsolute, onImagePick}) => {
    const inputFile = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const [src, setSrc] = useState(imageSrc);

    const handleFile = useCallback(
        (file: File) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setSrc(reader.result as string);
            };
            onImagePick(file);
        },
        [onImagePick],
    );

    const [{isOver, canDrop}, ref] = useDrop({
        accept: NativeTypes.FILE,
        drop: (item: {files: File[]}) => {
            const file = item.files[0];
            if (file?.type.startsWith('image/')) {
                handleFile(file);
            }
        },
        collect: monitor => ({isOver: monitor.isOver(), canDrop: monitor.canDrop()}),
    });

    const handleUpload = useCallback(() => {
        inputFile.current?.click();
    }, []);

    const handleFilePicked = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.currentTarget.files;
            if (files && files.length > 0) {
                handleFile(files[0]!);
            }
        },
        [handleFile],
    );

    useEffect(() => {
        setSrc(imageSrc);
    }, [imageSrc]);

    const isActive = isOver && canDrop;
    if (isLoading) {
        return (
            <Section
                style={{background: theme.subBackground, borderRadius: 10, position: isAbsolute ? 'absolute' : undefined}}
                align="center"
                distribute="center"
                direction="y"
                width={width}
                height={height}
            >
                <DotsSpinner color={theme.foreground} size={30} />
            </Section>
        );
    }

    return (
        <MainSection direction="y" width={width} height={height} isAbsolute={isAbsolute} onClick={handleUpload}>
            <ImageSection ref={ref} align="center" canDrop={isActive} direction="y" distribute="center" width={width} height={height}>
                {!!src && (
                    <>
                        <Section direction="y" distribute="center" align="center" spacing={20} width={width} height={height} className="overlay">
                            <ImageIcon />
                            <div>
                                <FormattedMessage id="widgets.imagePicker.dragImage" />
                            </div>
                        </Section>
                        <Thumbnail width="100%" height="100%" src={src} isLoading={false} />
                    </>
                )}
                {!src && (
                    <Section direction="y" align="center" distribute="center" spacing={20} width={width} height={height}>
                        <ImageIcon />
                        <div>
                            <FormattedMessage id="widgets.imagePicker.dragImage" />
                        </div>
                    </Section>
                )}
                <Upload className="upload" />
            </ImageSection>
            <FileInput ref={inputFile} type="file" accept="image/*" onChange={handleFilePicked} />
        </MainSection>
    );
};
