import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useTheme} from '@emotion/react';

import {Icon} from '../../ui/Icon';
import {useBackend} from '../backend/useBackend';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import type {TrainingRowModel} from '../backend/models/TrainingModel';
import amplitude from 'amplitude-js';

export const TrainingBookmark: React.FC<{training: TrainingRowModel, currentPage: String}> = ({training, currentPage}) => {
    const theme = useTheme();
    const backend = useBackend();
    const {formatMessage} = useIntl();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isBookmarked, setIsBookmarked] = React.useState<boolean>(false);
    const handleBookmark = React.useCallback(
        async (e: { preventDefault: () => void; }) => {
            if (training.id === undefined) {
                return;
            }
            e.preventDefault();
            setIsLoading(true);
            if (isBookmarked) {
                amplitude.getInstance().logEvent(`remove_bookmark_from_${currentPage}`, {trainingId: training.id, page: currentPage});
                await backend.unbookmarkTraining(training.id);
                e.preventDefault();
            } else {
                amplitude.getInstance().logEvent(`add_bookmark_from_${currentPage}`, {trainingId: training.id, page: currentPage});
                await backend.bookmarkTraining(training.id);
                e.preventDefault();
            }
            setIsBookmarked(b => !b);
            setIsLoading(false);
        },
        [backend, currentPage, isBookmarked, training.id],
    );
    useEffect(() => {
        setIsBookmarked(training.isBookmarked);
    }, [training.isBookmarked]);
    return isLoading ? (
        <DotsSpinner color={theme.primary} size={16} />
    ) : (
        <Icon
            color={theme.primary}
            name={isBookmarked ? 'bookmark' : 'unbookmark'}
            title={`${training.bookmarksCount} ${formatMessage({id: training.bookmarksCount < 2 ? 'app.training.bookmark' : 'app.training.bookmarks'})}`}
            onClick={handleBookmark}
        />
    );
};
