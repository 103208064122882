import React from 'react';
import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';
import {useTheme} from '@emotion/react';
import {useNavigate, useLocation} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {Logo} from '../logo/Logo';
import {Section} from '../../ui/Section';
import {useGoHome} from '../appRouter/useGoHome';
import {useLocale} from '../locale/Locale';
import {TextLayout} from '../../widgets/Text';
import {useBackend} from '../backend/useBackend';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {ErrorMessage} from './ErrorMessage';
import {useThemeSelector} from '../theme/Theme';
import {Input, PasswordInput, useInputState} from '../../widgets/Input';
import {InlineButton, PrimaryButton} from '../../widgets/Button';
import { useTrackers } from '../tracking/useTrackers';

const LogoSection = styled(Section)`
    cursor: pointer;
`;

export const Register: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const backend = useBackend();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [email, setEmail] = useInputState('');
    const [username, setUsername] = useInputState('');
    const [password, setPassword] = useInputState('');
    const [error, setError] = React.useState<string | undefined>();
    const trainingId = new URLSearchParams(useLocation().search).get('training');
    const questId = new URLSearchParams(useLocation().search).get('quest');
    const userId = new URLSearchParams(useLocation().search).get('user');
    const {changeLocale} = useLocale();
    const {changeTheme} = useThemeSelector();

    const handleRegister = React.useCallback(async () => {
        setError(undefined);
        setIsLoading(true);
        try {
            const user = await backend.register(username, email, password);
            const appVersion = await backend.getVersion();
            localStorage.setItem('version', appVersion.version.toString());
            changeLocale?.(user.settings.locale);
            changeTheme?.(user.settings.theme);
            amplitude.getInstance().setUserId(user.id);
            amplitude.getInstance().logEvent('register', {page: 'auth'});
            ReactGA.event("sign_up");
            setIsLoading(false);
            navigate("/register/interests" + (trainingId ? `?training=${trainingId}` : questId ? `?quest=${questId}` : userId ? `?user=${userId}` : ''));
        } catch (err) {
            setError((err as Error).message);
            setIsLoading(false);
        }
    }, [backend, username, email, password, changeLocale, changeTheme, navigate, trainingId, questId, userId]);
    const handleGotAccount = React.useCallback(() => {
        navigate('/login' + (trainingId ? `?training=${trainingId}` : questId ? `?quest=${questId}` : userId ? `?user=${userId}` : ''));
    }, [navigate, questId, trainingId, userId]);
    const handleEnterToRegisterEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await handleRegister()
        }
    };
    const goHome = useGoHome();
    useTrackers();
    return (
        <Section width="100%" height="100%" align="center" distribute="center">
            <Section direction="y" align="stretch" maxWidth="400px" width="100%" spacing={20} padding="20px">
                <LogoSection direction="y" align="center" spacing={20} onClick={goHome}>
                    <Logo width={100} />
                    <TextLayout weight="bold" size={2}>
                        Training Quest
                    </TextLayout>
                </LogoSection>
                <TextLayout color={theme.subForeground}>
                    <FormattedMessage id="app.auth.register.subtitle" />
                </TextLayout>
                <Input placeholder={formatMessage({id: 'app.auth.register.username'})} value={username.toLowerCase().trim()} onChange={setUsername} />
                <Input type="email" placeholder={formatMessage({id: 'app.auth.register.email'})} value={email} onChange={setEmail} />
                <PasswordInput
                    placeholder={formatMessage({id: 'app.auth.register.password'})}
                    value={password}
                    onChange={setPassword}
                    onKeyPress={handleEnterToRegisterEvent}
                />
                <PrimaryButton style={{width: '100%'}} disabled={isLoading} onClick={handleRegister}>
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={16} /> : <FormattedMessage id="app.auth.register.register" />}
                </PrimaryButton>
                {error && (
                    <ErrorMessage>
                        <FormattedMessage id={`app.error.${error}`} />
                    </ErrorMessage>
                )}
                <InlineButton style={{width: '100%'}} onClick={handleGotAccount}>
                    <FormattedMessage id="app.auth.register.gotAccount" />
                </InlineButton>
            </Section>
        </Section>
    );
};
