import {useNavigate} from 'react-router-dom';
import React, {useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Select} from '../../components/Select';
import {notify} from '../../components/toast/Toast';
import {useLocale} from '../locale/Locale';
import {SettingsSelectUser} from './SettingsSelectUser';
import {useThemeSelector} from '../theme/Theme';
import {ErrorButton, PrimaryButton} from '../../components/Button';
import {Label, TextInput, useInputValue} from '../../components/inputs/Input';

import {Section} from '../../ui/Section';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {LightboxConfirm} from '../../widgets/LightboxConfirm';
import {LightboxCard} from '../../widgets/LightboxCard';
import {ImagePicker} from '../../widgets/ImagePicker';
import {Grid} from '../../ui/Grid';
import {playSound} from '../play/soundPlayer';
import {Icon} from '../../ui/Icon';
import {TextLayout} from '../../widgets/Text';
import styled from '@emotion/styled';
import {useBackend} from '../backend/useBackend';
import {UserModel} from '../backend/models/UserModel';
import {useMedia} from 'react-use';
import {RichText} from '../../widgets/RichText/RichText';
import { useTrackers } from '../tracking/useTrackers';
import amplitude from 'amplitude-js';

interface SettingsProps {
    onClose: () => void;
    onSave: (user: UserModel) => void;
}
declare global {
    interface Window {
        Tawk_API: any;
        Tawk_LoadStart: any;
    }
}

const CloseIcon = styled(Icon)`
    align-self: flex-start;
`;

const BioRichText = styled(RichText)`
    .toolbar {
        background-color: ${props => props.theme.cardBackground};
    }
`;

function isEqual(obj1: any, obj2: any) {
    var props1 = Object.getOwnPropertyNames(obj1);
    var props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length !== props2.length) {
        return false;
    }
    for (var i = 0; i < props1.length; i++) {
        let val1 = obj1[props1[i]];
        let val2 = obj2[props1[i]];
        let isObjects = isObject(val1) && isObject(val2);
        if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
}
function isObject<T>(object: T) {
    return object != null && typeof object === 'object';
}

export const Settings: React.FC<SettingsProps> = ({onClose, onSave}) => {
    const backend = useBackend();
    const intl = useIntl();
    const match600 = useMedia('(max-width: 600px)');
    const {changeLocale} = useLocale();
    const {changeTheme} = useThemeSelector();
    const navigate = useNavigate();
    const [password, setPassword] = useInputValue();
    const [user, setUser] = React.useState<UserModel>();
    const [oldUser, setOldUser] = React.useState<UserModel>();
    const setUserUsername = React.useCallback((e: { target: { value: any; }; }) => setUser(v => v && {...v, username: e.target.value}), []);
    const setUserEmail = React.useCallback((e: { target: { value: any; }; }) => setUser(v => v && {...v, email: e.target.value}), []);
    const setUserBio = React.useCallback((value: any) => setUser(v => v && {...v, bio: value}), []);
    const setUserWebsiteUrl = React.useCallback((e: { target: { value: any; }; }) => setUser(v => v && {...v, websiteUrl: e.target.value}), []);
    const setUserFullname = React.useCallback((e: { target: { value: any; }; }) => setUser(v => v && {...v, fullname: e.target.value}), []);
    const [isAvatarLoading, setIsAvatarLoading] = React.useState(false);
    useTrackers();
    const handleAvatar = React.useCallback(
        async (file: File) => {
            setIsAvatarLoading(true);
            try {
                const newUser = await backend.uploadUserAvatar(user!.id, file);
                setUser(newUser);
                setOldUser(newUser);
                onSave(newUser);
                amplitude.getInstance().logEvent('upload_avatar', {page: 'settings_profile'});
            } catch (err) {
                notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            }
            setIsAvatarLoading(false);
        },
        [backend, intl, onSave, user],
    );
    const [isBannerLoading, setIsBannerLoading] = React.useState(false);
    const handleBanner = React.useCallback(
        async (file: File) => {
            setIsBannerLoading(true);
            try {
                const newUser = await backend.uploadUserBanner(user!.id, file);
                setUser(newUser);
                setOldUser(newUser);
                onSave(newUser);
                amplitude.getInstance().logEvent('upload_user_banner', {page: 'settings_profile'});
            } catch (err) {
                notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            }
            setIsBannerLoading(false);
        },
        [backend, intl, onSave, user],
    );
    const handleSave = React.useCallback(async () => {
        if (user && !isEqual(user, oldUser)) {
            try {
                const result = await backend.updateUser(user);
                setUser(result);
                setOldUser(result);
                onSave(result);
                notify(intl.formatMessage({id: 'app.settings.userSaved'}));
            } catch (err) {
                notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            }
        }
    }, [backend, intl, oldUser, onSave, user]);
    const handlePassword = React.useCallback(async () => {
        try {
            if (user) {
                await backend.updateUserPassword(user.id, password, password);
                notify(intl.formatMessage({id: 'app.settings.passwordChanged'}));
                amplitude.getInstance().logEvent('edit_settings_password', {page: 'settings_profile'});
            }
        } catch (err) {
            notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
        }
    }, [backend, intl, password, user]);
    const handleConfirmRemove = React.useCallback(async () => {
        try {
            // user && backend.deleteUser(user.id);
        } catch (err) {
            notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
        }
    }, [intl]);
    const handleLocale = React.useCallback(
        async (e: { target: { value: any; }; }) => {
            try {
                if (user) {
                    const result = await backend.updateUser({...user, settings: {...user.settings, locale: e.target.value}});
                    setUser(result);
                    changeLocale?.(result.settings.locale);
                    amplitude.getInstance().logEvent('change_locale', {page: 'settings_profile'});
                }
            } catch (err) {
                notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            }
        },
        [backend, changeLocale, intl, user],
    );
    const handleTheme = React.useCallback(
        async (e: { target: { value: any; }; }) => {
            try {
                if (user) {
                    const result = await backend.updateUser({...user, settings: {...user.settings, theme: e.target.value}});
                    setUser(result);
                    setOldUser(result);
                    changeTheme?.(result.settings.theme);
                    amplitude.getInstance().logEvent('change_theme', {page: 'settings_profile'});
                }
            } catch (err) {
                notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            }
        },
        [backend, changeTheme, intl, user],
    );
    const handleSoundTheme = React.useCallback(
        async (e: { target: { value: any; }; }) => {
            try {
                if (user) {
                    const result = await backend.updateUser({...user, settings: {...user.settings, soundTheme: e.target.value}});
                    setUser(result);
                    setOldUser(result);
                    playSound(result.settings.soundTheme);
                    amplitude.getInstance().logEvent('change_sound_theme', {page: 'settings_profile'});
                }
            } catch (err) {
                notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            }
        },
        [backend, intl, user],
    );
    const handleLogout = React.useCallback(async () => {
        try {
            amplitude.getInstance().logEvent('logout');
            await backend.logout();
        } catch (err) {
            notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
        }
        navigate('/login');
    }, [backend, navigate, intl]);
    // const portalNavigate = React.useCallback(async () => {
    //     // const response = await getPortalUrl();
    //     // window.open(response.portalUrl);
    // }, []);
    React.useEffect(() => {
        try {
            if (backend.authUser === undefined) {
                return;
            }
            backend.getUser(backend.authUser.id).then((newUser) => {
                setUser(newUser);
                setOldUser(newUser);
            });
        } catch (err) {
            notify(intl.formatMessage({id: (err as Error).message}), 'error');
        }
    }, [backend, intl]);

    const confirmRemove = useLightboxState();
    const selectUser = useLightboxState();
    const handleOpenPortal = useCallback(async () => {
        const {portalUrl} = await backend.paymentPortal();
        if (portalUrl) {
            window.open(portalUrl);
        }
    }, [backend]);

    return (
        <Section direction="y" width="100%" height="100%" padding="20px">
            <Section distribute="between" padding="0 0 10px 0">
                <TextLayout weight="bold" size={2}>
                    <FormattedMessage id="app.settings.title" />
                </TextLayout>
                <CloseIcon name="close" onClick={onClose} />
            </Section>
            <Section direction="y" grow={1} spacing={20} scroll={true}>
                {(['songoku@gmail.com', 'nguyen.hl.b@gmail.com', 'nmvevo@gmail.com'].includes(user?.email || '')) && (
                    <Section direction="y" spacing={10} maxWidth="250px">
                        <h2>Admin</h2>
                        <PrimaryButton onClick={selectUser.open}>Login as</PrimaryButton>
                    </Section>
                )}
                <Section direction="y" spacing={20}>
                    <h2>
                        <FormattedMessage id="app.settings.general" />
                    </h2>
                    <Grid limit={250} spacing={20}>
                        <Section direction="y">
                            <Label>
                                <FormattedMessage id="app.settings.language" />
                            </Label>
                            <Select value={user?.settings.locale} onChange={handleLocale}>
                                <option value="en-US">{intl.formatMessage({id: 'app.settings.en-US'})}</option>
                                <option value="fr-FR">{intl.formatMessage({id: 'app.settings.fr-FR'})}</option>
                            </Select>
                        </Section>
                        <Section direction="y">
                            <Label>
                                <FormattedMessage id="app.settings.soundTheme" />
                            </Label>
                            <Select value={user?.settings.soundTheme || 'default'} onChange={handleSoundTheme}>
                                <option value="default">{intl.formatMessage({id: 'app.settings.default'})}</option>
                                <option value="boxing">{intl.formatMessage({id: 'app.settings.boxing'})}</option>
                                <option value="digital">{intl.formatMessage({id: 'app.settings.digital'})}</option>
                                <option value="gong">{intl.formatMessage({id: 'app.settings.gong'})}</option>
                                <option value="magic">{intl.formatMessage({id: 'app.settings.magic'})}</option>
                                <option value="win">{intl.formatMessage({id: 'app.settings.win'})}</option>
                                <option value="wtf">{intl.formatMessage({id: 'app.settings.wtf'})}</option>
                            </Select>
                        </Section>
                        <Section direction="y">
                            <Label>
                                <FormattedMessage id="app.settings.theme" />
                            </Label>
                            <Select value={user?.settings.theme} onChange={handleTheme}>
                                <option value="default">{intl.formatMessage({id: 'app.settings.lightTheme'})}</option>
                                <option value="dark">{intl.formatMessage({id: 'app.settings.darkTheme'})}</option>
                            </Select>
                        </Section>
                    </Grid>
                </Section>
                {user && (
                    <>
                        <Section direction="y" spacing={20}>
                            <h2>
                                <FormattedMessage id="app.settings.userAccount" />
                            </h2>
                            <div>
                                <Label>
                                    <FormattedMessage id="app.settings.banner" />
                                </Label>
                                <ImagePicker
                                    imageSrc={user.banner}
                                    isLoading={isBannerLoading}
                                    width="100%"
                                    height={match600 ? '180px' : '250px'}
                                    onImagePick={handleBanner}
                                />
                            </div>
                            <Grid limit={250} spacing={20}>
                                <Section direction="y" spacing={20} className="avatar">
                                    <div>
                                        <Label>Avatar</Label>
                                        <ImagePicker
                                            imageSrc={user.avatar}
                                            isLoading={isAvatarLoading}
                                            width="250px"
                                            height="250px"
                                            onImagePick={handleAvatar}
                                        />
                                    </div>
                                </Section>
                                <Section direction="y" spacing={10} grow={1}>
                                    <TextInput
                                        type="text"
                                        label={intl.formatMessage({id: 'app.settings.fullname'})}
                                        value={user.fullname}
                                        onChange={setUserFullname}
                                        onBlur={() => {
                                            handleSave();
                                            amplitude.getInstance().logEvent('change_fullname', {page: 'settings_profile'});
                                        }}
                                    />
                                    <TextInput
                                        type="text"
                                        label={intl.formatMessage({id: 'app.settings.websiteUrl'})}
                                        value={user.websiteUrl || ''}
                                        onChange={setUserWebsiteUrl}
                                        onBlur={() => {
                                            handleSave();
                                            amplitude.getInstance().logEvent('change_website_url', {page: 'settings_profile'});
                                        }}
                                    />
                                </Section>
                            </Grid>
                        </Section>
                        <Section direction="y">
                            <Label>
                                <FormattedMessage id="app.settings.bio" />
                            </Label>
                            <BioRichText
                                value={user.bio || ''}
                                onChange={setUserBio}
                                onBlur={() => {
                                    handleSave();
                                    amplitude.getInstance().logEvent('change_bio', {page: 'settings_profile'});
                                }}
                            />
                        </Section>
                        <Section direction="y" spacing={20}>
                            <h2>
                                <FormattedMessage id="app.settings.payment" />
                            </h2>
                            <Section>
                                <PrimaryButton
                                    onClick={() => {
                                        handleOpenPortal();
                                        amplitude.getInstance().logEvent('click_on_payments_portal', {page: 'settings_profile'});
                                    }}
                                >
                                    <FormattedMessage id="app.payment.openPortal" />
                                </PrimaryButton>
                            </Section>
                        </Section>
                    </>
                )}
                {/* <Section dir="y" spacing={20} maxWidth="250px">
                    <h2>Subscriptions</h2>
                    <PrimaryButton onClick={portalNavigate}>
                        <FormattedMessage id="app.settings.portalNavigate" />
                    </PrimaryButton>
                </Section> */}
                {user && (
                    <>
                        <Section direction="y" spacing={20}>
                            <h2>
                                <FormattedMessage id="app.settings.updateUsername" />
                            </h2>
                            <Section direction="y">
                                <TextInput
                                    type="text"
                                    label={intl.formatMessage({id: 'app.settings.newUsername'})}
                                    value={user.username.toLowerCase()}
                                    onChange={setUserUsername}
                                    onBlur={() => {
                                        amplitude.getInstance().logEvent('change_username', {page: 'settings_profile'});
                                    }}
                                />
                                <PrimaryButton style={{marginRight: 'auto'}} onClick={handleSave}>
                                    <FormattedMessage id="app.settings.update" />
                                </PrimaryButton>
                            </Section>
                        </Section>
                        <Section direction="y" spacing={20}>
                            <h2>
                                <FormattedMessage id="app.settings.updateEmail" />
                            </h2>
                            <Section direction="y">
                                <TextInput type="email" label={intl.formatMessage({id: 'app.settings.newEmail'})} value={user.email} onChange={setUserEmail} />
                                <PrimaryButton style={{marginRight: 'auto'}} onClick={handleSave}>
                                    <FormattedMessage id="app.settings.update" />
                                </PrimaryButton>
                            </Section>
                        </Section>
                        <Section direction="y" spacing={20}>
                            <h2>
                                <FormattedMessage id="app.settings.updatePassword" />
                            </h2>
                            <Section direction="y">
                                <TextInput
                                    type="password"
                                    label={intl.formatMessage({id: 'app.settings.newPassword'})}
                                    value={password}
                                    onChange={setPassword}
                                />
                                <PrimaryButton style={{marginRight: 'auto'}} onClick={handlePassword}>
                                    <FormattedMessage id="app.settings.update" />
                                </PrimaryButton>
                            </Section>
                        </Section>
                        <Section direction="y" spacing={20}>
                            <ErrorButton onClick={handleLogout}>
                                <FormattedMessage id="app.settings.logout" />
                            </ErrorButton>
                        </Section>
                    </>
                )}
            </Section>
            <Lightbox isOpen={selectUser.isOpen} onRequestClose={selectUser.close}>
                <LightboxCard isOpen={selectUser.isOpen} padding="20px" maxHeight="90%" maxWidth="90%">
                    <SettingsSelectUser></SettingsSelectUser>
                </LightboxCard>
            </Lightbox>
            <Lightbox isOpen={confirmRemove.isOpen} onRequestClose={confirmRemove.close}>
                <LightboxConfirm
                    isOpen={confirmRemove.isOpen}
                    onCancel={confirmRemove.close}
                    onConfirm={handleConfirmRemove}
                    //
                    title={intl.formatMessage({id: 'app.settings.confirmRemoveTitle'})}
                    message={intl.formatMessage({id: 'app.settings.confirmRemoveMessage'})}
                />
            </Lightbox>
        </Section>
    );
};
