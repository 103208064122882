import {keyframes} from '@emotion/react';

import styled from '@emotion/styled';

import {Section} from '../ui/Section';
import {InvisibleButton} from '../components/Button';

const menuAppear = keyframes`
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
`;

export const Menu = styled(Section)<{isDark?: boolean}>`
    margin-top: 10px;
    animation: ${menuAppear} 0.3s;
    background-color: ${props => (props.isDark ? props.theme.background : props.theme.cardBackground)};
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    border-radius: ${props => props.theme.borderRadius};
    padding: 10px 0;
`;

export const MenuItem = styled(InvisibleButton)<{selected: boolean}>`
    color: ${props => (props.selected ? props.theme.foreground : props.theme.subForeground)};
    padding: 5px 20px;
    margin: 0 10px;
    border-radius: 5px;
    &:hover {
        color: ${props => props.theme.primaryForeground};
        background-color: ${props => props.theme.primary};
        transform: scale(1.05);
        svg * {
            fill: ${props => props.theme.primaryForeground};
        }
    }
    &:active {
        transform: scale(0.95);
    }
    svg {
        * {
            fill: ${props => props.theme.foreground};
        }
        visibility: ${props => (props.selected ? 'visible' : 'hidden')};
        margin-right: 5px;
    }
`;
