import {ThemeModel} from '../models/ThemeModel';

const primaryColor = '#13D684';
const subBackground = 'rgba(100, 100, 100, .1)';
const selectedBackground = 'rgba(100, 100, 100, .2)';
const cardBackground = '#eeeeee';

export const defaultTheme: ThemeModel = {
    name: 'default',
    title: 'centra_no2bold, sans-serif',
    body: 'centra_no2book, sans-serif',

    primary: primaryColor,
    explanation: '#30C5FF',
    rest: '#FFD17A',
    primaryForeground: '#FFFFFF',
    foreground: '#444444',
    subForeground: '#999999',
    background: '#FFFFFF',
    subBackground: subBackground,
    selectedBackground: selectedBackground,
    cardBackground: cardBackground,
    disabled: '#C2C9D4',
    input: subBackground,
    inputActive: selectedBackground,
    error: '#EB586A',
    scrollbar: subBackground,
    scrollbarBackground: 'transparent',
    focus: `inset 0px 0px 0px 2px ${primaryColor}`,
    borderRadius: `10px`,
};
