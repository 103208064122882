import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

import {Section} from '../../ui/Section';
import {TextLayout} from '../../widgets/Text';
import {PrimaryButton} from '../../widgets/Button';

export const RouteNotFound: React.FC = () => {
    const navigate = useNavigate();
    const handleGoBack = React.useCallback(() => navigate('/home'), [navigate]);
    return (
        <Section grow={1} align="center" distribute="center" direction="y" spacing={10}>
            <TextLayout weight="bold" size={2}>
                <FormattedMessage id="app.appRouter.routeNotFound.title" />
            </TextLayout>
            <TextLayout>
                <FormattedMessage id="app.appRouter.routeNotFound.subTitle" />
            </TextLayout>
            <PrimaryButton onClick={handleGoBack}>
                <FormattedMessage id="app.appRouter.routeNotFound.goBack" />
            </PrimaryButton>
        </Section>
    );
};
