import ReactGA from 'react-ga4';
import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import { useTheme } from '@emotion/react';
import { Section } from '../../ui/Section';
import { FormattedMessage } from 'react-intl';
import { TextLayout } from '../../widgets/Text';
import { useBackend } from '../backend/useBackend';
import { Trainings } from '../trainings/Trainings';
import { QuestFollow } from '../quests/QuestFollow';
import { PrimaryButton } from '../../widgets/Button';
import { useLoadData } from '../backend/useLoadData';
import { useTrackers } from '../tracking/useTrackers';
import { PremiumLabel } from '../trainings/TrainingCard';
import { useNavigate , useParams} from 'react-router-dom';
import { QuestRowModel } from '../backend/models/QuestModel';
import { SegmentedControl } from '../../widgets/SegmentedControl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { TrainingOrderBy, TrainingRowModel } from '../backend/models/TrainingModel';

const QuestTitle = styled.div`
    font-size: 1.5em;
    font-family: ${props => props.theme.title};
`;
const QuestIntro = styled.div`
    color: ${props => props.theme.subForeground};
    line-height: 28px;
`;

export const Explore: React.FC = () => {
    const theme = useTheme();
    const backend = useBackend();
    const navigate = useNavigate();
    const {tab} = useParams<{tab: string}>();
    const allTrainings = useLoadData<TrainingRowModel>((search, page, perPage, orderBy, filters) => {
        return backend.searchTrainings({search, page, perPage, visibilities: ['PUBLIC'], orderBy: orderBy as TrainingOrderBy, filters});
    });
    const [quests, setQuests] = useState<QuestRowModel[]>();

    useEffect(() => {
        backend
            .listQuests(1, 10)
            .then(setQuests)
            .catch(e => {
                console.log(e.message);
                if (e.message === 'userNotAuthenticated') {
                    localStorage.clear();
                    window.location.reload();
                }
            });
    }, [backend]);

    useTrackers();

    // tabs
    const tabs = useMemo(() => ([
        {value: 'trainings', label: <FormattedMessage id="app.explore.trainings" />},
        {value: 'quests', label: <FormattedMessage id="app.explore.quests" />}
    ]), []);

    const handleChangeTab = useCallback((newTab: any) => {
        navigate(`/explore/${newTab}`);
        amplitude.getInstance().logEvent(`check_explore_${newTab}`, {page: 'explore'});
    }, [navigate]);

    return (
        <Section direction="y" grow={1} scroll={true}>
            <Section direction="y" align="center" padding="20px 40px 10px 40px" spacing={5}>
                <Section direction="x" align="center">
                    <SegmentedControl value={tab} onChange={handleChangeTab} options={tabs} defaultValue="trainings" />
                </Section>
                <TextLayout margin="20px 0 0 0" size={1} color={theme.subForeground}>
                    {(tab === 'trainings' || !tab) && (
                        <FormattedMessage id="app.explore.allTrainingsSubtitle" />
                    )}
                    {tab === 'quests' && (
                        <FormattedMessage id="app.explore.questDescription" />
                    )}
                </TextLayout>
            </Section>
            <Section direction="y" padding="20px 0 0 20px" width="100%" maxWidth="1000px" margin="0 auto">
            {(tab === 'trainings' || !tab) && (
                <Trainings currentPage={'explore'} {...allTrainings} />
            )}
            {tab === 'quests' && quests && (
                <>
                    {quests.map(quest => (
                        <Section key={quest.id} direction="y" width="100%" maxWidth="1000px" padding="20px">
                            <div style={{position: 'relative'}}>
                            <img style={{borderRadius: '15px', maxWidth: '100%'}} src={quest.banner} alt="" />
                            <QuestFollow quest={quest as any} currentPage='explore' />
                            {quest.price && <PremiumLabel style={{bottom: 20}}>
                                {quest.price.value}{quest.price.currency === 'EUR' ? '€' : '$'}
                                {quest.price.period && <FormattedMessage
                                    id={`app.payment.${quest.price.period}`}
                                    values={{n: quest.price.interval === 1 ? '' : quest.price.interval}}
                                />}
                            </PremiumLabel>}
                            </div>
                            <Section direction="y" padding="20px" spacing={10} align="start">
                                <QuestTitle>{quest.title}</QuestTitle>
                                <QuestIntro>{quest.intro}</QuestIntro>
                                <PrimaryButton
                                    onClick={() => {
                                        amplitude.getInstance().logEvent(`check_quest_from_explore`, {questId: quest.id});
                                        ReactGA.event('check_quest', {questId: quest.id});
                                        navigate('/quests/' + quest.id);
                                    }}
                                >
                                    <FormattedMessage id="app.explore.learnMore" />
                                </PrimaryButton>
                            </Section>
                        </Section>
                    ))}
                </>
            )}
            </Section>
        </Section>
    );
};
