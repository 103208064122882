import {keyframes, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import amplitude from 'amplitude-js';
import {transparentize} from 'polished';
import React from 'react';
import {MouseEvent, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useMedia} from 'react-use';
import {Section} from '../../ui/Section';
import {LoadingPart} from '../../widgets/LoadingPart';
import {TextLayout} from '../../widgets/Text';
import {Thumbnail} from '../../widgets/Thumbnail';
import {ExerciseModel} from '../backend/models/ExerciseModel';
import {ExerciseThumbnail} from './ExerciseThumbnail';
import {SelectExerciseButton} from './SelectExerciseButton';

const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const ExerciseSection = styled(Section)<{selected: boolean}>`
    color: ${props => props.theme.foreground};
    cursor: pointer;
    animation: ${appear} 0.3s;
    transition: 0.3s all;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => (props.selected ? props.theme.subBackground : undefined)};
    .thumbnail {
        transition: transform 0.3s;
    }
    &:hover {
        background-color: ${props => props.theme.subBackground};
        .thumbnail {
            transform: scale(0.95);
        }
    }
    &:active {
        transform: scale(0.97);
    }
    ${props => props.selected && ` background-color: ${props.theme.subBackground};`}
`;
interface ExerciseRowProps {
    exercise: ExerciseModel;
    selected: boolean;
    onSelect?: (exercise: ExerciseModel) => void;
    onEdit: (exercise: ExerciseModel) => void;
}

export const ExerciseRow: React.FC<ExerciseRowProps> = ({exercise, selected, onSelect, onEdit}) => {
    const theme = useTheme();
    const handleEdit = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            onEdit(exercise);
            e.stopPropagation();
        },
        [exercise, onEdit],
    );
    return (
        <ExerciseSection onClick={handleEdit} selected={selected} spacing={10} padding="10px" align="center">
            <ExerciseThumbnail youtubeId={exercise.playYoutubeId} />
            <Section direction="y" distribute="center" grow={1}>
                <TextLayout align="left" weight="bold">
                    {exercise.name}
                </TextLayout>
                <TextLayout size={0.8} weight="bold" color={theme.subForeground} ellipsis={true}>
                    {exercise.tags.join(', ')}
                </TextLayout>
            </Section>
            {onSelect && (
                <SelectExerciseButton width="32" height="32" viewBox="0 0 32 32" fill="none" onClick={() => onSelect(exercise)}>
                    <circle cx="16" cy="16" r="16" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.8709 11.5987C24.2897 11.0175 23.3473 11.0175 22.7661 11.5987L14.3465 20.0182L9.61057 15.2823C9.02933 14.701 8.08694 14.701 7.5057 15.2823C6.92445 15.8635 6.92445 16.8059 7.5057 17.3871L13.2941 23.1755C13.8754 23.7568 14.8177 23.7568 15.399 23.1755C15.4 23.1745 15.4011 23.1734 15.4022 23.1723L24.8709 13.7036C25.4522 13.1223 25.4522 12.18 24.8709 11.5987Z"
                    />
                </SelectExerciseButton>
            )}
        </ExerciseSection>
    );
};

const NewExerciseSection = styled(Section)`
    color: ${props => props.theme.foreground};
    cursor: pointer;
    animation: ${appear} 0.3s;
    transition: 0.3s all;
    border-radius: ${props => props.theme.borderRadius};
    &:hover {
        .thumbnail {
            transform: scale(0.95);
        }
        background-color: ${props => props.theme.subBackground};
        circle[fill='white'] {
            fill: ${props => transparentize(0.8, props.theme.primary)};
        }
        path[fill='#AAAAAA'] {
            fill: ${props => props.theme.primary};
        }
        circle {
            transform: scale(1.1);
        }
        path {
            transform: scale(1.2);
        }
    }
    .thumbnail {
        position: relative;
        transition: transform 0.3s;
        cursor: pointer;
        svg {
            position: absolute;
            overflow: visible;
            circle {
                transition: all 0.3s;
                transform-origin: 50% 50%;
            }
            path {
                transition: all 0.3s;
                transform-origin: 50% 50%;
            }
        }
        [fill='white'] {
            fill: ${props => props.theme.subBackground};
        }
        [fill='#AAAAAA'] {
            fill: ${props => props.theme.subForeground};
        }
    }
    &:active {
        transform: scale(0.97);
    }
`;

export const NewExerciseRow: React.FC<{onSelect: () => void}> = ({onSelect}) => {
    const match500 = useMedia('(max-width: 500px)');
    return (
        <NewExerciseSection
            onClick={ () => {
                onSelect();
                amplitude.getInstance().logEvent('create_exercise', {page: 'exercise_library'});
            }}
            spacing={10}
            padding="10px"
            align="center"
        >
            <Section className="thumbnail" align="center" distribute="center" width={match500 ? '70px' : '90px'}>
                <svg style={{transform: match500 ? 'scale(0.8)' : 'scale(1)'}} width="32" height="32" viewBox="0 0 32 32">
                    <circle cx="16" cy="16" r="16" fill="white" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 8.43636C15.1967 8.43636 14.5454 9.08758 14.5454 9.8909V14.8364H9.59999C8.79667 14.8364 8.14545 15.4876 8.14545 16.2909C8.14545 17.0942 8.79667 17.7455 9.59999 17.7455H14.5454V22.6909C14.5454 23.4942 15.1967 24.1454 16 24.1454C16.8033 24.1454 17.4545 23.4942 17.4545 22.6909V17.7454H22.4C23.2033 17.7454 23.8545 17.0942 23.8545 16.2909C23.8545 15.4876 23.2033 14.8364 22.4 14.8364H17.4545V9.8909C17.4545 9.08758 16.8033 8.43636 16 8.43636Z"
                        fill="#AAAAAA"
                    />
                </svg>

                <Thumbnail width="100%" kind="empty" />
            </Section>
            <Section direction="y" distribute="center" grow={1}>
                <TextLayout weight="bold">
                    <FormattedMessage id="app.exercise.newExercise" />
                </TextLayout>
            </Section>
        </NewExerciseSection>
    );
};

export const LoadingExerciseRow: React.FC = () => {
    const match500 = useMedia('(max-width: 500px)');
    return (
        <Section spacing={10} padding="10px" width="100%">
            <Thumbnail kind="empty" width={match500 ? '70px' : '90px'} isLoading={true} />
            <Section direction="y" spacing={10} grow={1} distribute="center">
                <LoadingPart width="70%" height="15px" />
                <LoadingPart width="50%" height="10px" />
            </Section>
        </Section>
    );
};
