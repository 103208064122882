import {RichUtils, KeyBindingUtil, EditorState} from 'draft-js';
import type {EditorPlugin} from '@draft-js-plugins/editor';
import React from 'react';

export interface LinkPlugin extends EditorPlugin {
    addLink: (editorState: EditorState, url: string) => EditorState;
}

export const createLinkPlugin = (): LinkPlugin => ({
    addLink: (editorState: EditorState, url: string) => {
        const selection = editorState.getSelection();
        if (!url) {
            return RichUtils.toggleLink(editorState, selection, null);
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {url});
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'apply-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        return RichUtils.toggleLink(newEditorState, selection, entityKey);
    },
    keyBindingFn(event, {getEditorState}) {
        const editorState = getEditorState();
        const selection = editorState.getSelection();
        if (selection.isCollapsed()) {
            return;
        }
        if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
            return 'add-link';
        }
    },

    handleKeyCommand(command, editorState, ts, {setEditorState}) {
        if (command !== 'add-link') {
            return 'not-handled';
        }
        let link = window.prompt('Paste the link -');
        const selection = editorState.getSelection();
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
            return 'handled';
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
            url: link,
        });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'apply-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
        return 'handled';
    },

    decorators: [
        {
            strategy: (contentBlock, callback, contentState) => {
                contentBlock.findEntityRanges(character => {
                    const entityKey = character.getEntity();
                    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
                }, callback);
            },
            component: (props: any) => {
                const {contentState, entityKey} = props;
                const {url} = contentState.getEntity(entityKey).getData();
                return (
                    <a className="link" href={url} rel="noopener noreferrer" target="_blank" aria-label={url}>
                        {props.children}
                    </a>
                );
            },
        },
    ],
});
