import React from 'react';
import Iframe from 'react-iframe';
import styled from '@emotion/styled';
import {useTheme} from '@emotion/react';
import {Section} from '../ui/Section';

interface YoutubeResponsiveProps {
    youtubeId: string;
    mute?: boolean;
    radius?: string;
}

const Wrapper = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
`;

const Video = styled(Iframe)<{radius?: string}>`
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: ${props => props.radius || props.theme.borderRadius};
    @media (max-width: 800px) {
        border-radius: ${props => props.radius || '0'};
    }
`;

const EmptyVideo = styled(Section)`
    position: absolute;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => props.theme.subBackground};
`;

export const YoutubeResponsive: React.FC<YoutubeResponsiveProps> = ({youtubeId, mute, radius}) => {
    const theme = useTheme();
    return (
        <Wrapper>
            {youtubeId ? (
                <Video
                    radius={radius}
                    width="320"
                    height="180"
                    url={`https://www.youtube.com/embed/${youtubeId}?autoplay=false&mute={${mute ? 'true' : 'false'}}`}
                    allowFullScreen
                ></Video>
            ) : (
                <EmptyVideo width="100%" height="100%" align="center" distribute="center">
                    <svg width="107" height="85" viewBox="0 0 107 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M53.5 0C36.273 0 13.9135 4.05193 13.9135 4.05193L13.8573 4.11229C6.01119 5.29026 0 11.6193 0 19.3165V42.4962V42.5038V65.676V65.6835C0.00765648 69.3596 1.41104 72.9127 3.95817 75.7048C6.5053 78.497 10.0293 80.3453 13.8975 80.9179L13.9135 80.9405C13.9135 80.9405 36.273 85 53.5 85C70.727 85 93.0865 80.9405 93.0865 80.9405L93.0945 80.933C96.9668 80.3616 100.495 78.5118 103.044 75.7164C105.593 72.9209 106.996 69.3633 107 65.6835V65.676V42.5038V42.4962V19.3165C106.994 15.6391 105.592 12.0842 103.044 9.29048C100.497 6.49676 96.972 4.64736 93.1025 4.07457L93.0865 4.05193C93.0865 4.05193 70.727 0 53.5 0ZM41.1538 24.719L74.0769 42.4962L41.1538 60.2734V24.719Z"
                            fill={theme.subBackground}
                        />
                    </svg>
                </EmptyVideo>
            )}
        </Wrapper>
    );
};
