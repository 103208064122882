import React from 'react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import {FormattedMessage} from 'react-intl';

import {AppRouter} from './appRouter/AppRouter';
import {LocaleProvider} from './locale/Locale';
import {ThemeSelectorProvider} from './theme/Theme';

import './App.css';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import {UserModel} from './backend/models/UserModel';
import {AuthUserContext, getAuthUser} from './backend/AuthUser';
import { useBackend } from './backend/useBackend';
import { Toaster } from 'react-hot-toast';

if (process.env.REACT_APP_AMPLITUDE_ID !== undefined) {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_ID, undefined, {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true
    });
}

if (process.env.REACT_APP_GA_ID_GLOBAL !== undefined && process.env.REACT_APP_GA_ID !== undefined) {
    ReactGA.initialize([{trackingId: process.env.REACT_APP_GA_ID_GLOBAL}, {trackingId: process.env.REACT_APP_GA_ID}]);
}

if (process.env.REACT_APP_FB_PIXEL_ID !== undefined) {
    ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
}

Modal.setAppElement('#root');

const showInstallBanner = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent) && !window.matchMedia('(display-mode: standalone)').matches;
};

const GlobalBanner = styled.div`
    font-size: 0.8em;
    color: ${props => props.theme.subForeground};
    padding: 10px 20px 15px 20px;
    text-align: center;
    background-color: ${props => props.theme.subBackground};
    [stroke='black'] {
        stroke: ${props => props.theme.foreground};
    }
    a, span {
        color: ${props => props.theme.primary}
    }
    span {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const App: React.FC = () => {
    const [authUser, setAuthUser] = React.useState<UserModel | undefined>(getAuthUser());
    const [reloadApp, setReloadApp] = React.useState(false);
    const backend = useBackend();

    React.useEffect(() => {
        if (authUser) {
            amplitude.getInstance().setUserId(authUser.id);
        }
    }, [authUser]);

    React.useEffect(() => {
        async function fetchData() {
            if (authUser) {
                try {
                    const appVersion = await backend.getVersion();
                    const currentVersion = localStorage.getItem('version');
                    if (currentVersion !== appVersion.version.toString()) {
                        localStorage.setItem('version', 'outdated');
                        setReloadApp(true);
                    } else {
                        setReloadApp(false);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }
        fetchData();
    }, [backend, authUser]);

    type InlineLinkProps = {
        children: React.ReactNode;
    };

    type reloadAppLinkProps = {
        onClick?: () => void;
        children: React.ReactNode;
    }
    const InlineLink = ({ children }: InlineLinkProps) => {
        return <p>{children}</p>;
    };
    const ReloadAppLink = ({ onClick, children }: reloadAppLinkProps) => (
        <span onClick={onClick}>{children}</span>
    );
    const handleReloadApp = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations()
            .then(async function(registrations) {
                for(let registration of registrations) {
                    registration.unregister();
                }
                try {
                    const appVersion = await backend.getVersion();
                    localStorage.setItem('version', appVersion.version.toString());
                    setReloadApp(false);
                    const timestamp = new Date().getTime();
                    window.location.href = `${window.location.pathname}?v=${timestamp}`;
                    window.location.reload();
                } catch (error) {
                    console.error('Error:', error);
                }
            });
        }
    };

    const reloadAppLink =  <ReloadAppLink onClick={handleReloadApp}><FormattedMessage id="app.reloadAppLink"/></ReloadAppLink>

    return (
        <AuthUserContext.Provider value={{authUser, setAuthUser}}>
            <LocaleProvider>
                <ThemeSelectorProvider>
                    <DndProvider backend={HTML5Backend}>
                        <AppRouter />
                    </DndProvider>
                    <Toaster/>
                    {reloadApp && (
                        <GlobalBanner>
                            <FormattedMessage
                                id="app.reloadApp"
                                tagName={InlineLink}
                                values={{
                                reloadAppLink: reloadAppLink
                                }}
                            />
                        </GlobalBanner>
                    )}
                    {showInstallBanner() && (
                        <GlobalBanner>
                            <FormattedMessage id="app.toInstall" />
                            <svg
                                style={{paddingLeft: 10, marginBottom: -3, paddingRight: 10}}
                                width="17"
                                height="22"
                                viewBox="0 0 17 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5 7H3C1.89543 7 1 7.89543 1 9V19C1 20.1046 1.89543 21 3 21H14C15.1046 21 16 20.1046 16 19V9C16 7.89543 15.1046 7 14 7H12"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path d="M8.5 13.5V1.5M8.5 1.5L11.5 4.5M8.5 1.5L5.5 4.5" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            <FormattedMessage id="app.addToHomeScreen" />
                        </GlobalBanner>
                    )}
                </ThemeSelectorProvider>
            </LocaleProvider>
        </AuthUserContext.Provider>
    );
};

export default App;
