import styled from '@emotion/styled';
import {keyframes, useTheme} from '@emotion/react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Section} from '../../ui/Section';
import {PauseMode} from './modes/PauseMode';
import {FinishMode} from './modes/FinishMode';
import {PlayProgressBar} from './PlayProgressBar';
import {useSoundPlayers} from './soundPlayer';
import {FormattedMessage} from 'react-intl';
import {MenuIcon, SetLabel, TimerLabel, NextExerciseLabel, Topbar} from './MenuElements';
import {usePlaySteps} from './usePlaySteps';
import {formatSeconds} from '../backend/models/TrainingModel';
import type {TrainingModel} from '../backend/models/TrainingModel';

import {ReactComponent as NextIcon} from './assets/next.svg';
import {ReactComponent as PreviousIcon} from './assets/previous.svg';
import {ReactComponent as PauseIcon} from './assets/pause.svg';
import {ReactComponent as ExplanationsIcon} from './assets/explanations.svg';
import {ReactComponent as SoundOn} from './assets/soundOn.svg';
import {ReactComponent as SoundOff} from './assets/soundOff.svg';
import {ReactComponent as SpeechOn} from './assets/speechOn.svg';
import {ReactComponent as SpeechOff} from './assets/speechOff.svg';
import {ExplanationsMode} from './modes/ExplanationsMode';
import {useBackend} from '../backend/useBackend';
import {useLocale} from '../locale/Locale';
import amplitude from 'amplitude-js';
import Iframe from 'react-iframe';

interface PlayProps {
    training: TrainingModel;
    onExit: () => void;
}

const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const textAppear = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.2);
    }
    30% {
        opacity: 1;
        transform: scale(1);
    }
    80% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
`;

const MainSection = styled(Section)<{isCountdown: boolean}>`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    animation: ${appear} 0.5s;
    //
    color: ${props => (props.isCountdown ? props.theme.primaryForeground : props.theme.foreground)};
    background-color: ${props => (props.isCountdown ? props.theme.primary : props.theme.background)};
    //
    user-select: none;
    z-index: 2;
`;

const BottomBar = styled(Section)`
    z-index: 2;
    margin-bottom: 50px;
    padding: 0 50px;
    @media (max-width: 800px) {
        padding: 0 30px;
        margin-bottom: 40px;
    }
    @media (max-width: 400px) {
        padding: 0 20px;
        margin-bottom: 30px;
    }
`;

const timerAppear = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const TimerSvg = styled.svg`
    animation: ${timerAppear} 1s;
    width: 400px;
    @media (max-width: 800px) {
        width: 300px;
    }
    @media (max-height: 750px) {
        width: 300px;
    }
    @media (max-height: 500px) {
        width: 200px;
    }
    @media (max-height: 400px) {
        width: 150px;
    }
    path {
        transition: stroke-dasharray 1s linear;
    }
    text {
        font-family: ${props => props.theme.title};
        fill: ${props => props.theme.subForeground};
    }
`;

const CountDownLabel = styled.div`
    z-index: 1;
    font-family: ${props => props.theme.title};
    font-size: 12em;
    text-align: center;
    color: ${props => props.theme.primaryForeground};
    @media (max-width: 1200px) {
        font-size: 10em;
    }
    @media (max-height: 750px) {
        font-size: 10em;
    }
    @media (max-width: 800px) {
        font-size: 8em;
    }
    @media (max-height: 600px) {
        font-size: 8em;
    }
    @media (max-width: 600px) {
        font-size: 6em;
    }
    @media (max-height: 400px) {
        font-size: 5em;
    }
    animation: ${textAppear} 1s cubic-bezier(0.15, 0.45, 0.15, 1.35) 0s infinite;
`;
const CountDownSubtitle = styled.div`
    font-size: 4em;
    z-index: 1;
    padding: 0 30px;
    animation: ${appear} 0.4s ease-out;

    color: ${props => props.theme.primaryForeground};
    text-align: center;
    @media (max-width: 1200px) {
        font-size: 3em;
    }
    @media (max-height: 750px) {
        font-size: 3em;
    }
    @media (max-width: 800px) {
        font-size: 2em;
    }
    @media (max-height: 600px) {
        font-size: 2em;
    }
    @media (max-height: 400px) {
        font-size: 1.5em;
    }
`;
const CountDownExercise = styled.div`
    font-size: 6em;
    animation: ${appear} 0.6s ease-out;
    z-index: 1;
    padding: 0 30px;
    font-family: ${props => props.theme.title};
    text-align: center;
    color: ${props => props.theme.primaryForeground};
    @media (max-width: 1200px) {
        font-size: 5em;
    }
    @media (max-height: 750px) {
        font-size: 5em;
    }
    @media (max-width: 800px) {
        font-size: 4em;
    }
    @media (max-height: 600px) {
        font-size: 4em;
    }
    @media (max-height: 400px) {
        font-size: 2em;
    }
`;

const ExerciseLabel = styled.div`
    font-size: 4em;
    z-index: 1;
    padding: 0 30px;
    font-family: ${props => props.theme.title};
    text-align: center;
    @media (max-width: 1200px) {
        font-size: 3em;
    }
    @media (max-height: 750px) {
        font-size: 3em;
    }
    @media (max-width: 800px) {
        font-size: 2em;
    }
    @media (max-height: 600px) {
        font-size: 2em;
    }
    @media (max-height: 400px) {
        font-size: 1.5em;
    }
`;

const EffortLabel = styled.div`
    z-index: 1;
    color: ${props => props.theme.subForeground};
    font-family: ${props => props.theme.title};
    font-size: 4em;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 3em;
    }
`;

const Check = styled.svg`
    cursor: pointer;
    width: 120px;
    height: 120px;
    transition: transform 0.3s;
    &:hover {
        transform: scale(1.1);
    }
    @media (max-width: 1000px) {
        transform: scale(0.8);
        &:hover {
            transform: scale(0.9);
        }
    }
    @media (max-height: 400px) {
        transform: scale(0.6);
        height: 90px;
        overflow: visible; // hack for the size of the check
        &:hover {
            transform: scale(0.7);
        }
    }

    .check {
        animation-delay: 0.3s;
        fill: none;
        stroke: ${props => props.theme.primaryForeground};
        stroke-width: 10;
        stroke-linecap: round;
        stroke-dasharray: 100;
        /* stroke-dashoffset: 0; */
        /* opacity: 0; */
    }
    .checkCircle {
        animation-delay: 0.2s;
        transform-origin: 50% 50%;
        fill: ${props => props.theme.primary};
    }
`;

const YoutubeIframe = styled(Iframe)`
    /* max-width: 100%; */
    border: none;
    border-radius: ${props => props.theme.borderRadius};
    /* @media (max-width: 1000px) {
        width: 480px;
        height: 270px;
    }
    @media (max-width: 800px) {
        width: 320px;
        height: 180px;
    } */
    width: 320px;
    height: 180px;
    margin: 10px 10px 20px 10px;
`;

const ContentSection = styled(Section)`
    .prev {
        position: absolute;
        top: 50%;
        left: 10px;
    }
    .next {
        position: absolute;
        top: 50%;
        right: 10px;
    }
    @media (min-aspect-ratio: 1 / 1) {
        flex-direction: row;
    }
`;

export const Play: React.FC<PlayProps> = ({training, onExit}) => {
    const backend = useBackend();
    const locale = useLocale();
    const {playExercise, playRest, playWorkout, playTransition} = useSoundPlayers();
    const {step, stepIndex, progress, totalTime, isPaused, play, pause, nextStep, prevStep, changeStep, steps} = usePlaySteps(training);
    const [knownExercises, setKnownExercises] = useState<string[]>([]);
    const isVideoOn = false;
    const [isSoundOn, setSoundOn] = useState(localStorage.getItem('play.sound') === 'true' || localStorage.getItem('play.sound') === null);
    const toggleSound = useCallback(() => {
        localStorage.setItem('play.sound', (!isSoundOn).toString());
        setSoundOn(v => !v);
        amplitude.getInstance().logEvent('click_on_sound_button', {trainingId: training.id, page: 'play_view'});
    }, [isSoundOn, training]);
    const [isSpeechOn, setSpeechOn] = useState(localStorage.getItem('play.speech') === 'true');
    const toggleSpeech = useCallback(() => {
        localStorage.setItem('play.speech', (!isSpeechOn).toString());
        setSpeechOn(v => !v);
        amplitude.getInstance().logEvent('click_on_text_to_speech', {trainingId: training.id, page: 'play_view'});
    }, [isSpeechOn, training]);
    const theme = useTheme();
    const youtubePreviewId = useMemo(
        () => (isVideoOn && step && step.data.exercise.playYoutubeId && !step.data.isRest ? step.data.exercise.playYoutubeId : undefined),
        [isVideoOn, step],
    );
    const isNewExercise = useMemo(() => step && !step.data.exercise.known && knownExercises.indexOf(step.data.exercise.id) === -1, [knownExercises, step]);
    const nextStepName = useMemo(() => {
        const next = steps[stepIndex + 1];
        if (next === undefined) {
            return '';
        }
        if (next.data.isRest) {
            return 'rest';
        }
        return `${next.name} (${next.data.effort || 'custom'})`;
    }, [stepIndex, steps]);
    const nextStepThumbnail = useMemo(() => {
        const next = steps[stepIndex + 1];
        if (next === undefined) {
            return '';
        }
        return `https://img.youtube.com/vi/${next.data.exercise.playYoutubeId}/mqdefault.jpg`;
    }, [stepIndex, steps]);
    const [isExplanations, setIsExplanations] = useState(false);
    const showExplanations = useCallback(() => {
        setIsExplanations(true);
        pause();
        amplitude.getInstance().logEvent('check_exercise_explanation_from_play_view', {trainingId: training.id, page: 'play_view'});
    }, [pause, training]);
    const handleExplanationConfirm = useCallback(async () => {
        setIsExplanations(false);
        play();
        amplitude.getInstance().logEvent('click_on_i_get_it_exercise_explanation_from_play_view', {trainingId: training.id, page: 'play_view'});
        if (!step) {
            return;
        }
        try {
            await backend.knownExercise(step.data.exercise.id);
            setKnownExercises(d => [...d, step.data.exercise.id]);
        } catch {}
        // restart timer
        if (stepIndex === 0) {
            prevStep();
        } else {
            prevStep();
            nextStep();
        }
    }, [backend, nextStep, play, prevStep, step, stepIndex, training]);
    const handleExplanationClose = useCallback(() => {
        setIsExplanations(false);
        play();
    }, [play]);

    const speech = useCallback(
        (text: string) => {
            if (isSpeechOn) {
                const msg = new SpeechSynthesisUtterance();
                msg.lang = locale.locale || 'en-US';
                msg.text = text;
                window.speechSynthesis.speak(msg);
            }
        },
        [isSpeechOn, locale.locale],
    );

    // Handle start countdown
    useEffect(() => {
        if (totalTime === 0 && step) {
            isSoundOn && playExercise();
            speech(step.data.exercise.name + ' ' + step.data.effortSpeech);
            // if (!step.data.exercise.known && knownExercises.indexOf(step.data.exercise.id) === -1) {
            //     // pause();
            // }
        } else if (totalTime < 0) {
            isSoundOn && playTransition();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalTime]);

    // play sound for step transitions
    useEffect(() => {
        if (!step) {
            isSoundOn && playWorkout();
        } else if (step.data.isRest) {
            isSoundOn && playRest();
            speech((locale.locale === 'fr-FR' ? 'Repose-toi ' : 'Rest ') + step.duration + (locale.locale === 'FR' ? 'secondes' : 'seconds'));
        } else if (totalTime > 0) {
            const msg = new SpeechSynthesisUtterance();
            msg.lang = locale.locale || 'en-US';
            speech(step.data.exercise.name + ' ' + step.data.effortSpeech);
            window.speechSynthesis.speak(msg);
            isSoundOn && playExercise();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    const TRANSITION_COUNTDOWN = 3;

    // play countdown at the end of steps
    useEffect(() => {
        if (step && step.duration && step.duration - progress <= TRANSITION_COUNTDOWN && step.duration - progress >= 0) {
            isSoundOn && playTransition();
        }
    }, [isSoundOn, playTransition, progress, step]);

    return (
        <>
            {!step && <FinishMode isOpen={true} trainingId={training.id!} totalTime={totalTime} onExit={onExit} />}
            {step &&
                totalTime >= 0 &&
                (isExplanations ? (
                    <ExplanationsMode
                        isOpen={isPaused}
                        exercise={step.data.exercise}
                        totalTime={totalTime}
                        workoutId={training.id!}
                        stepIndex={stepIndex}
                        stepTitle={step.data.exercise.name}
                        setTitle={step.data.setTitle}
                        onConfirm={handleExplanationConfirm}
                        onClose={handleExplanationClose}
                    />
                ) : (
                    <PauseMode
                        isOpen={isPaused}
                        isSoundOn={isSoundOn}
                        exercise={step.data.exercise}
                        totalTime={totalTime}
                        workoutId={training.id!}
                        stepIndex={stepIndex}
                        stepTitle={step.data.exercise.name}
                        setTitle={step.data.setTitle}
                        onContinue={play}
                        onExit={onExit}
                        onToggleSound={setSoundOn}
                    />
                ))}
            <MainSection direction="y" isCountdown={totalTime < 0}>
                {step && totalTime >= 0 ? (
                    <>
                        <Topbar align="center" distribute="start" spacing={20}>
                            <TimerLabel>{formatSeconds(Math.floor(totalTime))}</TimerLabel>
                            <SetLabel style={{marginRight: 'auto'}}>{step.data.setTitle}</SetLabel>
                            <MenuIcon onClick={toggleSpeech}>{isSpeechOn ? <SpeechOn /> : <SpeechOff />}</MenuIcon>
                            <MenuIcon onClick={toggleSound}>{isSoundOn ? <SoundOn /> : <SoundOff />}</MenuIcon>
                        </Topbar>
                        <NextExerciseLabel>
                            <img src={nextStepThumbnail} alt='' />
                            <span>
                                {nextStepName &&
                                    (nextStepName === 'rest' ? (
                                        <>
                                            <FormattedMessage id="app.play.restFor" /> {steps[stepIndex + 1].duration}s
                                        </>
                                    ) : (
                                        <>{step.data.isRest ? `${step.name} (${step.data.effort || 'custom'})` : nextStepName}</>
                                    ))}
                            </span>
                        </NextExerciseLabel>
                        <Section direction="y" align="center" distribute="center" grow={1}>
                            {step.data.isRest ? (
                                <>
                                    <ExerciseLabel>
                                        <FormattedMessage id="app.play.rest" />
                                    </ExerciseLabel>
                                    <Section align="center" distribute="between" width="100%" padding="0 20px">
                                        <MenuIcon onClick={prevStep}>
                                            <PreviousIcon />
                                        </MenuIcon>
                                        <TimerSvg id="svg" viewBox="0 0 100 100">
                                            <circle fill="none" stroke={theme.subBackground} strokeWidth="5" cx="50" cy="50" r="40" />
                                            <path
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeWidth="5"
                                                stroke={step.duration && step.duration - progress < TRANSITION_COUNTDOWN ? theme.error : theme.rest}
                                                strokeDasharray={`${250.2 - (progress / step.duration!) * 250.2},250.2`}
                                                d="M50 10
            a 40 40 0 0 1 0 80
            a 40 40 0 0 1 0 -80"
                                            />
                                            <text x="50" y="50" textAnchor="middle" dy="7" fontSize="20">
                                                {formatSeconds(Math.min(step.duration! - Math.floor(progress), step.duration!))}
                                            </text>
                                        </TimerSvg>
                                        <MenuIcon onClick={nextStep}>
                                            <NextIcon />
                                        </MenuIcon>
                                    </Section>
                                    {/* <EffortLabel>{formatSeconds(Math.min(step.duration! - Math.floor(progress), step.duration!))}</EffortLabel> */}
                                </>
                            ) : (
                                <ContentSection direction="y" align="center">
                                    <MenuIcon className='prev' onClick={prevStep}>
                                        <PreviousIcon />
                                    </MenuIcon>
                                    <MenuIcon className='next' onClick={nextStep}>
                                        <NextIcon />
                                    </MenuIcon>
                                    {step.data.exercise.playYoutubeId && <YoutubeIframe
                                        title="Exercise Preview"
                                        width="640"
                                        height="360"
                                        url={`https://www.youtube.com/embed/${step.data.exercise.playYoutubeId}?autoplay=1`}
                                        allowFullScreen
                                    ></YoutubeIframe>}
                                    <Section direction="y" align="center" shrink={1}>
                                        <ExerciseLabel>{step.name}</ExerciseLabel>
                                        {step.duration !== undefined ? (
                                            <TimerSvg id="svg" viewBox="0 0 100 100">
                                                <circle fill="none" stroke={theme.subBackground} strokeWidth="5" cx="50" cy="50" r="40" />
                                                <path
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeWidth="5"
                                                    stroke={step.duration && step.duration - progress < TRANSITION_COUNTDOWN ? theme.error : theme.primary}
                                                    strokeDasharray={`${(progress / step.duration) * 250.2},250.2`}
                                                    d="M50 10
        a 40 40 0 0 1 0 80
        a 40 40 0 0 1 0 -80"
                                                />
                                                <text x="50" y="50" textAnchor="middle" dy="7" fontSize="20">
                                                    {formatSeconds(Math.min(step.duration, Math.floor(step.duration - progress)))}
                                                </text>
                                            </TimerSvg>
                                        ) : (
                                            <Section direction="y" align="center">
                                                <EffortLabel>{step && step.data.effort}</EffortLabel>
                                                <Check onClick={nextStep}>
                                                    <circle className="checkCircle" cx="60" cy="60" r="60" />
                                                    <path className="check" d="M 35,60 L 55,80 L 85,50" />
                                                </Check>
                                            </Section>
                                        )}
                                    </Section>
                                </ContentSection>
                            )}
                        </Section>
                        <BottomBar spacing={10} align="center" maxWidth="100%">
                            <MenuIcon style={{marginRight: 'auto'}}>
                                <PauseIcon onClick={ () => {
                                        pause();
                                        amplitude.getInstance().logEvent('click_on_pause_training', {trainingId: training.id, page: 'play_view'});
                                    }}
                                />
                            </MenuIcon>
                            <PlayProgressBar
                                training={training}
                                setIndex={step.data.setIndex}
                                stepIndex={step.data.stepIndex}
                                hasVideo={youtubePreviewId !== undefined}
                                onSelect={changeStep}
                            />
                            <MenuIcon color={isNewExercise ? theme.explanation : theme.foreground}>
                                <ExplanationsIcon onClick={showExplanations} />
                            </MenuIcon>
                        </BottomBar>
                    </>
                ) : (
                    <Section direction="y" align="center" distribute="center" grow={1} padding="40px">
                        <CountDownLabel>{-1 * totalTime}</CountDownLabel>
                        <CountDownSubtitle>
                            <FormattedMessage id="app.play.getReadyFor" />
                        </CountDownSubtitle>
                        {step ? (
                            <CountDownExercise>
                                {step.data.exercise.name} ({step.data.effort || 'custom'})
                            </CountDownExercise>
                        ) : (
                            <></>
                        )}
                    </Section>
                )}
            </MainSection>
        </>
    );
};
