import React from 'react';
import styled from '@emotion/styled';
import {VLayout} from '../Layout';

type InputStringChangeEvent =
    | string
    | React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
export type InputStringChangeCallback = (e: InputStringChangeEvent) => void;

export const useInputValue = (initialValue = '', onChange?: (value: string) => void) => {
    const [value, setValue] = React.useState(initialValue);
    return [
        value,
        (e: InputStringChangeEvent) => {
            const newValue = typeof e === 'string' ? (e as string) : (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
            setValue(newValue);
            onChange && onChange(newValue);
        },
    ] as const;
};

const MainLayout = styled(VLayout)`
    padding-bottom: 10px;
`;

export const Label = styled.div`
    color: ${props => props.theme.subForeground};
    font-size: 0.9em;
    user-select: none;
    padding-bottom: 10px;
`;

export const Input = styled.input`
    background-color: ${props => props.theme.input};
    font-family: ${props => props.theme.body};
    font-size: 1.1em;
    padding: 10px 15px;
    flex-shrink: 0;
    color: ${props => props.theme.foreground};
    border-radius: ${props => props.theme.borderRadius};
    border: none;
    outline: none;
    transition: background-color 0.2s;
    &:focus {
        background-color: ${props => props.theme.inputActive};
    }
    &::placeholder {
        color: ${props => props.theme.subForeground};
        opacity: 1; /* Firefox */
    }
`;

export const TextArea = styled.textarea`
    background-color: ${props => props.theme.input};
    font-family: ${props => props.theme.body};
    font-size: 0.9em;
    padding: 10px 15px;
    box-sizing: border-box;
    color: ${props => props.theme.foreground};
    border-radius: ${props => props.theme.borderRadius};
    border: none;
    outline: none;
    transition: background-color 0.2s;
    width: 100%;
    min-height: 100px;
    resize: vertical;
    &:focus {
        background-color: ${props => props.theme.inputActive};
    }
    &::placeholder {
        color: ${props => props.theme.subForeground};
        opacity: 1; /* Firefox */
    }
`;

export const InvisibleInput = styled.input`
    color: ${props => props.theme.foreground};
    font-size: 1em;
    border: none;
    outline: none;
    background-color: transparent;
    transition: all 0.2s;
    border-radius: ${props => props.theme.borderRadius};
    padding: 10px;
    &::placeholder {
        color: ${props => props.theme.subForeground};
        opacity: 1; /* Firefox */
    }
    border: 2px solid transparent;
    &:hover {
        border: 2px solid ${props => props.theme.subBackground};
    }
    &:focus {
        border: 2px solid ${props => props.theme.primary};
    }
`;

export const InvisibleTextarea = styled.textarea<{height?: number}>`
    flex: ${props => (props.height ? 'initial' : 1)};
    font-family: ${props => props.theme.body};
    color: ${props => props.theme.foreground};
    height: ${props => `${props.height}px` || 'auto'};
    min-height: 100px;
    font-size: 1em;
    border: none;
    outline: none;
    resize: none;
    transition: all 0.2s;
    border-radius: ${props => props.theme.borderRadius};
    padding: 10px;
    background-color: transparent;
    &::placeholder {
        color: ${props => props.theme.subForeground};
        opacity: 1;
    }
    border: 2px solid transparent;
    &:hover {
        border: 2px solid ${props => props.theme.subBackground};
    }
    &:focus {
        border: 2px solid ${props => props.theme.primary};
    }
`;

interface TextInputProps {
    type?: string;
    label?: React.ReactNode;
    className?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
}

export const TextInput: React.FC<TextInputProps> = ({type = 'text', label, className, value, onChange, onBlur}) => {
    return (
        <MainLayout className={className}>
            {label && <Label>{label}</Label>}
            <Input type={type} value={value} onChange={onChange} onBlur={onBlur} />
        </MainLayout>
    );
};
