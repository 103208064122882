import React from 'react';
import Editor from '@draft-js-plugins/editor';
import styled from '@emotion/styled';
import createImagePlugin from '@draft-js-plugins/image';
import createVideoPlugin from '@draft-js-plugins/video';
import {mdToDraftjs, draftjsToMd} from 'draftjs-md-converter';
import {useState, useCallback, useRef} from 'react';
import {EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js';
import type {DraftEditorCommand} from 'draft-js';

import {Section} from '../../ui/Section';
import {LinkButton} from './LinkButton';
import {ImageButton} from './ImageButton';
import {VideoButton} from './VideoButton';
import {RichTextButton} from './RichTextButton';
import {transparentize} from 'polished';
import {usePopoverState} from '../Popover';
import {createLinkPlugin} from './LinkPlugin';

const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();
const linkPlugin = createLinkPlugin();

interface RichTextProps {
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    //
    className?: string;
}

const ToolbarSection = styled(Section)`
    flex-wrap: wrap;
    background-color: ${props => props.theme.background};
`;

const EditorLayout = styled(Section)`
    /* height: 430px; */
    box-sizing: border-box;
    .DraftEditor-root {
        color: ${props => props.theme.subForeground};
        min-height: 150px;
        padding: 10px;
        overflow: auto;
        font-size: 0.8em;
        white-space: pre-wrap;
        border-radius: ${props => props.theme.borderRadius};
        background-color: ${props => props.theme.subBackground};
        h1 {
            font-size: 1.3em;
        }
        img {
            display: flex;
            margin: 10px auto;
            max-width: 100%;
            border-radius: ${props => props.theme.borderRadius};
        }
        strong,
        [style='font-weight: bold;'] {
            font-weight: normal;
            font-family: ${props => props.theme.title};
            color: ${props => props.theme.foreground};
        }
        h1 {
            color: ${props => props.theme.foreground};
            padding: 10px 0;
        }
        p {
            margin: 0 0 5px 0;
            line-height: 28px;
        }
        /* ul,
        ol {
            line-height: 22px;
        } */
        iframe {
            margin: auto;
            border-radius: 10px;
        }
        blockquote {
            color: ${props => props.theme.primary};
            background-color: ${props => transparentize(0.8, props.theme.primary)};
            padding: 10px;
            border-radius: 10px;
            margin: 0;
            /* border-left: 2px solid ${props => props.theme.subBackground};
            margin-left: 10px;
            padding-left: 10px; */
        }
        a {
            color: ${props => props.theme.primary};
        }
        .info {
            color: ${props => props.theme.primary};
            background-color: ${props => transparentize(0.8, props.theme.primary)};
            padding: 10px;
            border-radius: 10px;
        }
    }
`;

export const RichText: React.FC<RichTextProps> = ({value, onChange, onBlur, className}) => {
    const [editorState, setEditorState] = useState<EditorState>(() => EditorState.createWithContent(convertFromRaw(mdToDraftjs(value))));
    const imgPopover = usePopoverState();
    const videoPopover = usePopoverState();
    const linkPopover = usePopoverState();

    const handleEditProperty = useCallback(
        (editorState: EditorState) => {
            setEditorState(editorState);
            const rawObject = convertToRaw(editorState.getCurrentContent());
            onChange(draftjsToMd(rawObject));
        },
        [onChange],
    );
    const handleKeyCommand = useCallback(
        (command: DraftEditorCommand) => {
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
                handleEditProperty(newState);
                return 'handled';
            }
            return 'not-handled';
        },
        [editorState, handleEditProperty],
    );
    const handleRefocus = useCallback(() => {
        if (!imgPopover.isOpen && !linkPopover.isOpen && !videoPopover.isOpen) {
            editorRef.current?.focus();
        }
    }, [imgPopover.isOpen, linkPopover.isOpen, videoPopover.isOpen]);
    const editorRef = useRef<Editor>(null);
    return (
        <EditorLayout
            className={className}
            direction="y"
            onClick={handleRefocus}
        >
            <ToolbarSection className="toolbar" direction="x" spacing={10} sticky={true}>
                <RichTextButton type="block" icon="heading" tag="header-one" editorState={editorState} onStateChange={setEditorState} />
                <RichTextButton type="style" icon="bold" tag="BOLD" editorState={editorState} onStateChange={setEditorState} />
                <RichTextButton type="style" icon="italic" tag="ITALIC" editorState={editorState} onStateChange={setEditorState} />
                <RichTextButton type="style" icon="underline" tag="UNDERLINE" editorState={editorState} onStateChange={setEditorState} />
                <RichTextButton type="style" icon="strike" tag="STRIKETHROUGH" editorState={editorState} onStateChange={setEditorState} />
                <RichTextButton type="block" icon="ul" tag="unordered-list-item" editorState={editorState} onStateChange={setEditorState} />
                <RichTextButton type="block" icon="ol" tag="ordered-list-item" editorState={editorState} onStateChange={setEditorState} />
                <ImageButton popoverState={imgPopover} imagePlugin={imagePlugin} editorState={editorState} onStateChange={setEditorState} />
                <VideoButton popoverState={videoPopover} videoPlugin={videoPlugin} editorState={editorState} onStateChange={setEditorState} />
                <LinkButton popoverState={linkPopover} linkPlugin={linkPlugin} editorState={editorState} onStateChange={setEditorState} />
            </ToolbarSection>
            <Editor
                editorState={editorState}
                onChange={handleEditProperty}
                onBlur={onBlur}
                handleKeyCommand={handleKeyCommand}
                ref={editorRef}
                plugins={[imagePlugin, linkPlugin, videoPlugin]}
            />
        </EditorLayout>
    );
};
