import styled from '@emotion/styled';

export const SelectExerciseButton = styled.svg<{disabled?: boolean}>`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '0.5' : '1')};
    pointer-events: ${props => (props.disabled ? 'none' : undefined)};
    transition: 0.3s all;
    circle {
        transition: 0.3s all;
        fill: ${props => props.theme.foreground};
    }
    path {
        transition: 0.3s all;
        fill: ${props => props.theme.background};
    }
    &:hover {
        transform: scale(1.1);
        circle {
            fill: ${props => props.theme.primary};
        }
        path {
            fill: ${props => props.theme.primaryForeground};
        }
    }
`;
