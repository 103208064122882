import styled from '@emotion/styled';
import React from 'react';
import {ZLayout} from '../../components/Layout';

const ThumbnailLayout = styled(ZLayout)<{width?: number}>`
    width: 90px;
    height: ${(90 * 9) / 16}px;
    @media (max-width: 500px) {
        width: 70px;
        height: ${(70 * 9) / 16}px;
    }
`;

const Thumbnail = styled.img<{width?: number}>`
    width: 90px;
    height: ${(90 * 9) / 16}px;
    object-fit: cover;
    border-radius: 10px;
    @media (max-width: 500px) {
        width: 70px;
        height: ${(70 * 9) / 16}px;
    }
`;

const DefaultThumbnail = styled.div<{width?: number}>`
    width: 90px;
    height: ${(90 * 9) / 16}px;
    background-color: ${props => props.theme.subBackground};
    border-radius: 10px;
    @media (max-width: 500px) {
        width: 70px;
        height: ${(70 * 9) / 16}px;
    }
`;

interface SelectQuestTrainingThumbnailProps {
    thumbnail?: string;
    width?: number;
}

export const SelectQuestTrainingThumbnail: React.FC<SelectQuestTrainingThumbnailProps> = ({thumbnail, width}) => {
    return (
        <div className="thumbnail">
            <ThumbnailLayout width={width}>
                {thumbnail ? (
                    <Thumbnail width={width} src={thumbnail} alt="" />
                ) : (
                    <DefaultThumbnail width={width} />
                )}
            </ThumbnailLayout>
        </div>
    );
};
