import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {usePopper} from 'react-popper';
import {createPortal} from 'react-dom';
import {useClickAway} from 'react-use';
import {useRef, useMemo, useState, useEffect, useCallback, memo} from 'react';
import type PopperJS from '@popperjs/core';
import type {FC, ReactNode, SetStateAction, Dispatch} from 'react';

export interface PopoverState {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    handleToggle: (value: boolean) => void;
}

interface PopoverProps {
    node: ReactNode;
    //
    mode?: 'click' | 'hover';
    isOpen: boolean;
    padding?: string;
    position?: {x: number; y: number};
    placement?: PopperJS.Placement;
    portalTarget?: HTMLElement;
    hoverOpenDelay?: number;
    hoverCloseDelay?: number;
    children: ReactNode;
    ///
    onToggle: (isOpen: boolean) => void;
}

const Element = styled.div`
    display: flex;
    & > div {
        cursor: pointer;
        display: flex;
    }
`;

const appear = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const disappear = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
`;

const Wrapper = styled.div<{
    mode: 'click' | 'hover';
    padding: string;
    hoverOpenDelay: number;
    hoverCloseDelay: number;
}>`
    padding: ${props => props.padding};
    z-index: 1;
    animation: ${appear} ${props => props.hoverOpenDelay / 1000}s;
    &.disappear {
        animation: ${disappear} ${props => (props.mode === 'hover' ? props.hoverOpenDelay : 0) / 1000}s;
    }
`;

export const usePopoverState = (): PopoverState => {
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = useCallback((value: boolean) => setIsOpen(value), []);
    return {isOpen, setIsOpen, handleToggle};
};

const virtualReference = (position: {x: number; y: number}) => {
    return {
        getBoundingClientRect() {
            return {
                top: position.y,
                left: position.x,
                right: 0,
                width: 0,
                bottom: 0,
                height: 0,
            } as DOMRect;
        },
    };
};

export const Popover: FC<PopoverProps> = memo(
    ({
        mode = 'click',
        node,
        isOpen,
        padding = '10px',
        position,
        placement = 'bottom',
        portalTarget,
        hoverOpenDelay = 300,
        hoverCloseDelay = 300,
        //
        onToggle,
        //
        children,
    }) => {
        const clickRef = useRef<HTMLDivElement>(null);
        const timeoutId = useRef<NodeJS.Timeout | null>(null);

        const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
        const [referenceRef, setReferenceRef] = useState<HTMLDivElement | null>(null);

        const reference = useMemo(() => (position ? virtualReference(position) : referenceRef), [position, referenceRef]);
        const {styles, attributes} = usePopper(reference, popperRef, {
            placement,
        });

        const [disappear, setDisappear] = useState(false);
        const [isOverPopover, setIsOverPopover] = useState(false);
        const [isOverElement, setIsOverElement] = useState(false);

        useClickAway(clickRef, () => {
            if (mode === 'click' && !isOverElement) {
                onToggle(false);
            }
        });

        const handleMouseEnterElement = useCallback(() => {
            setIsOverElement(true);
            if (mode === 'hover') {
                onToggle(true);
            }
        }, [mode, onToggle]);
        const handleMouseLeaveElement = useCallback(() => setIsOverElement(false), []);
        const handleMouseEnterPopover = useCallback(() => setIsOverPopover(true), []);
        const handleMouseLeavePopover = useCallback(() => setIsOverPopover(false), []);
        const handleClick = useCallback(() => {
            if (mode === 'click') {
                onToggle(!isOpen);
            }
        }, [mode, isOpen, onToggle]);

        useEffect(
            () => () => {
                if (timeoutId.current !== null) {
                    clearTimeout(timeoutId.current);
                }
            },
            [],
        );

        useEffect(() => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
            if (!isOverElement && !isOverPopover) {
                setDisappear(true);
                timeoutId.current = setTimeout(() => {
                    if (mode === 'hover' && isOverElement === false && isOverPopover === false) {
                        onToggle(false);
                        setDisappear(false);
                    }
                }, hoverCloseDelay);
            }
        }, [hoverCloseDelay, isOverElement, isOverPopover, mode, onToggle]);

        if (!isOpen && node === null) {
            return null;
        }

        return (
            <Element
                ref={setReferenceRef}
                //
                onMouseEnter={handleMouseEnterElement}
                onMouseLeave={handleMouseLeaveElement}
            >
                <div onClick={handleClick}>{node}</div>
                {isOpen &&
                    (portalTarget ? (
                        createPortal(
                            <div ref={clickRef}>
                                <Wrapper
                                    ref={setPopperRef}
                                    mode={mode}
                                    style={styles.popper}
                                    padding={padding}
                                    className={disappear ? 'disappear' : ''}
                                    data-placement={placement}
                                    hoverOpenDelay={hoverOpenDelay}
                                    hoverCloseDelay={hoverCloseDelay}
                                    //
                                    onMouseEnter={handleMouseEnterPopover}
                                    onMouseLeave={handleMouseLeavePopover}
                                    {...attributes.popper}
                                >
                                    {children}
                                </Wrapper>
                            </div>,
                            portalTarget,
                        )
                    ) : (
                        <div ref={clickRef}>
                            <Wrapper
                                ref={setPopperRef}
                                mode={mode}
                                style={styles.popper}
                                padding={padding}
                                className={disappear ? 'disappear' : ''}
                                data-placement={placement}
                                hoverOpenDelay={hoverOpenDelay}
                                hoverCloseDelay={hoverCloseDelay}
                                //
                                onMouseEnter={handleMouseEnterPopover}
                                onMouseLeave={handleMouseLeavePopover}
                                {...attributes.popper}
                            >
                                {children}
                            </Wrapper>
                        </div>
                    ))}
            </Element>
        );
    },
);
