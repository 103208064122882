import React from 'react';
import styled from '@emotion/styled';
import {useTheme} from '@emotion/react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useLocation} from 'react-router-dom';

import {Logo} from '../logo/Logo';
import {Input} from '../../widgets/Input';
import {notify} from '../../components/toast/Toast';
import {Section} from '../../ui/Section';
import {useGoHome} from '../appRouter/useGoHome';
import {useBackend} from '../backend/useBackend';
import {TextLayout} from '../../widgets/Text';
import {DotsSpinner} from '../../widgets/Spinner/DotSpinner';
import {ErrorMessage} from './ErrorMessage';
import {useInputValue} from '../../components/inputs/Input';
import {PrimaryButton, InlineButton} from '../../components/Button';
import { useTrackers } from '../tracking/useTrackers';
import amplitude from 'amplitude-js';

const LogoSection = styled(Section)`
    cursor: pointer;
`;

export const ResetPassword: React.FC = () => {
    const theme = useTheme();
    const backend = useBackend();
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const [password, setPassword] = useInputValue('');
    const [passwordConfirmation, setPasswordConfirmation] = useInputValue('');
    const location = useLocation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>();
    const trainingId = new URLSearchParams(useLocation().search).get('training');
    const handleResetPassword = React.useCallback(async () => {
        setIsLoading(true);
        setError(undefined);
        try {
            if (password !== passwordConfirmation) {
                throw new Error('passwordMismatch');
            } else if (password.length < 6) {
                throw new Error('passwordTooShort');
            }
            await backend.resetPassword(password, passwordConfirmation, location.search);
            navigate('/login' + (trainingId ? `?training=${trainingId}` : ''));
            notify(formatMessage({id: 'app.auth.resetPassword.passwordSaved'}));
            amplitude.getInstance().logEvent('reset_password', {page: 'auth'});
        } catch (err) {
            setError((err as Error).message);
            setIsLoading(false);
        }
    }, [backend, formatMessage, navigate, location.search, password, passwordConfirmation, trainingId]);
    const handleLogin = React.useCallback(() => {
        navigate('/login' + (trainingId ? `?training=${trainingId}` : ''));
    }, [navigate, trainingId]);
    const goHome = useGoHome();
    useTrackers();
    return (
        <Section width="100%" height="100%" align="center" distribute="center">
            <Section direction="y" align="stretch" maxWidth="400px" width="100%" spacing={20} padding="20px">
                <LogoSection direction="y" align="center" spacing={20} onClick={goHome}>
                    <Logo width={100} />
                    <TextLayout weight="bold" size={2}>
                        Training Quest
                    </TextLayout>
                </LogoSection>
                <TextLayout color={theme.subForeground}>
                    <FormattedMessage id="app.auth.resetPassword.subtitle" />
                </TextLayout>
                <Input type="password" placeholder={formatMessage({id: 'app.auth.resetPassword.password'})} value={password} onChange={setPassword} />
                <Input
                    type="password"
                    placeholder={formatMessage({id: 'app.auth.resetPassword.passwordConfirmation'})}
                    value={passwordConfirmation}
                    onChange={setPasswordConfirmation}
                />
                <PrimaryButton style={{width: '100%'}} disabled={isLoading} onClick={handleResetPassword}>
                    {isLoading ? <DotsSpinner color={theme.primaryForeground} size={16} /> : <FormattedMessage id="app.auth.resetPassword.ResetPassword" />}
                </PrimaryButton>
                <InlineButton style={{width: '100%'}} onClick={handleLogin}>
                    <FormattedMessage id="app.auth.resetPassword.backToLogin" />
                </InlineButton>
                {error && (
                    <ErrorMessage>
                        <FormattedMessage id={`app.error.${error}`} />
                    </ErrorMessage>
                )}
            </Section>
        </Section>
    );
};
