import {useTheme} from '@emotion/react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Section} from '../ui/Section';
import {TQSpinner} from './Spinner/TQSpinner';
import {TextLayout} from './Text';

export const LoadingPage: React.FC = () => {
    const theme = useTheme();
    return (
        <Section direction="y" spacing={20} grow={1} align="center" distribute="center">
            <TQSpinner size={30} color={theme.primary} />
            <TextLayout size={1.2} weight="bold" color={theme.subForeground}>
                <FormattedMessage id="app.loading" />
            </TextLayout>
        </Section>
    );
};
