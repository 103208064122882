import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {Section} from '../../ui/Section';
import {useBackend} from '../backend/useBackend';
import {useLoadData} from '../backend/useLoadData';
import { SelectQuestTrainingsRow } from './SelectQuestTrainingsRow';
import { TrainingOrderBy, TrainingRowModel } from '../backend/models/TrainingModel';

interface SelectQuestTrainingsProps {
    isOpen: boolean;
    onSelect?: (training: TrainingRowModel) => void;
}

const appear = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const TrainingsSection = styled(Section)`
    animation: ${appear} 0.3s;
`;

export const SelectQuestTrainings: React.FC<SelectQuestTrainingsProps> = ({isOpen, onSelect}) => {
    const backend = useBackend();

    const loadData = useLoadData<TrainingRowModel>((search, page, perPage, orderBy) => {
        return backend.searchTrainings({
            search,
            page,
            perPage,
            authorId: backend.authUser?.id,
            orderBy: orderBy as TrainingOrderBy,
        });
    });

    const {data, loadingState} = loadData;

    return (
        <TrainingsSection direction="y" spacing={10} height="100%" width="100%" padding="20px">
            <Section grow={1} direction="y" scroll={true}>
                {(loadingState === 'loading' || loadingState === 'loaded') &&
                    data
                        .map((tr, i) => <SelectQuestTrainingsRow training={tr} selected={false} onSelect={onSelect} />
                )}
            </Section>
        </TrainingsSection>
    );
};
