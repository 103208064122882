import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from '../../ui/Icon';
import { keyframes, useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { useLightboxState } from '../../ui/Lightbox/useLightboxState';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { CreateTrainingDialog } from '../trainings/CreateTrainingDialog';
import { LightboxCard } from '../../widgets/LightboxCard';
import { StartStopwatchDialog } from './StartStopwatchDialog';
import { CreateActivityDialog } from './CreateActivityDialog';

const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.6;
    }
`;

const Overlay = styled.div`
  animation: ${appear} 0.1s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ToolsButtonIcon = styled(Icon)<{tapToInstallPresence: boolean, reloadAppPresence: boolean}>`
  cursor: pointer;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  right: 30px;
  bottom: 75px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
  @media (max-width: 600px) {
    right: 20px;
    bottom: ${props => (props.tapToInstallPresence && props.reloadAppPresence) ? `calc(5.8em + 108px)` : ((props.tapToInstallPresence || props.reloadAppPresence) ? `calc(5.8em + 90px)` : `5.8em`) };
  }
`;

const ToolsIcons = styled.div<{tapToInstallPresence: boolean, reloadAppPresence: boolean}>`
  animation: ${appear} 0.2s;
  cursor: pointer;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  right: 36px;
  bottom: 36px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  @media (max-width: 600px) {
    right: 24px;
    bottom: ${props => (props.tapToInstallPresence && props.reloadAppPresence) ? `calc(5.8em + 108px)` : ((props.tapToInstallPresence || props.reloadAppPresence) ? `calc(5.8em + 90px)` : `5.8em`) };
  }
  .toolGroup {
    display: flex;
    align-items: center;
    justify-content: center
  }
`;

const ToolButtonIcon = styled(Icon)`
  margin-top: 24px;
`;

const ToolButtonText = styled.span`
  margin-right: 12px;
  margin-top: 18px;
  padding: 5px 10px;
  color: #EBEBEB;
  background-color: #21E190;
  border-radius: 5px;
`;

interface ToolsProps {
  displayTools: boolean;
}

export const Tools: React.FC<ToolsProps> = ({ displayTools }) => {

  const theme = useTheme();
  const [showTools, setShowTools] = useState(displayTools) || false;
  const createTrainingLightBox = useLightboxState();
  const startStopwatchLightBox = useLightboxState();
  const createActivityLightBox = useLightboxState();

  const tapToInstallPresence = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent) && !window.matchMedia('(display-mode: standalone)').matches;
  };

  const reloadAppPresence = () => {
    return localStorage.getItem('version') === 'outdated'
  }

  const handleCreateTraining = useCallback(() => {
    createTrainingLightBox.open();
  }, [createTrainingLightBox]);

  const handleStartStopwatch = useCallback(() => {
    startStopwatchLightBox.open();
  }, [startStopwatchLightBox]);

  const handleCreateActivity = useCallback(() => {
    createActivityLightBox.open();
  }, [createActivityLightBox]);

  return (
    <>
      {!showTools &&
        <ToolsButtonIcon
          color={theme.primary}
          name='toolsButton'
          onClick={() => setShowTools(true)}
          tapToInstallPresence={tapToInstallPresence()}
          reloadAppPresence={reloadAppPresence()}
        />
      }
      {showTools && (
        <>
        <Overlay onClick={() => setShowTools(false)}>
            <ToolsIcons tapToInstallPresence={tapToInstallPresence()} reloadAppPresence={reloadAppPresence()}>
              <div className="toolGroup" onClick={handleCreateActivity}>
                <ToolButtonText>
                  <FormattedMessage id="app.tools.newActivity" />
                </ToolButtonText>
                <ToolButtonIcon name='newActivity' />
              </div>
              <div className="toolGroup" onClick={handleCreateTraining}>
                <ToolButtonText>
                  <FormattedMessage id="app.tools.newTraining" />
                </ToolButtonText>
                <ToolButtonIcon name='newTraining' />
              </div>
              <div className="toolGroup" onClick={handleStartStopwatch}>
                <ToolButtonText>
                  <FormattedMessage id="app.tools.stopwatch" />
                </ToolButtonText>
                <ToolButtonIcon name='stopwatch' />
              </div>
            </ToolsIcons>
          </Overlay>
        </>
      )}
      <Lightbox isOpen={createTrainingLightBox.isOpen} onRequestClose={createTrainingLightBox.close}>
          <LightboxCard isOpen={createTrainingLightBox.isOpen} maxWidth="90%">
              <CreateTrainingDialog onClose={createTrainingLightBox.close} />
          </LightboxCard>
      </Lightbox>
      <Lightbox isOpen={startStopwatchLightBox.isOpen} onRequestClose={startStopwatchLightBox.close}>
          <LightboxCard isOpen={startStopwatchLightBox.isOpen} maxWidth="90%">
              <StartStopwatchDialog onClose={startStopwatchLightBox.close} />
          </LightboxCard>
      </Lightbox>
      <Lightbox isOpen={createActivityLightBox.isOpen} onRequestClose={createActivityLightBox.close}>
          <LightboxCard isOpen={createActivityLightBox.isOpen} maxWidth="90%">
              <CreateActivityDialog onClose={createActivityLightBox.close} />
          </LightboxCard>
      </Lightbox>
    </>
  )
}
