import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Section} from '../../ui/Section';
import {TextLayout} from '../../widgets/Text';
import {PrimaryButton} from '../../widgets/Button';
import amplitude from 'amplitude-js';
import { Spacer } from '../../components/Layout';
import { notify } from '../../components/toast/Toast';

export const StartStopwatchDialog: React.FC<{onClose: () => void}> = ({onClose}) => {
    const intl = useIntl();
    return (
        <Section direction="y" padding="20px" spacing={20} width="100%">
            <TextLayout size={1.5} weight="bold" margin="0 auto" padding="0 40px">
                <FormattedMessage id="app.tools.startStopwatchTitle" />
            </TextLayout>
            <FormattedMessage id="app.tools.startStopwatchSubtitle" />
            <Spacer />
            <Section distribute="center">
                <PrimaryButton
                    onClick={() => {
                        amplitude.getInstance().logEvent('upvote_stopwatch', {page: 'tools'});
                        notify(intl.formatMessage({id: 'app.tools.upvotedStartStopwatch'}));
                        onClose();
                    }}
                ><FormattedMessage id={'app.tools.startStopwatchUpvote'} />
                </PrimaryButton>
            </Section>
        </Section>
    );
};
