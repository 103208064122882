import type {UserRowModel} from './UserModel';
import type {ExerciseModel} from './ExerciseModel';
import type {IntlShape} from 'react-intl';
import {PriceRowModel} from './PriceModel';
import {PaymentModel, PriceInfo} from './PaymentModel';
import {QuestRowModel} from './QuestModel';
import { PracticeSportModel, PracticeTagModel, PracticeTypeModel } from './PracticeModel';

export type TrainingOrderBy = 'RECENTLY_CREATED' | 'RECENTLY_UPDATED' | 'MOST_PLAYED' | 'MOST_BOOKMARKED' | 'MOST_DIFFICULT' | 'LEAST_DIFFICULT';

export type VisibilityType = 'PRIVATE' | 'HIDDEN' | 'PUBLIC';

type EffortType = 'DURATION' | 'REPS' | 'DISTANCE' | 'MAX_REPS' | 'MAX_HOLD' | 'MAX_DISTANCE' | 'CUSTOM';

export interface StepModel {
    id: string;
    exercise: ExerciseModel;
    effortType: EffortType;
    effortValue: number;
    effortLabel: string;
    rest: number;
}

export interface SetModel {
    id: string;
    reps: number;
    steps: StepModel[];
    title: string;
}

export interface TrainingModel {
    id: string;
    title: string;
    thumbnail: string;
    description: string;
    introYoutubeId: string;
    difficulty: number;
    duration: number;
    author: UserRowModel;
    visibility: VisibilityType;
    playsCount: number;
    bookmarksCount: number;
    isBookmarked: boolean;
    sets: SetModel[];
    prices?: PriceRowModel[];
    payment?: PaymentModel;
    paid?: boolean;
    quest?: QuestRowModel;
    type?: PracticeTypeModel;
    sport?: PracticeSportModel;
    tags: PracticeTagModel[];
    availableTags: PracticeTagModel[]
}

export type TrainingRowModel = Pick<
    TrainingModel,
    'id' | 'title' | 'visibility' | 'thumbnail' | 'difficulty' | 'duration' | 'author' | 'playsCount' | 'isBookmarked' | 'bookmarksCount' | 'paid' | 'quest'
> & PriceInfo;

export const formatSeconds = (seconds: number): string => {
    const addZero = (n: number) => (n < 10 ? `0${n}` : n);
    return `${addZero(Math.floor(seconds / 60))}:${addZero(seconds % 60)}`;
};

export const formatEffort = (step: StepModel, intl: IntlShape) => {
    switch (step.effortType) {
        case 'DISTANCE':
            return `${step.effortValue}m`;
        case 'DURATION':
            return `${formatSeconds(step.effortValue)}`;
        case 'REPS':
            return `x${step.effortValue}`;
        case 'MAX_DISTANCE':
            return intl.formatMessage({id: 'app.training.MAX_DISTANCE'});
        case 'MAX_HOLD':
            return intl.formatMessage({id: 'app.training.MAX_HOLD'});
        case 'MAX_REPS':
            return intl.formatMessage({id: 'app.training.MAX_REPS'});
    }
};
export const formatEffortSpeech = (step: StepModel, intl: IntlShape) => {
    switch (step.effortType) {
        case 'DISTANCE':
            return intl.locale === 'fr-FR' ? `sur ${step.effortValue} mètres` : `for ${step.effortValue} meters`;
        case 'DURATION':
            return intl.locale === 'fr-FR' ? `${step.effortValue} secondes` : `for ${step.effortValue} seconds`;
        case 'REPS':
            return intl.locale === 'fr-FR' ? `${step.effortValue} fois` : `${step.effortValue} times`;
        case 'MAX_DISTANCE':
            return intl.locale === 'fr-FR' ? `le plus loin possible` : 'max distance';
        case 'MAX_HOLD':
            return intl.locale === 'fr-FR' ? `le plus longtemps possible` : 'max hold';
        case 'MAX_REPS':
            return intl.locale === 'fr-FR' ? `un maximum de fois` : 'max reps';
    }
};
export const formatEffortMax = (intl: IntlShape, step: StepModel) => {
    switch (step.effortType) {
        case 'DISTANCE':
            return `${step.effortValue} ${intl.formatMessage({id: 'app.training.DISTANCE'})}`;
        case 'DURATION':
            return `${step.effortValue} ${intl.formatMessage({id: 'app.training.DURATION'})}`;
        case 'REPS':
            return `${step.effortValue} ${intl.formatMessage({id: 'app.training.REPS'})}`;
        case 'MAX_DISTANCE':
            return intl.formatMessage({id: 'app.training.MAX_DISTANCE'});
        case 'MAX_HOLD':
            return intl.formatMessage({id: 'app.training.MAX_HOLD'});
        case 'MAX_REPS':
            return intl.formatMessage({id: 'app.training.MAX_REPS'});
    }
};
