import React from 'react';

import styled from '@emotion/styled';
import {Section} from '../../ui/Section';
import {StepRow} from './StepRow';
import {FormattedMessage} from 'react-intl';
import {SetModel} from '../backend/models/TrainingModel';

const Title = styled(Section)`
    align-items: center;
    font-family: ${props => props.theme.title};
    color: ${props => props.theme.foreground};
    font-size: 1.4em;
    input {
        font-family: ${props => props.theme.title};
        color: ${props => props.theme.foreground};
    }
`;

const Empty = styled.div`
    padding: 10px;
    color: ${props => props.theme.subForeground};
`;

interface SetRowProps {
    set: SetModel;
}

export const SetRow: React.FC<SetRowProps> = ({set}) => {
    return (
        <Section direction="y" shrink={0} spacing={20}>
            <Title spacing={0}>
                <div>{set.title || <FormattedMessage id="app.training.newSet" />}</div>
                <div style={{padding: '0 5px'}}>x</div>
                <div>{set.reps}</div>
            </Title>
            {set.steps.length > 0 ? set.steps.map((step, i) => <StepRow key={i} step={step} />) : <Empty>This set is empty.</Empty>}
        </Section>
    );
};
