import React, {useCallback, useState} from 'react';
import styled from '@emotion/styled';

import {EditableTags} from '../../components/Tags';
import {YoutubeLink} from '../../components/YoutubeLink';
import {Input, Label} from '../../components/inputs/Input';
import {FormattedMessage, useIntl} from 'react-intl';
import {notify} from '../../components/toast/Toast';

import {useShortcut} from '../../utils/useShortcut';
import {Section} from '../../ui/Section';
import {Icon} from '../../ui/Icon';
import {TextLayout} from '../../widgets/Text';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {LightboxConfirm} from '../../widgets/LightboxConfirm';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {keyframes, useTheme} from '@emotion/react';
import {ExerciseModel} from '../backend/models/ExerciseModel';
import {useBackend} from '../backend/useBackend';
import {SelectExerciseButton} from './SelectExerciseButton';
import {ErrorButton} from '../../components/Button';
import {LoadingPage} from '../../widgets/LoadingPage';
import {ErrorPage} from '../../widgets/ErrorPage';
import {RichText} from '../../widgets/RichText/RichText';
import amplitude from 'amplitude-js';

const NameInput = styled(Input)`
    font-family: ${props => props.theme.title};
    font-size: 1.5em;
`;

interface ExerciseProps {
    currentExerciseId: string;
    onRemove?: (exercise: ExerciseModel) => void;
    onCancel?: () => void;
    onSelect?: (exercise: ExerciseModel) => void;
    onSave: (exercise: ExerciseModel) => void;
}

const appear = keyframes`
    0% { opacity: 0; transform: translateX(50px); }
    100% { opacity: 1; transform: translateX(0); }
`;

const ExerciseSection = styled(Section)`
    animation: ${appear} 0.3s;
`;

const ExerciseRichText = styled(RichText)`
    .toolbar {
        background-color: ${props => props.theme.cardBackground};
    }
`;

export const Exercise: React.FC<ExerciseProps> = ({currentExerciseId, onRemove, onCancel, onSave, onSelect}) => {
    const intl = useIntl();
    const theme = useTheme();
    const backend = useBackend();
    const [error, setError] = useState('');
    const [exercise, setExercise] = React.useState<ExerciseModel>({
        id: '',
        name: '',
        tags: [],
        playYoutubeId: '',
        explanationYoutubeId: '',
        explanation: '',
        known: false,
    });
    const handleChangeName = React.useCallback((e: { target: { value: any; }; }) => setExercise(ex => ({...ex, name: e.target.value})), []);
    const handleChangeExplanation = React.useCallback((value: any) => setExercise(ex => ({...ex, explanation: value})), []);
    const handleChangeTags = React.useCallback((value: any) => {
        setExercise(ex => ({...ex, tags: value}));
    }, []);
    const handleChangePlayYoutubeId = React.useCallback((id: any) => setExercise(ex => ({...ex, playYoutubeId: id})), []);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const remove = useLightboxState();
    const handleSave = React.useCallback(async () => {
        if (exercise.name === '') {
            onCancel && onCancel();
            return;
        }
        setIsLoading(true);
        try {
            if (exercise.id === '') {
                const newExercise = await backend.createExercise(exercise);
                onSave(newExercise);
                setIsLoading(false);
                return newExercise;
            } else if (exercise) {
                const newExercise = await backend.updateExercise(exercise);
                onSave(newExercise);
                setIsLoading(false);
                amplitude.getInstance().logEvent('edit_exercise', {exerciseId: newExercise.id, page: 'exercise_library'});
                return newExercise;
            }
        } catch (err) {
            notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
            setIsLoading(false);
        }
    }, [backend, exercise, intl, onCancel, onSave]);
    const handleRemove = React.useCallback(async () => {
        try {
            remove.close();
            await backend.deleteExercise(exercise.id);
            onRemove && onRemove(exercise);
        } catch (err) {
            notify(intl.formatMessage({id: `app.error.${(err as Error).message}`}), 'error');
        }
    }, [backend, exercise, intl, onRemove, remove]);
    const handleSelect = useCallback(async () => {
        const newExercise = await handleSave();
        if (newExercise) {
            onSelect?.(newExercise);
        }
    }, [handleSave, onSelect]);
    React.useEffect(() => {
        if (!currentExerciseId) {
            setExercise({
                id: '',
                name: '',
                tags: [],
                playYoutubeId: '',
                explanationYoutubeId: '',
                explanation: '',
                known: false,
            });
            return;
        }
        setIsLoading(true);
        backend
            .getExercise(currentExerciseId)
            .then(setExercise)
            .catch(err => {
                setError((err as Error).message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [backend, currentExerciseId, intl]);
    useShortcut('mod+s', handleSave, true);
    useShortcut('mod+enter', handleSave, true);
    if (error) {
        return <ErrorPage error={error} />;
    }
    if (isLoading) {
        return <LoadingPage />;
    }
    return (
        <ExerciseSection direction="y" height="100%" width="100%" padding="20px" spacing={10}>
            <Section distribute="between">
                <Icon name="back" onClick={handleSave} />
                <TextLayout size={1.2} weight="bold">
                    <FormattedMessage id={`app.exercise.${exercise.id === '' ? 'create' : 'edit'}`} />
                </TextLayout>
                {onSelect ? (
                    <SelectExerciseButton disabled={!exercise.name} width="32" height="32" viewBox="0 0 32 32" fill="none" onClick={handleSelect}>
                        <circle cx="16" cy="16" r="16" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24.8709 11.5987C24.2897 11.0175 23.3473 11.0175 22.7661 11.5987L14.3465 20.0182L9.61057 15.2823C9.02933 14.701 8.08694 14.701 7.5057 15.2823C6.92445 15.8635 6.92445 16.8059 7.5057 17.3871L13.2941 23.1755C13.8754 23.7568 14.8177 23.7568 15.399 23.1755C15.4 23.1745 15.4011 23.1734 15.4022 23.1723L24.8709 13.7036C25.4522 13.1223 25.4522 12.18 24.8709 11.5987Z"
                        />
                    </SelectExerciseButton>
                ) : (
                    <Icon disabled={!exercise.name} name="save" color={theme.primary} onClick={handleSave} />
                )}
            </Section>
            <Section direction="y" grow={1} spacing={10} scroll={true}>
                <NameInput placeholder={intl.formatMessage({id: 'app.exercise.namePlaceholder'})} value={exercise.name} onChange={handleChangeName} />
                <EditableTags value={exercise.tags} onChange={handleChangeTags} />
                <Section direction="y">
                    <Label>
                        <FormattedMessage id="app.exercise.exercisePlaceholder" />
                    </Label>
                    <YoutubeLink value={exercise.playYoutubeId} onChange={handleChangePlayYoutubeId} />
                </Section>
                <ExerciseRichText value={exercise.explanation} onChange={handleChangeExplanation} />
                {exercise.id !== '' && (
                    <ErrorButton onClick={remove.open}>
                        <FormattedMessage id="app.exercise.remove" />
                    </ErrorButton>
                )}
            </Section>
            <Lightbox isOpen={remove.isOpen} onRequestClose={remove.close}>
                <LightboxConfirm
                    isOpen={remove.isOpen}
                    title={`${intl.formatMessage({id: 'app.exercise.confirmRemoveTitle'})}`}
                    message={`${intl.formatMessage({id: 'app.exercise.confirmRemoveMessage'})}`}
                    onConfirm={handleRemove}
                    onCancel={remove.close}
                />
            </Lightbox>
        </ExerciseSection>
    );
};
