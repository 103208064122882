import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {useClickAway} from 'react-use';
import {FormattedMessage} from 'react-intl';
import {Manager, Reference, Popper} from 'react-popper';

import {Section} from '../../ui/Section';
import {Icon, IconName} from '../../ui/Icon';
import {InvisibleButton} from '../../components/Button';
import type {VisibilityType} from '../backend/models/QuestModel';
import amplitude from 'amplitude-js';

interface VisibilityData {
    value: VisibilityType;
    icon: React.ReactNode;
    label: React.ReactNode;
    description: React.ReactNode;
}

const config: VisibilityData[] = [
    {
        value: 'PUBLIC',
        icon: <Icon name="public" />,
        label: <FormattedMessage id="app.quest.public" />,
        description: <FormattedMessage id="app.quest.publicDescription" />,
    },
    {
        value: 'PRIVATE',
        icon: <Icon name="private" />,
        label: <FormattedMessage id="app.quest.private" />,
        description: <FormattedMessage id="app.quest.privateDescription" />,
    },
    {
        value: 'HIDDEN',
        icon: <Icon name="hidden" />,
        label: <FormattedMessage id="app.quest.hidden" />,
        description: <FormattedMessage id="app.quest.hiddenDescription" />,
    },
];

interface QuestVisibilityPopoverProps {
    value: VisibilityType;
    onChange: (value: VisibilityType) => void;
    questId: string;
}

const menuAppear = keyframes`
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
`;

const MenuSection = styled(Section)`
    margin-top: 20px;
    animation: ${menuAppear} 0.3s;
    background-color: ${props => props.theme.cardBackground};
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    border-radius: ${props => props.theme.borderRadius};
    max-width: 500px;
    z-index: 1;
`;

const Title = styled.div`
    font-family: ${props => props.theme.title};
`;

const Description = styled.div`
    font-size: 0.8em;
`;

const ItemButton = styled(InvisibleButton)<{selected: boolean}>`
    color: ${props => (props.selected ? props.theme.primaryForeground : props.theme.subForeground)};
    padding: 10px;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => (props.selected ? props.theme.primary : 'transparent')};
    &:hover {
        transform: ${props => (props.selected ? undefined : 'scale(0.98)')};
        color: ${props => (props.selected ? props.theme.primaryForeground : props.theme.foreground)};
        background-color: ${props => (props.selected ? props.theme.primary : props.theme.subBackground)};
        svg [fill='black'] {
            fill: ${props => (props.selected ? props.theme.primaryForeground : props.theme.foreground)};
        }
        svg [stroke='black'] {
            stroke: ${props => (props.selected ? props.theme.primaryForeground : props.theme.foreground)};
        }
    }
    svg [fill='black'] {
        fill: ${props => (props.selected ? props.theme.primaryForeground : props.theme.subForeground)};
    }
    svg [stroke='black'] {
        stroke: ${props => (props.selected ? props.theme.primaryForeground : props.theme.subForeground)};
    }
`;



export const QuestVisibilityPopover: React.FC<QuestVisibilityPopoverProps> = ({value, onChange, questId}) => {
    const [showPopup, setPopup] = React.useState<boolean>(false);
    const [isOverElement] = React.useState<boolean>(false);
    const menuRef = React.useRef<HTMLDivElement>(null);
    useClickAway(menuRef, () => {
        if (!isOverElement) {
            setPopup(false);
        }
    });
    const handleSelect = React.useCallback(
        (currentValue: VisibilityType) => {
            return () => {
                onChange(currentValue);
                amplitude.getInstance().logEvent('change_quest_privacy', {questId: questId, privacy: currentValue, page: 'edit_quest'});
                setPopup(false);
            };
        },
        [onChange, questId],
    );
    const handleShowPopup = React.useCallback(() => {
        amplitude.getInstance().logEvent('click_edit_quest_privacy_popover', {questId: questId, page: 'edit_quest'});
        setPopup(p => !p);
    }, [questId]);
    return (
        <Section>
            <Manager>
                <Reference>
                    {({ref}) => <Icon ref={ref} name={config.find(c => c.value === value)!.value.toLowerCase() as IconName} onClick={handleShowPopup} />}
                </Reference>
                <Popper placement={'bottom-start'}>
                    {({ref, style, placement}) =>
                        showPopup ? (
                            <div ref={menuRef}>
                                <MenuSection direction="y" spacing={10} padding="10px" ref={ref} style={style} data-placement={placement}>
                                    {config.map((v, i) => (
                                        <ItemButton key={i} onClick={handleSelect(v.value)} selected={v.value === value}>
                                            <Section spacing={10}>
                                                <div style={{width: 50, height: 50, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                                    {v.icon}
                                                </div>
                                                <Section direction="y" grow={1}>
                                                    <Title>{v.label}</Title>
                                                    <Description>{v.description}</Description>
                                                </Section>
                                            </Section>
                                        </ItemButton>
                                    ))}
                                </MenuSection>
                            </div>
                        ) : (
                            ''
                        )
                    }
                </Popper>
            </Manager>
        </Section>
    );
};
