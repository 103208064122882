import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Section} from '../../ui/Section';
import {EmptyState} from '../../components/EmptyState';

import {Icon} from '../../ui/Icon';
import {useBackend} from '../backend/useBackend';
import {SearchInput} from '../../widgets/Input';
import {useLoadData} from '../backend/useLoadData';
import {PracticeSportModel} from '../backend/models/PracticeModel';
import {LoadingSportRow, SportRow} from './SportRow';
import amplitude from 'amplitude-js';

interface SportsProps {
    isOpen: boolean;
    currentSport?: PracticeSportModel;
    onSelect?: (exercise: PracticeSportModel) => void;
    onCancel: () => void;
}

const appear = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const SportsSection = styled(Section)`
    animation: ${appear} 0.3s;
`;

export const Sports: React.FC<SportsProps> = ({isOpen, currentSport, onSelect, onCancel}) => {
    const intl = useIntl();
    const backend = useBackend();
    const [sport, setSport] = React.useState<PracticeSportModel>(
        currentSport || {
            id: '',
            key: '',
            frValue: '',
            enValue: ''
        },
    );

    const {data, error, loadingState, search, setSearch, moreBtn} = useLoadData<PracticeSportModel>((search, page, perPage, orderBy) => {
        return backend.searchSports({search, page, perPage});
    });

    React.useEffect(() => {
        if (currentSport) {
            setSport(currentSport);
        }
    }, [currentSport]);

    return (
        <SportsSection direction="y" spacing={10} height="100%" width="100%" padding="20px">
            <Section width="100%" spacing={10}>
                <SearchInput
                    grow={1}
                    placeholder={intl.formatMessage({id: 'app.sports.searchPlaceholder'})}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onBlur={() => {
                        amplitude.getInstance().logEvent('search_sport', {search: search, page: 'sports_library'});
                    }}
                />
                <Icon name="close" onClick={onCancel} />
            </Section>
            <Section grow={1} direction="y" scroll={true}>
                {sport.id !== '' && search === '' && (
                    <SportRow key={sport.id} selected={true} onSelect={onSelect} sport={sport} />
                )}
                {(loadingState === 'loading' || loadingState === 'loaded') &&
                    data
                        .filter(spt => sport.id === '' || search !== '' || sport.id !== spt.id)
                        .map((spt, i) => <SportRow key={spt.id} selected={false} onSelect={onSelect} sport={spt} />)}
                {loadingState === 'loading' && [0, 1, 2, 3, 4, 5].map(id => <LoadingSportRow key={id} />)}
                {loadingState === 'loaded' && moreBtn}
                {loadingState === 'error' && (
                    <EmptyState isError align="center" distribute="center" width="100%" height="100%" padding="20px">
                        <FormattedMessage id={`app.error.${error}`} />
                    </EmptyState>
                )}
            </Section>
        </SportsSection>
    );
};
