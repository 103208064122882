import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import amplitude from 'amplitude-js';
import { Grid } from '../../ui/Grid';
import styled from '@emotion/styled';
import { Section } from '../../ui/Section';
import { useLocale } from '../locale/Locale';
import { Select } from '../../components/Select';
import { SearchInput } from '../../widgets/Input';
import { useBackend } from '../backend/useBackend';
import { ErrorPage } from '../../widgets/ErrorPage';
import { Lightbox } from '../../ui/Lightbox/Lightbox';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadDataProps } from '../backend/useLoadData';
import { SelectableTags } from '../../components/Tags';
import { EmptyState } from '../../components/EmptyState';
import { LightboxCard } from '../../widgets/LightboxCard';
import { CreateTrainingDialog } from './CreateTrainingDialog';
import { PracticeTagModel } from '../backend/models/PracticeModel';
import { useLightboxState } from '../../ui/Lightbox/useLightboxState';
import type { TrainingRowModel } from '../backend/models/TrainingModel';
import { EmptyTrainingCard, NewTrainingRow, TrainingCard } from './TrainingCard';

interface TrainingsProps extends LoadDataProps<TrainingRowModel> {
    canCreate?: boolean;
    currentPage?: string;
}

const SearchSection = styled(Section)`
    background-color: ${props => props.theme.background};
    z-index: 1;
    margin-bottom: 0 !important; /* override spacing */
`;

export const Trainings: React.FC<TrainingsProps> = ({canCreate, currentPage, ...loadData}) => {
    const intl = useIntl();
    const locale = useLocale();
    const backend = useBackend();
    const isMobile = useMedia('(max-width: 600px)');

    const {data, loadingState, error, search, setSearch, orderBy, setOrderBy, filters, setFilters} = loadData;
    const [availableTags, setAvailableTags] = useState<PracticeTagModel[]>();
    const {isOpen, open, close} = useLightboxState();

    useEffect(() => {
        backend
            .listTags()
            .then(setAvailableTags)
            .catch(e => {
                console.log(e.message);
                if (e.message === 'userNotAuthenticated') {
                    localStorage.clear();
                    window.location.reload();
                }
            });
    }, [backend]);

    const computeFilters = (selectedKey: string, isSelected: boolean) => {
        setFilters(prevFilters => {
            if (isSelected) {
                if (!prevFilters.includes(selectedKey)) {
                    return [...prevFilters, selectedKey];
                }
            } else {
                return prevFilters.filter(key => key !== selectedKey);
            }
            return prevFilters;
        });
    };

    return (
        <>
            { availableTags && currentPage === "explore" &&
                <SelectableTags
                    selectedTags={filters}
                    tags={availableTags.map(tag => tag.key)}
                    labels={locale.locale === 'fr-FR' ? availableTags.map(tag => tag.frValue) : availableTags.map(tag => tag.enValue)}
                    onSelect={computeFilters}
                />
            }
            <SearchSection direction={isMobile ? 'y' : 'x'} sticky={true} spacing={10} padding="10px 20px 20px 20px">
                <SearchInput
                    grow={isMobile ? undefined : 1}
                    placeholder={intl.formatMessage({id: 'app.trainings.searchPlaceholder'})}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onBlur={ () => {
                        amplitude.getInstance().logEvent(`search_trainings_from_${currentPage}`, {search: search, page: currentPage});
                    }}
                />
                <Select
                    value={orderBy}
                    onChange={e => {
                        setOrderBy(e.target.value);
                        amplitude.getInstance().logEvent(`sort_trainings_from_${currentPage}`, {sort: e.target.value, page: currentPage});
                    }}
                >
                    <option value="RECENTLY_CREATED">{intl.formatMessage({id: 'app.training.RECENTLY_CREATED'})}</option>
                    <option value="RECENTLY_UPDATED">{intl.formatMessage({id: 'app.training.RECENTLY_UPDATED'})}</option>
                    <option value="MOST_PLAYED">{intl.formatMessage({id: 'app.training.MOST_PLAYED'})}</option>
                    <option value="MOST_BOOKMARKED">{intl.formatMessage({id: 'app.training.MOST_BOOKMARKED'})}</option>
                    <option value="MOST_DIFFICULT">{intl.formatMessage({id: 'app.training.MOST_DIFFICULT'})}</option>
                    <option value="LEAST_DIFFICULT">{intl.formatMessage({id: 'app.training.LEAST_DIFFICULT'})}</option>
                </Select>
            </SearchSection>
            <Grid limit={300} spacing={20} padding="0 20px">
                {canCreate &&
                    <NewTrainingRow
                        onClick={ () => {
                            open();
                            amplitude.getInstance().logEvent('click_on_create_new_training', {page: currentPage});
                        }}
                    />
                }
                {(loadingState === 'loaded' || (loadingState === 'loading' && data.length > 0)) &&
                    data.map((training, i) => <TrainingCard key={i} training={training} currentPage={currentPage || ''} />)}
                {loadingState === 'loading' && [0, 1, 2, 3, 4, 5].map(id => <EmptyTrainingCard key={id} />)}
            </Grid>
            {loadData.moreBtn}
            {loadingState === 'empty' && !canCreate && (
                <EmptyState align="center" distribute="center" padding="0 40px">
                    <FormattedMessage id={search === '' ? 'app.trainings.noTrainingYet' : 'app.trainings.noSearchResult'} />
                </EmptyState>
            )}
            {loadingState === 'error' && <ErrorPage error={error} />}
            <Lightbox isOpen={isOpen} onRequestClose={close}>
                <LightboxCard isOpen={isOpen} maxWidth="90%">
                    <CreateTrainingDialog onClose={close} />
                </LightboxCard>
            </Lightbox>
        </>
    );
};
