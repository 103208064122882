import React from 'react';

import {TextLayout} from '../../widgets/Text';
import styled from '@emotion/styled';
import {Section} from '../../ui/Section';
import {FormattedMessage, useIntl} from 'react-intl';
import {formatEffortMax, StepModel} from '../backend/models/TrainingModel';
import {useLightboxState} from '../../ui/Lightbox/useLightboxState';
import {Lightbox} from '../../ui/Lightbox/Lightbox';
import {LightboxCard} from '../../widgets/LightboxCard';
import {ExerciseShow} from './ExerciseShow';
import {Thumbnail} from '../../widgets/Thumbnail';
import amplitude from 'amplitude-js';

const InfoSection = styled(Section)`
    color: ${props => props.theme.subForeground};
    font-size: 0.9em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
interface StepRowProps {
    step: StepModel;
}

export const StepRow: React.FC<StepRowProps> = ({step}) => {
    const exercise = useLightboxState();
    const intl = useIntl();
    return (
        <Section spacing={10} width="100%" align="center">
            <Thumbnail
                onClick={() => {
                    exercise.open();
                    amplitude.getInstance().logEvent('check_exercise_explanation_from_training', {exerciseId: step!.exercise.id});
                }}
                width="90px"
                src={step!.exercise.playYoutubeId ? `https://img.youtube.com/vi/${step!.exercise.playYoutubeId}/mqdefault.jpg` : undefined}
            />
            <Section direction="y" align="start" distribute="center" spacing={5} grow={1}>
                <TextLayout
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        exercise.open();
                        amplitude.getInstance().logEvent('check_exercise_explanation_from_training', {exerciseId: step!.exercise.id});
                    }}
                    align="left"
                    weight="bold"
                    size={1.1}
                >
                    {step.exercise.name}
                </TextLayout>
                <InfoSection direction="x" spacing={5}>
                    <div>{formatEffortMax(intl, step)}</div>
                    <div>•</div>
                    {step.rest > 0 && (
                        <div>
                            <FormattedMessage id="app.training.REST" /> {step.rest} <FormattedMessage id="app.training.DURATION" />
                        </div>
                    )}
                    {step.rest === 0 && (
                        <div>
                            <FormattedMessage id="app.training.NO_REST" />
                        </div>
                    )}
                </InfoSection>
            </Section>
            <Lightbox isOpen={exercise.isOpen} onRequestClose={exercise.close}>
                <LightboxCard isOpen={exercise.isOpen} sheet={true} maxHeight="90%" width="90%" maxWidth="600px" animation="bottom">
                    <ExerciseShow exercise={step.exercise} onClose={exercise.close} />
                </LightboxCard>
            </Lightbox>
        </Section>
    );
};
