import React from 'react';
import styled from '@emotion/styled';
import {FormattedMessage} from 'react-intl';

import {Link} from '../../components/Link';
import {Section} from '../../ui/Section';
import {Thumbnail} from '../../widgets/Thumbnail';
import type {TrainingRowModel} from '../backend/models/TrainingModel';
import amplitude from 'amplitude-js';

const SubTitleSection = styled(Section)`
    color: ${props => props.theme.subForeground};
    flex-wrap: wrap;
    font-size: 0.9em;
    font-family: ${props => props.theme.title};
    padding-bottom: 5px;
`;

const StyledLink = styled(Link)`
    color: ${props => props.theme.subForeground};
    transition: all 0.3s;
    &:hover {
        color: ${props => props.theme.primary};
    }
`;

const AvatarThumbnail = styled(Thumbnail)`
    width: 20px;
    border-radius: 50%;
`;

export const TrainingSubtitle: React.FC<{training: TrainingRowModel, currentPage: String}> = ({training, currentPage}) => {
    return (
        <SubTitleSection align="center" spacing={5}>
            <AvatarThumbnail kind="avatar" src={training.author.avatar} />
            <StyledLink
                to={`/user/${training.author.id}`}
                onClick={() => {amplitude.getInstance().logEvent(`check_user_profile_from_training_in_${currentPage}`, {page: currentPage, trainingId: training.id, profileId: training.author.id});}}
            >
                    {training.author.fullname || training.author.username.toLowerCase()}
            </StyledLink>
            <span>•</span>
            <span>
                <FormattedMessage id={`app.training.difficulty.${training.difficulty}`} />
            </span>
            <span>•</span>
            <span>{Math.round(training.duration / 60) || 0} min</span>
        </SubTitleSection>
    );
};
