import {useNavigate} from 'react-router';
import {useCallback} from 'react';

import {useBackend} from '../backend/useBackend';

export const useGoHome = () => {
    const navigate = useNavigate();
    const backend = useBackend();
    const landingPage = process.env.REACT_APP_FRONT_URI!;
    return useCallback(() => {
        if (backend.authUser) {
            navigate('/home');
        } else {
            window.location.href = landingPage;
        }
    }, [backend, navigate, landingPage]);
};
