import React from 'react';
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

const contentAnim = keyframes`
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
`;

const Content = styled.div<{size?: number; color?: string}>`
    width: ${props => (props.size || 80) * 1.3}px;
    height: ${props => (props.size || 80) * 1.3}px;
    display: flex;
    justify-content: space-between;
    .dot {
        margin-top: 37.5%;
        width: 25%;
        height: 25%;
        animation: ${contentAnim} 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
        border-radius: 50%;
        background-color: ${props => props.color || 'black'};
    }
    .dot:nth-of-type(1) {
        animation-delay: -0.3s;
    }
    .dot:nth-of-type(2) {
        animation-delay: -0.15s;
    }
`;

export const DotsSpinner: React.FC<{size?: number; color?: string}> = ({color, size}) => {
    return (
        <Content color={color} size={size}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </Content>
    );
};
