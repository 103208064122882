import styled from '@emotion/styled';
import React from 'react';

import {Icon} from '../../ui/Icon';
import {Section} from '../../ui/Section';
import {Draggable} from 'react-beautiful-dnd';
import {DragHandle} from '../../widgets/DragHandle';
import {FormattedMessage} from 'react-intl';
import {Popover, usePopoverState} from '../../widgets/Popover';
import {Menu, MenuItem} from '../../widgets/Menu';
import {TrainingRowModel} from '../backend/models/TrainingModel';
import {TextLayout} from '../../widgets/Text';
import { SelectQuestTrainingThumbnail } from './SelectQuestTrainingThumbnail';

const TrainingLayout = styled(Section)<{active: boolean; isDragging: boolean}>`
    color: ${props => props.theme.subForeground};
    background: ${props => props.theme.background};
    border-radius: ${props => props.theme.borderRadius};
`;

const InfoSection = styled(Section)`
    color: ${props => props.theme.subForeground};
    font-size: 0.9em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface EditQuestTrainingRowProps {
    training: TrainingRowModel;
    index: number;
    isDragging: boolean;
    onActive?: () => void;
    onDesactive?: () => void;
    onRemove: (training: TrainingRowModel) => void;
}

export const EditQuestTrainingRow: React.FC<EditQuestTrainingRowProps> = ({index, training, isDragging, onActive, onDesactive, onRemove}) => {
    const [active, setActive] = React.useState<boolean>(false);
    const {isOpen, setIsOpen} = usePopoverState();

    const handleActive = () => {
        setActive(true);
        onActive && onActive();
    };
    const handleDesactive = () => {
        setActive(false);
        onDesactive && onDesactive();
    };
    const handleRemove = React.useCallback(() => {
        onRemove(training);
        setIsOpen(false);
    }, [onRemove, training, setIsOpen]);
    return (
        <Draggable key={training.id!.toString()} draggableId={training.id!.toString()} index={index}>
            {provided => (
                <TrainingLayout
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    active={active}
                    isDragging={isDragging}
                    spacing={10}
                    align="center"
                    onMouseEnter={handleActive}
                    onMouseLeave={handleDesactive}
                >
                    <div {...provided.dragHandleProps}>
                        <DragHandle />
                    </div>
                    <div>
                        <SelectQuestTrainingThumbnail thumbnail={training.thumbnail} />
                    </div>
                    <Section direction="y" align="start" distribute="center" spacing={5} grow={1}>
                        <TextLayout
                            style={{cursor: 'pointer'}}
                            align="left"
                            weight="bold"
                            size={1.1}
                        >
                            {training.title}
                        </TextLayout>
                        <InfoSection direction="x" spacing={5}>
                            <span>{Math.round(training.duration / 60) || 0} min</span>
                        </InfoSection>
                    </Section>
                    <Popover isOpen={isOpen} onToggle={setIsOpen} node={<Icon name="contextMenu" />}>
                        <Menu direction="y">
                            <MenuItem selected={false} onClick={handleRemove}>
                                <FormattedMessage id="app.training.remove" />
                            </MenuItem>
                        </Menu>
                    </Popover>
                </TrainingLayout>
            )}
        </Draggable>
    );
};
