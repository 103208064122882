import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import {useMedia} from 'react-use';
import {InvisibleLink} from '../../components/Link';
import {Section} from '../../ui/Section';
import {Thumbnail} from '../../widgets/Thumbnail';
import {ActivityModel} from '../backend/models/ActivityModel';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';

import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LoadingPart } from '../../widgets/LoadingPart';
import { Icon } from '../../ui/Icon';
import { useNavigate } from 'react-router-dom';

dayjs.extend(duration);
dayjs.extend(relativeTime, {rounding: Math.ceil});
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

const SubTitleSection = styled(Section)`
    font-family: ${props => props.theme.title};
    color: ${props => props.theme.subForeground};
`;

const HistoryRowTitle = styled.span`
    overflow: hidden;
    max-width: calc(100% - 100px);
    font-family: ${props => props.theme.title};
    font-size: 1.1em;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const EditActivityIcon = styled(Icon)`
    position: absolute;
    right: 50px;
    top: 12px;
`

export const HistoryRow: React.FC<{activity: ActivityModel}> = ({activity}) => {
    const match600 = useMedia('(max-width: 600px)');
    const doneDate = React.useMemo(() => dayjs(activity.date).format('L'), [activity.date]);
    const navigate = useNavigate();

    const handleEditActivity = React.useCallback(() => {
        navigate('/activities/' + (activity.id));
    }, [navigate, activity]);

    return (
        <Section key={activity.id} direction="x" spacing={10}>
            <InvisibleLink to={`/trainings/${activity.training?.id}`} disabled={activity.training?.id === undefined}>
                <Thumbnail width={match600 ? '100px' : '120px'} src={activity.training?.thumbnail} />
            </InvisibleLink>
            <Section width="80%" direction="y" spacing={5}>
                <HistoryRowTitle>
                <InvisibleLink to={`/trainings/${activity.training?.id}`} disabled={activity.training?.id === undefined}>
                    {activity.title ? activity.title : activity.training?.title}
                </InvisibleLink>
                </HistoryRowTitle>
                <InvisibleLink to={`/trainings/${activity.training?.id}`} disabled={activity.training?.id === undefined}>
                    <SubTitleSection spacing={5}>
                        <span>
                            {doneDate}
                            <span> • </span>
                            {Math.round(activity.duration / 60)}min
                        </span>
                    </SubTitleSection>
                </InvisibleLink>
            </Section>
            <div style={{position: 'relative'}}>
                { activity.manual &&
                    <EditActivityIcon
                        name="go"
                        onClick={handleEditActivity}
                    />
                }
            </div>
        </Section>
    );
};


export const EmptyHistoryRow: React.FC = () => {
    const match600 = useMedia('(max-width: 600px)');
    return (
        <Section direction="x" spacing={10}>
            <Thumbnail width={match600 ? '100px' : '120px'} kind="empty" ></Thumbnail>
            <Section width="100%" direction="y" spacing={5}>
                <LoadingPart height="20px" width="calc(100% - 100px)" />
                <LoadingPart height="15px" width="50%" />
            </Section>
        </Section>
    );
};
