import React from 'react';
import {Icon} from '../../ui/Icon';

import toast from 'react-hot-toast';

type ToastType = 'message' | 'error' | 'success';


export const notify = (message: string, type?: ToastType) => {
    toast(
        (t) => (
            <>
                <span style={{marginRight: '10px'}}>
                    {message}
                </span>
                <Icon width={15} name="close" onClick={() => toast.dismiss(t.id)} />
            </>
        ),
        {
            duration: 4000,
            position: 'bottom-left',
            style: {
                color: type === 'error' ? 'white' : '#222222',
                backgroundColor: (type === 'error' ? '#EB586A' : type === 'success' ? '#65FFBE' : '#65FFBE'),
                fontFamily: "'centra_no2bold', sans-serif",
                fontSize: '1em',
                padding: '10px 15px',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
    );
};
