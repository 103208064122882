import React from 'react';
import styled from '@emotion/styled';
import {transparentize} from 'polished';
import {FormattedMessage} from 'react-intl';

import {Icon} from '../../ui/Icon';
import {Section} from '../../ui/Section';
import {Thumbnail} from '../../widgets/Thumbnail';
import {TextLayout} from '../../widgets/Text';
import {LoadingPart} from '../../widgets/LoadingPart';
import {InvisibleLink} from '../../components/Link';
import {TrainingBookmark} from './TrainingBookmark';
import {TrainingSubtitle} from './TrainingSubtitle';
import type {TrainingRowModel} from '../backend/models/TrainingModel';
import {keyframes} from '@emotion/react';
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';

const visibilityIcons: Record<string, React.ReactNode> = {
    PUBLIC: null,
    PRIVATE: <Icon className="icon" name="private" />,
    HIDDEN: <Icon className="icon" name="hidden" />,
};

const animatedGradient = keyframes`
	0% { background-position: 0% 0%; }
	20% { background-position: 99% 0%; }
	40% { background-position: 0% 0%; }
	100% { background-position: 0% 0%; }
`;

export const PremiumLabel = styled.div`
    color: #222222;
    animation: ${animatedGradient} 6s ease infinite;
    background-size: 200% 100%;
    background-image: linear-gradient(45deg, #e4b65d, #f9e999, #e4b65d, #f9e999);
    background-position: 0% 0%;
    border-radius: 5px;
    font-family: ${props => props.theme.title};
    position: absolute;
    bottom: 10px;
    left: 15px;
    font-size: 0.8em;
    padding: 3px 5px;
`;

const QuestLabel = styled.div`
    color: #ffffff;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-family: ${props => props.theme.title};
    position: absolute;
    bottom: 10px;
    left: 70px;
    font-size: 0.8em;
    padding: 3px 5px;
`;

const TrainingTitle = styled.div`
    font-family: ${props => props.theme.title};
    font-size: 1.2em;
    color: ${props => props.theme.foreground};
    flex: 1;
    flex-wrap: nowrap;
`;

const TrainingThumbnail = styled.div<{hasThumbnail: boolean}>`
    position: relative;
    transition: 0.3s transform;
    color: ${props => (props.hasThumbnail ? 'white' : props.theme.subForeground)};
    &:hover {
        transform: scale(0.95);
    }
    .icon {
        position: absolute;
        top: 10px;
        right: 15px;
    }
    .icon,
    .playsIcon {
        [fill='black'] {
            fill: ${props => (props.hasThumbnail ? 'white' : props.theme.subForeground)};
        }
        [stroke='black'] {
            stroke: ${props => (props.hasThumbnail ? 'white' : props.theme.subForeground)};
        }
        filter: ${props => (props.hasThumbnail ? 'drop-shadow(0px 0 5px rgb(0 0 0 / 0.4))' : undefined)};
    }
    .playsText {
        color: ${props => (props.hasThumbnail ? 'white' : props.theme.subForeground)};
        text-shadow: ${props => (props.hasThumbnail ? '0 0 5px rgba(0, 0, 0, 0.4)' : undefined)};
        overflow: visible;
    }
    .playsCount {
        position: absolute;
        top: 10px;
        left: 15px;
    }
`;

interface TrainingCardProps {
    training: TrainingRowModel;
    isReadable?: boolean;
    currentPage: String;
}

export const TrainingCard: React.FC<TrainingCardProps> = ({training, isReadable = true, currentPage}) => {
    const trainingUrl = React.useMemo(() => `/trainings/${training.id}`, [training.id]);
    return (
        <Section direction="y" width="100%">
            {isReadable ? (
                <InvisibleLink to={trainingUrl} onClick={() => {
                    amplitude.getInstance().logEvent(`check_training_from_${currentPage}`, {trainingId: training.id, page: currentPage});
                    ReactGA.event('check_training', {trainingId: training.id});
                }}>
                    <TrainingThumbnail hasThumbnail={training.thumbnail !== ''}>
                        <Thumbnail src={training.thumbnail} darkGradient={training.thumbnail !== ''} />
                        {training.price ? <PremiumLabel>
                            {training.price.value}{training.price.currency === 'EUR' ? '€' : '$'}
                            {training.price.period && <FormattedMessage
                                id={`app.payment.${training.price.period}`}
                                values={{n: training.price.interval === 1 ? '' : training.price.interval}}
                            />}
                        </PremiumLabel> : null}
                        {training.quest ? <QuestLabel>{training.quest.title}</QuestLabel> : null}
                        {visibilityIcons[training.visibility]}
                        <Section className="playsCount" spacing={5}>
                            <Icon className="playsIcon" name="play" />
                            <TextLayout className="playsText" weight="bold" size={0.9}>
                                {training.playsCount}
                            </TextLayout>
                        </Section>
                    </TrainingThumbnail>
                </InvisibleLink>
            ) : (
                <Thumbnail src={training.thumbnail} />
            )}

            <Section direction="y" spacing={5} padding="10px 20px">
                <Section align="center" distribute="between" style={{overflow: 'hidden'}} spacing={10}>
                    <InvisibleLink to={trainingUrl}>
                        <TrainingTitle>{training.title}</TrainingTitle>
                    </InvisibleLink>
                    <TrainingBookmark training={training} currentPage={currentPage} />
                </Section>
                <TrainingSubtitle training={training} currentPage={currentPage} />
            </Section>
        </Section>
    );
};

const NewThumbnail = styled(Section)`
    position: relative;
    transition: 0.3s all;
    cursor: pointer;
    &:hover {
        transform: scale(0.95);
    }
    svg {
        position: absolute;
        overflow: visible;
        circle {
            transition: all 0.3s;
            transform-origin: 50% 50%;
        }
        path {
            transition: all 0.3s;
            transform-origin: 50% 50%;
        }
    }
    [fill='white'] {
        fill: ${props => props.theme.subBackground};
    }
    [fill='#AAAAAA'] {
        fill: ${props => props.theme.subForeground};
    }
    &:hover {
        [fill='white'] {
            fill: ${props => transparentize(0.8, props.theme.primary)};
        }
        [fill='#AAAAAA'] {
            fill: ${props => props.theme.primary};
        }
        circle {
            transform: scale(1.1);
        }
        path {
            transform: scale(1.2);
        }
    }
`;

export const NewTrainingRow: React.FC<{onClick: () => void}> = ({onClick}) => {
    return (
        <Section direction="y">
            <NewThumbnail align="center" distribute="center" onClick={onClick}>
                <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="55" cy="55" r="55" fill="white" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M55 29C52.2386 29 50 31.2386 50 34V51H33C30.2386 51 28 53.2386 28 56C28 58.7614 30.2386 61 33 61H50V78C50 80.7614 52.2386 83 55 83C57.7614 83 60 80.7614 60 78V61H77C79.7614 61 82 58.7614 82 56C82 53.2386 79.7614 51 77 51H60V34C60 31.2386 57.7614 29 55 29Z"
                        fill="#AAAAAA"
                    />
                </svg>
                <Thumbnail width="100%" kind="empty" />
            </NewThumbnail>
            <Section direction="y" padding="10px 20px">
                <TrainingTitle>
                    <FormattedMessage id="app.training.newTraining" />
                </TrainingTitle>
            </Section>
        </Section>
    );
};

export const EmptyTrainingCard: React.FC = () => {
    return (
        <Section direction="y">
            <Thumbnail isLoading={true} kind="empty" />
            <Section direction="y" spacing={10} padding="10px">
                <LoadingPart height="20px" width="80%" />
                <LoadingPart height="15px" width="70%" />
            </Section>
        </Section>
    );
};
