import styled from '@emotion/styled';
import React from 'react';
import {ZLayout} from '../../components/Layout';
import {Section} from '../../ui/Section';

import {ReactComponent as SearchIcon} from './assets/search.svg';

const ThumbnailLayout = styled(ZLayout)<{width?: number}>`
    width: 90px;
    height: ${(90 * 9) / 16}px;
    @media (max-width: 500px) {
        width: 70px;
        height: ${(70 * 9) / 16}px;
    }
`;

const OverlayLayout = styled(Section)<{isSearching?: boolean}>`
    background: ${props => (props.isSearching ? 'rgba(0, 0, 0, 0.8)' : 'transparent')};
    border-radius: ${props => props.theme.borderRadius};
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    svg {
        transition: transform 0.2s;
        transform: ${props => (props.isSearching ? 'scale(1)' : 'scale(0.8)')};
    }
    svg * {
        fill: ${props => (props.isSearching ? 'white' : 'transparent')};
    }
`;

const Thumbnail = styled.img<{width?: number}>`
    width: 90px;
    height: ${(90 * 9) / 16}px;
    object-fit: cover;
    border-radius: 10px;
    @media (max-width: 500px) {
        width: 70px;
        height: ${(70 * 9) / 16}px;
    }
`;

const DefaultThumbnail = styled.div<{width?: number}>`
    width: 90px;
    height: ${(90 * 9) / 16}px;
    background-color: ${props => props.theme.subBackground};
    border-radius: 10px;
    @media (max-width: 500px) {
        width: 70px;
        height: ${(70 * 9) / 16}px;
    }
`;

interface ExerciseThumbnailProps {
    width?: number;
    youtubeId?: string;
    isSearching?: boolean;
}

export const ExerciseThumbnail: React.FC<ExerciseThumbnailProps> = ({youtubeId, width, isSearching}) => {
    return (
        <div className="thumbnail">
            <ThumbnailLayout width={width}>
                {youtubeId ? (
                    <Thumbnail width={width} src={`https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`} alt="" />
                ) : (
                    <DefaultThumbnail width={width} />
                )}
                <OverlayLayout width="100%" isSearching={isSearching}>
                    <SearchIcon />
                </OverlayLayout>
            </ThumbnailLayout>
        </div>
    );
};
